import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { DELETE_API, GET_API, POST_API } from "../../Services/api";
import Spinner from "../Spinner";
import AllowanceListItems from "./AllowanceListItems";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Delete from "../../Assets/Icons/Delete.svg";

function EmployeeAllowance({ id }) {
  const listId = id;
  const [isOpen, setIsOpen] = useState(false);
  const [allowanceTypeList, setallowanceTypeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [employeeAllowanceList, setEmployeeAllowanceList] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [EmployeeAllowance, setEmployeeAllowance] = useState([
    {
      allowance_type_id: "",
      in_compliance: false,
      calculation_method: "",
      amount: "",
    },
  ]);

  useEffect(() => {
    getAllowanceTypeList();
    getEmployeeAllowanceList();
  }, []);

  const getEmployeeAllowanceList = async () => {
    try {
      setIsEmpLoading(true);
      const employeeAllowanceList = await GET_API(
        `/empAllowanceDetailsList/${listId}`
      );
      if (employeeAllowanceList?.data?.status === 200) {
        setEmployeeDetails(employeeAllowanceList?.data?.employee_details);
        setEmployeeAllowanceList(employeeAllowanceList?.data?.data);
        setIsEmpLoading(false);
      }
    } catch (error) {
      setIsEmpLoading(false);
      alertify.error("Failed to get Allowance Type List...");
    }
  };

  const getAllowanceTypeList = async () => {
    try {
      setLoading(true);
      const allowance_type_list = await GET_API("/allowanceTypeDropdown");
      if (allowance_type_list?.status === 200) {
        setLoading(false);
        setallowanceTypeList(allowance_type_list.data?.data);
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const addMoreList = () => {
    const newList = {
      allowance_type_id: "",
      in_compliance: false,
      calculation_method: "",
      amount: "",
    };
    const newEmployeeAllowance = [...EmployeeAllowance, newList];
    setEmployeeAllowance(newEmployeeAllowance);
  };

  const submitList = async () => {
    const keysToCheck = ["allowance_type_id", "amount", "calculation_method"];
    const isValid = EmployeeAllowance.some((obj) =>
      validation(obj, keysToCheck)
    );
    if (!isValid) {
      setButtonDisabled(true);
      try {
        setLoading(true);
        const addEmployeeAllowance = await POST_API(
          `/empAllowanceDetailsAdd/${listId}`,
          { allowanceDetails: EmployeeAllowance }
        );
        setEmployeeAllowance([
          {
            allowance_type_id: "",
            in_compliance: false,
            calculation_method: "",
            amount: "",
          },
        ]);
        setButtonDisabled(false);
        alertify.success(addEmployeeAllowance?.data?.message);
        setLoading(false);
        getEmployeeAllowanceList();
        // navigate(`/employee/allowance/${listId}`);
      } catch (e) {
        setButtonDisabled(false);
        setLoading(false);
        alertify.error(e.response.data.message);
      }
    } else {
      handleModal();
      // alertify.warning("Please Enter/Select all the Mandatory fields...");
    }
    return;
  };

  const validation = (obj, key) => {
    return key.some((key) => {
      const value = obj[key];
      return value === null || value === undefined || value === "";
    });
  };
  const deleteList = (index) => {
    const arrList = [...EmployeeAllowance];
    arrList.pop(index);
    setEmployeeAllowance(arrList);
  };

  const deleteListRow = async (rowID) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Allowance..?",
        async function () {
          if (rowID) {
            setLoading(true);
            const deleteList = await DELETE_API(
              `/empAllowanceDetailsDelete/${rowID}`
            );
            if (deleteList?.data?.status === 200) {
              alertify?.success(deleteList?.data?.message);
              getEmployeeAllowanceList();
              setLoading(false);
              return;
            }
            setLoading(false);
            alertify.error("Failed To Delete Allowance...");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const calculationMethod = [
    { value: "monthly", label: "Monthly" },
    { value: "daily", label: "Daily" },
  ];

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  if (loading || isEmpLoading) {
    return <Spinner />;
  }

  const errorModal = () => {
    return (
      <div>
        <Dialog
          className="testing"
          open={isOpen}
          onClose={handleModal}
          disableBackdropClick={false}
          PaperProps={{
            style: {
              minWidth: "400px",
              minHeight: "220px",
            },
          }}
        >
          <DialogTitle></DialogTitle>
          <DialogContent className="text-center">
            <CancelOutlinedIcon className="text-[#ef4444] text-7xl emp-allowance-error" />
            <p className="mt-4">
              Please Enter/Select all the Mandatory fields...
            </p>
          </DialogContent>
          <DialogActions className="text-center">
            <button
              type="button"
              className="reset-button"
              onClick={handleModal}
            >
              <span className="reset-button-label">Close</span>
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <h1 className="heading-text pt-3">Allowance</h1>
      <div className="card-wrapper">
        {EmployeeAllowance.map((list, i) => {
          return (
            <AllowanceListItems
              index={i}
              allowanceTypeList={allowanceTypeList}
              addMoreList={addMoreList}
              state={EmployeeAllowance}
              setState={setEmployeeAllowance}
              deleteList={deleteList}
              page="add"
            />
          );
        })}

        <div className="p-4 flex justify-end border-b-[1px] border-b-[#D9D9D9]">
          <div className="add-new-button-block" onClick={() => submitList()}>
            <button className="add-new-button">
              <span>Submit</span>
            </button>
          </div>
        </div>
        {employeeAllowanceList?.length > 0 &&
          employeeAllowanceList?.map((item, i) => {
            return (
              <div className="p-4 grid grid-cols-5 gap-5">
                <div className="input-block" style={{ width: "100%" }}>
                  <label className="add-new-input-label">
                    Allowance Type <span className="required-symbol">*</span>
                  </label>
                  <select
                    className="input-field"
                    // onChange={(e) => handleChange(e)}
                    name="allowance_type_id"
                  >
                    <option value="">Please Select</option>
                    {allowanceTypeList?.map((item) => {
                      return (
                        <option
                          value={item?.id}
                          selected={
                            employeeAllowanceList[i]["allowance_type_id"] ===
                            item?.id
                          }
                        >
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="input-block" style={{ width: "100%" }}>
                  <label className="add-new-input-label">
                    IN COMPLIANCE <span className="required-symbol">*</span>
                  </label>
                  <input
                    type="checkbox"
                    // onClick={(e) => handleChange(e)}
                    name="in_compliance"
                    checked={
                      employeeAllowanceList[i]["in_compliance"] === 1 ||
                      employeeAllowanceList[i]["in_compliance"] === true
                    }
                  />
                </div>
                <div className="input-block" style={{ width: "100%" }}>
                  <label className="add-new-input-label">
                    CALCULATION METHOD{" "}
                    <span className="required-symbol">*</span>
                  </label>
                  <select
                    className="input-field"
                    // onChange={(e) => handleChange(e)}
                    name="calculation_method"
                    // disabled={disabled}
                  >
                    <option value="">Please Select</option>
                    {calculationMethod.map((method) => {
                      return (
                        <option
                          value={method.value}
                          selected={
                            employeeAllowanceList[i]["calculation_method"] ===
                            method?.value
                          }
                        >
                          {method.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="input-block" style={{ width: "100%" }}>
                  <label className="add-new-input-label">
                    AMOUNT <span className="required-symbol">*</span>
                  </label>
                  <input
                    type="number"
                    name="amount"
                    placeholder="Enter Amount"
                    className="input-field"
                    value={employeeAllowanceList[i]["amount"]}
                  />
                </div>
                <button
                  onClick={() => {
                    deleteListRow(item?.id);
                  }}
                >
                  <img src={Delete} alt="" className="w-6 h-6" />
                </button>
              </div>
            );
          })}
      </div>

      {errorModal()}
    </div>
  );
}

export default EmployeeAllowance;
