import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GET_API, DELETE_API } from "../../../Services/api";
import ShiftTable from "../../DataTable/ShiftTable";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

function ShiftList() {
  const navigate = useNavigate();
  const [shiftList, setShiftList] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [pagination, setPagination] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("");

  const showShiftDetails = (listDetail) => {
    navigate("/master/hrms/shift/details", {
      state: {
        list: listDetail,
      },
    });
  };

  const navigateEditPage = (listDetail) => {
    navigate("/master/hrms/shift/edit", {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s.no", label: "S.No", minWidth: 100 },
    { id: "shift_name", label: "Shift Name", minWidth: 180 },
    { id: "time", label: "Time", minWidth: 150 },
    { id: "created_at", label: "Created at", minWidth: 100 },
    { id: "more_icon", label: "", minWidth: 100 },
  ];

  const getShiftList = async (page, size, search) => {
    try {
      setLoading(true);
      const shiftList = await GET_API(
        `/shiftsList?page=${page}&size=${size}&search=${search}`
      );
      if (shiftList?.status === 200) {
        if (
          shiftList?.data?.data?.current_page > shiftList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setShiftList(shiftList?.data?.data?.data);
        setLastPage(shiftList?.data?.data?.last_page);
        setFilteredBranch(shiftList?.data?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setShiftList([]);
      setLastPage("");
      setFilteredBranch([]);
    }
  };

  const isPageNotExit = () => {
    setCurrentPage(1);
    getShiftList(1, pageSize, searchKey);
  };

  useEffect(() => {
    getShiftList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Shift..?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteShifts/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getShiftList(currentPage, pageSize, searchKey);
              return;
            }
            alertify.error("Failed To Delete Shift");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Shift List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => setSearchKey(e?.target?.value)}
                style={{ outline: "none", border: "none" }}
                value={searchKey}
              />

              <img
                src={Search}
                alt=""
                className="cursor-pointer"
                onClick={() => {
                  getShiftList(currentPage, pageSize, searchKey);
                }}
              />
            </div>
            <div className="add-new-button-block">
              <Link to="/master/hrms/shift/create" className="add-new-button">
                <span>Add New</span>
                <img src={ColorAdd} alt="add" />
              </Link>
            </div>
          </div>

          {/**************** * Table ********************/}

          <ShiftTable
            list={filteredBranch}
            columns={columns}
            showBranchDetails={showShiftDetails}
            icon={More}
            multiple={true}
            showDetails={showShiftDetails}
            editPage={navigateEditPage}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            pagination={pagination}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default ShiftList;
