import React, { useState, useEffect } from "react";
import BasicInfo from "../../Employee/DetailsPage/BasicInfo";
import PositionInfo from "../../Employee/DetailsPage/PositionInfo";
import BankInfo from "../../Employee/DetailsPage/BankInfo";
import SalaryInfo from "../../Employee/DetailsPage/SalaryInfo";
import EducationalInfo from "../../Employee/DetailsPage/EducationalInfo.js";
import PersonalInfo from "../../Employee/DetailsPage/PersonalInfo";
import FoodAllowance from "../../Employee/DetailsPage/FoodAllowance.js";
import Allowance from "../../Employee/DetailsPage/Allowance.js";
import { GET_API } from "../../../Services/api";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import { useParams } from "react-router-dom";

function EmployeeDetails({ listDetails }) {
  const { id } = useParams();
  const [value, setValue] = useState("position_info");
  const [emoloyeeBreakDetails, setEmoloyeeBreakDetails] = useState([]);
  const [employeeDetails, setemployeeDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleTabChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getEmployeeBreakDetails();
    getEmployeeDetails();
  }, []);

  const getEmployeeBreakDetails = async () => {
    setIsLoading(true);
    try {
      const employeeBreakList = await GET_API(
        `/employeesView?employee_id=${listDetails?.employee_Id}`
      );
      if (employeeBreakList?.data?.status === 200) {
        setIsLoading(false);
        setEmoloyeeBreakDetails(employeeBreakList?.data?.data);
      }
    } catch (error) {
      alertify?.error("Failed to get employement Details");
    }
  };

  const getEmployeeDetails = async () => {
    setIsLoading(true);
    try {
      const employeeDetails = await GET_API(
        `/employeeGet/${listDetails?.employee_Id}`
      );
      if (employeeDetails?.data?.status === 200) {
        setIsLoading(false);
        setemployeeDetails(employeeDetails?.data?.data);
      }
    } catch (error) {
      alertify?.error("Failed to get employement Details");
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div>
        <PositionInfo
          listDetails={employeeDetails}
          breakDetails={emoloyeeBreakDetails}
          isEditAndView={false}
          nextTab={handleTabChange}
        />
      </div>

      <div>
        <BasicInfo
          listDetails={employeeDetails}
          isEditAndView={false}
          nextTab={handleTabChange}
        />
      </div>

      <div>
        <BankInfo
          listDetails={employeeDetails}
          isEditAndView={false}
          nextTab={handleTabChange}
          page="report"
        />
      </div>

      <div>
        <SalaryInfo
          listDetails={employeeDetails}
          isEditAndView={false}
          nextTab={handleTabChange}
          page="report"
        />
      </div>

      <div>
        <PersonalInfo
          listDetails={employeeDetails}
          isEditAndView={false}
          nextTab={handleTabChange}
        />
      </div>

      <div>
        <EducationalInfo
          listDetails={employeeDetails}
          isEditAndView={false}
          nextTab={handleTabChange}
        />
      </div>
      <div className="border-t-[1px] border-t-[#D9D9D9]">
        <div
          className="p-3 w-3/12 bg-primary-color text-center"
          style={{ borderRadius: "0px 0px 100px 0px" }}
        >
          <span className="text-white-color font-karla font-base ">
            Food Allowance Info
          </span>
        </div>
        <FoodAllowance
          id={id}
          listDetails={employeeDetails}
          isEditAndView={false}
          nextTab={handleTabChange}
        />
      </div>
      <div className="border-t-[1px] border-t-[#D9D9D9]">
        <div
          className="p-3 w-3/12 bg-primary-color text-center"
          style={{ borderRadius: "0px 0px 100px 0px" }}
        >
          <span className="text-white-color font-karla font-base ">
            Allowance Info
          </span>
        </div>
        <Allowance
          id={id}
          listDetails={employeeDetails}
          isEditAndView={false}
          nextTab={handleTabChange}
        />
      </div>

    </>
  );
}

export default EmployeeDetails;
