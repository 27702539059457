import React from "react";
import { Link, useLocation } from "react-router-dom";

function ExitReportDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const fathers_name = listDetails?.fathers_name
    ? listDetails?.fathers_name
    : "";
  const Details = [
    {
      label: "Employee Name",
      value: listDetails?.EmployeeName + " " + fathers_name,
    },
    {
      label: "Employee Code",
      value: listDetails?.employeeCode,
    },

    {
      label: "Department Name",
      value: listDetails?.department_name,
    },
    {
      label: "Designation Name",
      value: listDetails?.designation_name,
    },
    {
      label: "Employee Type Name",
      value: listDetails?.employeeTypeName,
    },
    {
      label: "Branch",
      value: listDetails?.branchName,
    },
    {
      label: "Gender",
      value: listDetails?.Gender,
    },
    {
      label: "Total Working Hours",
      value: listDetails?.EmployeeTotalworkingHours,
    },
    {
      label: "Employee out time",
      value: listDetails?.EmployeeOutTime,
    },
    {
      label: "Employee Log Date",
      value: listDetails?.EmployeeLogDate,
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/report/exit" className="in-active">
              Exit Report
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Exit Report Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
        <h4 className="card-header">Employee Exit Report Details</h4>
          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExitReportDetails;
