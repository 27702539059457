import Joi from "joi";
class POValidation {
  /* validators */
  stringValidator(name, min, max, length, specialCharacter, required) {
    let stringSchema = Joi.string()

      .min(min || 0)
      .max(max || 150);

    if (required) {
      stringSchema = stringSchema.required();
    } else {
      stringSchema = stringSchema.allow("", null);
    }
    if (specialCharacter) {
      stringSchema = stringSchema.pattern(
        new RegExp("^[a-zA-Z][a-zA-Z-' ']*$")
      );
    }

    if (length > 0) {
      stringSchema = stringSchema.length(length);
    }

    return stringSchema.messages({
      "string.empty": `Please enter "${name}"`,
      "string.base": `Enter valid ${name}`,
      "string.max": `${name} should not exceed ${max || 150} characters`,
      "string.min": `${name} should be at least ${min || 0} characters`,
      "string.length": `${name} length must be ${length || 0} characters long`,
      "string.pattern.base": `Please enter valid ${name}`,
    });
  }

  alphanNumeric(name, min, max, length, specialCharacter, required) {
    let stringSchema = Joi.string()

      .min(min || 0)
      .max(max || 150);

    if (required) {
      stringSchema = stringSchema.required();
    } else {
      stringSchema = stringSchema.allow("", null);
    }
    if (specialCharacter) {
      stringSchema = stringSchema.pattern(
        new RegExp("^[a-zA-Z0-9][a-zA-Z0-9-' ']*$")
      );
    }

    if (length > 0) {
      stringSchema = stringSchema.length(length);
    }

    return stringSchema.messages({
      "string.empty": `Please enter "${name}"`,
      "string.base": `Enter valid ${name}`,
      "string.max": `${name} should not exceed ${max || 150} characters`,
      "string.min": `${name} should be at least ${min || 0} characters`,
      "string.length": `${name} length must be ${length || 0} characters long`,
      "string.pattern.base": `Please enter valid ${name}`,
    });
  }
  numberValidator(name, min, max, required) {
    let numberSchema = Joi.number().integer();
    if (min > 0) {
      numberSchema = numberSchema.min(min);
    }
    if (max > 0) {
      numberSchema = numberSchema.max(max);
    }

    if (required) {
      numberSchema = numberSchema.required();
    } else {
      numberSchema = numberSchema.allow("", null);
    }

    return numberSchema.messages({
      "number.empty": `Please enter "${name}"`,
      "number.base": `Enter valid ${name}`,
      "number.max": `${name} should not exceed ${max || 150} characters`,
      "number.min": `${name} should be at least ${min || 0} characters`,
      "number.pattern.base": `Please enter valid ${name}`,
    });
  }

  emailValidator(required) {
    let emailSchema = Joi.string()
      .email({
        minDomainSegments: 2,
        tlds: { allow: false },
      })
      .label("Email");

    if (required) {
      emailSchema = emailSchema.required();
    } else {
      emailSchema = emailSchema.allow("", null);
    }

    return emailSchema.messages({
      "string.pattern.base": `Please Enter valid "Email ID"`,
      "string.email": "Invalid Email format.",
    });
  }

  selectValidator(name, required) {
    let numberSchema = Joi.number();
    if (required) {
      numberSchema = numberSchema.required();
    } else {
      numberSchema = numberSchema.allow("", null);
    }

    return numberSchema.messages({
      "number.empty": `Please Select "${name}"`,
      "number.base": `Select valid ${name}`,
    });
  }

  /* validators */

  Categories(data) {
    const categoriesSchema = Joi.object({
      name: this.stringValidator("Name", 3, 150, 0, true, true),
      description: this.stringValidator("Description", 0, 150, 0, true, false),
    }).unknown(true);
    return categoriesSchema.validate(data);
  }

  Warehouse(data) {
    const warehouseSchema = Joi.object({
      name: this.stringValidator("Name", 3, 150, 0, true, true),
      code: this.numberValidator("Warehouse code", 0, 0, true),
      contact_person_name: this.stringValidator(
        "Contact Person Name",
        3,
        150,
        0,
        true,
        true
      ),
      landline_no: this.numberValidator("Landline No", 8, 15, false),
      contact_person_mobile: this.numberValidator(
        "Contact Mobile Number",
        0,
        0,

        true
      ),
      contact_person_email: this.emailValidator(true),
      address: this.stringValidator("Address", 0, 150, 0, false, false),
      description: this.stringValidator("Description", 0, 150, 0, false, false),
    }).unknown(true);
    return warehouseSchema.validate(data);
  }

  Section(data) {
    const warehouseSchema = Joi.object({
      name: this.stringValidator("Name", 3, 150, 0, true, true),
      warehouse_id: this.selectValidator("Warehouse", true),
      description: this.stringValidator("Description", 0, 150, 0, false, false),
    }).unknown(true);
    return warehouseSchema.validate(data);
  }

  Suppliers(data) {
    const supplierSchema = Joi.object({
      name: this.stringValidator("Name", 3, 150, 0, true, true),
      supplier_code: this.numberValidator("Code", 0, 0, true),
      email: this.emailValidator(true),
      mobile_number: this.numberValidator("Contact Number", 0, 0, true),
      discount: Joi.number()
        .min(0)
        .max(100)
        .required()
        .messages({
          "number.empty": `Please enter "Disocunt"`,
          "number.base": `Enter valid Disocunt amount`,
          "number.max": `Disocunt amount should not exceed 100 characters`,
          "number.pattern.base": `Please enter valid Disocunt amount`,
        })
        .custom((value, helpers) => {
          if (value > 100) {
            return helpers.message("Disocunt must not be greater than 100");
          }
          return value;
        }),

      department_id: Joi.array()
        .min(1)
        .items(Joi.number().integer())
        .required()
        .error((errors) => {
          return errors.map((error) => {
            switch (error.type) {
              case "array.min":
                return {
                  message: "Department ID array must contain at least 1 item",
                };
              case "array.base":
                return { message: "Invalid array format for department_id" };
              case "array.includes":
                return {
                  message:
                    "Department ID array should contain only numeric values",
                };
              case "any.required":
                return { message: "Department ID array is required" };
              default:
                return error;
            }
          });
        }),

      billing_address: this.stringValidator(
        "Billing address",
        3,
        150,
        0,
        false,
        true
      ),
      billing_state: this.stringValidator("State", 3, 150, 0, false, true),
      billing_city: this.stringValidator("City", 3, 150, 0, true, true),
      billing_country: this.stringValidator("Country", 3, 150, 0, true, true),
      billing_pincode: this.numberValidator("Pincode", 0, 0, true),
      gstn_no: this.alphanNumeric("GST No", 14, 20, 0, true, true),
      bank_name: this.stringValidator("Bank Name", 3, 150, 0, true, true),
      account_number: this.alphanNumeric("Acoount No", 8, 20, 0, true, true),
      ifsc_code: this.alphanNumeric("IFSC code", 4, 20, 0, true, true),
      bank_branch: this.stringValidator("Branch name", 3, 150, 0, true, true),
      remarks: this.stringValidator("Remarks", 3, 150, 0, false, false),
    }).unknown(true);
    return supplierSchema.validate(data);
  }

  ProductPrice(data) {
    const productSchema = Joi.object({
      name: this.stringValidator("Product price", 3, 150, 0, true),
    }).unknown(true);
    return productSchema.validate(data);
  }

  Attribute(data) {
    const attributeSchema = Joi.object({
      name: this.stringValidator("Attribute Name", 3, 150, 0, true, true),
      description: this.stringValidator("Description", 3, 150, 0, false, false),
    }).unknown(true);
    return attributeSchema.validate(data);
  }

  AttributeValue(data) {
    const attributeSchema = Joi.object({
      po_attribute_id: this.selectValidator("Attribute", true),
      name: this.stringValidator("Attribute Name", 0, 150, 0, true, true),
    }).unknown(true);
    return attributeSchema.validate(data);
  }

  Tax(data) {
    const taxSchema = Joi.object({
      name: this.stringValidator("Name", 3, 150, 0, true, true),
      percentage: Joi.number()
        .required()
        .messages({
          "number.empty": `Please enter "Disocunt"`,
          "number.base": `Enter valid Disocunt amount`,
          "number.max": `Disocunt amount should not exceed 100 characters`,
          "number.pattern.base": `Please enter valid Disocunt amount`,
        })
        .custom((value, helpers) => {
          if (value > 100) {
            return helpers.message("Percentage must not be greater than 100");
          }
          return value;
        }),
    }).unknown(true);
    return taxSchema.validate(data);
  }

  Discount(data) {
    const discountSchema = Joi.object({
      name: this.stringValidator("Discount", 3, 150, 0, true, true),
    }).unknown(true);
    return discountSchema.validate(data);
  }

  POTerms(data) {
    const discountSchema = Joi.object({
      name: this.stringValidator("Term name", 3, 150, 0, true, true),
    }).unknown(true);
    return discountSchema.validate(data);
  }

  Agent(data) {
    const agentSchema = Joi.object({
      name: this.stringValidator("Agent name", 3, 150, 0, true, true),
      commission: this.numberValidator("Commission", 0, 0, true),
      address: this.stringValidator("Address", 3, 150, 0, false, true),
      description: this.stringValidator("Description", 3, 150, 0, true, false),
    }).unknown(true);
    return agentSchema.validate(data);
  }

  //validation fortransport and department
  NameAndDesc(data, page) {
    const categoriesSchema = Joi.object({
      name: this.stringValidator(page, 3, 150, 0, true, true),
      description: this.stringValidator(page, 3, 150, 0, false, false),
    }).unknown(true);
    return categoriesSchema.validate(data);
  }

  Inventory(data) {
    const inventrySchema = Joi.object({
      name: this.stringValidator("Name", 3, 150, 0, true, true),
      hsn_code: Joi.number()
        .integer()
        .min(10000000)
        .max(99999999)
        .required()
        .messages({
          "number.min": "HSN Code Must be 8 character long",
          "number.max": "HSN Code Must be 8 character long",
          "number.required": "Please enter HSN code",
          "number.base": "Please enter HSN code",
        }),

      product_code: Joi.number()
        .integer()
        .min(1000)
        .max(9999)
        .required()
        .messages({
          "number.min": "Product Code Must be 4 character long",
          "number.max": "Product Code Must be 4 character long",
          "number.required": "Please enter Product code",
          "number.base": "Please enter Product code",
        }),

      model_no: Joi.number().integer().min(100).required().messages({
        "number.min": "Product Code Must be 3 character long",
        "number.required": "Please enter Product code",
        "number.base": "Please enter Product code",
      }),
      uom: this.stringValidator("UOM", 3, 150, 0, true, true),
      category_id: this.selectValidator("Category", true),
      quantity: this.numberValidator("Quantity", 1, 0, true),
      price: this.numberValidator("Price amount", 1, 0, true),
      description: this.stringValidator("Description", 3, 150, 0, false, false),
      product_images_to_show: Joi.array().min(1).max(4).required().messages({
        "array.base": "Product Images must be an array",
        "array.min": "Please choose atlease {#limit} Product Image",
        "array.max": "Product Images must not exceed {#limit} items",
        "any.required": "Product Images is required",
        "string.base": "Each item in Product Images must be a string",
        "string.empty": "Each item in Product Images cannot be empty",
      }),

      product_attributes: Joi.array()
        .min(1)
        .items(
          Joi.object({
            attribute_id: Joi.number().required().messages({
              "number.base": "Please choose attribute",
              "number.empty": "Please choose attribute",
              "any.required": "Attribute is required",
            }),
            attribute_value_id: Joi.number().required().messages({
              "number.base": "Please choose attribute value",
              "number.empty": "Please choose attribute value",
              "any.required": "Attribute is required value",
            }),
          }).unknown(true)
        )
        .required()
        .messages({
          "array.min": "Please select atleast one Product Attribute",
        }),

      tax_details: Joi.array()
        .min(1)
        .items(
          Joi.object({
            tax: Joi.string().required().messages({
              "string.base": "Please Enter tax amount",
              "string.empty": "Please Enter tax amount",
              "any.required": "Tax amount is required",
            }),
            from_amount: Joi.string().required().messages({
              "string.base": "Please enter Amount",
              "string.empty": "Please enter Amount",
              "any.required": "Tax is required Amount",
            }),
            to_amount: Joi.string().required().messages({
              "string.base": "Please enter Amount",
              "string.empty": "Please enter Amount",
              "any.required": "Tax is required Amount",
            }),
          }).unknown(true)
        )
        .required()
        .messages({
          "array.min": "Please select atleast one Product Attribute",
        }),
    }).unknown(true);
    return inventrySchema.validate(data);
  }

  PurchaseRequestItem(data) {
    const PRSchema = Joi.object({
      po_brand_id: this.numberValidator("Brand", 0, 0, true),
      po_style_id: this.numberValidator("Style", 0, 0, true),
      po_product_id: this.numberValidator("Product", 0, 0, true),
      hsn_code: this.stringValidator("HSN code", 3, 150, 0, false, true),
      uom: this.stringValidator("UOM", 0, 0, 0, false, true),
      expected_date: Joi.string().required().messages({
        "string.empty": `Please Select Expected date`,
        "string.base": `Enter valid Expected date`,
      }),
      product_images: Joi.array().min(1).max(4).required().messages({
        "array.base": "Product Images must be an array",
        "array.min": "Please choose atlease {#limit} Product Image",
        "array.max": "Product Images must not exceed {#limit} items",
        "any.required": "Product Images is required",
        "string.base": "Each item in Product Images must be a string",
        "string.empty": "Each item in Product Images cannot be empty",
      }),
      size_data: Joi.array()
        .min(1)
        .items(
          Joi.object({
            attribute_id: Joi.number().required().messages({
              "number.base": "Please choose Size",
              "number.empty": "Please choose Size",
              "any.required": "Size is required",
            }),
            quantity: Joi.number().required().messages({
              "number.base": "Please Enter quantity",
              "number.empty": "Please Enter quantity",
              "any.required": "Quantity is required",
            }),
          }).unknown(true)
        )
        .required()
        .messages({
          "array.min": "Please select atleast one Product Attribute",
        }),
    }).unknown(true);
    return PRSchema.validate(data);
  }

  PurchaseRequest(data) {
    const PRSchema = Joi.object({
      po_warehouse_id: this.numberValidator("Warehouse", 0, 0, true),
      po_department_id: this.numberValidator("Department", 0, 0, true),
      po_supplier_id: this.numberValidator("Supplier", 0, 0, true),
      po_agent_id: this.numberValidator("Agent", 0, 0, true),
      po_transport_id: this.numberValidator("Transport", 0, 0, true),
      items: Joi.array().min(1).messages({
        "array.min": "Please add atlease {#limit} Product Item",
        "any.required": "Product Item is required",
      }),
    }).unknown(true);
    return PRSchema.validate(data);
  }

  Invoice(data) {
    const InvoiceSchema = Joi.object({
      invoice_number: this.numberValidator("Invoice Number", 0, 0, true),
      purchase_order_id: this.numberValidator("PO Number", 0, 0, true),
      supplier_id: this.numberValidator("Supplier", 0, 0, true),
      amount: this.numberValidator("Amount", 0, 0, true),
      paid_amount: this.numberValidator("Paid amount", 0, 0, true),
      payment_status: Joi.string().required().messages({
        "string.empty": "Please select Payment status",
        "any.required": "Please select Payment status",
      }),

      invoice_date: Joi.string().required().messages({
        "string.empty": "Please choose Payment status",
        "any.required": "Please choose Payment status",
      }),
    }).unknown(true);
    return InvoiceSchema.validate(data);
  }

  Logistics(data) {
    const logisticSchema = Joi.object({
      purchase_order_id: this.numberValidator("PO Number", 0, 0, true),
      invoice_id: this.numberValidator("Invoice", 0, 0, true),
      mode_of_transport: Joi.string()
        .min(3)
        .max(30)
        .required()
        .pattern(new RegExp("^[a-zA-Z][a-zA-Z-' ']*$"))
        .messages({
          "string.empty": `Please enter "Mode of transport"`,
          "string.base": `Enter valid Mode of transport`,
          "string.max": `Mode of transport should not exceed 150 characters`,
          "string.min": `Mode of transport should be at least 3 characters`,
          "string.pattern.base": `Please enter valid Mode of transport`,
        }),
      transport_name: Joi.string()
        .min(3)
        .max(30)
        .required()
        .pattern(new RegExp("^[a-zA-Z][a-zA-Z-' ']*$"))
        .messages({
          "string.empty": `Please enter Transport name`,
          "string.base": `Enter valid Transport name`,
          "string.max": `Transport name should not exceed 150 characters`,
          "string.min": `Transport name should be at least 3 characters`,
          "string.pattern.base": `Please enter valid Transport name`,
        }),
      lr_number: this.numberValidator("LR number", 0, 0, true),
      amount: this.numberValidator("Amount", 0, 0, true),
      consignment_number: this.numberValidator(
        "Consignment number",
        0,
        0,
        true
      ),
    }).unknown(true);
    return logisticSchema.validate(data);
  }
}
const POValidator = new POValidation();
export default POValidator;
