import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const RejoinModal = ({
  isOpen,
  handleModal,
  handleSubmit,
  handleChange,
  remarks,
  isBtnDisable,
}) => {
  const handleClickAway = () => {};
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Dialog
        className="testing"
        open={isOpen}
        onClose={handleModal}
        disableBackdropClick={false}
        PaperProps={{
          style: {
            minWidth: "500px",
            minHeight: "300px",
          },
        }}
      >
        <DialogTitle>Employee Rejoin</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="field-block rejoin-employee-width">
              <label className="label-name">Rejoin Date</label>
              <input
                type="date"
                className="input-field"
                name="rejoined_on"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
            <div className="field-block rejoin-employee-width">
              <label className="label-name">Remarks</label>
              <textarea
                rows={5}
                className="input-field"
                type="text"
                autoComplete="off"
                placeholder="Enter Remarks"
                name="remarks"
                value={remarks}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>

            <div className="text-right flex mt-6 justify-end">
              <button disabled={isBtnDisable} type="submit" className="submit-button">
                <span className="reset-button-label">
                  <span className="submit-button-label">Rejoin</span>
                </span>
              </button>
              <button
                type="button"
                className="reset-button ml-3"
                onClick={handleModal}
              >
                <span className="reset-button-label">Cancel</span>
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </ClickAwayListener>
  );
};

export default RejoinModal;
