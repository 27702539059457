import React, { useState, useEffect } from "react";
import { GET_API } from "../../../Services/api";
import FileUpload from "../../../Assets/Icons/FileUpload.svg";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import alertify from "alertifyjs";
import Spinner from "../../Spinner";
import Select from "react-select";
import Joi from "joi";

function EmployeePersonalInfo({ stateChanger, empDetails, errors, setErrors }) {
  const [casteList, setcasteList] = useState([]);
  const [religionList, setreligionList] = useState([]);
  const [IdProofList, setIdProofList] = useState([]);

  const [loading1, setloading1] = useState(false);
  const [loading2, setloading2] = useState(false);
  const [loading3, setloading3] = useState(false);

  useEffect(() => {
    getCasteList();
    getReligionList();
    getIDProofList();
  }, []);

  const getCasteList = async () => {
    setloading1(true);
    try {
      const casteList = await GET_API("/casteDropdown");
      if (casteList?.data?.status === 200) {
        setloading1(false);
        const cast = casteList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        cast.unshift({ value: "", label: "Select..." });
        setcasteList(cast);
      }
    } catch (error) {
      alertify?.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Failed to get Caste List"
      );
      setloading1(false);
    }
  };

  const getReligionList = async () => {
    setloading2(true);
    try {
      const religionList = await GET_API("/religionDropdown");
      if (religionList?.data?.status === 200) {
        const religion = religionList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        religion.unshift({ value: "", label: "Select..." });
        setreligionList(religion);
        setloading2(false);
      }
    } catch (error) {
      alertify?.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Failed to get Religion List"
      );
      setloading2(false);
    }
  };
  const getIDProofList = async () => {
    setloading3(true);
    try {
      const list = await GET_API("governmentIdProofDropdown");
      if (list?.data?.status === 200) {
        const idProof = list?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        idProof.unshift({ value: "", label: "Select..." });
        setIdProofList(idProof);
        setloading3(false);
      }
    } catch (error) {
      alertify?.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Failed to get Religion List"
      );
      setloading3(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    stateChanger(() => ({ ...empDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    stateChanger(() => ({ ...empDetails, [name]: e?.value }));
  };

  const maritalStatus = [
    { value: "", label: "Please Select" },
    { value: "single", label: "Single" },
    { value: "married", label: "Married" },
  ];

  const handleImageChange = (e) => {
    const fileReader = new FileReader();
    const { error } = schema.validate({
      size: e?.target?.files[0].size,
      type: e?.target?.files[0].type,
    });
    error?.message ? setErrors({ proof_image: error?.message }) : setErrors({});
    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        stateChanger(() => ({
          ...empDetails,
          proof_image: fileReader.result,
          proof_image_format: {
            size: e?.target?.files[0].size,
            type: e?.target?.files[0].type,
          },
        }));
      }
    };
    fileReader?.readAsDataURL(e?.target?.files[0]);
  };

  const schema = Joi.object({
    size: Joi.number()
      .required()
      .max(5 * 1024 * 1024)
      .message("Image size must be less than 5MB."),

    type: Joi.string().valid("image/jpeg", "image/png").required().messages({
      "any.only": "Invalid image format. Only JPEG and PNG are allowed.",
    }),
  });

  const genderList = [
    { label: "Select...", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  if (loading1 || loading2 || loading3) return <Spinner />;

  return (
    <div className="border-t-[1px] border-t-[#D9D9D9]">
      <div
        className="w-3/12 p-3 bg-primary-color text-center"
        style={{ borderRadius: "0px 0px 100px 0px" }}
      >
        <span className="text-white-color font-karla font-base">
          Personal Info
        </span>
      </div>
      <form>
        <div className="form">
          <div className="field-block" name="email">
            <label className="label-name">Email Id</label>
            <input
              className="input-field"
              type="text"
              autoComplete="off"
              onChange={(e) => handleChange(e)}
              name="email"
              placeholder="Enter Email ID"
              value={empDetails?.email}
            />
            {errors?.email ? (
              <div className="text-[#FF5C42] text-sm font-medium font-karla">
                {errors?.email}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="phone_no">
            <label className="label-name">
              Mobile Number
              <span className="font-bold" style={{ color: "#FF5C42" }}>
                *
              </span>
            </label>
            <input
              className="input-field"
              type="number"
              autoComplete="off"
              onChange={(e) => handleChange(e)}
              name="phone_no"
              placeholder="Enter Phone Number"
              value={empDetails?.phone_no}
            />
            {errors?.phone_no ? (
              <div className="text-[#FF5C42] text-sm font-medium font-karla">
                {errors?.phone_no}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="alternate_phone_no">
            <label className="label-name">Alternative Mobile Number</label>
            <input
              className="input-field"
              type="number"
              autoComplete="off"
              onChange={(e) => handleChange(e)}
              name="alternate_phone_no"
              placeholder="Enter Alternate mobile Number"
              value={empDetails?.alternate_phone_no}
            />
            {errors?.alternate_phone_no ? (
              <div className="text-[#FF5C42] text-sm font-medium font-karla">
                {errors?.alternate_phone_no}
              </div>
            ) : null}
          </div>

          <div className="field-block dropdown-select-to-second" name="gender">
            <label className="label-name">
              Gender
              <span className="required-symbol">*</span>
            </label>
            <Select
              options={genderList}
              value={genderList?.find(
                (option) => option?.value === empDetails?.gender
              )}
              onChange={(e) => handleSelectChange(e, "gender")}
              name="gender"
              className=""
              isSearchable
            />
          </div>

          <div className="field-block" name="marital_status">
            <label className="label-name">
              Marital Status
              <span className="font-bold" style={{ color: "#FF5C42" }}>
                *
              </span>
            </label>
            <Select
              options={maritalStatus}
              value={maritalStatus?.find(
                (option) => option?.value === empDetails?.marital_status
              )}
              onChange={(e) => handleSelectChange(e, "marital_status")}
              name="marital_status"
              className=""
              isSearchable
            />

            {errors?.marital_status ? (
              <div className="text-[#FF5C42] text-sm font-medium font-karla">
                {errors?.marital_status}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="fathers_name">
            <label className="label-name">
              Father Name
              <span className="font-bold" style={{ color: "#FF5C42" }}>
                *
              </span>
            </label>
            <input
              className="input-field"
              type="text"
              autoComplete="off"
              onChange={(e) => handleChange(e)}
              name="fathers_name"
              placeholder="Enter Father Name"
              value={empDetails?.fathers_name}
            />
            {errors?.fathers_name ? (
              <div className="text-[#FF5C42] text-sm font-medium font-karla">
                {errors?.fathers_name}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="religion_id">
            <label className="label-name">
              Religion
              <span className="font-bold" style={{ color: "#FF5C42" }}>
                *
              </span>
            </label>
            <Select
              options={religionList}
              value={religionList?.find(
                (option) => option?.value === empDetails?.religion_id
              )}
              onChange={(e) => handleSelectChange(e, "religion_id")}
              name="religion_id"
              className=""
              isSearchable
            />

            {errors?.religion_id ? (
              <div className="text-[#FF5C42] text-sm font-medium font-karla">
                {errors?.religion_id}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="caste_id">
            <label className="label-name">
              Caste
              <span className="font-bold" style={{ color: "#FF5C42" }}>
                *
              </span>
            </label>
            <Select
              options={casteList}
              value={casteList?.find(
                (option) => option?.value === empDetails?.caste_id
              )}
              onChange={(e) => handleSelectChange(e, "caste_id")}
              name="caste_id"
              className=""
              isSearchable
            />
            {errors?.caste_id ? (
              <div className="text-[#FF5C42] text-sm font-medium font-karla">
                {errors?.caste_id}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="government_proof_id">
            <label className="label-name">
              ID Proof
              <span className="font-bold" style={{ color: "#FF5C42" }}>
                *
              </span>
            </label>
            <Select
              options={IdProofList}
              value={IdProofList?.find(
                (option) => option?.value === empDetails?.government_proof_id
              )}
              onChange={(e) => handleSelectChange(e, "government_proof_id")}
              name="government_proof_id"
              className=""
              isSearchable
            />
            {errors?.government_proof_id ? (
              <div className="text-[#FF5C42] text-sm font-medium font-karla">
                {errors?.government_proof_id}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="id_proof_number">
            <label className="label-name">
              Proof Number
              <span className="font-bold" style={{ color: "#FF5C42" }}>
                *
              </span>
            </label>
            <input
              className="input-field"
              type="number"
              autoComplete="off"
              onChange={(e) => {
                handleChange(e);
              }}
              name="id_proof_number"
              placeholder="Enter ID Proof"
              value={empDetails?.id_proof_number}
            />
            {errors?.id_proof_number ? (
              <div className="text-[#FF5C42] text-sm font-medium font-karla">
                {errors?.id_proof_number}
              </div>
            ) : null}
          </div>

          <div className="" name="proof_image">
            <label className="label-name">
              Proof image
              <span className="font-bold" style={{ color: "#FF5C42" }}>
                *
              </span>
            </label>
            <div className="flex gap-2 items-center">
              {!empDetails.proof_image ? (
                <UploadFileIcon className="employee-proof-picture" />
              ) : (
                <img
                  name="proof_image"
                  className="h-[35px] w-[35px]"
                  src={empDetails?.proof_image}
                  accept="png,jpeg,svg"
                  alt=""
                />
              )}

              <div className="p-1 bg-[#FFF2EC] rounded flex justify-center items-center gap-1 cursor-pointer">
                <label
                  for="proof_image_files"
                  className="label-name cursor-pointer"
                >
                  Choose
                </label>
                <input
                  className="w-0 cursor-pointer"
                  id="proof_image_files"
                  style={{ visibility: "hidden" }}
                  type="file"
                  accept=".jpeg,.jpg,.png"
                  onChange={(e) => handleImageChange(e)}
                />
                <img src={FileUpload} alt="" />
              </div>
            </div>
            {errors?.proof_image ? (
              <div className="text-[#FF5C42] text-sm font-medium font-karla">
                {errors?.proof_image}
              </div>
            ) : null}
          </div>
        </div>
      </form>
    </div>
  );
}

export default EmployeePersonalInfo;
