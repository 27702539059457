import React, { useState, useEffect, useContext } from "react";
import BonusApprovalTable from "../../DataTable/BonusApprovalTable";
import Search from "../../../Assets/Icons/Search.svg";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import { GET_API, POST_API } from "../../../Services/api";
import "alertifyjs/build/css/alertify.css";
import { PrivilegeContext } from "../../../PrivilegeContext";

function BonusApproval() {
  const [loading, setLoading] = useState(false);
  const [bonusList, setBonusList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const { access } = useContext(PrivilegeContext);
  const [addAccess, setAddAccess] = useState(false);

  useEffect(() => {
    getBonusList(currentPage, pageSize);
  }, [currentPage, pageSize]);

  useEffect(() => {
    getAccess();
  }, [access]);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "approve_bonus") {
        setAddAccess(item?.add);
      }
      return "";
    });
  };

  const headCells = [
    { field: "EmployeeCode", headerName: "Employee Code" },
    { field: "employee_name", headerName: "Employee Name" },
    { field: "department_name", headerName: "Department" },
    { field: "designation_name", headerName: "Designation" },
    { field: "amount", headerName: "Amount" },
  ];

  const isPageNotExit = () => {
    setCurrentPage(1);
    getBonusList(1, pageSize);
  };

  const getBonusList = async (page, size) => {
    setLoading(true);
    try {
      const bonusList = await GET_API(
        `/bonusApproval?page=${page}&size=${size}`
      );
      if (bonusList?.status === 200) {
        if (
          bonusList?.data?.data?.current_page > bonusList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setBonusList(bonusList?.data?.data?.data);
        setLastPage(bonusList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  const handleApprove = async (type) => {
    const data = {
      selectedRows,
      appType: type,
      approved_by: parseInt(localStorage?.getItem("user_id")),
    };
    if (selectedRows.length <= 0) {
      alertify
        .alert("Please select Row to Approve/Reject")
        .setHeader("<b> Alert </b> ");
      return;
    }
    try {
      const approveBonus = await POST_API("/updatebonusApproval", data);
      if (approveBonus?.status === 200) {
        alertify.success(`Bonus ${data?.appType}d successfully...!`);
        getBonusList(currentPage, pageSize);
        return;
      }
    } catch (error) {
      alertify.error(`Failed to ${data?.appType}d Bonus`);
    }
  };

  return (
    <div className=" bg-[#F7F6F4] grow w-[calc(100%-80px-20%)]">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Bonus Logs</h4>
          <div className="search-block" style={{ justifyContent: "flex-end" }}>
            <div className="flex gap-3">
              {addAccess && (
                <>
                  <div
                    className="add-new-button-block"
                    onClick={() => {
                      handleApprove("reject");
                    }}
                  >
                    <button
                      to=""
                      className="add-new-button"
                      // disabled={selectedRows?.length >=1 ? false:true}
                    >
                      <span>Reject</span>
                    </button>
                  </div>
                  <div
                    className="add-new-button-block"
                    onClick={() => {
                      handleApprove("approve");
                    }}
                  >
                    <button
                      to=""
                      className="add-new-button"
                      // disabled={selectedRows?.length >=1 ? false:true}
                    >
                      <span>Approve</span>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          {/**************** * Table ********************/}
          <div className="p-5 min-h-[400px]">
            <BonusApprovalTable
              columns={headCells}
              list={bonusList}
              setSelectedRows={setSelectedRows}
              setPageNo={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              lastPage={lastPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BonusApproval;
