import Select from "react-select";
import InvoiceBulkTable from "../../DataTable/PurchaseOrder/InvoiceBulkTable";
import { useEffect, useState } from "react";
import Spinner from "../../Spinner";
import { GET_API, POST_API } from "../../../Services/api";
import alertify from "alertifyjs";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Validation from "../../../Validation/POValidation";
import { useNavigate } from "react-router-dom";

function CreateInvoice() {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [purchaseOrderList, setPurchaseOrderList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [error, seterror] = useState(false);
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState({
    invoice_number: "",
    purchase_order_id: "",
    supplier_id: "",
    amount: "",
    paid_amount: "",
    payment_status: "",
    invoice_date: "",
    invoice_items: [],
  });

  useEffect(() => {
    getPurchaseOrderList();
    getSupplierList();
  }, []);

  const getPurchaseOrderList = async () => {
    setLoading(true);
    try {
      const POList = await GET_API("po/purchaseOrderDropdown");
      if (POList?.data?.status === 200) {
        const PO = POList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.po_number };
        });
        PO.unshift({ value: "", label: "Select..." });
        setPurchaseOrderList(PO);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get PO List...");
    }
  };

  const getSupplierList = async () => {
    setLoading1(true);
    try {
      const supplierListData = await GET_API("po/suppliersDropdown");
      if (supplierListData?.data?.status === 200) {
        const supplier = supplierListData?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        supplier.unshift({ value: "", label: "Select..." });
        setSupplierList(supplier);
        setLoading1(false);
      }
    } catch (error) {
      setLoading1(false);
      alertify?.error("Failed to get Supplier List...");
    }
  };

  const columns = [
    { id: "product_name", label: "S.No" },
    { id: "product_name", label: "Product Name" },
    { id: "hsn", label: "HSN Number" },
    { id: "brand", label: "Brand" },
    { id: "uom", label: "UOM" },
    { id: "qauntity", label: "Quantity" },
    { id: "price", label: "Price" },
  ];

  const payment = [
    {
      label: "Select...",
      value: "",
    },
    {
      label: "Paid",
      value: "paid",
    },
    {
      label: "Not Paid",
      value: "not_paid",
    },
    {
      label: "Partially paid",
      value: "partially_paid",
    },
  ];

  const getProductInvoiceDetails = async (name, e) => {
    if (e?.value === "") return;
    try {
      setLoading(true);
      const invoiceDetails = await GET_API(`po/purchaseOrderById/${e?.value}`);
      if (invoiceDetails?.data?.status === 200) {
        const summaryDetails = invoiceDetails?.data?.data?.summaryDetails;
        setInvoice({
          ...invoice,
          [name]: e?.value,
          invoice_items: summaryDetails,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get Invoice details");
    }
  };

  const handleChange = (name, value) => {
    setInvoice({ ...invoice, [name]: value });
  };

  const CreateNewInvoice = async (e) => {
    e?.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.Invoice(invoice);
    if (isValid && isValid?.error) {
      seterror({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterror({});
      setButtonDisabled(false);
    }
    try {
      const createInvoiceList = await POST_API("po/addInvoice", invoice);
      if (createInvoiceList?.data?.status === 200) {
        alertify?.success(createInvoiceList?.data?.message);
        setButtonDisabled(false);
        navigate("/po/invoice/previous");
      }
    } catch (error) {
      setButtonDisabled(false);
      alertify?.error("Failed to create invoice list...");
      Object.keys(error?.response?.data?.data).forEach(function (key) {
        alertify?.error(error?.response?.data?.data[key].toString());
      });
    }
  };

  const resetForm = () => {
    setInvoice({
      invoice_number: "",
      purchase_order_id: "",
      supplier_id: "",
      amount: "",
      paid_amount: "",
      payment_status: "",
      invoice_date: "",
      invoice_items: [],
    });
  };

  if (loading || loading1) {
    return <Spinner />;
  }

  return (
    <div className="po-container">
      <div className="po-header">Invoice</div>
      <div className="po-contect-box">
        <div className="header-name">New Invoice</div>
        <form
          className="po-form"
          onSubmit={(e) => {
            CreateNewInvoice(e);
          }}
        >
          <div className="form-block-2">
            <div className="grid-block">
              <div className="input-field-block">
                <span className="label-name">
                  Invoice Number <span className="required-symbol">*</span>
                </span>
                <input
                  type="number"
                  name="invoice_number"
                  className="input-field"
                  placeholder="Enter Invoice Number"
                  onChange={(e) => {
                    handleChange("invoice_number", e?.target?.value);
                  }}
                  value={invoice?.invoice_number}
                />
                {error.invoice_number ? (
                  <div className="validation-error-label">
                    {error.invoice_number}
                  </div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  PO Number <span className="required-symbol">*</span>
                </span>
                <Select
                  options={purchaseOrderList}
                  name="purchase_order_id"
                  isSearchable
                  onChange={(e) => {
                    getProductInvoiceDetails("purchase_order_id", e);
                  }}
                  value={purchaseOrderList?.find(
                    (option) => option?.value === invoice.purchase_order_id
                  )}
                />
                {error.purchase_order_id ? (
                  <div className="validation-error-label">
                    {error.purchase_order_id}
                  </div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  Supplier <span className="required-symbol">*</span>
                </span>
                <Select
                  options={supplierList}
                  name="supplier_id"
                  isSearchable
                  onChange={(e) => {
                    handleChange("supplier_id", e?.value);
                  }}
                  value={supplierList?.find(
                    (option) => option?.value === invoice.supplier_id
                  )}
                />
                {error.supplier_id ? (
                  <div className="validation-error-label">
                    {error.supplier_id}
                  </div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  Total Amount <span className="required-symbol">*</span>
                </span>
                <input
                  type="text"
                  name="amount"
                  placeholder="Enter Total amount"
                  className="input-field"
                  onChange={(e) => {
                    handleChange("amount", e?.target?.value);
                  }}
                  value={invoice?.amount}
                />
                {error.amount ? (
                  <div className="validation-error-label">{error.amount}</div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  Paid Amount <span className="required-symbol">*</span>
                </span>
                <input
                  type="text"
                  className="input-field"
                  name="paid_amount"
                  placeholder="Enter Paid amount"
                  onChange={(e) => {
                    handleChange("paid_amount", e?.target?.value);
                  }}
                  value={invoice?.paid_amount}
                />
                {error.paid_amount ? (
                  <div className="validation-error-label">
                    {error.paid_amount}
                  </div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  Payment <span className="required-symbol">*</span>
                </span>
                <Select
                  options={payment}
                  name="payment_status"
                  onChange={(e) => {
                    handleChange("payment_status", e?.value);
                  }}
                  value={payment?.find(
                    (option) => option?.value === invoice.payment_status
                  )}
                  isSearchable
                />
                {error.payment_status ? (
                  <div className="validation-error-label">
                    {error.payment_status}
                  </div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  Invoice Date <span className="required-symbol">*</span>
                </span>
                <input
                  type="date"
                  name="invoice_date"
                  className="input-field"
                  placeholder="Select product to get Color"
                  onChange={(e) => {
                    handleChange("invoice_date", e?.target?.value);
                  }}
                  value={invoice?.invoice_date}
                />
                {error.invoice_date ? (
                  <div className="validation-error-label">
                    {error.invoice_date}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <InvoiceBulkTable
            columns={columns}
            list={invoice?.invoice_items}
            totalPrice={0}
          />

          <div className="submit-reset-button">
            <button type="button" className="reset-button" onClick={resetForm}>
              <span className="reset-button-label">Reset</span>
            </button>
            <button
              disabled={buttonDisabled}
              type="submit"
              className="submit-button"
            >
              <span className="submit-button-label">Add</span>
              <img src={ColorAdd} alt="" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateInvoice;
