import React from "react";

function IMS({ fill }) {
  return (
    <>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="21.999"
          y="16.0264"
          width="6"
          height="12.9987"
          rx="3"
          transform="rotate(90 21.999 16.0264)"
          fill={fill}
        />
        <rect
          x="2.00122"
          y="9.02637"
          width="5.99939"
          height="13"
          rx="2.99969"
          fill={fill}
        />
        <ellipse
          opacity="0.5"
          cx="18.9993"
          cy="5.02637"
          rx="2.99969"
          ry="3"
          fill={fill}
        />
        <ellipse
          opacity="0.5"
          cx="18.9993"
          cy="12.0264"
          rx="2.99969"
          ry="3"
          fill={fill}
        />
        <ellipse
          opacity="0.5"
          cx="12"
          cy="5.02637"
          rx="2.99969"
          ry="3"
          fill={fill}
        />
        <ellipse
          opacity="0.5"
          cx="12"
          cy="12.0264"
          rx="2.99969"
          ry="3"
          fill={fill}
        />
        <ellipse
          opacity="0.5"
          cx="5.00092"
          cy="5.02637"
          rx="2.99969"
          ry="3"
          fill={fill}
        />
      </svg>
    </>
  );
}

export default IMS;
