import React, { useEffect, useState } from "react";
import Noimage from "../../../Assets/Icons/NoImage.svg";
import FileUpload from "../../../Assets/Icons/FileUpload.svg";
import { GET_API, POST_API } from "../../../Services/api";
import Select from "react-select";
import alertify from "alertifyjs";
import Spinner from "../../Spinner";
import Add from "../../../Assets/Icons/ColorAdd.svg";
import removeImage from "../../../Assets/Icons/Delete.svg";
import POValidator from "../../../Validation/POValidation";
import { Link, useNavigate } from "react-router-dom";
import RecycleBin from "../../../Assets/Icons/Delete.svg";

function AddInventory() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [attribute, setAttribute] = useState([]);
  const [error, setError] = useState({});
  const [buttonDisabled, setbuttonDisabled] = useState(false);
  const [attributeValueList, setAttributeValueList] = useState({});
  const [inventory, setInventory] = useState({
    name: "",
    hsn_code: "",
    product_code: "",
    model_no: "",
    uom: "",
    category_id: "",
    quantity: "",
    price: "",
    description: "",
    product_attributes: [
      {
        attribute_id: "",
        attribute_value_id: "",
      },
    ],
    tax_details: [
      {
        tax: "",
        from_amount: "",
        to_amount: "",
      },
    ],
    product_images: [],
    product_images_to_show: [],
  });

  useEffect(() => {
    getProductCategoryList();
    getProductAttribute();
  }, []);

  const getProductCategoryList = async () => {
    setLoading(true);
    try {
      const categoryList = await GET_API("po/categories");
      if (categoryList?.data?.status === 200) {
        const attribute = categoryList?.data?.data?.data.map((item) => {
          return { label: item?.name, value: item?.id };
        });
        attribute.unshift({ label: "Select...", value: "" });
        setCategory(attribute);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to get Category list...");
    }
  };

  const getProductAttribute = async () => {
    try {
      const productAttribute = await GET_API("po/attributesDropdown");
      if (productAttribute?.data?.status === 200) {
        const attribute = productAttribute?.data?.data?.map((item) => {
          return { label: item?.name, value: item?.id };
        });
        attribute.unshift({ label: "Select...", value: "" });
        setAttribute(attribute);
        setLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get attribute list...");
    }
  };

  const handleRemoveImage = (index) => {
    const product_images_arr = [...inventory?.product_images];
    const filterArr = product_images_arr.filter((item, i) => {
      return i !== index;
    });
    setInventory({
      ...inventory,
      product_images: filterArr,
      product_images_to_show: filterArr,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setInventory({ ...inventory, [name]: value });
  };

  const handleSelectChange = async (e) => {
    const { value } = e;
    setInventory({ ...inventory, category_id: value });
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    const filePromises = Array.from(files).map((file) => {
      return new Promise((resolve) => {
        const fileReader = new FileReader();

        fileReader.onload = () => {
          if (fileReader.readyState === 2) {
            resolve({
              dataURL: fileReader.result,
              size: file.size,
              type: file.type,
            });
          }
        };

        fileReader.readAsDataURL(file);
      });
    });

    Promise.all(filePromises).then((fileDataArray) => {
      const imageArr = [];
      fileDataArray?.map((list) => {
        imageArr.push(list?.dataURL);
        return setInventory((prevInventory) => ({
          ...prevInventory,
          product_images: imageArr,
          product_images_to_show: imageArr,
        }));
      });
    });
  };

  const addProduct = async () => {
    setbuttonDisabled(true);
    const isValid = POValidator.Inventory(inventory);
    if (isValid && isValid.error) {
      setbuttonDisabled(false);
      setError({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setError({});
      setbuttonDisabled(false);
    }
    try {
      const addNewProduct = await POST_API("po/addProduct", inventory);
      if (addNewProduct?.data?.status === 200) {
        navigate("/po/inventory/product");
        alertify?.success(addNewProduct?.data?.message);
      }
    } catch (error) {
      for (const key in error?.response?.data?.data) {
        if (error?.response?.data?.data.hasOwnProperty(key)) {
          error?.response?.data?.data[key].forEach((value) => {
            alertify.error(value);
          });
        }
      }
    }
  };

  const getAttributeValue = async (e, i) => {
    const { value } = e;
    if (value === "") return;
    try {
      const arr = [...inventory?.product_attributes];
      arr[i].attribute_id = value;
      arr[i].attribute_value_id = "";
      setInventory({ ...inventory, product_attributes: arr });
      setAttributeValueList({
        ...attributeValueList,
        [i]: [{ label: "Select", value: "" }],
      });
      const attributeValueResponse = await GET_API(
        `po/attributeValueByAttrId/${value}`
      );

      if (attributeValueResponse?.data?.status === 200) {
        const attributeValuesData = attributeValueResponse?.data?.data?.map(
          (item) => {
            return { label: item?.name, value: item?.id };
          }
        );

        attributeValuesData.unshift({ label: "Select...", value: "" });
        setAttributeValueList({
          ...attributeValueList,
          [i]: attributeValuesData,
        });
      }
    } catch (error) {
      alertify?.error("Failed to get attribute values...");
    }
  };

  const handleAttributeValueChange = (e, i) => {
    const { value } = e;
    if (value === "") return;
    const arr = [...inventory?.product_attributes];
    arr[i].attribute_value_id = value;
    setInventory({ ...inventory, product_attributes: arr });
  };

  const addNewAttribute = () => {
    const arr = [...inventory?.product_attributes];
    arr.push({ attribute_id: "", attribute_value_id: " " });
    setInventory({ ...inventory, product_attributes: arr });
  };
  const addNewTax = () => {
    const arr = [...inventory?.tax_details];
    arr.push({ tax: "", from_amount: " ", to_amount: "" });
    setInventory({ ...inventory, tax_details: arr });
  };

  const removeAttribute = (index) => {
    const arr = [...inventory?.product_attributes];
    const filterArr = arr.filter((item, i) => {
      return i !== index;
    });
    setInventory({ ...inventory, product_attributes: filterArr });
  };

  const removeTax = (index) => {
    const arr = [...inventory?.tax_details];
    const filterArr = arr.filter((item, i) => {
      return i !== index;
    });
    setInventory({ ...inventory, tax_details: filterArr });
  };

  const resetForm = () => {
    setInventory({
      name: "",
      hsn_code: "",
      product_code: "",
      model_no: "",
      uom: "",
      category_id: "",
      quantity: "",
      description: "",
      product_attributes: [
        {
          attribute_id: "",
          attribute_value_id: "",
        },
      ],
      product_images: [],
      product_images_to_show: [],
    });
  };

  const handleTaxCalculation = (e, index) => {
    const { name, value } = e?.target;
    const taxArr = [...inventory?.tax_details];
    taxArr[index][name] = value;
    setInventory({ ...inventory, tax_details: taxArr });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="po-container">
      <div className="flex justify-between items-center">
        <div className="po-header">Inventory</div>
        <div className="bread-crumbs">
          <div>
            <Link to="/po/inventory/product" className="in-active">
              Inventory List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Inventory</Link>
          </div>
        </div>
      </div>

      <div className="po-contect-box">
        <form className="po-form">
          <div className="form-block-2">
            <div className="header">Add Item</div>
            <div className="grid-block">
              <div className="input-field-block">
                <span className="label-name">
                  Product Name
                  <span className="required-symbol">*</span>
                </span>
                <input
                  type="text"
                  name="name"
                  className="input-field"
                  placeholder="Enter Product Name"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                  value={inventory?.name}
                />
                {error.name ? (
                  <div className="validation-error-label">{error.name}</div>
                ) : null}
              </div>
              <div className="input-field-block">
                <span className="label-name">
                  HSN Code <span className="required-symbol">*</span>
                </span>
                <input
                  type="number"
                  name="hsn_code"
                  className="input-field"
                  placeholder="Enter HSN Code"
                  onChange={(e) => handleChange(e)}
                  value={inventory?.hsn_code}
                />
                {error.hsn_code ? (
                  <div className="validation-error-label">{error.hsn_code}</div>
                ) : null}
              </div>
              <div className="input-field-block">
                <span className="label-name">
                  Product Code <span className="required-symbol">*</span>
                </span>
                <input
                  type="number"
                  name="product_code"
                  className="input-field"
                  placeholder="Enter Product Code"
                  onChange={(e) => handleChange(e)}
                  value={inventory?.product_code}
                />
                {error.product_code ? (
                  <div className="validation-error-label">
                    {error.product_code}
                  </div>
                ) : null}
              </div>
              <div className="input-field-block">
                <span className="label-name">
                  Model No <span className="required-symbol">*</span>
                </span>
                <input
                  type="text"
                  name="model_no"
                  className="input-field"
                  placeholder="Enter Model No"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                  value={inventory?.model_no}
                />
                {error.model_no ? (
                  <div className="validation-error-label">{error.model_no}</div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  UOM(unit of measurement)
                  <span className="required-symbol">*</span>
                </span>
                <input
                  type="text"
                  name="uom"
                  className="input-field"
                  autoComplete="off"
                  placeholder="UOM"
                  onChange={(e) => handleChange(e)}
                  value={inventory?.uom}
                />
                {error.uom ? (
                  <div className="validation-error-label">{error.uom}</div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  Category <span className="required-symbol">*</span>
                </span>
                <Select
                  options={category}
                  value={
                    inventory.category_id
                      ? category?.find(
                          (option) => option?.value === inventory.category_id
                        )
                      : ""
                  }
                  name="category_id"
                  isSearchable
                  onChange={(e) => handleSelectChange(e)}
                />
                {error.category_id ? (
                  <div className="validation-error-label">
                    {error.category_id}
                  </div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  Quantity <span className="required-symbol">*</span>
                </span>
                <input
                  type="number"
                  name="quantity"
                  className="input-field"
                  placeholder="Enter Quantity"
                  onChange={(e) => handleChange(e)}
                  value={inventory?.quantity}
                />
                {error.quantity ? (
                  <div className="validation-error-label">{error.quantity}</div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  Price <span className="required-symbol">*</span>
                </span>
                <input
                  type="number"
                  name="price"
                  className="input-field"
                  placeholder="Enter Price amount"
                  onChange={(e) => handleChange(e)}
                  value={inventory?.price}
                />
                {error.price ? (
                  <div className="validation-error-label">{error.price}</div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">Description</span>
                <textarea
                  rows={5}
                  type="number"
                  name="description"
                  className="input-field"
                  placeholder="Enter Description"
                  onChange={(e) => handleChange(e)}
                  value={inventory?.description}
                />
                {error.description ? (
                  <div className="validation-error-label">
                    {error.description}
                  </div>
                ) : null}
              </div>

              <div className="input-field-block">
                <div className="flex items-end">
                  <div className="w-[20%]">
                    <img src={Noimage} alt="" className="h-[40px] w-[40px]" />
                  </div>
                  <div className="w-[80%] p-2 w-max h-max bg-[#FFF2EC] rounded flex justify-center items-center gap-1 cursor-pointer">
                    <label for="files" className="label-name cursor-pointer">
                      Choose
                    </label>
                    <input
                      type="file"
                      className="w-0 cursor-pointer"
                      id="files"
                      style={{ visibility: "hidden" }}
                      accept=".jpeg,.jpg,.png"
                      multiple
                      name="product_images[]"
                      onChange={(e) => handleImageChange(e)}
                    />
                    <img src={FileUpload} alt="" />
                  </div>
                </div>

                <div className="flex gap-1 items-center flex-wrap max-h-[164px] overflow-scroll">
                  {inventory?.product_images?.length !== 0 &&
                    inventory?.product_images?.map((image, i) => {
                      return (
                        <div className="relative">
                          <img
                            src={removeImage}
                            alt=""
                            className="h-[20px] p-0.5 bg-[#ccc] rounded cursor-pointer absolute left-14	top-1.5"
                            onClick={() => {
                              handleRemoveImage(i);
                            }}
                          />
                          <img
                            src={image}
                            alt=""
                            className="h-[80px] w-[80px] rounded"
                          />
                        </div>
                      );
                    })}
                </div>
                {error.product_images_to_show ? (
                  <div className="validation-error-label">
                    {error.product_images_to_show}
                  </div>
                ) : null}
              </div>
            </div>

            <div>
              <span className="label-name">
                Attribute<span className="required-symbol">*</span>
              </span>
              <div className="flex flex-col gap-2">
                {inventory?.product_attributes?.map((item, index) => {
                  return (
                    <div className="flex gap-2 items-center w-[60%] justify-between">
                      <Select
                        options={attribute}
                        className="w-[200px]"
                        value={
                          inventory?.product_attributes[index]?.attribute_id
                            ? attribute?.find(
                                (option) =>
                                  option?.value ===
                                  inventory?.product_attributes?.attribute_id
                              )
                            : ""
                        }
                        name="attribute_id"
                        onChange={(e) => {
                          getAttributeValue(e, index);
                        }}
                        isSearchable
                      />

                      <Select
                        className="w-[200px]"
                        options={attributeValueList[index]}
                        name="attribute_value_id"
                        isSearchable
                        onChange={(e) => {
                          handleAttributeValueChange(e, index);
                        }}
                        value={
                          inventory?.product_attributes[index]
                            ?.attribute_value_id
                            ? attribute?.find(
                                (option) =>
                                  option?.value ===
                                  inventory?.product_attributes[index]
                                    ?.attribute_value_id
                              )
                            : ""
                        }
                      />
                      {index === 0 && (
                        <img
                          src={Add}
                          alt="add_btn"
                          className="bg-[#000] cursor-pointer"
                          onClick={() => {
                            addNewAttribute();
                          }}
                        />
                      )}
                      {index > 0 && (
                        <span
                          className="required-symbol cursor-pointer p-1"
                          onClick={() => {
                            removeAttribute(index);
                          }}
                        >
                          <img src={RecycleBin} alt="" />
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
              {error.product_attributes ? (
                <div className="validation-error-label">
                  {error.product_attributes}
                </div>
              ) : null}
            </div>

            <div className="mt-2">
              <span className="label-name">
                Tax<span className="required-symbol">*</span>
              </span>
              <div className="flex flex-col gap-2">
                {inventory?.tax_details?.map((item, index) => {
                  return (
                    <div className="flex gap-2 items-center w-[65%] justify-between">
                      <input
                        type="number"
                        className="input-field"
                        name="tax"
                        placeholder="Enter Tax amount"
                        onChange={(e) => {
                          handleTaxCalculation(e, index);
                        }}
                      />
                      <input
                        type="number"
                        className="input-field"
                        name="from_amount"
                        placeholder="Amount From"
                        onChange={(e) => {
                          handleTaxCalculation(e, index);
                        }}
                      />
                      <input
                        type="number"
                        className="input-field"
                        name="to_amount"
                        placeholder="Amount To"
                        onChange={(e) => {
                          handleTaxCalculation(e, index);
                        }}
                      />
                      {index === 0 && (
                        <img
                          src={Add}
                          alt="add_btn"
                          className="bg-[#000] cursor-pointer"
                          onClick={() => {
                            addNewTax();
                          }}
                        />
                      )}
                      {index > 0 && (
                        <span
                          className="required-symbol cursor-pointer p-1"
                          onClick={() => {
                            removeTax(index);
                          }}
                        >
                          <img src={RecycleBin} alt="" />
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
              {error.tax_details ? (
                <div className="validation-error-label">
                  {error.tax_details}
                </div>
              ) : null}
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={() => resetForm()}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                type="button"
                className="submit-button"
                disabled={buttonDisabled}
              >
                <span
                  className="submit-button-label"
                  onClick={() => addProduct()}
                >
                  Add Product
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  // function ProductAttribute({ inventory, setInventory, i }) {
  //   const [overallAttributeValue, setOverallAttributeValue] = useState([
  //     {
  //       attribute_id: "",
  //       attribute_value_id: "",
  //     },
  //   ]);

  //   return (
  //     <div className="flex flex-col gap-2">
  //       {overallAttributeValue?.map((item, index) => (
  //         <ProductAttributeList
  //           index={index}
  //           overallAttributeValue={overallAttributeValue}
  //           setOverallAttributeValue={setOverallAttributeValue}
  //           inventory={inventory}
  //           setInventory={setInventory}
  //         />
  //       ))}
  //     </div>
  //   );
  // }

  // function ProductAttributeList({
  //   index,
  //   overallAttributeValue,
  //   setOverallAttributeValue,
  //   inventory,
  //   setInventory,
  // }) {
  //   const [attributeList, setAttributeList] = useState([]);

  //   return <></>;
  // }
}

export default AddInventory;
