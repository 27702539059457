import React, { useEffect, useState } from "react";
import Select from "react-select";
import Arrow from "../../../Assets/Icons/RightArrow.svg";
import Spinner from "../../Spinner";
import { GET_API, POST_API } from "../../../Services/api";
import alertify from "alertifyjs";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Validation from "../../../Validation/Validation";

function UserPrivileges() {
  const [loading, setLoading] = useState(false);
  const [activeModel, setActiveModel] = useState();
  const [modelList, setModelList] = useState([]);
  const [buttonDisabled, setbuttonDisabled] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [errors, setErrors] = useState({});
  const [userPrivilege, setUserPrivilege] = useState({
    id: "",
    type: "",
    module_id: "",
    data: [],
  });
  useEffect(() => {
    getModelList();
  }, []);

  const getModelList = async () => {
    setLoading(true);
    try {
      const modelList = await GET_API("/moduleList");
      if (modelList?.data?.status === 200) {
        const floor = modelList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floor.unshift({ value: "", label: "Select..." });
        setModelList(floor);
        setLoading(false);
        return;
      }
    } catch (error) {}
  };

  const getMenuList = async (e) => {
    if (e?.value === "") return;
    setLoading(true);
    setActiveModel(e?.value);
    const type = userPrivilege?.type;
    const id = userPrivilege?.id;
    try {
      const list = await GET_API(`/menuList/${type}/${e?.value}/${id}`);
      if (list?.data?.status === 200) {
        setLoading(false);
        setUserPrivilege({
          ...userPrivilege,
          module_id: e?.value,
          data: list?.data?.data,
        });
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get Menu list...");
    }
  };

  const handleActiveStatus = (type, e, menuID, menuIndex, submenuIndex) => {
    const menuList = userPrivilege?.data?.map((item, i) => {
      const submenuList = item?.submenus?.map((submenu, i) => {
        if (submenu?.menu_id === menuID && submenuIndex === i) {
          return { ...submenu, [type]: e?.target?.checked };
        } else {
          return { ...submenu };
        }
      });
      return { ...item, submenus: submenuList };
    });
    setUserPrivilege({ ...userPrivilege, data: menuList });
  };

  const handleGroupSelect = (e, menuIndex) => {
    const menuList = userPrivilege?.data?.map((item, i) => {
      if (menuIndex === i) {
        const submenuList = item?.submenus?.map((submenu) => {
          return {
            ...submenu,
            add: e?.target?.checked,
            edit: e?.target?.checked,
            delete: e?.target?.checked,
            view: e?.target?.checked,
          };
        });
        return {
          ...item,
          menu: { ...item?.menu, groupSelect: e?.target?.checked },
          submenus: submenuList,
        };
      } else {
        return { ...item };
      }
    });
    setUserPrivilege({ ...userPrivilege, data: menuList });
  };

  const handleSubmit = async () => {
    const isValid = Validation.validatePrivilege(userPrivilege);
    if (isValid && isValid?.error) {
      setbuttonDisabled(false);
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setErrors({});
      setbuttonDisabled(false);
    }
    try {
      const addUserRolePrivilege = await POST_API(
        "addUserRolePrivileges",
        userPrivilege
      );
      if (addUserRolePrivilege?.data?.status === 200) {
        setbuttonDisabled(false);
        alertify?.success(addUserRolePrivilege?.data?.message);
      }
    } catch (error) {
      setbuttonDisabled(false);
      alertify?.error("Failed to add privilege");
    }
  };

  const handleType = (e) => {
    setUserPrivilege({ ...userPrivilege, id: "", type: e?.value });
    getPrivilegeForUserOrRole(e?.value);
  };

  const getPrivilegeForUserOrRole = async (type) => {
    if (type === "") return;
    setLoading(true);
    try {
      const url =
        type === "role" ? "RolesListDropdown" : "userEmployeeDropdown";
      const getList = await GET_API(url);
      if (getList?.data?.status === 200) {
        setLoading(false);
        const typeListData = getList?.data?.data.map((item) => {
          if (type === "user") {
            return {
              label: `${item?.first_name} (${item?.Employeecode})`,
              value: item?.id,
            };
          } else {
            return {
              label: item?.name,
              value: item?.id,
            };
          }
        });
        typeListData.unshift({ value: "", label: "Select..." });
        setTypeList(typeListData);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get Type details...");
    }
  };

  const handleChange = (e) => {
    if (e?.value === "") return;
    setUserPrivilege({ ...userPrivilege, id: e?.value });
  };
  const type = [
    {
      label: "Select...",
      value: "",
    },
    {
      label: "User",
      value: "user",
    },
    {
      label: "Role",
      value: "role",
    },
  ];

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="grow bg-[#F7F6F4]">
      <div className="sm-container">
        <h1 className="heading-text">User Privilege</h1>
        <div className="border-[1px] border-primary p-4 bg-white-color shadow-lg rounded">
          <div className="w-full flex gap-2">
            <div className="w-6/12 min-h-[80px] flex flex-col justify-between">
              <span className="font-karla text-text-dark text-base">Type</span>
              <Select
                isSearchable
                options={type}
                onChange={(e) => handleType(e)}
                value={type?.find(
                  (option) => option?.value === userPrivilege?.type
                )}
              />
              {errors.type ? (
                <div className="validation-error-label">{errors.type}</div>
              ) : null}
            </div>
            <div className="w-6/12 min-h-[80px] flex flex-col justify-end">
              <Select
                isSearchable
                options={typeList}
                value={typeList?.find(
                  (option) => option?.value === userPrivilege?.id
                )}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              {errors.id ? (
                <div className="validation-error-label">{errors.id}</div>
              ) : null}
            </div>
          </div>
          <span className="font-karla text-text-dark text-base">Modules</span>
          <div className="flex gap-2 w-full">
            <div className="w-10/12">
              <Select
                isSearchable
                options={modelList}
                onChange={(e) => getMenuList(e)}
                value={
                  activeModel
                    ? modelList?.find((option) => option?.value === activeModel)
                    : ""
                }
              />

              {errors.module_id ? (
                <div className="validation-error-label">{errors.module_id}</div>
              ) : null}
            </div>
            <button
              disabled={buttonDisabled}
              type="submit"
              className="submit-button"
              onClick={() => {
                handleSubmit();
              }}
            >
              <span className="submit-button-label">Submit</span>
              <img src={ColorAdd} alt="" />
            </button>
          </div>

          {userPrivilege?.data &&
            userPrivilege?.data?.map((list, i) => {
              return (
                <MenuItems
                  menuList={list}
                  userPrivilege={userPrivilege}
                  setUserPrivilege={setUserPrivilege}
                  menuIndex={i}
                  handleActiveStatus={handleActiveStatus}
                />
              );
            })}
        </div>
      </div>
    </div>
  );

  function MenuItems({ menuList, menuIndex, handleActiveStatus }) {
    const [showContent, setShowContent] = useState(true);
    return (
      <div className="border-[1px] border-primary p-4 bg-white-color shadow-lg rounded mt-4">
        <div className="items-center flex justify-between items-center">
          <div className="flex gap-2">
            <input
              type="checkbox"
              className="cursor-pointer"
              onChange={(e) => {
                handleGroupSelect(e, menuIndex);
              }}
              checked={userPrivilege?.data[menuIndex]?.menu?.groupSelect}
            />
            <span className="font-karla text-lg text-text-dark">
              {menuList?.menu?.display_name}
            </span>
          </div>
          <div className="cursor-pointer">
            <img
              src={Arrow}
              alt=""
              style={{
                transform: showContent ? "rotate(90deg)" : "rotate(0)",
              }}
              onClick={() => {
                setShowContent((prev) => !prev);
              }}
            />
          </div>
        </div>
        {showContent &&
          menuList?.submenus?.map((submenu, i) => {
            return (
              <SubMenuItems
                submenu={submenu}
                menuIndex={menuIndex}
                submenuIndex={i}
                handleActiveStatus={handleActiveStatus}
              />
            );
          })}
      </div>
    );
  }

  function SubMenuItems({
    submenu,
    menuIndex,
    submenuIndex,
    handleActiveStatus,
  }) {
    return (
      <div className="pt-5 font-montserrat text-sm font-semibold">
        <span>{submenu?.display_name}</span>
        <div className="py-5 flex justify-between items-center border-b-[1px] border-b-[#DADADA]">
          <div className="flex gap-2">
            <input
              type="checkbox"
              className="cursor-pointer"
              checked={
                userPrivilege?.data[menuIndex]?.submenus[submenuIndex]?.add
                  ? userPrivilege?.data[menuIndex]?.submenus[submenuIndex]?.add
                  : ""
              }
              onChange={(e) => {
                handleActiveStatus(
                  "add",
                  e,
                  submenu?.menu_id,
                  menuIndex,
                  submenuIndex
                );
              }}
            />
            <div className="font-montserrat text-text-light text-sm font-medium	">
              Add
            </div>
          </div>
          <div className="flex gap-2">
            <input
              type="checkbox"
              className="cursor-pointer"
              onChange={(e) => {
                handleActiveStatus(
                  "edit",
                  e,
                  submenu?.menu_id,
                  menuIndex,
                  submenuIndex
                );
              }}
              checked={
                userPrivilege?.data[menuIndex]?.submenus[submenuIndex]?.edit
                  ? userPrivilege?.data[menuIndex]?.submenus[submenuIndex]?.edit
                  : ""
              }
            />
            <div className="font-montserrat text-text-light text-sm font-medium	">
              Edit
            </div>
          </div>
          <div className="flex gap-2">
            <input
              type="checkbox"
              className="cursor-pointer"
              checked={
                userPrivilege?.data[menuIndex]?.submenus[submenuIndex]?.view
                  ? userPrivilege?.data[menuIndex]?.submenus[submenuIndex]?.view
                  : ""
              }
              onChange={(e) => {
                handleActiveStatus(
                  "view",
                  e,
                  submenu?.menu_id,
                  menuIndex,
                  submenuIndex
                );
              }}
            />
            <div className="font-montserrat text-text-light text-sm font-medium	">
              View
            </div>
          </div>
          <div className="flex gap-2">
            <input
              type="checkbox"
              className="cursor-pointer"
              onChange={(e) => {
                handleActiveStatus(
                  "delete",
                  e,
                  submenu?.menu_id,
                  menuIndex,
                  submenuIndex
                );
              }}
              checked={
                userPrivilege?.data[menuIndex]?.submenus[submenuIndex]?.delete
                  ? userPrivilege?.data[menuIndex]?.submenus[submenuIndex]
                      ?.delete
                  : ""
              }
            />
            <div className="font-montserrat text-text-light text-sm font-medium	">
              Delete
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default UserPrivileges;
