import React, { useState, useEffect } from "react";
import alertify from "alertifyjs";
import Spinner from "../../Spinner";
import { GET_API } from "../../../Services/api";
import "alertifyjs/build/css/alertify.css";

function EmployeeAllowance({ id, page }) {
  const listId = id;

  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [employeeAllowanceList, setEmployeeAllowanceList] = useState([]);

  useEffect(() => {
    if (listId) {
      getEmployeeAllowanceList();
    }
  }, [listId]);

  const getEmployeeAllowanceList = async () => {
    try {
      setIsEmpLoading(true);
      const employeeAllowanceList = await GET_API(
        `/empAllowanceDetailsList/${listId}`
      );
      if (employeeAllowanceList?.data?.status === 200) {
        setEmployeeAllowanceList(employeeAllowanceList?.data?.data);
        setIsEmpLoading(false);
      }
    } catch (error) {
      setIsEmpLoading(false);
      alertify.error("Failed to get Allowance Type List...");
    }
  };

  if (isEmpLoading) {
    return <Spinner />;
  }

  return (
   
      <div className="list-container mt-3">
        <div className="p-4 grid grid-cols-3 gap-5">
          {employeeAllowanceList?.length > 0 &&
            employeeAllowanceList?.map((item, i) => {
              return (
                <>
                  <div className="list">
                    <label className="list-label">Allowance Type </label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {item?.allowance_type_name}
                    </div>
                  </div>
                  <div className="list">
                    <label className="list-label">In Compliance</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {item?.in_compliance === 1 ? "True" : "False"}
                    </div>
                  </div>
                  <div className="list">
                    <label className="list-label">Calculation Method</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {item?.calculation_method}
                    </div>
                  </div>
                  <div className="list">
                    <label className="list-label">Amount</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {item?.amount}
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
  );
}

export default EmployeeAllowance;
