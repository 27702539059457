import React from "react";
import { Link, useLocation } from "react-router-dom";

function TerminationReportDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const father_name = listDetails?.fathers_name
    ? listDetails?.fathers_name
    : "";
  const Details = [
    {
      label: "Employee Name",
      value: listDetails?.employeeName + " " + father_name,
    },

    {
      label: "Employee Code",
      value: listDetails?.employeeCode,
    },
    {
      label: "Branch name",
      value: listDetails?.branchName,
    },
    {
      label: "Department",
      value: listDetails?.departmentName,
    },

    {
      label: " Gender",
      value: listDetails?.gender,
    },
    {
      label: "Terminate by",
      value: listDetails?.terminated_by_name,
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/report/termination" className="in-active">
              Termination Report
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Termination Report Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Employee Terminate Details</h4>
          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TerminationReportDetails;
