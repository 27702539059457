import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Select from "react-select";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { GET_API, POST_API } from "../../Services/api";
import alertify from "alertifyjs";

const CustomDialog = ({
  isOpen,
  onClose,
  terminateEmployeeId,
  terminateEmployeeName,
  terminateEmployeeCode,
  getEmployeeList,
  pageSize,
  currentPage,
  filterParams,
  searchKey,
  employeeList,
}) => {
  const [terminateEmployee, setTerminateEmployee] = useState({
    employee_id: "",
    termination_id: "",
    terminated_by: "",
    remarks: "",
  });
  const [terminateTypeList, setTerminateTypeList] = useState([]);
  const [isButtonDisable, setIsButtonDisable] = useState(false);

  useEffect(() => {
    getTerminationTypeList();
    const user_id = localStorage.getItem("user_id");
    setTerminateEmployee({
      ...terminateEmployee,
      terminated_by: user_id,
      employee_id: terminateEmployeeId,
    });
  }, [terminateEmployeeId]);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setTerminateEmployee({ ...terminateEmployee, [name]: value });
  };

  const handleTerminate = async () => {
    setIsButtonDisable(true);
    try {
      const terminate = await POST_API("/terminateEmployee", terminateEmployee);
      if (terminate.status === 200) {
        alertify.success(terminate?.data?.message);
        getEmployeeList(currentPage, pageSize, filterParams, searchKey);
        onClose();
        setIsButtonDisable(false);
        return;
      }
      onClose();
    } catch (error) {
      setIsButtonDisable(false);
      alertify.error("Failed to Terminate Employee");
    }
  };
  const getTerminationTypeList = async () => {
    try {
      const terminationTypeList = await GET_API("/TerminationTypeList");
      if (terminationTypeList?.status === 200) {
        const terminate = terminationTypeList?.data?.data?.data.map((item) => {
          return { label: item?.name, value: item?.id };
        });
        terminate.unshift({ label: "Select...", value: "" });
        setTerminateTypeList(terminate);
      }
    } catch (error) {
      alertify.error(error?.response?.data?.message);
    }
  };

  const handleSelectChange = async (e, selectName) => {
    const { value } = e;
    setTerminateEmployee({ ...terminateEmployee, [selectName]: value });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        style: {
          minWidth: "500px",
          minHeight: "300px",
        },
      }}
    >
      <DialogTitle>Terminate Employee</DialogTitle>
      <DialogContent>
        <div className="flex justify-between">
          <div>
            <label className="label-name">Employee Name</label>
            <div className="font-karla text-text-dark">
              {terminateEmployeeName}
            </div>
          </div>
          <div>
            <label className="label-name">Employee Code</label>
            <div className="font-karla text-text-dark">
              {terminateEmployeeCode}
            </div>
          </div>
        </div>
        <div className="mt-3">
          <label className="label-name">Terminate Type</label>
          <Select
            options={terminateTypeList}
            onChange={(e) => handleSelectChange(e, "termination_id")}
            name="termination_id"
            value={terminateTypeList?.find(
              (option) => option?.value === terminateEmployee?.termination_id
            )}
            className=""
            isSearchable
          />
        </div>
        <div className="mt-3">
          <label className="label-name">Remarks</label>
          <TextareaAutosize
            minRows={5}
            placeholder="Enter Reason"
            onChange={handleChange}
            name="remarks"
            style={{
              width: "100%",
              resize: "none",
              padding: "8px",
              marginTop: "8px",
              border: "1px solid #ccc",
              fontFamily: "Karla",
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <button
          onClick={handleTerminate}
          type="button"
          className="submit-button"
          disabled={isButtonDisable}
        >
          <span className="submit-button-label">Terminate</span>
        </button>
        <button type="button" className="reset-button" onClick={onClose}>
          <span className="reset-button-label">Cancel</span>
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
