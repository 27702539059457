import React, { useState, useEffect } from "react";
import More from "../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Search from "../../Assets/Icons/Search.svg";
import { GET_API, DELETE_API } from "../../Services/api";
import HolidaySchemeTable from "../DataTable/HolidaySchemeTable";
import Footer from "../Footer";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Spinner from "../Spinner";

function HolidayScheme() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [holidaySchemeList, setHolidaySchemeList] = useState([]);
  const [filteredHolidayScheme, setFilteredHolidayScheme] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");
  const navigateEditPage = (listDetail) => {
    navigate("/master/hrms/holidayscheme/edit", {
      state: {
        list: listDetail,
      },
    });
  };

  const showBranchDetails = (listDetail) => {
    navigate("/master/hrms/holidayscheme/details", {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s.no", label: "S.No", minWidth: 170 },
    { id: "holiday_scheme_name", label: "Holiday Scheme Name", minWidth: 100 },
    { id: "description", label: "Description", minWidth: 100 },
    { id: "special_notes", label: "Special Notes", minWidth: 100 },
    { id: "created_at", label: "Created at", minWidth: 100 },
    { id: "more_icon", label: "", minWidth: 100 },
  ];

  useEffect(() => {
    getHolidaySchemeList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getHolidaySchemeList(1, pageSize, searchKey);
  };

  const getHolidaySchemeList = async (page, size, search) => {
    setLoading(true);
    try {
      const schmemeList = await GET_API(
        `/HolidaySchemeList?page=${page}&size=${size}&search=${search}`
      );
      if (schmemeList.status === 200) {
        if (
          schmemeList?.data?.data?.current_page >
          schmemeList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setHolidaySchemeList(schmemeList?.data?.data?.data);
        setFilteredHolidayScheme(schmemeList?.data?.data?.data);
        setLastPage(schmemeList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (e) {
      alertify.error(e?.response?.data?.message);
      setHolidaySchemeList([]);
      setFilteredHolidayScheme([]);
      setLastPage("");
      setCurrentPage(1);
      setLoading(false);
    }
  };

  const handleHolidaySchemeSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = holidaySchemeList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredHolidayScheme(searchedList);
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Holiday Scheme..?",
        async function () {
          if (row_id) {
            try {
              const deleteRow = await DELETE_API(
                `/deleteHolidaysScheme/${row_id}`
              );
              if (deleteRow.status === 200) {
                alertify.success(deleteRow?.data?.message);
                getHolidaySchemeList(currentPage, pageSize, searchKey);
                setCurrentPage(1);
                return;
              }
            } catch (error) {
              alertify.error("Failed To Delete HolidayScheme");
            }
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Holiday Scheme List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => setSearchKey(e?.target?.value)}
                value={searchKey}
                style={{ outline: "none", border: "none" }}
              />

              <img
                src={Search}
                alt=""
                className="cursor-pointer"
                onClick={() => {
                  getHolidaySchemeList(currentPage, pageSize, searchKey);
                }}
              />
            </div>

            <div className="add-new-button-block">
              <Link
                to="/master/hrms/holidayscheme/add"
                className="add-new-button"
              >
                <span>Add New</span>
                <img src={ColorAdd} alt="add" />
              </Link>
            </div>
          </div>
          {/**************** * Table ********************/}
          <HolidaySchemeTable
            list={filteredHolidayScheme}
            columns={columns}
            icon={More}
            multiple={true}
            editPage={navigateEditPage}
            showDetails={showBranchDetails}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HolidayScheme;
