import React from "react";
import { Link, useLocation } from "react-router-dom";

function BranchDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const Details = [
    {
      label: "Branch Name",
      value: listDetails?.name,
    },

    {
      label: "No.of.Floors",
      value: listDetails?.no_of_floors,
    },
    {
      label: "Address",
      value: listDetails?.address,
    },

    {
      label: "Town / City",
      value: listDetails?.town,
    },
    {
      label: "State",
      value: listDetails?.state,
    },
    {
      label: "Pin Code",
      value: listDetails?.pincode,
    },
    {
      label: "Contact Person",
      value: listDetails?.contact_person,
    },

    {
      label: "Contact Number",
      value: listDetails?.phone,
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className=" sm-container">
          <div className="bread-crumbs">
            <div>
              <Link to="/master/hrms/branch" className="in-active">
                Branch List
              </Link>
              <Link>{" > "}</Link>
              <Link className="active">Branch Details</Link>
            </div>
          </div>
          <div className="card-wrapper">
            <h4 className="card-header">Branch Details</h4>

            <div className="list-container">
              <div className="list-content-block">
                {Details.map((list, i) => {
                  return (
                    <div className="list " key={i}>
                      <label className="list-label">{list.label}</label>
                      <div
                        style={{ wordBreak: "break-word" }}
                        className="list-value"
                      >
                        {list.value}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BranchDetails;
