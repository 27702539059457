import { useEffect, useRef } from "react";
const UseOutsideClick = (callback) => {
  let ref = useRef();
  useEffect(() => {
    let handleClick = async (e) => {
      if (!ref?.current?.contains(e.target)) {
        await callback();
      }
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });
  return ref;
};
export default UseOutsideClick;
