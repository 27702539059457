import React from "react";
import { Route } from "react-router-dom";

import RoleList from "../RoleMaster/RoleList";

import User from "../UserMaster/User";
import AddUser from "../UserMaster/AddUser";

import AllowanceType from "../FinanceMaster/AllowanceType/allowance";
import GovtIDProof from "../GoveIDProofMaster/IDProofList";

import Department from "../EnterpriseMaster/Department/DepartmentList";
import CreateDepartment from "../EnterpriseMaster/Department/CreateDepartment";
import EditDepartment from "../EnterpriseMaster/Department/EditDepartment";
import DepartmentDetails from "../EnterpriseMaster/Department/DepartmentDetails";

import Designation from "../EnterpriseMaster/Designation/DesignationList";
import CreateDesignation from "../EnterpriseMaster/Designation/CreateDesignation";
import EditDesignation from "../EnterpriseMaster/Designation/EditDesignation";
import DesignationDetails from "../EnterpriseMaster/Designation/DesignationDetails";

import Shift from "../EnterpriseMaster/Shift/ShiftList";
import CreateShift from "../EnterpriseMaster/Shift/CreateShift";
import EditShift from "../EnterpriseMaster/Shift/EditShift";
import ShiftDetails from "../EnterpriseMaster/Shift/ShiftDetails";

import Termination from "../EnterpriseMaster/Termination/TerminationList";

import Break from "../EnterpriseMaster/BreakTime/BreakTimeList";
import CreateBreak from "../EnterpriseMaster/BreakTime/CreateBreakTime";
import EditBreak from "../EnterpriseMaster/BreakTime/EditBreakTime";
import BreakDetails from "../EnterpriseMaster/BreakTime/BreakTimeDetails";

import BreakFine from "../EnterpriseMaster/BreakTime/BreakFine";

import Caste from "../EnterpriseMaster/Caste/CasteList";

import District from "../EnterpriseMaster/District";

import Town from "../EnterpriseMaster/Town";

import Religion from "../EnterpriseMaster/Religion/ReligionList";

import BloodGroup from "../Employee/BloodGroup";

import Consultancy from "../AgentMaster/Consultancy/ConsultancyList";
import CreateConsultancy from "../AgentMaster/Consultancy/CreateConsultancy";
import EditConsultancy from "../AgentMaster/Consultancy/EditConsultancy";
import ConsultancyDetails from "../AgentMaster/Consultancy/ConsultanyDetails";

import Security from "../AgentMaster/Security/SecurityList";
import CreateSecurity from "../AgentMaster/Security/CreateSecurity";
import EditSecurity from "../AgentMaster/Security/EditSecurity";
import SecurityDetails from "../AgentMaster/Security/SecurityDetails";

import EmploymentType from "../Employee/EmploymentType";
import CreateEmploymentType from "../Employee/CreateEmploymentType";
import EditEmploymentType from "../Employee/EditEmploymentType";
import EmploymentTypeDetails from "../Employee/EmploymentTypeDetails";

import HalfdayVarient from "../Employee/HalfdayVarient";
import AddHalfdayVarient from "../Employee/CreateHalfdayVariant";
import EditHalfdayVarient from "../Employee/EditHalfdayVariant";
import HalfDayVariantDetails from "../Employee/HalfDayVariantDetails";
function EmployeeMastersRoutes() {
  return (
    <>
      {/***Employee */}
      <Route path="role" element={<RoleList />}></Route>

      <Route path="user" element={<User />}></Route>
      <Route path="user/create" element={<AddUser />}></Route>

      <Route path="allowancetype" element={<AllowanceType />}></Route>
      <Route path="govtidproof" element={<GovtIDProof />}></Route>

      <Route path="department" element={<Department />}></Route>
      <Route path="department/create" element={<CreateDepartment />}></Route>
      <Route path="department/edit" element={<EditDepartment />}></Route>
      <Route path="department/details" element={<DepartmentDetails />}></Route>

      <Route path="designation" element={<Designation />}></Route>
      <Route path="designation/create" element={<CreateDesignation />}></Route>
      <Route path="designation/edit" element={<EditDesignation />}></Route>
      <Route
        path="designation/details"
        element={<DesignationDetails />}
      ></Route>

      <Route path="shift" element={<Shift />}></Route>
      <Route path="shift/create" element={<CreateShift />}></Route>
      <Route path="shift/edit" element={<EditShift />}></Route>
      <Route path="shift/details" element={<ShiftDetails />}></Route>

      <Route path="termination" element={<Termination />}></Route>

      <Route path="break" element={<Break />}></Route>
      <Route path="break/create" element={<CreateBreak />}></Route>
      <Route path="break/edit" element={<EditBreak />}></Route>
      <Route path="break/details" element={<BreakDetails />}></Route>

      <Route path="breakfine" element={<BreakFine />}></Route>

      <Route path="caste" element={<Caste />}></Route>

      <Route path="district" element={<District />}></Route>

      <Route path="town" element={<Town />}></Route>

      <Route path="religion" element={<Religion />}></Route>

      <Route path="bloodgroup" element={<BloodGroup />}></Route>

      <Route path="agent/consultancy" element={<Consultancy />}></Route>
      <Route
        path="agent/consultancy/edit"
        element={<EditConsultancy />}
      ></Route>
      <Route
        path="agent/consultancy/details"
        element={<ConsultancyDetails />}
      ></Route>
      <Route
        path="agent/consultancy/add"
        element={<CreateConsultancy />}
      ></Route>

      <Route path="agent/security" element={<Security />}></Route>
      <Route path="agent/security/edit" element={<EditSecurity />}></Route>
      <Route
        path="agent/security/details"
        element={<SecurityDetails />}
      ></Route>
      <Route path="agent/security/add" element={<CreateSecurity />}></Route>

      <Route path="employementType" element={<EmploymentType />}></Route>
      <Route
        path="employementType/add"
        element={<CreateEmploymentType />}
      ></Route>
      <Route
        path="employementType/edit"
        element={<EditEmploymentType />}
      ></Route>
      <Route
        path="employementType/details"
        element={<EmploymentTypeDetails />}
      ></Route>

      <Route path="halfDayVariant" element={<HalfdayVarient />}></Route>
      <Route path="halfDayVariant/add" element={<AddHalfdayVarient />}></Route>
      <Route
        path="halfDayVariant/edit"
        element={<EditHalfdayVarient />}
      ></Route>
      <Route
        path="halfDayVariant/details"
        element={<HalfDayVariantDetails />}
      ></Route>
    </>
  );
}

export default EmployeeMastersRoutes;
