// import React from "react";
// import Paper from "@mui/material/Paper";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import dayjs from "dayjs";
// import Pagination from "./Pagination";

// export default function StickyHeadTable({
// list,
// columns,
// printVoucher,
// setPageNo,
// pageSize,
// setPageSize,
// lastPage,
// currentPage,
// }) {
//   return (
//     <Paper sx={{ width: "100%", overflow: "hidden" }}>
//       <TableContainer sx={{ maxHeight: 320 }} ref={printVoucher}>
//         <Table stickyHeader aria-label="sticky table">
//           <TableHead>
//             <TableRow>
//               {columns?.map((column, i) => (
//                 <TableCell
//                   className="table-header"
//                   key={i}
//                   align={column.align}
//                 >
//                   {column?.label}
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {list?.length === 0 ? (
//               <TableRow>
//                 <TableCell
//                   colSpan="8"
//                   className="table-body td-data-not-found h-[220px]"
//                 >
//                   Data Not Found..!
//                 </TableCell>
//               </TableRow>
//             ) : (
//               list?.map((row, i) => {
//                 return (
//                   <TableRow
//                     hover
//                     role="checkbox"
//                     tabIndex={-1}
//                     key={i}
//                     className="table-column"
//                   >
//                     {
//                       <TableCell className="table-body !w-[7%]">
//                         {(currentPage - 1) * pageSize + (i + 1)}
//                       </TableCell>
//                     }
//                     {
//                       <TableCell className="table-body">
//                         {row?.empCode}
//                       </TableCell>
//                     }
//                     {
//                       <TableCell className="table-body">
//                         {row?.empName}
//                       </TableCell>
//                     }
//                     {
//                       <TableCell className="table-body">
//                         {row?.allowanceName}
//                       </TableCell>
//                     }
//                     {
//                       <TableCell className="table-body">
//                         {dayjs(row?.actualEntryTime).format(
//                           "DD-MM-YYYY hh:mm:ss a"
//                         )}
//                       </TableCell>
//                     }
//                     {
//                       <TableCell className="table-body">
//                         {dayjs(row?.actualExitTime).format(
//                           "DD-MM-YYYY hh:mm:ss a"
//                         )}
//                       </TableCell>
//                     }
//                     {
//                       <TableCell className="table-body">
//                         {row?.amount}
//                       </TableCell>
//                     }
//                   </TableRow>
//                 );
//               })
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <Pagination
//         setPageNo={setPageNo}
//         setPageSize={setPageSize}
//         lastPage={lastPage}
//         currentPage={currentPage}
//         pageSize={pageSize}
//       />
//     </Paper>
//   );
// }

import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function DataTable({ list, setSelectedRows, printVoucher }) {
  const columns = [
    { field: "empCode", headerName: "Employee Code" },
    { field: "empName", headerName: "Employee Name" },
    { field: "allowanceName", headerName: "Allowance Name" },
    { field: "actualEntryTime", headerName: "Entry Time" },
    { field: "actualExitTime", headerName: "Exit Time" },
    { field: "amount", headerName: "Amount" },
  ];

  const handleSelectionModelChange = (selectionModel) => {
    const selectedRows = selectionModel.map((selectedId) =>
      list.find((row) => row.id === selectedId)
    );
    setSelectedRows(selectedRows);
  };
  const heightStyle =
    list?.length === 0 ? { height: 320, width: "100%" } : { width: "100%" };
  return (
    <div style={heightStyle} ref={printVoucher}>
      <DataGrid
        rows={list}
        columns={columns}
        checkboxSelection
        sx={{
          ".MuiDataGrid-footerContainer": {
            display: "none",
          },
        }}
        onRowSelectionModelChange={handleSelectionModelChange}
      />
    </div>
  );
}
