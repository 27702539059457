import React from "react";
import { Link, useLocation } from "react-router-dom";
import Edit from "../../../../Assets/Icons/Edit.svg";
import View from "../../../../Assets/Icons/View.svg";

function WarehouseDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const Details = [
    {
      label: "Warehouse Name",
      value: listDetails?.name,
    },

    {
      label: "Code",
      value: listDetails?.code,
    },
    {
      label: "Contact Person",
      value: listDetails?.contact_person_name,
    },
    {
      label: "Landline Number",
      value: listDetails?.landline_no,
    },

    {
      label: "Contact Number",
      value: listDetails?.contact_person_mobile,
    },
    {
      label: "Email",
      value: listDetails?.contact_person_email,
    },
    {
      label: "Address",
      value: listDetails?.address,
    },
    {
      label: "Description",
      value: listDetails?.description,
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className=" sm-container">
          <h1 className="heading-text">Branch</h1>
          <div className="card-wrapper">
            <h4 className="card-header">Warehouse Details</h4>
            <div className="search-block">
              <Link to="/po/master/warehouse" className="view-list-button">
                <span>View List</span>
                <img src={View} alt="add" />
              </Link>
              <Link
                to="/po/master/warehouse/edit"
                className="edit-button"
                state={{ list: listDetails }}
              >
                <span>Edit</span>
                <img src={Edit} alt="add" />
              </Link>
            </div>
            <div className="list-container">
              <div className="list-content-block">
                {Details.map((list, i) => {
                  return (
                    <div className="list " key={i}>
                      <label className="list-label">{list.label}</label>
                      <div
                        style={{ wordBreak: "break-word" }}
                        className="list-value"
                      >
                        {list.value}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WarehouseDetails;
