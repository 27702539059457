import React from "react";
import { Link } from "react-router-dom";

function PageNotFound() {
  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="card-wrapper h-full page-not-found h-screen">
        <div className="p-8 text-center">
          <h2 className="text-9xl font-karla">404</h2>
          <h4 className="text-2xl font-karla">Page Not Found</h4>
          <div className="p-5 flex justify-center">
            <div className="add-new-button-block">
              <Link className="add-new-button" to="/dashboard">
                Go To Dashboard Page
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
