import React from "react";
import Select from "react-select";
import Checkbox from "@mui/material/Checkbox";
import Delete from "../../../Assets/Icons/Delete.svg";

function AllowanceListItems({
  allowanceTypeList,
  index,
  deleteList,
  addMoreList,
  allowanceValue,
  setallowanceValue,
  item,
}) {
  const handleAllowanceSelect = (index, e, name) => {
    const { value } = e;
    const newItems = [...allowanceValue];
    newItems[index][name] = value;
    setallowanceValue(newItems);
  };

  const handleAmountChange = (index, e) => {
    const { name, value } = e?.target;
    const newItems = [...allowanceValue];
    newItems[index][name] = value;
    setallowanceValue(newItems);
  };

  const handleInComplianceChange = (index, e, name) => {
    const { checked } = e?.target;
    const newItems = [...allowanceValue];
    newItems[index][name] = checked ? 1 : 0;
    setallowanceValue(newItems);
  };

  const calculationMethod = [
    { value: "monthly", label: "Monthly" },
    { value: "daily", label: "Daily" },
  ];

  return (
    <div className="p-6 grid grid-cols-5 gap-5">
      <div className="input-block-allowance" name="allowance">
        <label className="add-new-input-label">Allowance Type</label>
        <Select
          id={`name-${index}`}
          options={allowanceTypeList}
          onChange={(e) => handleAllowanceSelect(index, e, "allowance_type_id")}
          name="allowance"
          value={allowanceTypeList?.find(
            (option) => option?.value === Number(item.allowance_type_id)
          )}
          isSearchable
        />
      </div>

      <div className="input-block-allowance" name="in_compliance">
        <label className="add-new-input-label">In Compliance</label>
        <Checkbox
          className="p-0 bg-primary-color"
          onChange={(e) => handleInComplianceChange(index, e, "in_compliance")}
          inputProps={{ "aria-label": "controlled" }}
          checked={
            item?.in_compliance === "1" || item?.in_compliance === 1
              ? true
              : false
          }
        />
      </div>

      <div className="input-block-allowance" name="calculation_method">
        <label className="add-new-input-label">Calculation Method</label>
        <Select
          id={`name-${index}`}
          options={calculationMethod}
          onChange={(e) =>
            handleAllowanceSelect(index, e, "calculation_method")
          }
          name="calculation_method"
          value={calculationMethod?.find(
            (option) => option?.value === item.calculation_method
          )}
          isSearchable
        />
      </div>
      <div className="input-block-allowance" name="amount">
        <label className="add-new-input-label">Amount</label>
        <input
          type="number"
          name="amount"
          placeholder="Enter Amount"
          className="input-field"
          onChange={(e) => handleAmountChange(index, e)}
          value={item !== "undefined" ? item["amount"] : 0}
        />
      </div>
      {index > 0 ? (
        <button
          onClick={() => {
            deleteList(index);
          }}
        >
          <img src={Delete} alt="" className="w-6 h-6" />
        </button>
      ) : (
        <div className="flex items-center">
          <div
            className="add-new-button-block add-new-button-container mt-5"
            onClick={addMoreList}
          >
            <button className="add-new-button">
              <span>Add</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AllowanceListItems;
