import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import View from "../../../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../../Validation/POValidation";
import { GET_API, PUT_API } from "../../../../Services/api";
import ColorAdd from "../../../../Assets/Icons/ColorAdd.svg";
import Select from "react-select";
import Spinner from "../../../Spinner";

function CreateSection() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const listData = state?.list;
  const [sectionDetails, setSectionDetails] = useState({
    id: listData?.id,
    name: listData?.name,
    warehouse_id: listData?.warehouse_id,
    description: listData?.description,
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [warehouseList, setWarehouseList] = useState([]);

  useEffect(() => {
    getWarehouseList();
  }, []);

  const getWarehouseList = async () => {
    setLoading(true);
    try {
      const warehouseList = await GET_API("/po/warehouse");
      if (warehouseList.status === 200) {
        const warehouse = warehouseList?.data?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        setWarehouseList(warehouse);
        setLoading(false);
        return;
      }
    } catch (error) {
      alertify.error("Failed to get Warehouse List...");
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setSectionDetails(() => ({ ...sectionDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    setSectionDetails(() => ({ ...sectionDetails, [name]: e?.value }));
  };

  const updateSection = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.Section(sectionDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const createSection = await PUT_API("/po/updateSection", sectionDetails);
      if (createSection?.data?.status === 200) {
        setButtonDisabled(false);
        alertify.success(createSection?.data?.message);
        navigate("/master/po/warehouseSection");
      }
    } catch (error) {
      setButtonDisabled(false);
      for (const key in error?.response?.data?.data) {
        if (error?.response?.data?.data.hasOwnProperty(key)) {
          error?.response?.data?.data[key].forEach((value) => {
            alertify.error(value);
          });
        }
      }
    }
  };
  const resetForm = () => {
    setSectionDetails({
      id: listData?.id,
      name: listData?.name,
      warehouse_id: listData?.warehouse_id,
      description: listData?.description,
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/po/warehouseSection" className="in-active">
              Warehouse Section List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Edit Warehouse Section</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Warehouse Section</h4>

          {/***************Form************* **/}

          <form onSubmit={(e) => updateSection(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Section name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter Section Name"
                  value={sectionDetails.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Warehouse
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={warehouseList}
                  defaultValue={sectionDetails?.warehouse_id}
                  value={
                    sectionDetails?.warehouse_id
                      ? warehouseList?.find(
                          (option) =>
                            option?.value === sectionDetails?.warehouse_id
                        )
                      : ""
                  }
                  onChange={(e) => handleSelectChange(e, "warehouse_id")}
                  name="warehouse_id"
                  className=""
                  isSearchable
                />
                {errors.warehouse_id ? (
                  <div className="validation-error-label">
                    {errors.warehouse_id}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Description</label>
                <textarea
                  className="input-field"
                  type="text"
                  name="description"
                  autoComplete="off"
                  rows={5}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Description"
                  value={sectionDetails?.description}
                />
                {errors.description ? (
                  <div className="validation-error-label">
                    {errors.description}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateSection;
