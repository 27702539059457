import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Pagination from "./Pagination";
import TableRow from "@mui/material/TableRow";
import MoreOption from "../MoreOption";
import dayjs from "dayjs";
import config from "../../Services/config.json";
import { Link } from "react-router-dom";

export default function StickyHeadTable({
  list,
  columns,
  showDetails,
  icon,
  multiple,
  deleteRow,
  editPage,
  allowance,
  termination,
  terminateEmployee,
  activeEdit,
  activeDelete,
  foodAllowance,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
  download,
  downloadEmployeeForm,
  isEnableIDCard,
  idCardPreviewPage,
}) {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list.map((row, i) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    {
                      <TableCell className="!w-[7%] table-body ">
                        {row?.profile_image !== "" ? (
                          <img
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "40px",
                            }}
                            src={config?.path + row?.profile_image}
                            alt="profile_image"
                          />
                        ) : (
                          <div
                            className="h-[40px] w-[40px] text-base rounded-3xl flex justify-center items-center text-text-dark"
                            style={{ backgroundColor: "#ccc" }}
                          >
                            {row?.first_name?.charAt(0).toUpperCase()}
                          </div>
                        )}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        <Link
                          to={`/employee/details/${row?.employee_Id}`}
                          style={{ color: "#0284C7" }}
                          className="text-sky-600 border-b-[1px] border-b-[#0284C7]"
                        >
                          {row.emp_code}
                        </Link>
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row?.first_name}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row?.departmentname}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row?.phone_no}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.employee_active === 0 ? (
                          <span
                            className=" bg-[#FF5C42] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Deleted
                          </span>
                        ) : row?.employee_active === 1 ? (
                          <span
                            className="bg-[#3EAB82] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Approved
                          </span>
                        ) : row?.employee_active === 3 ? (
                          <span
                            className="bg-[#EEC9C9] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Pending
                          </span>
                        ) : row?.employee_active === 4 ? (
                          <span
                            className="bg-[#00C0EF] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Rejected Master Log
                          </span>
                        ) : row?.employee_active === 5 ? (
                          <span
                            className=" bg-[#553C7E] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Terminated
                          </span>
                        ) : null}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {dayjs(row?.created_at).format("DD-MM-YYYY hh:mm:ss")}
                      </TableCell>
                    }
                    <MoreOption
                      icon={icon}
                      row={row}
                      showDetails={showDetails}
                      multiple={multiple}
                      deleteRow={deleteRow}
                      editPage={editPage}
                      allowance={allowance}
                      termination={termination}
                      terminateEmployee={terminateEmployee}
                      activeEdit={activeEdit}
                      activeDelete={activeDelete}
                      foodAllowance={foodAllowance}
                      download={download}
                      downloadEmployeeForm={downloadEmployeeForm}
                      isEnableIDCard={isEnableIDCard}
                      idCardPreviewPage={idCardPreviewPage}
                    />
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
