import React from "react";
import { Route } from "react-router-dom";

import FoodAllowanceList from "../Voucher/FoodAllowance/FoodAllowanceList";

function ApproveRoutes() {
  return (
    <>
      <Route path="foodAllowance" element={<FoodAllowanceList />}></Route>
    </>
  );
}

export default ApproveRoutes;
