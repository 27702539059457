import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Pagination from "./Pagination";
import TableRow from "@mui/material/TableRow";

export default function StickyHeadTable({
  list,
  columns,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
}) {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={i}
                  align={column.align}
                  className="table-header"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, i) => {
                const father_name = row?.father_name ? row.father_name : "";
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    {
                      <TableCell className="table-body !w-[7%]">
                        {(currentPage - 1) * pageSize + (i + 1)}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row.EmployeeCode}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.first_name + " " + father_name}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.departmentname}
                      </TableCell>
                    }
                    {/* {
                      <TableCell className="table-body">
                        {row.designationName}
                      </TableCell>
                    } */}
                    {
                      <TableCell className="table-body">
                        {row.logDate}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.clockInTime}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.shiftTime}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        <b
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          ₹{" "}
                          {Math.round(
                            (row.timeDifferenceInSeconds / 60) *
                              row.FinePerMinute
                          )}
                        </b>
                      </TableCell>
                    }
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
