import React from "react";
import { Navigate } from "react-router";

function UserAuthenticator({ children }) {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");
  if (!token || !user_id || !user_role) return <Navigate to="/login" />;
  return children;
}

export default UserAuthenticator;
