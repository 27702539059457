import React from "react";

function BankInfo({ listDetails, page }) {
  const Details = [
    {
      label: "Account Holder Name",
      value: listDetails?.account_holder_name
        ? listDetails?.account_holder_name
        : "-",
    },
    {
      label: "Account Number",
      value: listDetails?.account_number ? listDetails?.account_number : "-",
    },
    {
      label: "IFSC Code",
      value: listDetails?.ifsc_code ? listDetails?.ifsc_code : "-",
    },
    {
      label: "Bank Name",
      value: listDetails?.bank_name ? listDetails?.bank_name : "-",
    },
    {
      label: "Branch",
      value: listDetails?.bank_branch ? listDetails?.bank_branch : "-",
    },
    {
      label: "PF Number",
      value: listDetails?.pf_number ? listDetails?.pf_number : "-",
    },
    {
      label: "PF Complaint",
      value: listDetails?.pf_complaint === 0 ? "No" : "Yes",
    },
    {
      label: "ESI Number",
      value: listDetails?.esi_number,
    },
    {
      label: "Bonus",
      value: listDetails?.bonus + " ₹",
    },
    {
      label: "PRPY Scheme",
      value: listDetails?.pmrpy_scheme === 0 ? "No" : "Yes",
    },
  ];

  return (
    <div className="border-t-[1px] border-t-[#D9D9D9]">
      {page === "report" && (
        <div
          className="p-3 w-3/12 bg-primary-color text-center"
          style={{ borderRadius: "0px 0px 100px 0px" }}
        >
          <span className="text-white-color font-karla font-base ">
            Bank Info
          </span>
        </div>
      )}

      <div className="list-container mt-3">
        <div className="list-content-block">
          {Details.map((list) => {
            return (
              <div className="list">
                <label className="list-label">{list?.label}</label>
                <div style={{ wordBreak: "break-word" }} className="list-value">
                  {list?.value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default BankInfo;
