import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { GET_API } from "../../../Services/api";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";

function AttendanceReportDetails() {
  const { state } = useLocation();
  const id = state?.list;

  const [attendanceDetails, setAttendanceDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAttendanceReportDetails();
  }, [id]);
  const getAttendanceReportDetails = async () => {
    setLoading(true);
    const details = await GET_API(`attendanceReportView/${id}`);
    if (details?.data?.status === 200) {
      setAttendanceDetails(details?.data?.data);
      setLoading(false);
    }
  };
  const Details = [
    {
      label: "Employee Name",
      value: `${attendanceDetails?.first_name} ${attendanceDetails?.fathers_name}`,
    },
    {
      label: "Employee Code",
      value: attendanceDetails?.employeeCode,
    },

    {
      label: "Department Name",
      value: attendanceDetails?.departmentname,
    },
    {
      label: "Designation Name",
      value: attendanceDetails?.designationname,
    },
    {
      label: "Clock Time",
      value: attendanceDetails?.date,
    },
    {
      label: "Caste",
      value: attendanceDetails?.Caste,
    },
    {
      label: "Religion",
      value: attendanceDetails?.Religion,
    },
    {
      label: "Fine Amount",
      value: attendanceDetails?.fine_amount,
    },
    {
      label: "Total Working Hours",
      value: attendanceDetails?.total_working_hours,
    },
    {
      label: "Agent Name",
      value: attendanceDetails?.agentName,
    },
    {
      label: "Break Duration",
      value: attendanceDetails?.break_duration,
    },
    {
      label: "Shift",
      value: attendanceDetails?.shiftName,
    },
    {
      label: "Clock In time",
      value: attendanceDetails?.clock_in_time,
    },
    {
      label: "Clock Out time",
      value: attendanceDetails?.clock_out_time,
    },
  ];

  const showLogList = () => {
    if (attendanceDetails?.logs.length === 0) {
      alertify
        .alert("Log Details Details Not Found...!")
        .set("movable", false)
        .setHeader("<b>Log Details</b> ");
    } else {
      var itemElement = document.createElement("div");
      itemElement.innerHTML = `
              <table className="log-table mt-[4px]">
                <tr className="bg-[#FFEDC2]">
                  <td className="log-table log-table-data content-label font-extrabold">
                      Time
                  </td>
                  <td className="log-table log-table-data content-label font-extrabold">
                      IP Address
                  </td>
                </tr>
               ${
                 attendanceDetails?.logs &&
                 JSON.parse(attendanceDetails?.logs)
                   .map((item) => {
                     return `<tr>
                      <td className="log-table log-table-data content-label whitespace-nowrap">
                     ${item?.time}
                      </td>
                      <td className="log-table log-table-data content-label">
                        ${item?.ip}
                      </td>
                     
                    </tr>`;
                   })
                   .join("")
               }
                </table>`;
      alertify
        .alert(itemElement)
        .set("movable", false)
        .setHeader("<b> Log Details.</b> ");
    }
  };

  if (loading) return <Spinner />;
  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/report/attendance" className="in-active">
              Attendance Report
            </Link>
            <Link>{" > "}</Link>
            <Link className="active"> Attendance Report Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Attendance Report Details</h4>

          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
              <div className="list ">
                <div
                  className="add-new-button-block w-[20%]"
                  onClick={() => {
                    showLogList();
                  }}
                >
                  <button className="add-new-button">
                    <span>Logs</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AttendanceReportDetails;
