import axios from "axios";
import config from "./config.json";

const baseURL = config.base_url;
export const BASE_URL = config.base_url;
export const BASE_PATH = config.path;

axios.defaults.baseURL = baseURL;

// axios.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (401 === error.response.status) {
//       localStorage.removeItem("token");
//       window.location.href = "/login";
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );

const setHeaders = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
};

export const GET_API = async (url) => {
  await setHeaders();
  return axios.get(url);
};

export const POST_API = async (url, payload) => {
  await setHeaders();
  return axios.post(url, payload);
};

export const DELETE_API = async (url) => {
  await setHeaders();
  return axios.delete(url);
};

export const PUT_API = async (url, payload) => {
  await setHeaders();
  return axios.put(url, payload);
};

export const UPLOAD_API = async (url, payload) => {
  const token = localStorage.getItem("token");

  return fetch(baseURL + url, {
    method: "POST",
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
    },
    body: payload,
  });
};
