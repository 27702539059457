import React, { useState, useEffect } from "react";
import View from "../../Assets/Icons/View.svg";
import Save from "../../Assets/Icons/Save.svg";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { GET_API } from "../../Services/api";
import Validation from "../../Validation/Validation";
import { POST_API } from "../../Services/api";
import alertify from "alertifyjs";
import Spinner from "../Spinner";
import "alertifyjs/build/css/alertify.css";

function EditAgentCommission() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const listData = state?.list;
  const [errors, seterrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [agentType, setagentType] = useState([]);
  const [editAgentDetails, setEditAgentDetails] = useState({
    id: listData?.id,
    agent_id: listData?.agent_id,
    agent_type_id: listData?.agent_id,
    amount: listData?.amount,
    percentage: listData?.percentage,
    remarks: listData?.remarks,
  });

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setEditAgentDetails(() => ({ ...editAgentDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    const { value } = e;
    setEditAgentDetails(() => ({
      ...editAgentDetails,
      [name]: value,
      agent_type_id: value,
    }));
  };

  const editAgentcommission = async (e) => {
    e.preventDefault();

    setButtonDisabled(true);
    const isValid = Validation.validateAgentCommission(editAgentDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const addAgent = await POST_API(
        "/updateAgentCommission",
        editAgentDetails
      );
      if (addAgent?.status === 200) {
        alertify.success("Agent Commission Updated Successfully!");
        navigate("/expense/agentcommission");
      }
    } catch (error) {
      alertify.error("Failed to Edit Agent Commision");
    }
  };

  useEffect(() => {
    getAgentType();
  }, []);

  const getAgentType = async () => {
    setLoading(true);
    try {
      const getType = await GET_API("/agentDropdown/consultancy");
      if (getType.status === 200) {
        const agent = getType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.company_name };
        });
        setagentType(agent);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to get Agent Type List...");
    }
  };

  const resetForm = () => {
    setEditAgentDetails({
      agent_id: listData?.agent_id,
      agent_type_id: listData?.agent_id,
      amount: listData?.amount,
      percentage: listData?.percentage,
      remarks: listData?.remarks,
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/expense/agentcommission" className="in-active">
              Agent Commission List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Edit Agent Commission</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Edit Agent Commission</div>

          <form onSubmit={(e) => editAgentcommission(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Agents Type
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={agentType}
                  value={
                    editAgentDetails.agent_id
                      ? agentType?.find(
                          (option) =>
                            option?.value === editAgentDetails.agent_id
                        )
                      : ""
                  }
                  onChange={(e) => handleSelectChange(e, "agent_id")}
                  name="agent_id"
                  className=""
                  isSearchable
                />
                {errors.agent_id ? (
                  <div className="validation-error-label">
                    {errors.agent_id}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Amount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="number"
                  autoComplete="off"
                  placeholder="Enter Amount"
                  name="amount"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={editAgentDetails?.amount}
                />
                {errors.amount ? (
                  <div className="validation-error-label">{errors.amount}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Percentage
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="number"
                  autoComplete="off"
                  placeholder="Enter Percentage"
                  name="percentage"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={editAgentDetails?.percentage}
                />
                {errors.percentage ? (
                  <div className="validation-error-label">
                    {errors.percentage}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Remarks</label>
                <textarea
                  rows={5}
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  placeholder="Enter Remarks"
                  name="remarks"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={editAgentDetails?.remarks}
                />
                {errors.remarks ? (
                  <div className="validation-error-label">{errors.remarks}</div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                type="submit"
                className="submit-button"
                disabled={buttonDisabled}
              >
                <span className="submit-button-label">Save</span>
                <img src={Save} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditAgentCommission;
