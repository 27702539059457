import React, { useState, useEffect, useContext } from "react";
import alertify from "alertifyjs";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import More from "../../../Assets/Icons/More.svg";
import LoanTable from "../../DataTable/LoanTable";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import ExcelExport from "../../../Assets/Icons/excel-export.svg";
import { GET_API, BASE_PATH, BASE_URL } from "../../../Services/api";
import Spinner from "../../Spinner";
import "alertifyjs/build/css/alertify.css";
import { PrivilegeContext } from "../../../PrivilegeContext";
import ButtonLoader from "../../ButtonLoader";
import Filter from "../../../Assets/Icons/Filter.svg";
import PDFExport from "../../../Assets/Icons/pdf-export.svg";

function LoanList() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loanList, setLoanList] = useState([]);
  const [filteredLoan, setFilteredLoan] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const { access } = useContext(PrivilegeContext);
  const [addAccess, setAddAccess] = useState(false);
  const [showAccess, setShowAccess] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [filter, setFilter] = useState(false);

  const [branchList, setBranchList] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [filterParams, setFilterParams] = useState("");
  const [religionList, setReligionList] = useState([]);
  const [currentDate, setCurrentDate] = useState("");
  const [showFilterResultData, setShowFilterResultData] = useState("");

  const [isBranchLoading, setIsBranchLoading] = useState(false);
  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [isEmpTypeLoading, setIsEmpTypeLoading] = useState(false);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
  const [isDesignationLoading, setIsDesignationLoading] = useState(false);
  const [isShiftLoading, setIsShiftLoading] = useState(false);
  const [isHrAgentLoading, setIsHrAgentLoading] = useState(false);

  // Export Excel and PDF
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isExportPdfLoading, setIsExportPdfLoading] = useState(false);

  // const [filteredEmployee, setFilteredEmployee] = useState([]);
  const [filterList, setfilterList] = useState({
    employee_id: "",
    branch_id: "",
    employee_type_id: "",
    religion_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    joining_from_date: "1997-01-01",
    joining_to_date: currentDate,
  });

  useEffect(() => {
    getBranchList();
    getEmployeeType();
    getDepartmentList();
    getDesignationList();
    getEmployeeList();
    getReligionList();
    getCurrentDate();
  }, []);

  const genderList = [
    { label: "Select...", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const paymentType = [
    { value: "", label: "Please Select" },
    { value: "bank", label: "Bank" },
    { value: "cash", label: "Cash" },
  ];

  const showLoanDetails = (listDetail) => {
    navigate("/expense/loan/details", {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s_no", label: "S.No" },
    { id: "EmployeeCode", label: "Employee Code" },
    { id: "employee_name", label: "Employee Name" },
    { id: "payment_type", label: "Payment Type" },
    { id: "amount", label: "Amount" },
    { id: "paid_amount", label: "Paid Amount" },
    { id: "pending_amount", label: "Pending Amount" },
    { id: "paid_status", label: "Paid Status" },
    { id: "status", label: "Status" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getLoanList(currentPage, pageSize, filterParams, searchKey);
  }, [currentPage, pageSize, showFilterResultData]);

  useEffect(() => {
    getAccess();
  }, [access]);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "loan") {
        setAddAccess(item?.add);
        setShowAccess(item?.view);
      }
      return "";
    });
  };
  const isPageNotExit = () => {
    setCurrentPage(1);
    getLoanList(1, pageSize, filterParams, searchKey);
  };

  const getLoanList = async (page, size, filters, search) => {
    setLoading(true);
    try {
      const loanList = await GET_API(
        `/FinanceDetailsList/loan?page=${page}&size=${size}${filters}&search=${search}`
      );
      if (loanList.status === 200) {
        if (
          loanList?.data?.data?.current_page > loanList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setLoanList(loanList?.data?.data?.data);
        setFilteredLoan(loanList?.data?.data?.data);
        setLastPage(loanList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (e) {
      setLoanList([]);
      setFilteredLoan([]);
      setLastPage("");
      setLoading(false);
      alertify.error("Failed To get Loan List");
    }
  };

  const exportAttenfdanceForm = async () => {
    try {
      setIsExportLoading(true);
      const downloadForm = await GET_API(`${BASE_URL}export-finance/loan`);
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = `${BASE_PATH}${downloadForm?.data?.data}`;
        alink.setAttribute("target", "_blank");
        alink.download = "Attendance.xlsx";
        alink.click();
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };

  const getExportasPDF = async () => {
    try {
      setIsExportPdfLoading(true);
      const downloadForm = await GET_API(`FinanceDetailsListPdf`);
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = downloadForm?.data?.filename;
        alink.setAttribute("target", "_blank");
        alink.download = "Loan.pdf";
        alink.click();
        setIsExportPdfLoading(false);
      }
    } catch (error) {
      setIsExportPdfLoading(false);
    }
  };

  const getCurrentDate = () => {
    const day = new Date();
    const date = day?.getDate();
    const month = day?.getMonth();
    const year = day?.getFullYear();
    // setCurrentDate(year + "-" + month + "-" + date);
    setfilterList({
      ...filterList,
      joining_to_date: year + "-" + month + "-" + date,
    });
  };

  const getEmployeeList = async (page, size, filters) => {
    try {
      setLoading(true);
      const employeeList = await GET_API(`/employeeDropdown`);
      if (employeeList.status === 200) {
        if (
          employeeList?.data?.data?.current_page >
          employeeList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }

        const empList = employeeList?.data?.data?.map((emp) => {
          return {
            label: `${emp?.first_name} (${emp?.emp_code})`,
            value: emp?.id,
          };
        });
        empList.unshift({ label: "Select...", value: "" });
        setEmployeeName(empList);

        setLastPage(employeeList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (e) {
      alertify.error("Failed to get Employee List");
    }
  };

  const getBranchList = async () => {
    setLoading(true);
    try {
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });

        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getReligionList = async () => {
    setLoading(true);
    try {
      const list = await GET_API("/religionDropdown");
      if (list.status === 200) {
        const religion = list?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        setLoading(false);
        setReligionList(religion);
        return;
      }
    } catch (error) {
      setReligionList([]);
      setLoading(false);
    }
  };

  const getDepartmentList = async () => {
    setLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Designation List");
    }
  };

  const handleFilter = async (e, name, type) => {
    const value = type === "date" ? e?.target?.value : e?.value;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    const isEmptyObject = (obj) => {
      return Object.keys(obj).length === 0;
    };

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }

    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  if (
    loading ||
    isBranchLoading ||
    isEmpLoading ||
    isEmpTypeLoading ||
    isDepartmentLoading ||
    isDesignationLoading ||
    isShiftLoading ||
    isHrAgentLoading
  ) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <div className="card-header">Loan List</div>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => setSearchKey(e?.target?.value)}
                value={searchKey}
                style={{ outline: "none", border: "none" }}
              />

              <img
                src={Search}
                className="cursor-pointer"
                onClick={() => {
                  getLoanList(currentPage, pageSize, filterParams, searchKey);
                }}
                alt=""
              />
            </div>

            <div className="flex gap-3 items-center">
              <div
                className="filter-button"
                onClick={() => setFilter((prev) => !prev)}
              >
                <span className="filter-btn-label">Filter</span>
                <img src={Filter} alt="" />
              </div>
              <div className="add-new-button-block ">
                {isExportLoading ? (
                  <ButtonLoader isBtnDisable={isExportLoading} />
                ) : (
                  <button
                    className="add-new-button"
                    onClick={() => exportAttenfdanceForm()}
                  >
                    <span>Export</span>
                    <img
                      src={ExcelExport}
                      alt="export"
                      style={{ height: "25px" }}
                    />
                  </button>
                )}
              </div>
              <div className="add-new-button-block ">
                {isExportPdfLoading ? (
                  <ButtonLoader isBtnDisable={isExportPdfLoading} />
                ) : (
                  <button
                    className="add-new-button whitespace-pre"
                    onClick={() => getExportasPDF()}
                  >
                    <span>Export as PDF</span>
                    <img
                      src={PDFExport}
                      alt="export"
                      style={{ height: "25px" }}
                    />
                  </button>
                )}
              </div>
              {addAccess && (
                <div className="add-new-button-block ">
                  <Link
                    to="/expense/loan/deduction/add"
                    className="add-new-button whitespace-pre"
                  >
                    <span>Add Loan Deduction</span>
                    <img src={ColorAdd} alt="add" />
                  </Link>
                </div>
              )}

              {addAccess && (
                <div className="add-new-button-block ">
                  <Link
                    to="/expense/loan/add"
                    className="add-new-button whitespace-pre"
                  >
                    <span>Add New</span>
                    <img src={ColorAdd} alt="add" />
                  </Link>
                </div>
              )}
            </div>
          </div>
          {filter && (
            <form>
              <div className="form ">
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList?.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id", "select")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Name</label>
                  <Select
                    options={employeeName}
                    value={employeeName?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id", "select")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Type</label>
                  <Select
                    options={employeetype}
                    value={employeetype?.find(
                      (option) => option?.value === filterList.employee_type_id
                    )}
                    onChange={(e) =>
                      handleFilter(e, "employee_type_id", "select")
                    }
                    name="employee_type_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Gender</label>
                  <Select
                    options={genderList}
                    value={
                      filterList.gender
                        ? genderList?.find(
                            (option) => option?.value === filterList.gender
                          )
                        : ""
                    }
                    onChange={(e) => handleFilter(e, "gender", "select")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Religion</label>
                  <Select
                    options={religionList}
                    value={religionList?.find(
                      (option) => option?.value === filterList?.religion_id
                    )}
                    onChange={(e) => handleFilter(e, "religion_id", "select")}
                    name="religion_id"
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Department</label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id", "select")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) =>
                      handleFilter(e, "designation_id", "select")
                    }
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Payment Type</label>
                  <Select
                    options={paymentType}
                    value={paymentType?.find(
                      (option) => option?.value === filterList?.payment_type
                    )}
                    onChange={(e) => handleFilter(e, "payment_type", "select")}
                    name="payment_type"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block">
                  <label className="label-name">Joining Date</label>
                  <div className="flex gap-1 justify-center items-center">
                    <input
                      className="input-field"
                      type="date"
                      autoComplete="off"
                      name="joining_from_date"
                      value={filterList?.joining_from_date}
                      max={currentDate}
                      onChange={(e) => {
                        handleFilter(e, "joining_from_date", "date");
                      }}
                    />
                    <label className="label-name">to</label>
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="joining_to_date"
                      onChange={(e) => {
                        handleFilter(e, "joining_to_date", "date");
                      }}
                      value={
                        filterList?.joining_to_date
                          ? filterList?.joining_to_date
                          : ""
                      }
                      // min={filterList?.joining_from_date}
                      max={currentDate}
                    />
                  </div>
                </div>
                <div className="field-block">
                  <label className="label-name">Finance Date</label>
                  <input
                    className="input-field "
                    type="date"
                    autoComplete="off"
                    name="finance_date"
                    value={filterList?.finance_date}
                    onChange={(e) => handleFilter(e, "finance_date", "date")}
                  />
                </div>
                <div className="field-block flex justify-center">
                  <div className="add-new-button-block w-[40%] h-max">
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        showFilterResult(e);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Show Result</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
          <LoanTable
            list={filteredLoan}
            columns={columns}
            icon={More}
            activeDelete={false}
            activeEdit={false}
            multiple={showAccess}
            showDetails={showLoanDetails}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
            enableMoreOption={showAccess}
          />
        </div>
      </div>
    </div>
  );
}

export default LoanList;
