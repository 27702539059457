import React from "react";

function SalaryInfo({ listDetails, page }) {
  const Details = [
    {
      label: "Basic Salary",
      value: listDetails?.dummy_basic ? listDetails?.dummy_basic : "-",
    },
    {
      label: "PF",
      value: listDetails?.pf_amount ? listDetails?.pf_amount : "-",
    },
    // {
    //   label: "Gross Salary",
    //   value: listDetails?.gross_salary ? listDetails?.gross_salary : "-",
    // },
    {
      label: "Through Bank Account",
      value: listDetails?.account_salary ? listDetails?.account_salary : "-",
    },
    {
      label: "In Cash",
      value: listDetails?.in_cash ? listDetails?.in_cash : "-",
    },
    {
      label: "Approval",
      value: listDetails?.approved_by_name
        ? listDetails?.approved_by_name
        : "-",
    },
    {
      label: "Allowance applicable",
      value: listDetails?.is_food_allowance_applicable === 1 ? "Yes" : "No",
    },
  ];

  return (
    <div
      className={` ${
        page === "report" ? "border-t-[1px] border-t-[#D9D9D9]" : ""
      } `}
    >
      {page === "report" && (
        <div
          className="p-3 w-3/12 bg-primary-color text-center"
          style={{ borderRadius: "0px 0px 100px 0px" }}
        >
          <span className="text-white-color font-karla font-base ">
            Salary Info
          </span>
        </div>
      )}

      <div className="list-container mt-3">
        <div className="list-content-block">
          {Details.map((list) => {
            return (
              <div className="list">
                <label className="list-label">{list?.label}</label>
                <div style={{ wordBreak: "break-word" }} className="list-value">
                  {list?.value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default SalaryInfo;
