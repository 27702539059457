import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Select from "react-select";
import Pagination from "./Pagination";

export default function StickyHeadTable({
  list,
  columns,
  advance,
  paymentType,
  bulkAdvance,
  setBulkAdvance,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
}) {
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, i) => {
                return (
                  <ListRow
                    row={row}
                    index={i}
                    page={currentPage}
                    rowsPerPage={pageSize}
                    advance={advance}
                    bulkAdvance={bulkAdvance}
                    setBulkAdvance={setBulkAdvance}
                    paymentType={paymentType}
                    currentPage={currentPage}
                    pageSize={pageSize}
                  />
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}

function ListRow({
  row,
  index,
  page,
  rowsPerPage,
  advance,
  bulkAdvance,
  setBulkAdvance,
  paymentType,
  currentPage,
  pageSize,
}) {
  const handleAdvanceType = (e, empID) => {
    var isEmployeeExist = bulkAdvance?.data?.some((item) => {
      return item?.employee_id === empID;
    });

    if (!isEmployeeExist) {
      const advanceList = {
        employee_id: empID,
        advance_type_id: e?.value,
        amount: "",
        payment_type: "",
      };
      const totalList = { ...bulkAdvance };
      totalList?.data?.push(advanceList);
      setBulkAdvance(totalList);
    } else {
      const changedRow = bulkAdvance?.data?.map((list) => {
        if (list?.employee_id === empID) {
          return { ...list, advance_type_id: e?.value };
        } else {
          return list;
        }
      });
      setBulkAdvance({ ...bulkAdvance, data: [] });
      setBulkAdvance({ ...bulkAdvance, data: changedRow });
    }
  };

  const handleAmount = (e, empID) => {
    const { value } = e?.target;
    var isEmployeeExist = bulkAdvance?.data?.some((item) => {
      return item?.employee_id === empID;
    });

    if (!isEmployeeExist) {
      const advanceList = {
        employee_id: empID,
        advance_type_id: "",
        amount: value,
      };
      const totalList = { ...bulkAdvance };
      totalList?.data?.push(advanceList);
      setBulkAdvance(totalList);
    } else {
      const changedRow = bulkAdvance?.data?.map((list) => {
        if (list?.employee_id === empID) {
          return { ...list, amount: value };
        } else {
          return list;
        }
      });
      setBulkAdvance({ ...bulkAdvance, data: [] });
      setBulkAdvance({ ...bulkAdvance, data: changedRow });
    }
  };

  const handlePaymentType = (e, empID) => {
    var isEmployeeExist = bulkAdvance?.data?.some((item) => {
      return item?.employee_id === empID;
    });

    if (!isEmployeeExist) {
      const advanceList = {
        employee_id: empID,
        advance_type_id: "",
        amount: "",
        payment_type: e?.value,
      };
      const totalList = { ...bulkAdvance };
      totalList?.data?.push(advanceList);
      setBulkAdvance(totalList);
    } else {
      const changedRow = bulkAdvance?.data?.map((list) => {
        if (list?.employee_id === empID) {
          return { ...list, advance_type_id: e?.value };
        } else {
          return list;
        }
      });
      setBulkAdvance({ ...bulkAdvance, data: [] });
      setBulkAdvance({ ...bulkAdvance, data: changedRow });
    }
  };

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={index}
      className="table-column"
    >
      {
        <TableCell className="table-body !w-[7%]">
          {(currentPage - 1) * rowsPerPage + (index + 1)}
        </TableCell>
      }
      {
        <TableCell className="table-body">
          {row?.first_name + " " + row?.fathers_name}
        </TableCell>
      }
      {<TableCell className="table-body">{row?.emp_code}</TableCell>}
      {
        <TableCell className="table-body">
          <Select
            options={advance}
            onChange={(e) => handleAdvanceType(e, row?.employee_Id)}
            name="advance"
            className=""
            isSearchable
          />
        </TableCell>
      }
      {
        <TableCell className="table-body">
          <input
            type="number"
            name="amount"
            className="border-[1px] border-[#D9D9D9] p-1 rounded"
            onChange={(e) => handleAmount(e, row?.employee_Id)}
          />
        </TableCell>
      }
      {
        <TableCell className="table-body">
          <Select
            options={paymentType}
            onChange={(e) => handlePaymentType(e, row?.employee_Id)}
            name="advance"
            className=""
            isSearchable
          />
        </TableCell>
      }
      {<TableCell className="table-body">Reset</TableCell>}
    </TableRow>
  );
}
