import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GET_API } from "../../../Services/api";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";

function InviceDetails() {
  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  const [logisticsDetails, setLogisticsDetails] = useState([]);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    setLoading(true);
    try {
      const invoiceDetails = await GET_API(`po/logisticsById/${state}`);
      if (invoiceDetails?.data?.status === 200) {
        setLogisticsDetails(invoiceDetails?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get invoice list");
      setLoading(false);
    }
  };

  const Details = [
    {
      label: "PO Number",
      value: logisticsDetails?.po_number,
    },

    {
      label: "Mode of Transport",
      value: logisticsDetails?.mode_of_transport,
    },
    {
      label: "Transport Name",
      value: logisticsDetails?.transport_name,
    },
    {
      label: "LR Number",
      value: logisticsDetails?.lr_number,
    },

    {
      label: "Cost",
      value: logisticsDetails?.amount,
    },
    {
      label: "Consignment Number",
      value: logisticsDetails?.consignment_number,
    },
    {
      label: "Invoice Number",
      value: logisticsDetails?.invoice_number,
    },
  ];

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="po-container">
      <div className="po-header">Logistics</div>
      <div className="po-contect-box">
        <div className="header-name">Logistics Details</div>
        <div className="po-pr-details-content-block">
          <div className="list-content-block">
            {Details.map((list, i) => {
              return (
                <div className="list " key={i}>
                  <label className="list-label">{list.label}</label>
                  <div
                    style={{ wordBreak: "break-word" }}
                    className="list-value"
                  >
                    {list.value}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InviceDetails;
