import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import View from "../../Assets/Icons/View.svg";
import Save from "../../Assets/Icons/Save.svg";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../Validation/Validation";
import { GET_API, POST_API } from "../../Services/api";
import Select from "react-select";
import Spinner from "../Spinner";

function EditHoliday() {
  const navigate = useNavigate();
  const [religionList, setReligionList] = useState([]);
  const [selectedReligion, setselectedReligion] = useState([]);
  const { state } = useLocation();
  const listData = state?.list;
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editHolidayDetails, setEditHolidayDetails] = useState({
    id: listData?.id,
    name: listData?.name,
    religion_name: listData?.religion_name,
    description: listData?.description !== null ? listData?.description : "",
    leave_type: listData?.leave_type,
    financial_year: listData?.financial_year,
    religion_id: listData?.religion_id,
  });

  useEffect(() => {
    getReligionsList();
  }, []);

  const getReligionsList = async () => {
    try {
      setLoading(true);
      const religion = await GET_API("/religionDropdown");
      if (religion?.status === 200) {
        setLoading(false);
        const list = religion?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        setReligionList(list);
        setReligions(list);
        return;
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to get Religion List");
    }
  };

  const setReligions = (religion) => {
    const rel = listData?.religion_id.split(",").map(Number);
    const values = [];
    religion?.map((religion) => {
      if (rel.includes(religion?.value)) {
        return values.push(religion);
      }
    });
    getSelectedReligion(values);
  };
  const getSelectedReligion = (values) => {
    setselectedReligion(values);
    const idArr = [];
    values.map((item) => {
      return idArr.push(item?.value);
    });

    setEditHolidayDetails({ ...editHolidayDetails, religion_id: idArr });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditHolidayDetails(() => ({
      ...editHolidayDetails,
      [name]: value,
    }));
  };

  // const handleSelectChange = (e, name) => {
  //   setEditHolidayDetails(() => ({ ...editHolidayDetails, [name]: e?.value }));
  // };

  const saveHoliday = async (e) => {
    setButtonDisabled(true);
    const isValid = Validation.validateHoliday(editHolidayDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    try {
      const editHoliday = await POST_API("/updateHolidays", editHolidayDetails);
      if (editHoliday?.status === 200) {
        alertify.success(editHoliday?.data?.message);
        navigate("/master/hrms/Holiday");
        setButtonDisabled(false);
      }
    } catch (error) {
      alertify.error("Failed To update Holidat List");
      setButtonDisabled(false);
    }
  };

  const handleReligion = (e) => {
    const idArr = [];
    e.map((religion) => {
      idArr.push(religion?.value);
    });
    setEditHolidayDetails({ ...editHolidayDetails, religion_id: idArr });
    setselectedReligion(e);
  };

  const resetform = () => {
    const religionID = listData?.religion_id.split(",").map(Number);
    const values = [];
    religionList?.map((religion) => {
      if (religionID.includes(religion?.value)) {
        return values.push(religion);
      }
    });
    getSelectedReligion(values);
    setEditHolidayDetails({
      id: listData?.id,
      name: listData?.name,
      religion_name: listData?.religion_name,
      description: listData?.description,
      leave_type: listData?.leave_type,
      financial_year: listData?.financial_year,
      religion_id: religionID,
    });
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/holiday" className="in-active">
              Holiday List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Edit Holiday</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Holiday</h4>

          <form>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Holiday Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  value={editHolidayDetails.name}
                />
                {errors.name && (
                  <span className="validation-error-label">{errors.name}</span>
                )}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Religion
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  name="religion_id"
                  onInputChange={(newValue) => setInputValue(newValue)}
                  inputValue={inputValue}
                  value={selectedReligion}
                  onChange={handleReligion}
                  options={religionList}
                  isMulti
                  placeholder={
                    selectedReligion.length === 0 ? "Select options" : ""
                  }
                />
                {errors.religion_id && (
                  <span className="validation-error-label">
                    {errors.religion_id}
                  </span>
                )}
              </div>
              <div className="field-block">
                <label className="label-name">Leave Type</label>
                <input
                  className="input-field"
                  type="text"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="leave_type"
                  value={editHolidayDetails.leave_type}
                />
                {errors.leave_type && (
                  <span className="validation-error-label">
                    {errors.leave_type}
                  </span>
                )}
              </div>

              <div className="field-block">
                <label className="label-name">Financial Year</label>
                <input
                  className="input-field"
                  type="text"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="financial_year"
                  value={editHolidayDetails.financial_year}
                />
                {errors.financial_year && (
                  <span className="validation-error-label">
                    {errors.financial_year}
                  </span>
                )}
              </div>
              <div className="field-block">
                <label className="label-name">Description</label>
                <textarea
                  className="input-field"
                  type="text"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="description"
                  value={editHolidayDetails.description}
                  rows={5}
                />
                {errors.description && (
                  <span className="validation-error-label">
                    {errors.description}
                  </span>
                )}
              </div>
            </div>
          </form>
          <div className="submit-reset-button">
            <button
              type="button"
              className="reset-button"
              onClick={() => {
                resetform();
              }}
            >
              <span className="reset-button-label">Reset</span>
            </button>
            <button
              type="submit"
              className="submit-button"
              onClick={() => saveHoliday()}
              disabled={buttonDisabled}
            >
              <span className="submit-button-label">Save</span>
              <img src={Save} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EditHoliday;
