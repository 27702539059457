import React, { useState, useEffect } from "react";
import alertify from "alertifyjs";
import Select from "react-select";
import { Link } from "react-router-dom";
import View from "../../Assets/Icons/View.svg";
import Save from "../../Assets/Icons/Save.svg";
import { useNavigate } from "react-router-dom";
import { GET_API } from "../../Services/api";
import Validation from "../../Validation/Validation";
import { POST_API } from "../../Services/api";
import Spinner from "../Spinner";
import "alertifyjs/build/css/alertify.css";

function CreateAgent() {
  const navigate = useNavigate();
  const [errors, seterrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [agentDetails, setAgentDetails] = useState({
    agent_id: "",
    amount: "",
    percentage: "",
    remarks: "",
  });
  const [agentType, setagentType] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setAgentDetails(() => ({ ...agentDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    setAgentDetails(() => ({ ...agentDetails, [name]: e?.value }));
  };

  const createAgentcommisson = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateAgentCommission(agentDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const addAgent = await POST_API("/addAgentCommission", agentDetails);
      if (addAgent) {
        alertify.success(addAgent?.data?.data);
        navigate("/expense/agentcommission");
      }
    } catch (error) {
      alertify.error("Failed to add Agent Commision");
    }
  };

  useEffect(() => {
    getAgentType();
  }, []);

  const getAgentType = async () => {
    setLoading(true);
    try {
      const getType = await GET_API("/agentDropdown/consultancy");
      if (getType.status === 200) {
        const agent = getType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.company_name };
        });
        agent.unshift({ value: "", label: "Select..." });
        setagentType(agent);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to get Agent Type List...");
    }
  };

  const resetForm = () => {
    setAgentDetails({
      agent_id: "",
      amount: "",
      percentage: "",
      remarks: "",
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/expense/agentcommission" className="in-active">
              Agent Commission List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Agent Commission</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Add Agent Commission</div>

          <form onSubmit={(e) => createAgentcommisson(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Agent Name
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={agentType}
                  value={
                    agentDetails.agent_id
                      ? agentType?.find(
                          (option) => option?.value === agentDetails.agent_id
                        )
                      : ""
                  }
                  onChange={(e) => handleSelectChange(e, "agent_id")}
                  name="agent_id"
                  className=""
                  isSearchable
                />
                {errors.agent_id ? (
                  <div className="validation-error-label">
                    {errors.agent_id}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Amount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="number"
                  autoComplete="off"
                  placeholder="Enter Amount"
                  name="amount"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={agentDetails?.amount}
                />
                {errors.amount ? (
                  <div className="validation-error-label">{errors.amount}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Percentage
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="number"
                  autoComplete="off"
                  placeholder="Enter Percentage"
                  name="percentage"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={agentDetails?.percentage}
                />
                {errors.percentage ? (
                  <div className="validation-error-label">
                    {errors.percentage}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Remarks</label>
                <textarea
                  rows={5}
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  placeholder="Enter Remarks"
                  name="remarks"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={agentDetails?.remarks}
                />
                {errors.remarks ? (
                  <div className="validation-error-label">{errors.remarks}</div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                type="submit"
                className="submit-button"
                disabled={buttonDisabled}
              >
                <span className="submit-button-label">Add</span>
                <img src={Save} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateAgent;
