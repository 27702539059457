import React, { useState, useEffect } from "react";
import Select from "react-select";
import More from "../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { DELETE_API, GET_API, BASE_PATH, BASE_URL } from "../../Services/api";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import alertify from "alertifyjs";
import EmployeeTable from "../DataTable/EmployementList";
import Spinner from "../Spinner";
import Terminate from "../Employee/Terminate";
import Filter from "../../Assets/Icons/Filter.svg";
import "alertifyjs/build/css/alertify.css";
import Search from "../../Assets/Icons/Search.svg";
import ExcelExport from "../../Assets/Icons/excel-export.svg";
import config from "../../Services/config.json";
import ButtonLoader from "../ButtonLoader";

function AllEmployeeList() {
  const navigate = useNavigate();
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [district, setDistrict] = useState([]);
  const [town, setTown] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isCustomDialogOpen, setCustomDialogOpen] = useState(false);
  const [terminateEmployeeId, setTerminateEmployeeId] = useState(0);
  const [terminateEmployeeName, setTerminateEmployeeName] = useState(0);
  const [terminateEmployeeCode, setTerminateEmployeeCode] = useState(0);
  const [filter, setFilter] = useState(false);
  const [filteredEmployee, setFilteredEmployee] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [filterParams, setFilterParams] = useState("");
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [filterList, setfilterList] = useState({
    branch_id: "",
    employee_id: "",
    employee_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    from_date: "",
    district: "",
    town: "",
    pf_employee: "",
    to_date: "",
  });

  const header = [
    { label: "User", value: "user" },
    { label: "Employee Code", value: "emp_code" },
    { label: "Employee Name", value: "employee_name" },
    { label: "Department", value: "email" },
    { label: "Mobile Number", value: "dob" },
    { label: "Status", value: "is_active" },
    { label: "Created at", value: "create_at" },
    { label: "", value: "" },
  ];

  useEffect(() => {
    getBranchList();
    getEmployeeType();
    getDepartmentList();
    getEmployeeNameList();
    getDesignationList();
    getDistrictList();
    getTownList();
  }, []);

  useEffect(() => {
    getEmployeeList(currentPage, pageSize, filterParams, searchKey);
  }, [currentPage, pageSize, showFilterResultData]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getEmployeeList(1, pageSize, filterParams, searchKey);
  };

  const getEmployeeList = async (page, size, filters, search) => {
    try {
      setLoading(true);
      const employeeList = await GET_API(
        `/employeesList?page=${page}&size=${size}${filters}&search=${search}`
      );

      if (employeeList.status === 200) {
        if (
          employeeList?.data?.data?.current_page >
          employeeList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }

        const employeeListArr = [];
        employeeList?.data?.data?.data?.map((item) => {
          return employeeListArr.push(item);
        });
        const empList = employeeList?.data?.data?.data.map((emp) => {
          return {
            label: `${emp?.first_name} (${emp?.emp_code})`,
            value: emp?.employee_id,
          };
        });
        empList.unshift({ label: "Select...", value: "" });
        setEmployeeList(employeeListArr);
        setFilteredEmployee(employeeList?.data?.data?.data);
        setLastPage(employeeList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (e) {
      alertify.error("Failed to get Employee List");
    }
  };

  const getEmployeeNameList = async () => {
    try {
      const empName = await GET_API("/employeeDropdown");
      if (empName?.status === 200) {
        const empList = empName?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeName(empList);
      }
    } catch (error) {
      setEmployeeName([]);
      alertify.error("Failed to get Employee List");
    }
  };

  const getBranchList = async () => {
    setLoading(true);
    try {
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Please Select" });
        setEmployeetype(type);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getDepartmentList = async () => {
    setLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDistrictList = async () => {
    setLoading(true);
    try {
      const districtList = await GET_API("/DistrictDropdown");
      if (districtList.status === 200) {
        const district = districtList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        district.unshift({ value: "", label: "Select..." });
        setDistrict(district);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get District List");
    }
  };

  const getTownList = async () => {
    setLoading(true);
    try {
      const townList = await GET_API("/TownDropdown");
      if (townList.status === 200) {
        const town = townList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        town.unshift({ value: "", label: "Select..." });
        setTown(town);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Town List");
    }
  };

  const getDesignationList = async () => {
    setLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Designation List");
    }
  };

  const showBranchDetails = (listDetail) => {
    navigate(`/employee/details/${listDetail?.employee_Id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  const downloadEmployeeForm = async (rowId) => {
    setLoading(true);
    const downloadForm = await GET_API(
      `${config?.base_url}downloadEmpPdf?employee_id=${rowId?.employee_Id}`
    );
    if (downloadForm?.data?.status === 200) {
      const alink = document.createElement("a");
      alink.href = downloadForm?.data?.data?.filename;
      alink.setAttribute("target", "_blank");
      alink.download = "EmployeeForm.pdf";
      alink.click();
      setLoading(false);
    }
  };

  const exportEmployeeForm = async () => {
    try {
      setIsExportLoading(true);
      const downloadForm = await GET_API(`${BASE_URL}export-employees`);
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = `${BASE_PATH}${downloadForm?.data?.data}`;
        alink.setAttribute("target", "_blank");
        alink.download = "EmployeeForm.xlsx";
        alink.click();
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Employee..?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteEmployee/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getEmployeeList(currentPage, pageSize, filterParams, searchKey);
              return;
            }
            alertify.error("Failed To Delete Floor");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const navigateEditPage = (listDetail) => {
    navigate(`/employee/edit/${listDetail?.employee_Id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  const navigateIDCardPreviewPage = (listDetail) => {
    navigate(`/employee/idCard/preview/${listDetail?.employee_Id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  const terminateEmployee = (employeeId) => {
    setCustomDialogOpen(true);
    setTerminateEmployeeId(employeeId?.employee_Id);
    setTerminateEmployeeName(employeeId?.first_name);
    setTerminateEmployeeCode(employeeId?.emp_code);
  };

  const handleCloseCustomDialog = () => {
    setCustomDialogOpen(false);
  };

  const genderList = [
    { label: "Please Select", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const validEmployee = [
    { label: "Please Select", value: "" },
    { label: "Valid", value: "valid" },
    { label: "Invalid", value: "invalid" },
  ];
  const employeePFTypeList = [
    { label: "Please Select", value: "" },
    { label: "PF Employee", value: 1 },
    { label: "Non PF Employee", value: 0 },
  ];

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleFilter = async (e, name) => {
    const { value } = e;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }

    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <div className="card-header">Employee List</div>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => setSearchKey(e?.target?.value)}
                value={searchKey}
                style={{ outline: "none", border: "none" }}
              />

              <img
                src={Search}
                className="cursor-pointer"
                onClick={() => {
                  getEmployeeList(
                    currentPage,
                    pageSize,
                    filterParams,
                    searchKey
                  );
                }}
                alt=""
              />
            </div>
            <div className="flex gap-3">
              <div
                className="filter-button"
                onClick={() => setFilter((prev) => !prev)}
              >
                <span className="filter-btn-label">Filter</span>
                <img src={Filter} alt="" />
              </div>
              <div className="add-new-button-block">
                {isExportLoading ? (
                  <ButtonLoader isBtnDisable={isExportLoading} />
                ) : (
                  <button
                    className="add-new-button"
                    onClick={() => exportEmployeeForm()}
                  >
                    <span>Export</span>
                    <img
                      src={ExcelExport}
                      alt="export"
                      style={{ height: "25px" }}
                    />
                  </button>
                )}
              </div>
              <div className="add-new-button-block">
                <Link to="/employee/add" className="add-new-button">
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            </div>
          </div>
          {filter && (
            <form>
              <div className="form ">
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList?.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Name</label>
                  <Select
                    options={employeeName}
                    value={employeeName?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Type</label>
                  <Select
                    options={employeetype}
                    value={employeetype?.find(
                      (option) => option?.value === filterList.employee_type_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_type_id")}
                    name="employee_type_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Gender</label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Department</label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) => handleFilter(e, "designation_id")}
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block">
                  <label className="label-name">Joining Date</label>
                  <div className="flex gap-1 justify-center items-center">
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="joining_from_date"
                      onChange={(e) => {
                        handleFilter(e);
                      }}
                    />
                    <label className="label-name">to</label>
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="joining_to_date"
                      onChange={(e) => {
                        handleFilter(e);
                      }}
                    />
                  </div>
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Valid Employee</label>
                  <Select
                    options={validEmployee}
                    value={validEmployee?.find(
                      (option) => option?.value === filterList.valid_emp
                    )}
                    onChange={(e) => handleFilter(e, "valid_emp")}
                    name="valid_emp"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">District</label>
                  <Select
                    options={district}
                    value={district?.find(
                      (option) => option?.value === filterList.district
                    )}
                    onChange={(e) => handleFilter(e, "district")}
                    name="district"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Town</label>
                  <Select
                    options={town}
                    value={town?.find(
                      (option) => option?.value === filterList.town
                    )}
                    onChange={(e) => handleFilter(e, "town")}
                    name="town"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Employee PF Type</label>
                  <Select
                    options={employeePFTypeList}
                    value={employeePFTypeList?.find(
                      (option) => option?.value === filterList.pf_employee
                    )}
                    onChange={(e) => handleFilter(e, "pf_employee")}
                    name="pf_employee"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block flex justify-center">
                  <div className="add-new-button-block w-[40%] h-max ">
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        showFilterResult(e);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Show Result</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}

          <EmployeeTable
            list={filteredEmployee}
            multiple={true}
            showDetails={showBranchDetails}
            deleteRow={handleDelete}
            editPage={navigateEditPage}
            icon={More}
            columns={header}
            termination={true}
            terminateEmployee={terminateEmployee}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
            download={true}
            downloadEmployeeForm={downloadEmployeeForm}
            isEnableIDCard={true}
            idCardPreviewPage={navigateIDCardPreviewPage}
          />
        </div>
      </div>
      {/***Employee Terminate */}
      <Terminate
        isOpen={isCustomDialogOpen}
        terminateEmployeeId={terminateEmployeeId}
        terminateEmployeeName={terminateEmployeeName}
        terminateEmployeeCode={terminateEmployeeCode}
        onClose={handleCloseCustomDialog}
        getEmployeeList={getEmployeeList}
        searchKey={searchKey}
        currentPage={currentPage}
        pageSize={pageSize}
        filterParams={filterParams}
        employeeList={employeeList}
      />
    </div>
  );
}

export default AllEmployeeList;
