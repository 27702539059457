import React, { useContext, useEffect, useState } from "react";
import { GET_API, POST_API } from "../../../Services/api";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import EarlyExitApproveTable from "../../DataTable/EarlyExitTable";
import Search from "../../../Assets/Icons/Search.svg";
import More from "../../../Assets/Icons/More.svg";
import { useNavigate } from "react-router-dom";
import { PrivilegeContext } from "../../../PrivilegeContext";

function EarlyExitApproval() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [earlyExitList, setEarlyExitList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const { access } = useContext(PrivilegeContext);
  const [addAccess, setAddAccess] = useState(false);

  useEffect(() => {
    getEarlyExitList(currentPage, pageSize);
  }, [currentPage, pageSize]);

  useEffect(() => {
    getAccess();
  }, [access]);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "approve_early_exit") {
        setAddAccess(item?.add);
      }
      return "";
    });
  };

  const columns = [
    { field: "EmployeeCode", headerName: "Employee Code" },
    { field: "employee_name", headerName: "Employee Name" },
    { field: "department_name", headerName: "Department" },
    { field: "designation_name", headerName: "Designation" },
    { field: "shift_from_time", headerName: "Shift From Time" },
    { field: "shift_to_time", headerName: "Shift To Time" },
    { field: "clock_out_time", headerName: "Actual Exit Time" },
    {
      field: "exit_time",
      headerName: "Exit Time",
      width: 150,
      renderCell: (params) => {
        const rowID = params?.row;
        const handleExitTimeChange = (e, rowID) => {
          const newList = earlyExitList?.map((row) => {
            return row?.id === rowID?.id
              ? { ...row, clock_out: e?.target?.value }
              : row;
          });
          setEarlyExitList(newList);
        };
        return (
          <input
            className="border-solid border-[1px] border-primary rounded"
            type="time"
            onChange={(e) => handleExitTimeChange(e, rowID)}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (row) => (
        <img
          src={More}
          alt=""
          onClick={() => navigateDetailsPage(row)}
          className="log-icon"
        />
      ),
    },
  ];

  const navigateDetailsPage = (row) => {
    navigate(`/approve/earlyExit/details/${row?.id}`, {
      state: {
        list: row?.row,
      },
    });
  };

  const isPageNotExit = () => {
    setCurrentPage(1);
    getEarlyExitList(1, pageSize);
  };

  const getEarlyExitList = async (page, size) => {
    setLoading(true);
    try {
      const earlyExit = await GET_API(
        `/employeeEarlyExitList?page=${page}&size=${size}`
      );
      if (earlyExit?.status === 200) {
        if (
          earlyExit?.data?.data?.current_page > earlyExit?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setEarlyExitList(earlyExit?.data?.data?.data);
        setLastPage(earlyExit?.data?.data?.last_page);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleApprove = async (type) => {
    const attributesToKeep = ["id", "clock_out"];
    const newData = selectedRows.map((entry) => {
      const newObj = {};
      attributesToKeep.forEach((attr) => {
        if (entry[attr] !== undefined) {
          newObj[attr] = entry[attr];
        }
      });
      return newObj;
    });
    const listData = {
      data: newData,
      approved_by: localStorage?.getItem("user_id"),
    };

    if (newData.length <= 0) {
      alertify.alert("Please select Any Row...").setHeader("<b> Alert </b> ");
      return;
    }
    try {
      const addExitTime = await POST_API("/earlyExitUpdate", listData);
      if (addExitTime?.data?.status === 200) {
        alertify.success(addExitTime?.data?.message);
        getEarlyExitList(currentPage, pageSize);
        return;
      }
    } catch (error) {
      alertify.error(`Failed to Add Exit Time...`);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow grow w-[calc(100%-80px-20%)]">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Early Exit Logs</h4>
          <div className="search-block" style={{ justifyContent: "flex-end" }}>
            <div className="flex gap-3">
              {addAccess && (
                <>
                  <div
                    className="add-new-button-block"
                    onClick={() => {
                      handleApprove("reject");
                    }}
                  ></div>
                  <div
                    className="add-new-button-block"
                    onClick={() => {
                      handleApprove("approve");
                    }}
                  >
                    <button
                      to=""
                      className="add-new-button"
                      disabled={selectedRows?.length >= 1 ? false : true}
                    >
                      <span>Add</span>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          {/**************** * Table ********************/}
          <div className="p-5 min-h-[400px]">
            <EarlyExitApproveTable
              columns={columns}
              list={earlyExitList}
              setSelectedRows={setSelectedRows}
              setPageNo={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              lastPage={lastPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EarlyExitApproval;
