import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Pagination from "./Pagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import MoreOption from "../MoreOption";

export default function StickyHeadTable({
    list,
    columns,
    showDetails,
    icon,
    activeEdit,
    activeDelete,
    multiple,
    setPageNo,
    pageSize,
    setPageSize,
    lastPage,
    currentPage
}) {
   
    const navigate = useNavigate();
    const showDetailsPage = (listDetail) => {
        const id = listDetail?.empId;
        navigate(`/report/cost/details/${id}`, {
            state: {
                list: listDetail,
            },
        });
    }

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 320 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, i) => (
                                <TableCell
                                    key={i}
                                    align={column.align}
                                    className="table-header"
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.length === 0 ? (
                            <TableRow>
                                <TableCell
                                    colSpan="8"
                                    className="table-body td-data-not-found h-[220px]"
                                >
                                    Data Not Found..!
                                </TableCell>
                            </TableRow>
                        ) : (
                            list
                                .map((row, i) => {
                                    const amount = Number(row?.salary !== null ? row?.salary : row?.seasonal_salary) + Number(row.food_allowance ? row.food_allowance : 0);
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={i}
                                            className="table-column"
                                        >
                                            {
                                                <TableCell className="table-body !w-[7%]">
                                                    {(currentPage - 1) * pageSize + (i + 1)}
                                                </TableCell>
                                            }
                                            {
                                                <TableCell className="table-body">
                                                    <a onClick={() => showDetailsPage(row)} style={{ color: "#0284C7" }} className="cursor-pointer text-sky-600 border-b-[1px] border-b-[#0284C7]" >
                                                        {row.emp_code}
                                                    </a>
                                                </TableCell>
                                            }
                                            {
                                                <TableCell className="table-body">
                                                    {row.first_name}
                                                </TableCell>
                                            }
                                            {
                                                <TableCell className="table-body">
                                                    {row?.salary !== null ? row?.salary : (row?.seasonal_salary ? row?.seasonal_salary : 0)}
                                                </TableCell>
                                            }
                                            {
                                                <TableCell className="table-body">
                                                    {row.food_allowance ? row.food_allowance : 0}
                                                </TableCell>
                                            }
                                            {
                                                <TableCell className="table-body" style={{ textAlign: "right" }}>
                                                    {amount}
                                                </TableCell>
                                            }
                                            {
                                                <MoreOption
                                                    icon={icon}
                                                    row={row}
                                                    showDetails={showDetails}
                                                    multiple={multiple}
                                                    activeEdit={activeEdit}
                                                    activeDelete={activeDelete}
                                                />
                                            }
                                        </TableRow>
                                    );
                                })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                setPageNo={setPageNo}
                setPageSize={setPageSize}
                lastPage={lastPage}
                currentPage={currentPage}
                pageSize={pageSize}
            />
        </Paper>
    );
}
