import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import Search from "../../../Assets/Icons/Search.svg";
import { Link } from "react-router-dom";
import { GET_API, POST_API, DELETE_API } from "../../../Services/api";
import Validation from "../../../Validation/Validation";
import alertify from "alertifyjs";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Edit from "../../../Assets/Icons/Edit.svg";
import Spinner from "../../Spinner";
import BonusListMasterTable from "../../DataTable/BonusListMasterTable";
import "alertifyjs/build/css/alertify.css";

function Bonus() {
  const [bonusList, setBonusList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [errors, seterrors] = useState({});
  const [editStatus, setEditStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");
  const [bonusDetails, setBonusDetails] = useState({
    name: "",
    description: "",
    type: "bonus",
  });

  const columns = [
    { id: "s.no", label: "S.No", minWidth: 170 },
    { id: "bonus_name", label: "Bonus Name", minWidth: 100 },
    { id: "description", label: "Description", minWidth: 100 },
    { id: "created_at", label: "Created at", minWidth: 100 },
    { id: "more_icon", label: "", minWidth: 100 },
  ];

  useEffect(() => {
    getBonusList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getBonusList(1, pageSize, searchKey);
  };

  const getBonusList = async (page, size, search) => {
    setLoading(true);
    try {
      const holiday = await GET_API(
        `/FinanceCategoriesList/bonus?page=${page}&size=${size}&search=${search}`
      );
      if (holiday?.status === 200) {
        if (
          holiday?.data?.data?.current_page > holiday?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setBonusList(holiday?.data?.data?.data);
        setFilteredList(holiday?.data?.data?.data);
        setLastPage(holiday?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setBonusList([]);
      setFilteredList([]);
      setLastPage("");
      setCurrentPage(1);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBonusDetails(() => ({ ...bonusDetails, [name]: value, type: "bonus" }));
  };

  const addBonus = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateFinance(bonusDetails, "Bonus");
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const createFloor = await POST_API("/addFinanceCategory", bonusDetails);
      if (createFloor.status === 200) {
        setButtonDisabled(false);
        setBonusDetails({ name: "", description: "", type: "bonus" });
        getBonusList(currentPage, pageSize, searchKey);
        setCurrentPage(1);
        alertify.success(createFloor?.data?.message);
        return;
      }
    } catch (error) {
      setButtonDisabled(false);
      setBonusDetails({ name: "", description: "", type: "bonus" });
      setCurrentPage(1);
    }
  };
  const editBonus = async (e) => {
    setButtonDisabled(true);
    e.preventDefault();
    const isValid = Validation.validateFinance(bonusDetails, "Bonus");
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    const editFloor = await POST_API("/updateCategories", bonusDetails);
    if (editFloor.status === 200) {
      setButtonDisabled(false);
      setBonusDetails({ name: "", description: "", type: "bonus" });
      getBonusList(currentPage, pageSize, searchKey);
      setCurrentPage(1);
      setEditStatus(false);
      alertify.success(editFloor?.data?.message);
      return;
    }
    alertify.error(editFloor?.response?.data?.message);
  };

  const resetForm = () => {
    setEditStatus(false);
    setBonusDetails({
      ...bonusDetails,
      name: "",
      description: "",
      type: "bonus",
    });
  };

  const handleFloorSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = bonusList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredList(searchedList);
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Bonus",
        async function () {
          if (row_id) {
            try {
              const deleteRow = await DELETE_API(
                `/deleteFinanceCategories/${row_id}`
              );
              if (deleteRow.status === 200) {
                alertify.success(deleteRow?.data?.message);
                getBonusList(currentPage, pageSize, searchKey);
                setCurrentPage(1);
                return;
              }
            } catch (error) {
              alertify.error("Failed To Delete Bonus");
            }
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Add Bonus</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => setSearchKey(e?.target?.value)}
                style={{ outline: "none", border: "none" }}
                value={searchKey}
              />

              <img
                src={Search}
                alt=""
                className="cursor-pointer"
                onClick={() => {
                  getBonusList(currentPage, pageSize, searchKey);
                }}
              />
            </div>
          </div>
          <div className="add-list-container">
            <div className="input-block">
              <label className="add-new-input-label">
                Bonus Name <span className="required-symbol">*</span>
              </label>

              <input
                type="text"
                name="name"
                className="input-field"
                placeholder="Bonus Name"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={bonusDetails.name}
              />
              {errors.name ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.name}
                </div>
              ) : null}
            </div>
            <div className="input-block">
              <label className="add-new-input-label">Description</label>
              <textarea
                name="description"
                className="input-field"
                placeholder="Enter Description"
                onChange={(e) => {
                  handleChange(e);
                }}
                rows={5}
                value={bonusDetails.description}
                autoComplete="off"
              />
              {errors.description ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.description}
                </div>
              ) : null}
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                {editStatus ? (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      editBonus(e);
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Save</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                ) : (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      addBonus(e);
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Add New</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                )}
              </div>
              <Link className="edit-button" onClick={() => resetForm()}>
                <span className="button-label">Reset</span>
                <img src={Edit} alt="add" />
              </Link>
            </div>
          </div>
          <BonusListMasterTable
            list={filteredList}
            columns={columns}
            icon={More}
            editStatus={setEditStatus}
            editList={setBonusDetails}
            multiple={false}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default Bonus;
