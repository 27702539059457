import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import { GET_API, POST_API } from "../../../Services/api";
import alertify from "alertifyjs";
import Spinner from "../../Spinner";
import BulkAdvanceTable from "../../DataTable/BulkAdvanceTable";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Select from "react-select";
import Filter from "../../../Assets/Icons/Filter.svg";
import Search from "../../../Assets/Icons/Search.svg";

function AddBulkAdvance() {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [employeeDetailsList, setEmployeeDetailsList] = useState([]);
  const [advanceType, setadvanceType] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [loading6, setLoading6] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const [bulkAdvance, setbulkAdvance] = useState({
    created_by: localStorage?.getItem("user_id"),
    data: [],
  });

  const [filterList, setfilterList] = useState({
    branch_id: "",
    employee_id: "",
    employee_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    joining_from_date: "",
    joining_to_date: "",
  });
  const [filterParams, setFilterParams] = useState("");
  const [branchList, setBranchList] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    getEmployeeList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize, filterParams]);

  useEffect(() => {
    getAdanceType();
    getBranchList();
    getEmployeeType();
    getDepartmentList();
    getDesignationList();
  }, []);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getEmployeeList(1, pageSize, searchKey);
  };

  const getEmployeeList = async (page, size, search) => {
    try {
      setLoading6(true);
      const employeeList = await GET_API(
        `/employeesList?page=${page}&size=${size}${filterParams}&search=${search}`
      );

      if (employeeList.status === 200) {
        if (
          employeeList?.data?.data?.current_page >
          employeeList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }

        const employeeListArr = [];
        employeeList?.data?.data?.data?.map((item) => {
          return employeeListArr.push(item);
        });
        setEmployeeDetailsList(employeeListArr);
        const empList = employeeList?.data?.data?.data.map((emp) => {
          return {
            label: `${emp?.first_name} (${emp?.emp_code})`,
            value: emp?.employee_id,
          };
        });
        empList.unshift({ label: "Select...", value: "" });
        setEmployee(empList);
        setLoading6(false);
      }
    } catch (e) {
      setLoading6(false);
      alertify.error("Failed to get Employee List");
    }
  };

  const getBranchList = async () => {
    setLoading2(true);
    try {
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setLoading2(false);
      }
    } catch (e) {
      setLoading2(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setLoading3(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setLoading3(false);
      }
    } catch (e) {
      setLoading3(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getDepartmentList = async () => {
    setLoading4(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setLoading4(false);
      }
    } catch (e) {
      setLoading4(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setLoading5(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setLoading5(false);
      }
    } catch (e) {
      setLoading5(false);
      alertify.error("Failed to Get Designation List");
    }
  };

  const genderList = [
    { label: "Please Select", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const getAdanceType = async () => {
    setLoading1(true);
    try {
      const financeType = await GET_API(
        "/FinanceCategoriesListDropdown/advance"
      );
      if (financeType?.data?.status === 200) {
        const advance = financeType?.data?.data?.map((amount) => {
          return { value: amount?.id, label: amount?.name };
        });
        setadvanceType(advance);
        setLoading1(false);
      }
    } catch (error) {
      alertify?.error("Failed to get Advance List...");
      setLoading1(false);
    }
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "emp_name", label: "Employee Name" },
    { id: "emp_code", label: "Employee Code" },
    { id: "advance", label: "Advance Type" },
    { id: "amount", label: "Amount" },
    { id: "payment_type", label: "Payment Type" },
    { id: "action", label: "Action" },
  ];

  const paymentType = [
    {
      label: "Bank",
      value: "bank",
    },
    {
      label: "Cash",
      value: "cash",
    },
  ];

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleFilter = async (e, name) => {
    const { value } =
      name === "joining_from_date" || name === "joining_to_date"
        ? e?.target
        : e;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }

    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const handleBulkAdvanceCalculation = async () => {
    try {
      const bulkAdvanceList = await POST_API(
        "/addBulkFinanceDetails",
        bulkAdvance
      );
      if (bulkAdvanceList?.data?.status === 200) {
        navigate("/expense/advance");
        alertify?.success(bulkAdvanceList?.data?.message);
      }
    } catch (error) {
      alertify?.error("Failed to Add Advance...");
    }
  };
  if (loading1 || loading2 || loading3 || loading4 || loading5 || loading6)
    return <Spinner />;

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/expense/advance" className="in-active">
              Advance List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Bulk Advance</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Add Bulk Advance</div>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => setSearchKey(e?.target?.value)}
                value={searchKey}
                style={{ outline: "none", border: "none" }}
              />

              <img
                src={Search}
                className="cursor-pointer"
                onClick={() => {
                  getEmployeeList(currentPage, pageSize, searchKey);
                }}
                alt=""
              />
            </div>
            <div className="flex gap-3">
              <div
                className="filter-button"
                onClick={() => setFilter((prev) => !prev)}
              >
                <span className="filter-btn-label">Filter</span>
                <img src={Filter} alt="" />
              </div>
              <div className="add-new-button-block">
                <button
                  className="add-new-button"
                  disabled={bulkAdvance?.data?.length === 0 ? true : false}
                  onClick={handleBulkAdvanceCalculation}
                >
                  <span>Add</span>
                  <img src={ColorAdd} alt="add" />
                </button>
              </div>
            </div>
          </div>

          {filter && (
            <form>
              <div className="form ">
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">
                    Branch Name
                    <span className="required-symbol">*</span>
                  </label>
                  <Select
                    options={branchList}
                    value={branchList?.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">
                    Employee Name
                    <span className="required-symbol">*</span>
                  </label>
                  <Select
                    options={employee}
                    value={employee?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">
                    Employee Type
                    <span className="required-symbol">*</span>
                  </label>
                  <Select
                    options={employeetype}
                    value={employeetype?.find(
                      (option) => option?.value === filterList.employee_type_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_type_id")}
                    name="employee_type_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">
                    Gender
                    <span className="required-symbol">*</span>
                  </label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">
                    Department
                    <span className="required-symbol">*</span>
                  </label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">
                    Designation
                    <span className="required-symbol">*</span>
                  </label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) => handleFilter(e, "designation_id")}
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block">
                  <label className="label-name">
                    Joining Date
                    <span className="required-symbol">*</span>
                  </label>
                  <div className="flex gap-1 justify-center items-center">
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="joining_from_date"
                      onChange={(e) => {
                        handleFilter(e, "joining_from_date");
                      }}
                      value={filterList?.joining_from_date}
                      max={filterList?.joining_to_date}
                    />
                    <label className="label-name">to</label>
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="joining_to_date"
                      onChange={(e) => {
                        handleFilter(e, "joining_to_date");
                      }}
                      value={filterList?.joining_to_date}
                      min={filterList?.joining_from_date}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
          <BulkAdvanceTable
            columns={columns}
            list={employeeDetailsList}
            advance={advanceType}
            paymentType={paymentType}
            bulkAdvance={bulkAdvance}
            setBulkAdvance={setbulkAdvance}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default AddBulkAdvance;
