import React, { useState } from "react";
import { Link } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import Save from "../../../Assets/Icons/Save.svg";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { POST_API } from "../../../Services/api";

function EditBank() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const listData = state?.list;
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [editBankDetails, setEditBankDetails] = useState({
    id: listData?.id,
    branch_name: listData?.branch_name,
    bank_name: listData?.bank_name,
    ifsc_code: listData?.ifsc_code,
  });

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setEditBankDetails(() => ({ ...editBankDetails, [name]: value }));
  };

  const saveBank = async (e) => {
    setButtonDisabled(true);
    const isValid = Validation.ValidateBank(editBankDetails);

    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    POST_API("/updatebank", editBankDetails)
      .then(async function (data) {
        alertify.success(data?.data?.message);
        setButtonDisabled(false);
        navigate("/master/hrms/bank");
      })

      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error?.response?.data?.message);
      });
  };
  const resetForm = () => {
    setEditBankDetails({
      id: listData?.id,
      branch_name: listData?.branch_name,
      bank_name: listData?.bank_name,
      ifsc_code: listData?.ifsc_code,
    });
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/bank" className="in-active">
              Bank List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Edit Bank</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Bank</h4>

          <form>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Bank Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="bank_name"
                  placeholder="Enter Bank Name"
                  value={editBankDetails.bank_name}
                />
                {errors.bank_name ? (
                  <div className="validation-error-label">
                    {errors.bank_name}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Branch Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="branch_name"
                  placeholder="Enter Branch Name"
                  value={editBankDetails.branch_name}
                />
                {errors.branch_name ? (
                  <div className="validation-error-label">
                    {errors.branch_name}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  IFSC Code
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="ifsc_code"
                  placeholder="Please Enter Your IFSC Code"
                  value={editBankDetails.ifsc_code}
                />
                {errors.ifsc_code ? (
                  <div className="validation-error-label">
                    {errors.ifsc_code}
                  </div>
                ) : null}
              </div>
            </div>
          </form>

          <div className="submit-reset-button">
            <button type="button" className="reset-button" onClick={resetForm}>
              <span className="reset-button-label">Reset</span>
            </button>
            <button
              type="submit"
              className="submit-button"
              onClick={() => saveBank()}
              disabled={buttonDisabled}
            >
              <span className="submit-button-label">Save</span>
              <img src={Save} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditBank;
