import React from "react";
import { Link, useLocation } from "react-router-dom";

function LoanReportDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const father_name =
    listDetails?.fathers_name !== "" && listDetails?.fathers_name !== undefined
      ? listDetails?.fathers_name
      : "";
  const Details = [
    {
      label: "Employee Name",
      value: listDetails?.employee_name + " " + father_name,
    },
    {
      label: "Employee Code",
      value: listDetails?.employeeCode,
    },
    {
      label: "Branch Name",
      value: listDetails?.branchName,
    },
    {
      label: "Employee Type Name",
      value: listDetails?.employeeTypeName,
    },
    {
      label: "Department Name",
      value: listDetails?.department_name,
    },
    {
      label: "Designation Name",
      value: listDetails?.designation_name,
    },
    {
      label: "Loan Type",
      value: listDetails?.type,
    },
    {
      label: "Amount",
      value: listDetails?.amount ? "₹" + listDetails?.amount : "",
    },
    {
      label: "Voucher Paid Status",
      value: listDetails?.voucher_status === 1 ? "Paid" : "Unpaid ",
    },
    {
      label: "Status",
      value: listDetails?.paid_amount === 1 ? "Paid" : "Unpaid",
    },

    {
      label: "Paid Amount",
      alue: listDetails?.paid_amount ? "₹" + listDetails?.paid_amount : "",
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/report/loan" className="in-active">
              Loan Report
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Loan Report Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Loan Details</h4>
          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoanReportDetails;
