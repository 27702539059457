import React, { useState, useEffect } from "react";
import BannerImage from "../Assets/Login/BannerImage.png";
import Banner from "../Assets/Login/Banner.png";
import Logo from "../Assets/Logo/Logo.jpg";
import LoginUser from "../Assets/Icons/LoginUser.svg";
import Password from "../Assets/Icons/Password.js";
import LoginIcon from "../Assets/Login/LoginIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import "alertifyjs/build/css/alertify.css";
import { handleLogin } from "./HandleLogin";
function Login() {
  const navigate = useNavigate();

  const [passwordType, setPasswordType] = useState("password");
  const [errors, setErrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const showPassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_role");
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };
  return (
    <div
      className="h-screen flex justify-center items-center p-10"
      style={{ backgroundImage: `url(${Banner})` }}
    >
      <div className="bg-white-color h-full w-full w-100 rounded flex shadow-2xl	">
        <div className="w-1/2">
          <img
            src={BannerImage}
            alt=""
            style={{ objectFit: "cover" }}
            className="h-full w-full"
          />
        </div>
        <div className="w-1/2 bg-[#fff] ">
          <div className="p-2">
            <div className="flex justify-center items-center gap-2">
              <img src={Logo} alt="" className="h-[180px]" />
            </div>
            <form
              className="mt-[32px] flex flex-col gap-1 flex justify-center items-center"
              style={{ padding: "0 20% 0 20%" }}
              onSubmit={(e) =>
                handleLogin(e, login, navigate, setButtonDisabled, setErrors)
              }
            >
              <div className="flex flex-col w-full min-h-[90px] gap-1">
                <label className="text-text-dark font-montserrat text-base font-medium">
                  User Name
                </label>
                <div className="username-field">
                  <input
                    type="text"
                    name="email"
                    className="font-karla text-base text-text-light"
                    autoComplete="off"
                    style={{
                      outline: "none",
                      border: "none",
                      boxShadow: "none",
                      width: "100%",
                    }}
                    placeholder="User Name"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <img src={LoginUser} alt="" />
                </div>
                {errors.email ? (
                  <div className="validation-error-label">{errors.email}</div>
                ) : null}
              </div>

              <div className="flex flex-col w-full min-h-[110px] gap-1">
                <label className="text-text-dark font-montserrat text-base font-medium">
                  Password
                </label>
                <div className="password-field">
                  <input
                    className="font-karla text-base text-text-light"
                    type={passwordType}
                    name="password"
                    style={{
                      outline: "none",
                      border: "none",
                      boxShadow: "none",
                      width: "100%",
                    }}
                    placeholder="Password"
                    autoComplete="off"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      showPassword();
                    }}
                  >
                    <Password active={passwordType === "text" ? true : false} />
                  </div>
                </div>
                <div className="flex justify-end items-end">
                  <Link
                    to="/login/identify"
                    className="font-karla text-xs font-normal text-primary-color "
                  >
                    Forgot Password!
                  </Link>
                </div>
                {errors?.password ? (
                  <div className="validation-error-label">
                    {errors?.password}
                  </div>
                ) : null}
              </div>

              <button
                type="submit"
                className="flex bg-primary-color w-full justify-center items-center px-4 py-3 rounded w-full"
                disabled={buttonDisabled}
              >
                <span className="text-white-color text-lg font-montserrat font-normal">
                  Login
                </span>
                <img src={LoginIcon} alt="" />
              </button>
            </form>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default Login;
