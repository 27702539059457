import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import View from "../../../Assets/Icons/View.svg";
import Save from "../../../Assets/Icons/Save.svg";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { POST_API, GET_API } from "../../../Services/api";

function EditBreakTime() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const listData = state?.list;
  const [errors, seterrors] = useState({});
  const [shiftOptions, setShiftOptions] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [editBreakTimeDetails, setEditBreakTimeDetails] = useState({
    id: listData?.id,
    name: listData?.name,
    from_time: listData?.from_time,
    to_time: listData?.to_time,
    shift_id: listData?.shift_id,
  });

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setEditBreakTimeDetails(() => ({ ...editBreakTimeDetails, [name]: value }));
  };

  const handleOptionChange = (e, name) => {
    setEditBreakTimeDetails(() => ({
      ...editBreakTimeDetails,
      [name]: e?.value,
    }));
  };

  const getShiftList = async () => {
    try {
      const shiftList = await GET_API("/shiftDropdown");
      if (shiftList?.data?.status === 200) {
        const shift = shiftList?.data?.data.map((val, i) => {
          return { value: val?.id, label: val?.name };
        });
        setShiftOptions(shift);
      }
    } catch (error) {
      alertify?.error("Failed to get Shift list...");
    }
  };
  useEffect(() => {
    getShiftList();
  }, []);

  const saveBreakTime = async (e) => {
    setButtonDisabled(true);
    const isValid = Validation.validateBreakTime(editBreakTimeDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    POST_API("/updateBreakTime", editBreakTimeDetails)
      .then(async function (data) {
        alertify.success(data?.data?.message);
        navigate("/master/hrms/break");
        setButtonDisabled(false);
      })
      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error.response.data.message);
      });
  };

  const resetForm = () => {
    setEditBreakTimeDetails({
      id: listData?.id,
      name: listData?.name,
      from_time: listData?.from_time,
      to_time: listData?.to_time,
      shift_id: listData?.shift_id,
    });
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/break" className="in-active">
              Break List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Edit Break Time</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Break Time</h4>

          <form>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Break Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter Break name"
                  value={editBreakTimeDetails.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  From Time
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="time"
                  autoComplete="off"
                  placeholder="Please select from time"
                  name="from_time"
                  value={editBreakTimeDetails.from_time}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {errors.from_time ? (
                  <div className="validation-error-label">
                    {errors.from_time}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  To Time
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="time"
                  autoComplete="off"
                  placeholder="Please select to time"
                  name="to_time"
                  value={editBreakTimeDetails.to_time}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {errors.to_time || errors.undefined ? (
                  <div className="validation-error-label">
                    {errors.to_time ? errors?.to_time : errors?.undefined}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Shift Name
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={shiftOptions}
                  value={shiftOptions?.find(
                    (option) => option?.value === editBreakTimeDetails.shift_id
                  )}
                  onChange={(e) => handleOptionChange(e, "shift_id")}
                  name="shift_id"
                  className=""
                  isSearchable
                />
                {errors.shift_id ? (
                  <div className="validation-error-label">
                    {errors.shift_id}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                type="button"
                className="submit-button"
                onClick={() => saveBreakTime()}
                disabled={buttonDisabled}
              >
                <span className="submit-button-label">Save</span>
                <img src={Save} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditBreakTime;
