import React, { useEffect, useState } from "react";
import FileUpload from "../../../Assets/Icons/FileUpload.svg";
import { GET_API, POST_API } from "../../../Services/api";
import alertify from "alertifyjs";
import config from "../../../Services/config.json";
import Spinner from "../../Spinner";
import { useGeneralSettings } from "../../../GeneralSettingsContext";

function GeneralSettings() {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setGeneralSettings } = useGeneralSettings();

  const [generalSetting, setGeneralSetting] = useState({
    title: "",
    logo: "",
    logo_to_show: "",
    favicon: "",
    favicon_to_show: "",
    primary_color: "#000000",
    secondary_color: "#000000",
  });

  useEffect(() => {
    getGeneralSettings();
  }, []);

  const getGeneralSettings = async () => {
    setLoading(true);
    try {
      const settings = await GET_API("settings");
      if (settings?.data?.status === 200) {
        const list = settings?.data?.data;

        setGeneralSettings({
          title: list?.title,
          logo_to_show: config?.path + list?.logo,
          favicon_to_show: config?.path + list?.favicon,
          primary_color: list?.primary_color,
          secondary_color: list?.secondary_color,
        });

        setGeneralSetting({
          ...generalSetting,
          title: list?.title,
          logo_to_show: config?.path + list?.logo,
          favicon_to_show: config?.path + list?.favicon,
          primary_color: list?.primary_color,
          secondary_color: list?.secondary_color,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get general setting value");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setGeneralSetting({ ...generalSetting, [name]: value });
  };

  const handleImageChange = (e, showName) => {
    const files = e.target.files;
    const { name } = e?.target;
    const filePromises = Array.from(files).map((file) => {
      return new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          if (fileReader.readyState === 2) {
            resolve({
              dataURL: fileReader.result,
              size: file.size,
              type: file.type,
            });
          }
        };
        fileReader.readAsDataURL(file);
      });
    });

    Promise.all(filePromises).then((fileDataArray) => {
      fileDataArray?.map((list) => {
        return setGeneralSetting((prevInventory) => ({
          ...prevInventory,
          [name]: list?.dataURL,
          [showName]: list?.dataURL,
        }));
      });
    });
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    setButtonDisabled(true);
    try {
      const addSettings = await POST_API("addSettings", generalSetting);
      if (addSettings?.data?.status === 200) {
        setButtonDisabled(false);
        alertify?.success(addSettings?.data?.message);
        getGeneralSettings();
      }
    } catch (error) {
      Object.keys(error?.response?.data?.data).forEach(function (key) {
        alertify?.error(error?.response?.data?.data[key].toString());
      });
      setButtonDisabled(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="grow bg-[#F7F6F4]">
      <div className="sm-container">
        <h1 className="heading-text">General Settings</h1>
        <div className="border-[1px] border-primary p-4 bg-white-color shadow-lg rounded">
          <div className="w-full flex gap-2">
            <form
              className="flex gap-4 w-full min-h-[80px] flex flex-col justify-between border-[1px] border-primary p-4 bg-white-color shadow-lg rounded"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div className="w-6/12 flex justify-between">
                <span className="text-text-light font-karla text-base">
                  Title
                </span>
                <input
                  type="text"
                  name="title"
                  className="input-field"
                  onChange={(e) => handleChange(e)}
                  value={generalSetting?.title}
                  autoComplete="off"
                />
              </div>
              <div className="w-6/12 flex justify-between">
                <span className="text-text-light font-karla text-base">
                  Logo
                </span>
                <div className="flex justify-center items-center gap-4">
                  <img
                    src={generalSetting?.logo_to_show}
                    alt=""
                    style={{
                      height: "50px",
                      width: "55px",
                      borderRadius: "30px",
                    }}
                  />
                  <div className="p-1 bg-[#FFF2EC] rounded flex justify-center items-center gap-1 cursor-pointer">
                    <label
                      for="logoFiles"
                      className="label-name cursor-pointer"
                    >
                      Choose
                    </label>
                    <input
                      className="w-0 cursor-pointer"
                      id="logoFiles"
                      style={{ visibility: "hidden" }}
                      type="file"
                      name="logo"
                      accept=".jpeg,.jpg,.png"
                      onChange={(e) => handleImageChange(e, "logo_to_show")}
                    />
                    <img src={FileUpload} alt="" />
                  </div>
                </div>
              </div>
              <div className="w-6/12 flex justify-between">
                <span className="text-text-light font-karla text-base">
                  Favicon
                </span>
                <div className="flex justify-center items-center gap-4">
                  <img
                    src={generalSetting?.favicon_to_show}
                    alt=""
                    style={{
                      height: "50px",
                      width: "55px",
                      borderRadius: "30px",
                    }}
                  />
                  <div className="p-1 bg-[#FFF2EC] rounded flex justify-center items-center gap-1 cursor-pointer">
                    <label
                      for="faviconFiles"
                      className="label-name cursor-pointer"
                    >
                      Choose
                    </label>
                    <input
                      className="w-0 cursor-pointer"
                      id="faviconFiles"
                      name="favicon"
                      style={{ visibility: "hidden" }}
                      type="file"
                      accept=".jpeg,.jpg,.png"
                      onChange={(e) => handleImageChange(e, "favicon_to_show")}
                    />
                    <img src={FileUpload} alt="" />
                  </div>
                </div>
              </div>
              <div className="w-6/12 flex justify-between">
                <span className="text-text-light font-karla text-base">
                  Primary Color
                </span>
                <div className="flex justufy-center items-center">
                  <span className="font-karla text-text-light">
                    {generalSetting?.primary_color}
                  </span>
                  <input
                    type="color"
                    name="primary_color"
                    onChange={(e) => handleChange(e)}
                    value={generalSetting?.primary_color}
                  />
                </div>
              </div>
              <div className="w-6/12 flex justify-between">
                <span className="text-text-light font-karla text-base">
                  Secondary Color
                </span>
                <div className="flex justufy-center items-center">
                  <span className="font-karla text-text-light">
                    {generalSetting?.secondary_color}
                  </span>
                  <input
                    type="color"
                    name="secondary_color"
                    onChange={(e) => handleChange(e)}
                    value={generalSetting?.secondary_color}
                  />
                </div>
              </div>
              <div className="w-6/12 flex justify-between">
                <div className="add-new-button-block">
                  <button
                    type="submit"
                    className="add-new-button"
                    disabled={buttonDisabled}
                  >
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralSettings;
