import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
// import Barcode from "react-barcode";
import alertify from "alertifyjs";
import Export from "../../../Assets/Icons/Export.svg";
import Logo from "../../../Assets/Logo/Logo.jpg";
import { GET_API, POST_API } from "../../../Services/api";
import { useReactToPrint } from "react-to-print";
import Spinner from "../../Spinner";
import "alertifyjs/build/css/alertify.css";
import QRCode from "react-qr-code";

function PayslipDetailsView() {
  let componentRef = useRef();
  const { id } = useParams();
  const [payslipDetails, setPayslipDetails] = useState({});
  const [voucherNumber, setVoucherNumber] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPayslip();
  }, []);

  const getPayslip = async () => {
    setLoading(true);
    try {
      const payroll = await GET_API(`/viewPayslipDetails/${id}`);
      if (payroll?.status === 200) {
        setVoucherNumber(payroll?.data?.data[0]?.voucher_no);
        const listData = payroll?.data?.data;
        setPayslipDetails(JSON.parse(listData[0]?.data));
        setLoading(false);
      }
    } catch (error) {
      alertify.error(error?.response?.data?.message);
    }
  };

  const print = useReactToPrint({
    content: () => componentRef.current,
  });

  const generatePayroll = async () => {
    setLoading(true);
    try {
      const payroll = await POST_API(`/payslipGenerate/${id}`, {
        payslipDetails,
        createdBy: localStorage.getItem("user_id"),
      });
      if (payroll.status === 200) {
        setLoading(false);
        const payslip = getPayslip();
        setTimeout(() => print(), 2000);
      }
    } catch (error) {
      setLoading(false);
      alertify.error(error?.response?.data?.message);
    }
  };

  const intToWords = (rawValue) => {
    var num = rawValue,
      a = [
        "",
        "one ",
        "two ",
        "three ",
        "four ",
        "five ",
        "six ",
        "seven ",
        "eight ",
        "nine ",
        "ten ",
        "eleven ",
        "twelve ",
        "thirteen ",
        "fourteen ",
        "fifteen ",
        "sixteen ",
        "seventeen ",
        "eighteen ",
        "nineteen ",
      ],
      b = [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ],
      c = ["thousand", "million", ""],
      words = "";

    num = ("000000000" + num.toString()).substr(-9).match(/.{3}/g);

    for (var i = 0; i < c.length; i++) {
      var n = num[i],
        str = "";
      str += words != "" ? " " + c[c.length - 1 - i] + " " : "";
      str += n[0] != 0 ? a[Number(n[0])] + "hundred " : "";
      n = n.substr(1);
      str +=
        n != 0
          ? (str != "" ? "and " : "") +
            (a[Number(n)] || b[n[0]] + " " + a[n[1]])
          : "";
      words += str;
    }
    return words.replace(/ +/g, " ").replace(/ $/, "");
  };

  if (loading) {
    <Spinner />;
  }

  const fathers_name =
    payslipDetails?.employee?.fathers_name !== null
      ? payslipDetails?.employee?.fathers_name
      : "";

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="heading-text">Payslip Details</h1>
          </div>
          <div className="bread-crumbs">
            <div>
              <Link to="/payroll/payslipdetails" className="in-active">
                Payslip List
              </Link>
              <Link>{" > "}</Link>
              <Link className="active">Payslip Details</Link>
            </div>
          </div>
        </div>
        <div className="card-wrapper">
          <div>
            <h4 className="payslip-header">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img src={Logo} className="h-[50px] w-[50px]" alt="" />
                  <div className="text-text-dark font-karla">Vasavi Tiles</div>
                </div>

                <div className="flex gap-3">
                  <QRCode
                    value={voucherNumber ? voucherNumber : ""}
                    style={{ height: "80px" }}
                  />
                </div>
              </div>
            </h4>
            <div className="p-1 pl-3 pr-3">
              <div className="flex justify-between font-karla">
                <div>
                  <div>
                    Payslip for the month of
                    <b>{payslipDetails?.displayMonth}</b>
                  </div>
                  <div className="flex">
                    <label className="list-label ">Employee Code:</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="advance-section-list-value"
                    >
                      {payslipDetails?.employee?.emp_code}
                    </div>
                  </div>
                  <div className="flex">
                    <label className="list-label">Employee Name: </label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="advance-section-list-value"
                    >
                      {payslipDetails?.employee?.first_name +
                        " " +
                        fathers_name}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex">
                    <label className="list-label">Designation: </label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="advance-section-list-value"
                    >
                      {payslipDetails?.employee?.designation_name}
                    </div>
                  </div>
                  <div className="flex">
                    <label className="list-label">Date: </label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="advance-section-list-value"
                    >
                      {payslipDetails?.date}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-3 pt-1">
              <table className=" w-[100%] border border-[#d9d9d9] border-collapse ">
                <tbody>
                  <tr>
                    <td className="border  border-[#d9d9d9] p-3 pt-3 p w-[40%]  align-baseline">
                      <table className="w-[100%] font-karla">
                        <tbody>
                          <tr>
                            <td>Holiday Scheme</td>
                            <td className="text-right">
                              <b>
                                {payslipDetails?.employee?.holiday_scheme_name}
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>Present Days</td>
                            <td className="text-right">
                              <b>
                                {payslipDetails?.attendance?.presentDays} days
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>Absent Days</td>
                            <td className="text-right">
                              <b>
                                {payslipDetails?.attendance?.absentDays} days
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>Total Absent Days</td>
                            <td className="text-right">
                              <b>
                                {payslipDetails?.attendance?.totalAbsentDays}{" "}
                                days
                              </b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[40%]  align-baseline">
                      <table className="w-[100%] font-karla">
                        <tbody>
                          <tr>
                            <th colSpan="4" className="text-left">
                              <b> Earnings</b>
                            </th>
                          </tr>
                          {payslipDetails?.earnings &&
                            payslipDetails?.earnings.map((list, i) => {
                              return (
                                <tr>
                                  <td>{list?.name}</td>
                                  <td>{list?.type}</td>
                                  <td className="text-right">
                                    {list?.totalAmount}
                                  </td>
                                  <td className="text-right">
                                    <b>{list?.actualAmount}</b>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </td>
                    <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[20%] align-baseline">
                      <table className="w-[100%] font-karla">
                        <tbody>
                          <tr>
                            <th colSpan="2" className="text-left">
                              <b>Deductions</b>
                            </th>
                          </tr>
                          {payslipDetails?.deductions &&
                            payslipDetails?.deductions.map((list, i) => {
                              return (
                                <tr>
                                  <td>{list?.name}</td>
                                  <td className="text-right">
                                    <b>{list?.amount}</b>
                                  </td>
                                </tr>
                              );
                            })}
                          {payslipDetails?.otherDeductions &&
                            payslipDetails?.otherDeductions.map((list, i) => {
                              return (
                                <tr>
                                  <td>{list?.name}</td>
                                  <td className="text-right">
                                    <b>{list?.amount}</b>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                      <table className="w-[100%] font-karla">
                        <tbody>
                          <tr>
                            <td>
                              <b>Total Days</b>
                            </td>
                            <td className="text-right">
                              {payslipDetails?.attendance?.totalDays} days
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                      <table className="w-[100%] font-karla">
                        <tbody>
                          <tr>
                            <td className="w-[65%]">
                              <b>Total</b>
                            </td>
                            <td className="text-right">
                              (-{payslipDetails?.totalEarnings?.deduction})
                            </td>
                            <td className="text-right">
                              {payslipDetails?.totalEarnings?.total}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                      <table className="w-[100%] font-karla">
                        <tbody>
                          <tr>
                            <td>
                              <b>Total</b>
                            </td>
                            <td className="text-right">
                              {payslipDetails?.totalDeductions?.total}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="p-3">
              <p className="flex items-center">
                <span className="font-karla">
                  Salary to be paid in cash:&nbsp;
                </span>
                <b className="flex items-center font-karla">
                  <span className="text-3xl">
                    Rs. {payslipDetails?.salaryToBePaid}
                  </span>
                  (
                  {payslipDetails?.salaryToBePaid &&
                    intToWords(payslipDetails?.salaryToBePaid)}
                  &nbsp;Rupees)
                </b>
              </p>
            </div>
            <br />
            <div className="p-3 pt-4">
              <div className="flex justify-between items-center">
                <div>
                  <div className="text-text-dark font-semibold font-karla">
                    Admit by
                  </div>
                </div>
                <div>
                  <div className="text-text-dark font-semibold font-karla">
                    Checked by
                  </div>
                </div>
                <div>
                  <div className="text-text-dark font-semibold font-karla">
                    Employee Signature
                  </div>
                </div>
              </div>
            </div>
            {/* PF Salary details */}
            {payslipDetails?.pfEarnings &&
              payslipDetails?.pfEarnings.length > 0 && (
                <>
                  <div id="scissors">
                    <div></div>
                  </div>
                  <h4 className="payslip-pf-header">
                    <div className="flex justify-between items-center">
                      PF Salary Voucher
                    </div>
                  </h4>

                  <div className="p-1 pl-3 pr-3">
                    <div className="flex justify-between">
                      <div>
                        <div className="flex">
                          <label className="list-label">Employee Code: </label>
                          <div
                            style={{ wordBreak: "break-word" }}
                            className="advance-section-list-value"
                          >
                            {payslipDetails?.employee?.emp_code}
                          </div>
                        </div>
                        <div className="flex">
                          <label className="list-label">Employee Name: </label>
                          <div
                            style={{ wordBreak: "break-word" }}
                            className="advance-section-list-value"
                          >
                            {payslipDetails?.employee?.first_name +
                              " " +
                              payslipDetails?.employee?.fathers_name}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="flex">
                          <label className="list-label">Designation: </label>
                          <div
                            style={{ wordBreak: "break-word" }}
                            className="advance-section-list-value"
                          >
                            {payslipDetails?.employee?.designation_name}
                          </div>
                        </div>
                        <div className="flex">
                          <label className="list-label">Date: </label>
                          <div
                            style={{ wordBreak: "break-word" }}
                            className="advance-section-list-value"
                          >
                            {payslipDetails?.date}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 pt-1">
                    <table className=" w-[100%] border border-[#d9d9d9] border-collapse ">
                      <tbody>
                        <tr>
                          <td className="border  border-[#d9d9d9] p-3 pt-3 p w-[40%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <td>Holiday Scheme</td>
                                  <td className="text-right">
                                    <b>
                                      {
                                        payslipDetails?.employee
                                          ?.holiday_scheme_name
                                      }
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Present Days</td>
                                  <td className="text-right">
                                    <b>
                                      {
                                        payslipDetails?.pfAttendance
                                          ?.presentDays
                                      }
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Paid Leave Days</td>
                                  <td className="text-right">
                                    <b>
                                      {payslipDetails?.pfAttendance?.paidDays}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Net Present Days</td>
                                  <td className="text-right">
                                    <b>
                                      {
                                        payslipDetails?.pfAttendance
                                          ?.netPresentDays
                                      }
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[40%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <th colSpan="4" className="text-left">
                                    <b> Earnings</b>
                                  </th>
                                </tr>
                                {payslipDetails?.pfEarnings &&
                                  payslipDetails?.pfEarnings.map((list, i) => {
                                    return (
                                      <tr>
                                        <td>{list?.name}</td>
                                        <td>{list?.type}</td>
                                        <td className="text-right">
                                          {list?.totalAmount}
                                        </td>
                                        <td className="text-right">
                                          {list?.actualAmount}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </td>
                          <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[20%] align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <th colSpan="2" className="text-left">
                                    <b>Deductions</b>
                                  </th>
                                </tr>
                                {payslipDetails?.pfDeductions &&
                                  payslipDetails?.pfDeductions.map(
                                    (list, i) => {
                                      return (
                                        <tr>
                                          <td>{list?.name}</td>
                                          <td className="text-right">
                                            <b>{list?.amount}</b>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <td>
                                    <b>Total Days</b>
                                  </td>
                                  <td className="text-right">
                                    {payslipDetails?.attendance?.totalDays} days
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <td className="w-[65%]">
                                    <b>Total</b>
                                  </td>
                                  <td className="text-right">
                                    {payslipDetails?.pfEarnings &&
                                      payslipDetails?.pfEarnings[0] &&
                                      payslipDetails?.pfEarnings[0]
                                        .actualAmount}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <td>
                                    <b>Total</b>
                                  </td>
                                  <td className="text-right">
                                    {payslipDetails?.pfTotalDeductions?.total}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="p-3 ">
                    <p className="flex items-center">
                      <span>Salary to be credited in Bank:&nbsp;</span>
                      <b className="flex items-center">
                        <span className="text-2xl">
                          {" "}
                          Rs. {payslipDetails?.pfSalaryInBank}
                        </span>
                        (
                        {payslipDetails?.pfSalaryInBank &&
                          intToWords(payslipDetails?.pfSalaryInBank)}
                        Rupees)
                      </b>
                    </p>
                  </div>
                  <br />

                  <div className="p-3 pt-4">
                    <div className="flex justify-between items-center">
                      <div>
                        <b>Admit by</b>
                      </div>
                      <div>
                        <b>Checked by</b>
                      </div>
                      <div>
                        <b>Employee Signature</b>
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
          {payslipDetails?.voucherNo === "" && (
            <div className="search-block ">
              <div className="export-btn" onClick={generatePayroll}>
                <span className="export-btn-text">Generate Payroll</span>
                <img src={Export} alt="" />
              </div>
            </div>
          )}

          <hr className=" border-[#d9d9d9] mt-6"></hr>
          <div className="p-3">
            <h3>
              <b>Attendance Details: </b>
            </h3>
          </div>
          <div className="grid grid-cols-2 p-6 pt-2 gap-2">
            <div>
              <p>
                Month : <b>{payslipDetails?.displayMonth}</b>
              </p>
              <p>
                Total days Present :
                <b>
                  {payslipDetails?.presentDetails &&
                    payslipDetails?.presentDetails.length}
                </b>
              </p>
            </div>
            <div className="flex justify-end items-center gap-3">
              <p className="flex jusitify-center items-center gap-2">
                <span className="bg-[#11B76B] border rounded border-[#11B76B] h-[14px] w-[14px]"></span>{" "}
                <span>Present</span>
              </p>
              <p className="flex jusitify-center items-center gap-2">
                <span className="bg-[#ed452a] border rounded border-[#ed452a]  h-[14px] w-[14px]"></span>
                <span>Absent</span>
              </p>
            </div>
          </div>
          <div className="p-1 flex flex-col items-center justify-center">
            <table className="overflow-x-scroll  text-center w-2/4">
              <tr>
                {payslipDetails?.daysArr &&
                  payslipDetails?.daysArr.map((list, i) => (
                    <th className="border attendance-header border-[#d9d9d9] p-3">
                      {list}
                    </th>
                  ))}
              </tr>
              {payslipDetails?.attendance_details &&
                Object.keys(payslipDetails?.attendance_details).map((key) => (
                  <>
                    <tr>
                      {payslipDetails?.attendance_details[key].map(
                        (item, index) => {
                          {
                            return (
                              <td className="font-karla border border-[#d9d9d9] p-3">
                                <span
                                  className={`rounded px-2 py-1 ${
                                    item.value === "P"
                                      ? "bg-[#11B76B] text-[#FFF]"
                                      : item.value === "A"
                                      ? "bg-[#ed452a] text-[#FFF]"
                                      : item.value === "F"
                                      ? "bg-[#DADADA] text-[#FFF]"
                                      : item.value === "-"
                                      ? "text-[#111211]"
                                      : ""
                                  }`}
                                >
                                  {item.date}
                                </span>
                              </td>
                            );
                          }
                        }
                      )}
                    </tr>
                  </>
                ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PayslipDetailsView;
