import React from "react";
import { Link, useLocation } from "react-router-dom";
import Edit from "../../Assets/Icons/Edit.svg";
import View from "../../Assets/Icons/View.svg";

function EmploymentTypeDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const Details = [
    {
      label: "Name",
      value: listDetails?.name,
    },

    // {
    //   label: "Employment Type",
    //   value: listDetails?.description,
    // },
    {
      label: "Tag Color",
      value: listDetails?.color,
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/employementType" className="in-active">
              Employment Type List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Employment Type Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Employment Type Details</h4>

          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmploymentTypeDetails;
