import React, { useState, useEffect } from "react";
import More from "../../../../Assets/Icons/More.svg";
import { Link, useNavigate } from "react-router-dom";
import { DELETE_API, GET_API } from "../../../../Services/api";
import Search from "../../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import SectionTable from "../../../DataTable/PurchaseOrder/SectionTable";

function Sections() {
  const navigate = useNavigate();
  const [sectionList, setSectionList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "name", label: "Name" },
    { id: "warehoue", label: "Warehouse Name" },
    { id: "description", label: "Description" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getSectionList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getSectionList(1, pageSize, searchKey);
  };

  const getSectionList = async (page, size, search) => {
    setLoading(true);
    try {
      const sectionList = await GET_API(
        `/po/section?page=${page}&size=${size}&search=${search}`
      );
      if (sectionList?.data?.status === 200) {
        if (
          sectionList?.data?.data?.current_page >
          sectionList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setSectionList(sectionList?.data?.data?.data);
        setFilteredList(sectionList?.data?.data?.data);
        setLastPage(sectionList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to Get Section List");
      setCurrentPage(1);
    }
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Section..?",
        async function () {
          try {
          } catch (error) {}
          if (row_id) {
            const deleteRow = await DELETE_API(`/po/deleteSection/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getSectionList(currentPage, pageSize, searchKey);
              setCurrentPage(1);
              return;
            }
            alertify.error("Failed To Delete Section");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const navigateEditPage = (listDetail) => {
    navigate("/master/po/warehouseSection/edit", {
      state: {
        list: listDetail,
      },
    });
  };

  const showBranchDetails = (listDetail) => {
    navigate("/master/po/warehouseSection/details", {
      state: {
        list: listDetail,
      },
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Section</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => setSearchKey(e?.target?.value)}
                style={{ outline: "none", border: "none" }}
                value={searchKey}
              />

              <img
                src={Search}
                alt=""
                className="cursor-pointer"
                onClick={() => getSectionList(currentPage, pageSize, searchKey)}
              />
            </div>

            <div className="add-new-button-block">
              <Link
                to="/master/po/warehouseSection/create"
                className="add-new-button"
              >
                <span>Add New</span>
                <img src={ColorAdd} alt="add" />
              </Link>
            </div>
          </div>

          {/**************** * Table ********************/}

          <SectionTable
            list={filteredList}
            columns={columns}
            icon={More}
            multiple={true}
            editPage={navigateEditPage}
            showDetails={showBranchDetails}
            deleteRow={handleDelete}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
            setPageNo={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
}
export default Sections;
