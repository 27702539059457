import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Divider from "../../Assets/Logo/IDCard/Divider.png";
import ShopName from "../../Assets/Logo/IDCard/ShopName.png";
import ShopNameWhite from "../../Assets/Logo/IDCard/ShopNameWhite.png";
import Address from "../../Assets/Logo/IDCard/Address.png";
import Human from "../../Assets/Logo/IDCard/Human.png";
import User from "../../Assets/Logo/IDCard/User.svg";
import NameBlocker from "../../Assets/Logo/IDCard/NameBlocker.png";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";

function IDCard() {
  const { state } = useLocation();
  const componentRef = useRef();

  const details = state?.list;

  const printVoucher = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div className="grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/employee/list" className="in-active">
              Employee List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">ID Card Preview</Link>
          </div>
        </div>
        <div className="flex justify-end items-center">
          <button
            type="button"
            className="submit-button"
            onClick={() => printVoucher()}
          >
            <span className="submit-button-label">Print</span>
          </button>
        </div>
        <div
          className="card-wrapper h-full flex justify-center gap-3 w-full"
          style={{ padding: "50px" }}
          ref={componentRef}
        >
          <div
            className="idcard-front h-[100%] border-[1px] border-primary"
            style={{ width: "32%" }}
          >
            <img
              src={Divider}
              alt="divider"
              style={{
                height: "5%",
                width: "100%",
                transform: "rotate(180deg)",
              }}
            />
            <div
              className="px-4 py-3 flex gap-5"
              style={{ height: "30%", backgroundColor: "#CC5343" }}
            >
              <div>
                <img src={Human} alt="" className="h-[60px]" />
              </div>
              <div className="pt-5">
                <img src={ShopNameWhite} alt="" className="h-[80px]" />
              </div>
            </div>
            <img
              src={Divider}
              alt="divider"
              style={{ height: "5%", width: "100%" }}
            />
            <div
              style={{ height: "30%", width: "100%" }}
              className="flex flex-col justify-center items-center relative"
            >
              <div
                className="absolute"
                style={{
                  height: "180px",
                  width: "180px",
                  top: "-35px",
                  backgroundColor: "#fff",
                  borderRadius: "100px",
                  border: "3px solid #fcb415",
                }}
              >
                <img src={User} alt="" />
              </div>
            </div>
            <div
              style={{ height: "25%", width: "100%" }}
              className="py-5 flex justify-between"
            >
              <img src={NameBlocker} alt="" />
              <div className="flex flex-col justify-center items-center">
                <div className="text-text-light font-semibold text-xl">
                  {details?.first_name}
                </div>
                <div
                  className="font-semibold text-xs"
                  style={{ color: "#CC5343" }}
                >
                  {details?.designationname}
                </div>
                <div className="text-xs text-text-light font-semibold">
                  Emp No : {details?.emp_code}
                </div>
              </div>
              <img src={NameBlocker} alt="" />
            </div>
            <img
              src={Divider}
              alt="divider"
              style={{
                height: "5%",
                width: "100%",
              }}
            />
          </div>
          <div
            className="idcard-back h-full  border-[1px] border-primary"
            style={{ width: "32%" }}
          >
            <div
              className="text-white-color font-semibold"
              style={{ height: "70%", backgroundColor: "#C04738" }}
            >
              <div
                className="bg-[#fff] h-inherit flex flex-col justify-around items-center "
                style={{
                  height: "95%",
                  backgroundColor: "#CC5343",
                  borderRadius: "160px",
                }}
              >
                <div className="flex flex-col gap-3 justify-center items-center w-full">
                  {/* <div className="flex w-full text-xs">
                    <div className="w-6/12 flex justify-end">
                      <div style={{ width: "65%" }}>DOB</div>
                    </div>
                    <div className="w-6/12 flex justify-start">
                      <div
                        style={{ width: "58%" }}
                        className="flex justify-between"
                      >
                        <div> : </div>
                        <div>{details?.date_of_birth}</div>
                      </div>
                    </div>
                  </div> */}
                  <div className="flex justify-center px-5 pt-5 gap-3">
                    <div className="flex flex-col gap-3 text-xs">
                      <div>DOB</div>
                      <div>MOBILE</div>
                      <div>BG</div>
                      <div>ADDRESS</div>
                    </div>
                    <div className="flex flex-col gap-3 text-xs">
                      <div>:</div>
                      <div>:</div>
                      <div>:</div>
                      <div>:</div>
                    </div>
                    <div className="flex flex-col gap-3 text-xs">
                      <div>
                        {details?.date_of_birth ? details?.date_of_birth : "-"}
                      </div>
                      <div>{details?.phone_no ? details?.phone_no : "-"}</div>
                      <div>
                        {details?.blood_group_name
                          ? details?.blood_group_name
                          : "-"}
                      </div>
                      <div>{details?.present_address}</div>
                    </div>
                  </div>

                  {/* <div className="flex w-full text-xs">
                    <div className="w-6/12 flex justify-end">
                      <div style={{ width: "65%" }}>MOBILE</div>
                    </div>
                    <div className="w-6/12 flex justify-start">
                      <div
                        style={{ width: "60%" }}
                        className="flex justify-between"
                      >
                        <div> : </div>
                        <div>{details?.phone_no}</div>
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full text-xs">
                    <div className="w-6/12 flex justify-end">
                      <div style={{ width: "65%" }}>BG</div>
                    </div>
                    <div className="w-6/12 flex justify-start">
                      <div
                        style={{ width: "32%" }}
                        className="flex justify-between"
                      >
                        <div> : </div>
                        <div>B+ve</div>
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full text-xs">
                    <div className="w-6/12 flex justify-end">
                      <div style={{ width: "65%" }}>ADDRESS</div>
                    </div>
                    <div className="w-6/12 flex justify-start">
                      <div
                        style={{ width: "90%" }}
                        className="flex justify-between"
                      >
                        <div> : </div>

                        <div>{details?.present_address}</div>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div
                  className="qr-code-border h-[100px] flex justify-center items-center"
                  style={{}}
                >
                  <div
                    style={{
                      backgroundColor: "#fff",
                      border: "3px solid #f27a20",
                      padding: "2px",
                    }}
                  >
                    <QRCode
                      value=""
                      style={{
                        height: "75px",
                        width: "max-content",
                      }}
                    />
                  </div>
                </div>
              </div>

              <img
                src={Divider}
                alt="divider"
                style={{
                  height: "5%",
                  width: "100%",
                  transform: "rotate(180deg)",
                }}
              />
            </div>

            <div style={{ height: "30%" }}>
              <div style={{ height: "90%" }}>
                <div className="bg-[#fff] flex justify-center items-center p-1">
                  <img src={ShopName} alt="" className="h-[80px]" />
                </div>
                <div className="bg-[#fff] flex justify-center items-center">
                  <img src={Address} alt="" style={{ height: "34px" }} />
                </div>
              </div>
              <img
                src={Divider}
                alt="divider"
                style={{ height: "10%", width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IDCard;
