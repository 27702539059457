import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "./Pagination";

export default function DataTable({
  list,
  setSelectedRows,
  columns,
  setPageNo,
  setPageSize,
  lastPage,
  currentPage,
  pageSize,
}) {
  const handleSelectionModelChange = (selectionModel, i) => {
    const selectedRows = selectionModel.map((selectedRowId) => {
      return list.find((row) => row.id === selectedRowId);
    });
    setSelectedRows(selectedRows);
  };

  const heightStyle =
    list.length === 0 ? { height: 320, width: "100%" } : { width: "100%" };

  return (
    <div style={heightStyle}>
      <DataGrid
        rows={list}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick={true}
        onRowSelectionModelChange={handleSelectionModelChange}
        onCellClick={false}
        sx={{
          ".MuiDataGrid-footerContainer": {
            display: "none",
          },
        }}
      />
      <div className="bg-[#fff]">
        <Pagination
          setPageNo={setPageNo}
          setPageSize={setPageSize}
          lastPage={lastPage}
          currentPage={currentPage}
          pageSize={pageSize}
          shape="rounded"
        />
      </div>
    </div>
  );
}
