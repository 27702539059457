import React, { useState, useEffect, useContext } from "react";
import AgentTable from "../DataTable/AgentsTable";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { DELETE_API, GET_API } from "../../Services/api";
import alertify from "alertifyjs";
import Select from "react-select";
import Spinner from "../Spinner";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import Search from "../../Assets/Icons/Search.svg";
import Footer from "../../Components/Footer";
import More from "../../Assets/Icons/More.svg";
import { PrivilegeContext } from "../../PrivilegeContext";

function AllCommisionList() {
  const navigate = useNavigate();
  const [filteredList, setFilteredList] = useState([]);
  const [agentcommisonList, setAgentCommissonList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const { access } = useContext(PrivilegeContext);
  const [addAccess, setAddAccess] = useState(false);
  const [showAccess, setShowAccess] = useState(false);
  const [deleteAccess, setdeleteAccess] = useState(false);
  const [editAccess, setEditAccess] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const [agentName, setAgentName] = useState({})
  const [agentOptions, setAgentOptions] = useState([]);
  const [isAgentLoading, setIsAgentLoading] = useState(false);
  const handleSelectChange = (e) => {
    setAgentName(e);
  };

  const showAgentCommissonDetails = (listDetail) => {
    navigate(`/expense/agentcommission/details/${listDetail?.agent_id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s.no", label: "S.No", minWidth: 170 },
    { id: "emp_code", label: "Employee Code", minWidth: 100 },
    { id: "employee_name", label: "Employee Name", minWidth: 100 },
    { id: "present_days", label: "Present Days", minWidth: 100 },
    { id: "agent_employee_amount", label: "Employee Amount", minWidth: 100 },
    { id: "agent_company_amount", label: "Company Amount", minWidth: 100 },
    { id: "more_icon", label: "", minWidth: 100 },
  ];

  const getAgentOptions = async () => {
    setIsAgentLoading(true);
    try {
      const getType = await GET_API("/agentDropdown/consultancy");
      if (getType.status === 200) {
        const agent = getType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.company_name };
        });
        agent.unshift({ value: "", label: "Select..." });
        setAgentOptions(agent);
        setIsAgentLoading(false);
      }
    } catch (error) {
      setIsAgentLoading(false);
      setAgentOptions([]);
      alertify.error("Failed to get Agent List...");
    }
  };

  useEffect(() => {
    getAgentCommissonList(currentPage, pageSize, searchKey, agentName?.value);
  }, [currentPage, pageSize, agentName?.value]);

  useEffect(() => {
    getAccess();
  }, [access]);

  useEffect(() => {
    getAgentOptions();
  }, []);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "agent_commission") {
        setAddAccess(item?.add);
        setShowAccess(item?.view);
        setdeleteAccess(item?.delete);
        setEditAccess(item?.edit);
      }
      return "";
    });
  };

  const isPageNotExit = () => {
    setCurrentPage(1);
    getAgentCommissonList(1, pageSize, searchKey, agentName?.value);
  };

  const getAgentCommissonList = async (page, size, search, agentId) => {
    setLoading(true);
    try {
      const agentCommissionList = await GET_API(
        `/empWiseAgentCommissionList/${agentId}?page=${page}&size=${size}&search=${search}`
      );
      if (agentCommissionList.status === 200) {
        if (
          agentCommissionList?.data?.data?.current_page >
          agentCommissionList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setAgentCommissonList(agentCommissionList?.data?.data?.data);
        setFilteredList(agentCommissionList?.data?.data?.data);
        setLastPage(agentCommissionList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (e) {
      setAgentCommissonList([]);
      setFilteredList([]);
      setLastPage("");
      setCurrentPage(1);
      setLoading(false);
      alertify.error("Failed to get Agent Commission List");
    }
  };

  const handleEditPage = (listDetail) => {
    navigate("/expense/agentcommission/edit", {
      state: {
        list: listDetail,
      },
    });
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Agent Commission...?",
        async function () {
          if (row_id) {
            try {
              const deleteRow = await DELETE_API(
                `/deleteAgentCommission/${row_id}`
              );
              if (deleteRow.status === 200) {
                alertify.success(deleteRow?.data?.message);
                getAgentCommissonList(currentPage, pageSize, searchKey, agentName?.value);
                return;
              }
            } catch (error) {
              alertify.error("Failed To Delete Agent Commission List...");
            }
          }
        },
        function () { }
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading || isAgentLoading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <div className="card-header">Agent Commission List</div>

          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                style={{ outline: "none", border: "none" }}
                onChange={(e) => setSearchKey(e?.target?.value)}
                value={searchKey}
              />

              <img
                src={Search}
                alt=""
                className="cursor-pointer"
                onClick={() => {
                  getAgentCommissonList(currentPage, pageSize, searchKey, agentName?.value);
                }}
              />
            </div>
            <div className="flex gap-3">
              <div className="dropdown-select-to-first">
                <Select
                  options={agentOptions}
                  value={agentName}
                  onChange={(e) => handleSelectChange(e)}
                  placeholder="Select Agent"
                  name="agent_id"
                  className="w-[250px]"
                  isSearchable
                />
              </div>
              {addAccess && (
                <div className="add-new-button-block">
                  <Link
                    to="/expense/agentcommission/add"
                    className="add-new-button"
                  >
                    <span>Add New</span>
                    <img src={ColorAdd} alt="add" />
                  </Link>
                </div>
              )}
            </div>
          </div>
          <AgentTable
            list={agentcommisonList}
            columns={columns}
            icon={More}
            editPage={handleEditPage}
            multiple={true}
            deleteRow={handleDelete}
            showDetails={showAgentCommissonDetails}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
            activeEdit={editAccess}
            activeDelete={deleteAccess}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AllCommisionList;





// import React, { useState, useEffect, useContext } from "react";
// import AgentTable from "../DataTable/AgentsTable";
// import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import { DELETE_API, GET_API } from "../../Services/api";
// import alertify from "alertifyjs";
// import Spinner from "../Spinner";
// import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
// import Search from "../../Assets/Icons/Search.svg";
// import Footer from "../../Components/Footer";
// import More from "../../Assets/Icons/More.svg";
// import { PrivilegeContext } from "../../PrivilegeContext";

// function AllCommisionList() {
//   const navigate = useNavigate();
//   const [filteredList, setFilteredList] = useState([]);
//   const [agentcommisonList, setAgentCommissonList] = useState([]);
//   const [loading, setLoading] = useState(false);

//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(10);
//   const [lastPage, setLastPage] = useState();
//   const { access } = useContext(PrivilegeContext);
//   const [addAccess, setAddAccess] = useState(false);
//   const [showAccess, setShowAccess] = useState(false);
//   const [deleteAccess, setdeleteAccess] = useState(false);
//   const [editAccess, setEditAccess] = useState(false);
//   const [searchKey, setSearchKey] = useState("");

//   const showAgentCommissonDetails = (listDetail) => {
//     navigate(`/expense/agentcommission/details/${listDetail?.agent_id}`, {
//       state: {
//         list: listDetail,
//       },
//     });
//   };

//   const columns = [
//     { id: "s.no", label: "S.No", minWidth: 170 },
//     { id: "agent_name", label: "Agent Name", minWidth: 100 },
//     { id: "amount", label: "Amount", minWidth: 100 },
//     { id: "percentage", label: "Percentage", minWidth: 100 },
//     { id: "remarks", label: "Remarks", minWidth: 100 },
//     { id: "created_at", label: "Date", minWidth: 100 },
//     { id: "more_icon", label: "", minWidth: 100 },
//   ];

//   useEffect(() => {
//     getAgentCommissonList(currentPage, pageSize, searchKey);
//   }, [currentPage, pageSize]);

//   useEffect(() => {
//     getAccess();
//   }, [access]);

//   const getAccess = () => {
//     access.map((item) => {
//       if (item?.submenu === "agent_commission") {
//         setAddAccess(item?.add);
//         setShowAccess(item?.view);
//         setdeleteAccess(item?.delete);
//         setEditAccess(item?.edit);
//       }
//       return "";
//     });
//   };

//   const isPageNotExit = () => {
//     setCurrentPage(1);
//     getAgentCommissonList(1, pageSize, searchKey);
//   };

//   const getAgentCommissonList = async (page, size, search) => {
//     setLoading(true);
//     try {
//       const agentCommissionList = await GET_API(
//         `/AgentCommissionList?page=${page}&size=${size}&search=${search}`
//       );
//       if (agentCommissionList.status === 200) {
//         if (
//           agentCommissionList?.data?.data?.current_page >
//           agentCommissionList?.data?.data?.last_page
//         ) {
//           isPageNotExit();
//           return;
//         }
//         setAgentCommissonList(agentCommissionList?.data?.data?.data);
//         setFilteredList(agentCommissionList?.data?.data?.data);
//         setLastPage(agentCommissionList?.data?.data?.last_page);
//         setLoading(false);
//       }
//     } catch (e) {
//       setAgentCommissonList([]);
//       setFilteredList([]);
//       setLastPage("");
//       setCurrentPage(1);
//       setLoading(false);
//       alertify.error("Failed to get Agent Commission List");
//     }
//   };

//   const handleEditPage = (listDetail) => {
//     navigate("/expense/agentcommission/edit", {
//       state: {
//         list: listDetail,
//       },
//     });
//   };

//   const handleDelete = (row_id) => {
//     alertify
//       .confirm(
//         "Confirm",
//         "Confirm to Delete Agent Commission...?",
//         async function () {
//           if (row_id) {
//             try {
//               const deleteRow = await DELETE_API(
//                 `/deleteAgentCommission/${row_id}`
//               );
//               if (deleteRow.status === 200) {
//                 alertify.success(deleteRow?.data?.message);
//                 getAgentCommissonList(currentPage, pageSize, searchKey);
//                 return;
//               }
//             } catch (error) {
//               alertify.error("Failed To Delete Agent Commission List...");
//             }
//           }
//         },
//         function () {}
//       )
//       .set("movable", false)
//       .set("labels", { ok: "Delete!", cancel: "Cancel!" });
//   };

//   if (loading) {
//     return <Spinner />;
//   }

//   return (
//     <div className="bg-[#F7F6F4] grow">
//       <div className="sm-container">
//         <div className="card-wrapper">
//           <div className="card-header">Agent Commission List</div>
//           <div className="search-block">
//             <div className="search">
//               <input
//                 type="text"
//                 className="search-input-box"
//                 placeholder="Search..."
//                 autoComplete="off"
//                 style={{ outline: "none", border: "none" }}
//                 onChange={(e) => setSearchKey(e?.target?.value)}
//                 value={searchKey}
//               />

//               <img
//                 src={Search}
//                 alt=""
//                 className="cursor-pointer"
//                 onClick={() => {
//                   getAgentCommissonList(currentPage, pageSize, searchKey);
//                 }}
//               />
//             </div>
//             {addAccess && (
//               <div className="add-new-button-block">
//                 <Link
//                   to="/expense/agentcommission/add"
//                   className="add-new-button"
//                 >
//                   <span>Add New</span>
//                   <img src={ColorAdd} alt="add" />
//                 </Link>
//               </div>
//             )}
//           </div>

//           <AgentTable
//             list={agentcommisonList}
//             columns={columns}
//             icon={More}
//             editPage={handleEditPage}
//             multiple={true}
//             deleteRow={handleDelete}
//             showDetails={showAgentCommissonDetails}
//             setPageNo={setCurrentPage}
//             pageSize={pageSize}
//             setPageSize={setPageSize}
//             lastPage={lastPage}
//             currentPage={currentPage}
//             activeEdit={editAccess}
//             activeDelete={deleteAccess}
//           />
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// }

// export default AllCommisionList;
