import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import Select from "react-select";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { POST_API, GET_API } from "../../../Services/api";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";

function CreateBreakTime() {
  const navigate = useNavigate();
  const [breakTimeDetails, setBreakTimeDetails] = useState({
    name: "",
    from_time: "",
    to_time: "",
    shift_id: [],
    shift_label: "",
  });
  const [shiftOptions, setShiftOptions] = useState([]);
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setBreakTimeDetails(() => ({ ...breakTimeDetails, [name]: value }));
  };

  const handleOptionChange = (e, name) => {
    setSelectedOptions(e);
    let shiftID = [...e]?.map((emp, i) => {
      return emp.value;
    });
    setBreakTimeDetails(() => ({ ...breakTimeDetails, shift_id: shiftID }));
  };

  const getShiftList = async () => {
    setLoading(true);
    try {
      const shiftList = await GET_API("/shiftDropdown");
      if (shiftList?.data?.status === 200) {
        const shift = shiftList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        setLoading(false);
        setShiftOptions(shift);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get Shift List");
    }
  };

  useEffect(() => {
    getShiftList();
  }, []);

  const createBreakTime = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateBreakTime(breakTimeDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const addBreakTime = await POST_API("/addBreakTime", breakTimeDetails);
      if (addBreakTime?.data?.status === 200) {
        setButtonDisabled(false);
        alertify.success(addBreakTime?.data?.message);
        navigate("/master/hrms/break");
      }
    } catch (error) {
      setButtonDisabled(false);
      alertify.error(error?.response?.data?.message);
    }
  };

  const resetForm = () => {
    setBreakTimeDetails({
      name: "",
      from_time: "",
      to_time: "",
      shift_id: "",
      shift_label: "",
    });
  };

  if (loading) return <Spinner />;
  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/break" className="in-active">
              Break List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Break Time</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Add Break Time</h4>

          {/***************Form************* **/}

          <form onSubmit={(e) => createBreakTime(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Break Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter Break name"
                  value={breakTimeDetails?.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Shift Name
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={shiftOptions}
                  value={selectedOptions ? selectedOptions : ""}
                  onChange={(e) => handleOptionChange(e, "shift_id")}
                  name="shift_id"
                  className=""
                  isMulti
                  isSearchable
                />
                {errors.shift_id ? (
                  <div className="validation-error-label">
                    {errors.shift_id}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  From Time
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="time"
                  autoComplete="off"
                  placeholder="Please select from time"
                  name="from_time"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={breakTimeDetails?.from_time}
                />
                {errors.from_time ? (
                  <div className="validation-error-label">
                    {errors.from_time}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  To Time
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="time"
                  autoComplete="off"
                  placeholder="Please select to time"
                  name="to_time"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={breakTimeDetails?.to_time}
                />
                {/* {errors.to_time || errors?.undefined ? (
                  <div className="validation-error-label">{errors.to_time}</div>
                ) : null} */}

                {errors.to_time || errors.undefined ? (
                  <div className="validation-error-label">
                    {errors.to_time ? errors?.to_time : errors?.undefined}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Save</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateBreakTime;
