import React, { useState, useEffect } from "react";
import More from "../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GET_API, DELETE_API } from "../../Services/api";
import Search from "../../Assets/Icons/Search.svg";
import HolidayListTable from "../DataTable/HolidayListTable";
import Spinner from "../Spinner";
import Footer from "../Footer";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

function HolidayList() {
  const navigate = useNavigate();
  const [holidatyList, setHolidatyList] = useState([]);
  const [filteredHolidayList, setFilteredHolidayList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");

  const showDetails = (listDetail) => {
    navigate("/master/hrms/holiday/details", {
      state: {
        list: listDetail,
      },
    });
  };

  const navigateEditPage = (listDetail) => {
    navigate("/master/hrms/holiday/edit", {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s.no", label: "S.No", minWidth: 170 },
    { id: "holiday_name", label: "Holiday Name", minWidth: 100 },
    { id: "religion", label: "Religion", minWidth: 100 },
    { id: "description", label: "Description", minWidth: 100 },
    { id: "leave_type", label: "Leave Type", minWidth: 100 },
    { id: "financial_year", label: "Financial Year", minWidth: 100 },
    { id: "created_at", label: "Created at", minWidth: 100 },
    { id: "more_icon", label: "", minWidth: 100 },
  ];

  const getHolidayList = async (page, size, search) => {
    setLoading(true);
    try {
      const holiday = await GET_API(
        `/holidaysList?page=${page}&size=${size}&search=${search}`
      );
      if (holiday?.status === 200) {
        if (
          holiday?.data?.data?.current_page > holiday?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setHolidatyList(holiday?.data?.data?.data);
        setFilteredHolidayList(holiday?.data?.data?.data);
        setLastPage(holiday?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setHolidatyList([]);
      setFilteredHolidayList([]);
      setLastPage("");
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    getHolidayList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getHolidayList(1, pageSize, searchKey);
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Holiday",
        async function () {
          if (row_id) {
            try {
              const deleteRow = await DELETE_API(
                `/deleteHolidayList/${row_id}`
              );
              if (deleteRow.status === 200) {
                alertify.success(deleteRow?.data?.message);
                getHolidayList(currentPage, pageSize, searchKey);
                setCurrentPage(1);
                return;
              }
            } catch (error) {
              alertify.error("Failed To Delete Holiday");
            }
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Holiday List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => setSearchKey(e?.target?.value)}
                value={searchKey}
                style={{ outline: "none", border: "none" }}
              />

              <img
                src={Search}
                alt=""
                className="cursor-pointer"
                onClick={() => {
                  getHolidayList(currentPage, pageSize, searchKey);
                }}
              />
            </div>

            <div className="add-new-button-block">
              <Link to="/master/hrms/holiday/add" className="add-new-button">
                <span>Add New</span>
                <img src={ColorAdd} alt="add" />
              </Link>
            </div>
          </div>
          <HolidayListTable
            list={filteredHolidayList}
            columns={columns}
            icon={More}
            multiple={true}
            setState={setHolidatyList}
            setSearch={setFilteredHolidayList}
            editPage={navigateEditPage}
            showDetails={showDetails}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HolidayList;
