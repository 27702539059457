import React from "react";
import { Link, useLocation } from "react-router-dom";
import Edit from "../../../Assets/Icons/Edit.svg";
import View from "../../../Assets/Icons/View.svg";

function ShiftDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const Details = [
    {
      label: "Shift Name",
      value: listDetails?.name,
    },
    {
      label: "From Time",
      value: listDetails?.from_time,
    },
    {
      label: "To Time",
      value: listDetails?.to_time,
    },
    {
      label: "Fine Per Min",
      value: listDetails?.fine_per_min,
    },
    {
      label: "Max Time Limit",
      value: listDetails?.max_time_limit,
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/shift" className="in-active">
              Shift List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Shift Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Shift Details</h4>
          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list" key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShiftDetails;
