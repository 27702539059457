import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import More from "../../../Assets/Icons/More.svg";
import { DELETE_API, GET_API } from "../../../Services/api";
import SeasonalSalaryListTable from "../../DataTable/SeasonalSalaryListTable";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import "alertifyjs/build/css/alertify.css";

function SeasonalSalaryList() {
  const navigate = useNavigate();
  const [seasonalSalaryList, setSeasonalSalaryList] = useState([]);
  const [filteredSeasonalSalary, setFilteredSeasonalSalary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "employee_type_id", label: "Employee Type" },
    { id: "from_date", label: "From Date" },
    { id: "to_date", label: "To Date" },
    { id: "per_day_salary", label: "Per Day Salary" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getSeasonalSalary(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getSeasonalSalary(1, pageSize, searchKey);
  };

  const getSeasonalSalary = async (page, size, search) => {
    setLoading(true);
    try {
      const SeasonalSalary = await GET_API(
        `/seasonalSalaryList?page=${page}&size=${size}&search=${search}`
      );
      if (SeasonalSalary.status === 200) {
        if (
          SeasonalSalary?.data?.data?.current_page >
          SeasonalSalary?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setSeasonalSalaryList(SeasonalSalary?.data?.data?.data);
        setFilteredSeasonalSalary(SeasonalSalary?.data?.data?.data);
        setLastPage(SeasonalSalary?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setSeasonalSalaryList([]);
      setFilteredSeasonalSalary([]);
      setLastPage("");
      setCurrentPage(1);
      setLoading(false);
      alertify.error("Failed to Get Seasonal Salary List");
    }
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Seasonal Salary?",
        async function () {
          if (row_id) {
            try {
              const deleteRow = await DELETE_API(
                `/deleteSeasonalSalaryDetails/${row_id}`
              );
              if (deleteRow.status === 200) {
                alertify.success(deleteRow?.data?.message);
                getSeasonalSalary(currentPage, pageSize, searchKey);
                setCurrentPage(1);
                return;
              }
            } catch (error) {
              alertify.error("Failed To Delete Seasonal Salary");
            }
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const navigateEditPage = (listDetail) => {
    navigate("/master/hrms/seasonalsalary/edit", {
      state: {
        list: listDetail,
      },
    });
  };

  const showBranchDetails = (listDetail) => {
    navigate("/master/hrms/seasonalsalary/details", {
      state: {
        list: listDetail,
      },
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Seasonal Salary List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => setSearchKey(e?.target?.value)}
                value={searchKey}
                style={{ outline: "none", border: "none" }}
              />
              <img
                src={Search}
                alt=""
                className="cursor-pointer"
                onClick={() => {
                  getSeasonalSalary(currentPage, pageSize, searchKey);
                }}
              />
            </div>

            <div className="add-new-button-block">
              <Link
                to="/master/hrms/seasonalsalary/add"
                className="add-new-button"
              >
                <span>Add New</span>
                <img src={ColorAdd} alt="add" />
              </Link>
            </div>
          </div>

          <SeasonalSalaryListTable
            list={filteredSeasonalSalary}
            columns={columns}
            icon={More}
            multiple={true}
            editPage={navigateEditPage}
            showDetails={showBranchDetails}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default SeasonalSalaryList;
