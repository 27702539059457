import React from "react";
import { Link, useLocation } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import "alertifyjs/build/css/alertify.css";

function MasterLogs() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const originalData = listDetails?.original_data
    ? JSON.parse(listDetails?.original_data)
    : "";
  const changedData = listDetails?.changed_data
    ? JSON.parse(listDetails?.changed_data)
    : "";
  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/approve/masters" className="in-active">
              Masters Logs
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Masters Log Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Masters Log Details</h4>
          <div className="log-content">
            <div className="log-field-block">
              <span className="content-label">Module Name</span>
              <span className="content-value">{listDetails?.module_name}</span>
            </div>
            <div className="log-field-block">
              <span className="content-label">Save Type</span>
              <span className="content-value">{listDetails?.save_type}</span>
            </div>
            {originalData && (
              <>
                <span className="content-label">Actual Data</span>
                <table className="log-table mt-[4px]">
                  {Object.keys(originalData).map((key, index) => {
                    return (
                      <tr>
                        <td className="log-table log-table-data content-label">
                          {key.toUpperCase().replace("_", " ")}
                        </td>
                        <td className="log-table-data log-table content-value">
                          {originalData[key]}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </>
            )}

            {changedData && (
              <>
                <span className="content-label">Actual Data</span>
                <table className="log-table mt-[4px]">
                  {Object.keys(changedData).map((key, index) => {
                    return (
                      <tr>
                        <td className="log-table log-table-data content-label">
                          {key.toUpperCase().replace("_", " ")}
                        </td>
                        <td className="log-table-data log-table content-value">
                          {changedData[key]}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MasterLogs;
