import React, { useState, useEffect } from "react";
import View from "../../../Assets/Icons/View.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { GET_API, POST_API } from "../../../Services/api";
import Validation from "../../../Validation/Validation";
import alertify from "alertifyjs";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import "alertifyjs/build/css/alertify.css";
import Spinner from "../../Spinner";

function CreateManualAttendance() {
  const navigate = useNavigate();
  const [errors, seterrors] = useState({});
  const [fineList, setFineList] = useState([]);
  const [employeeList, setemployeeList] = useState([]);
  const [approvedBy, setApprovedBy] = useState([]);
  const [empName, setEmpName] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [leaveDetails, setleaveDetails] = useState({
    employee_id: "",
    clock_in: "",
    clock_out: "",
    approval_status: "",
    fine_type_id: "",
    fine_amount: 0,
    approved_by: 0,
    start_date: "",
    end_date: "",
    special_entry: 0,
    created_by: localStorage?.getItem("user_id"),
  });

  useEffect(() => {
    getEmployeeList();
    getFineList();
  }, []);

  const handleFineAmount = async (name, value, setValue) => {
    const checkManualAttendanceShift = await POST_API(
      "checkManualAttendanceShift",
      {
        employee_id: setValue?.employee_id,
        shift_in: setValue?.clock_in,
      }
    );
    if (checkManualAttendanceShift?.data?.status === 200) {
      setleaveDetails({
        ...leaveDetails,
        [name]: value,
        fine_amount: checkManualAttendanceShift?.data?.data?.fineAmount,
      });
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    const setValue = { ...leaveDetails, [name]: value };
    setleaveDetails(setValue);
    if (
      name === "clock_in" &&
      setValue?.clock_in !== "" &&
      setValue?.employee_id !== ""
    ) {
      handleFineAmount(name, value, setValue);
    }
  };

  const handleSelectChange = (value, name) => {
    setleaveDetails(() => ({ ...leaveDetails, [name]: value }));
  };

  const handleEmployee = async (key, e) => {
    setLoading1(true);
    setEmpName("");
    try {
      const getEmployeeName = await GET_API(`/employeeGet/${e?.value}`);
      if (getEmployeeName.status === 200) {
        setEmpName(`${getEmployeeName?.data?.data?.first_name}`);
        setleaveDetails({
          ...leaveDetails,
          [key]: e?.value,
          clock_in: "",
          clock_out: "",
          approval_status: "",
          fine_type_id: "",
          fine_amount: "",
          approved_by: "",
        });
        setLoading1(false);
      }
    } catch (error) {
      setLoading1(false);
      alertify.error("Failed to load Employee Name");
    }
  };

  const handleSpecialEntry = (e) => {
    const { name, checked } = e?.target;
    const value = {
      ...leaveDetails,
      fine_amount: 0,
      special_entry: e?.target?.checked ? 1 : 0,
    };
    if (e?.target?.checked) {
      setleaveDetails(value);
    } else {
      if (leaveDetails?.employee_id !== "" && leaveDetails?.clock_in !== "") {
        handleFineAmount(name, checked, value);
        return;
      }
    }
  };
  const createManualAttendance = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateManualAttendance(leaveDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].context?.key]:
          isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    try {
      const manualAttendance = await POST_API(
        "/AddManualAttendance",
        leaveDetails
      );
      if (manualAttendance.status === 200) {
        alertify.success("Manual Attendance added successfully!");
        navigate("/manualattendance");
        setButtonDisabled(false);
      }
    } catch (error) {
      setButtonDisabled(false);
      alertify.error("Failed to Add Manual Attendance");
    }
  };

  const getEmployeeList = async () => {
    try {
      setLoading2(true);
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList.status === 200) {
        setLoading2(false);
        const empList = employeeList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.emp_code };
        });
        const appBy = employeeList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.first_name };
        });
        appBy.unshift({ value: "", label: "Select..." });
        empList.unshift({ value: "", label: "Select..." });
        setemployeeList(empList);
        setApprovedBy(appBy);
      }
    } catch (error) {
      alertify.error("Failed To get Employee List");
      setLoading2(false);
    }
  };

  const getFineList = async () => {
    try {
      setLoading3(true);
      const fineList = await GET_API("financeCategoriesDropdownFine");
      if (fineList.status === 200) {
        const fList = fineList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        fList.unshift({ value: "", label: "Select..." });
        setFineList(fList);
        setLoading3(false);
      }
    } catch (error) {
      alertify.error("Failed To get Fine List");
      setLoading3(false);
    }
  };
  const resetForm = () => {
    setleaveDetails({
      employee_id: "",
      date: "",
      clock_in: "",
      clock_out: "",
      approval_status: "",
      fine_type_id: "",
      fine_amount: "",
      approved_by: 0,
      start_date: "",
      end_date: "",
      no_of_days: "",
      created_by: localStorage?.getItem("user_id"),
    });
    setEmpName("");
  };

  useEffect(() => {
    if (
      leaveDetails.start_date !== "" &&
      leaveDetails?.end_date !== "" &&
      leaveDetails.start_date <= leaveDetails.end_date
    ) {
      const fromDate = new Date(leaveDetails?.start_date);
      const toDate = new Date(leaveDetails?.end_date);
      const diffTime = Math.abs(fromDate - toDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
      setleaveDetails(() => ({ ...leaveDetails, no_of_days: diffDays }));
    } else {
      setleaveDetails(() => ({ ...leaveDetails, no_of_days: 0 }));
    }
  }, [leaveDetails.end_date, leaveDetails?.start_date]);

  if (loading1 || loading2 || loading3) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/manualAttendance" className="in-active">
              Manual Attendance List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Manual Attendance</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Add Manual Attendance</div>

          <form onSubmit={(e) => createManualAttendance(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Employee code
                  <span className="font-bold" style={{ color: "#FF5C42" }}>
                    *
                  </span>
                </label>
                <Select
                  options={employeeList}
                  value={
                    leaveDetails.employee_id
                      ? employeeList?.find(
                          (option) => option?.value === leaveDetails.employee_id
                        )
                      : ""
                  }
                  onChange={(e) => handleEmployee("employee_id", e)}
                  name="employee_id"
                  className=""
                  isSearchable
                />
                {errors["employee_id"] ? (
                  <div className="validation-error-label">
                    {errors["employee_id"]}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Employee Name
                  <span className="font-bold" style={{ color: "#FF5C42" }}>
                    *
                  </span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  placeholder="Employee Name"
                  name="employee_name"
                  value={empName}
                  readOnly
                  disabled
                />
              </div>

              <div className="field-block">
                <label className="label-name">
                  From Date
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="date"
                  autoComplete="off"
                  placeholder="Select From Date"
                  name="start_date"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={leaveDetails?.start_date}
                  max={leaveDetails?.end_date}
                />
                {errors?.start_date ? (
                  <div className="validation-error-label">
                    {errors?.start_date}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  To Date
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="date"
                  autoComplete="off"
                  placeholder="Select To Date"
                  name="end_date"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={leaveDetails?.end_date}
                  min={leaveDetails?.start_date}
                />
                {errors?.end_date ? (
                  <div className="validation-error-label">
                    {errors?.end_date}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  No Of Days
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  placeholder="Enter No.Of.Days"
                  name="no_of_days"
                  readOnly
                  disabled
                  value={leaveDetails?.no_of_days}
                />
                {errors?.no_of_days ? (
                  <div className="validation-error-label">
                    {errors?.no_of_days}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  In Time
                  <span className="font-bold" style={{ color: "#FF5C42" }}>
                    *
                  </span>
                </label>
                <input
                  className="input-field"
                  type="time"
                  autoComplete="off"
                  placeholder="In Time"
                  name="clock_in"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={leaveDetails?.clock_in ? leaveDetails?.clock_in : ""}
                />
                {errors["clock_in"] ? (
                  <div className="validation-error-label">
                    {errors["clock_in"]}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Out Time
                  <span className="font-bold" style={{ color: "#FF5C42" }}>
                    *
                  </span>
                </label>

                <input
                  className="input-field"
                  type="time"
                  autoComplete="off"
                  placeholder="Out Time"
                  name="clock_out"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={leaveDetails?.clock_out}
                />
                {errors["clock_out"] ? (
                  <div className="validation-error-label">
                    {errors["clock_out"]}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">Fine Type</label>
                <Select
                  options={fineList}
                  value={fineList?.find(
                    (option) => option?.value === leaveDetails.fine_type_id
                  )}
                  onChange={(e) => handleSelectChange(e?.value, "fine_type_id")}
                  name="fine_type_id"
                  className=""
                  isSearchable
                />
                {errors["fine_type_id"] ? (
                  <div className="validation-error-label">
                    {errors["fine_type_id"]}
                  </div>
                ) : null}
              </div>

              <div className="h-max flex gap-2">
                <label className="label-name">Special Entry</label>
                <input
                  className=" input-field"
                  type="checkbox"
                  name="special_entry"
                  autoComplete="off"
                  onChange={(e) => {
                    handleSpecialEntry(e);
                  }}
                />
                {errors["special_entry"] ? (
                  <div className="validation-error-label">
                    {errors["special_entry"]}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Fine Amount</label>
                <input
                  className=" input-field"
                  type="number"
                  autoComplete="off"
                  placeholder="Fine Amount"
                  name="fine_amount"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={leaveDetails?.fine_amount}
                />
                {errors["fine_amount"] ? (
                  <div className="validation-error-label">
                    {errors["fine_amount"]}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Approval</label>
                <Select
                  options={approvedBy}
                  value={approvedBy?.find(
                    (option) => option?.value === leaveDetails.approved_by
                  )}
                  onChange={(e) => handleSelectChange(e?.value, "approved_by")}
                  name="approved_by"
                  className=""
                  isSearchable
                />
                {errors["approved_by"] ? (
                  <div className="validation-error-label">
                    {errors["approved_by"]}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateManualAttendance;
