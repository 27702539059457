import React from "react";

function FMS({ fill }) {
  return (
    <>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="8.0004"
          y="8.62622"
          width="6"
          height="14.9985"
          rx="3"
          transform="rotate(-90 8.0004 8.62622)"
          fill={fill}
        />
        <rect
          x="8.0004"
          y="15.6262"
          width="6"
          height="14.9985"
          rx="3"
          transform="rotate(-90 8.0004 15.6262)"
          fill={fill}
        />
        <rect
          x="8.0004"
          y="22.6262"
          width="6"
          height="14.9985"
          rx="3"
          transform="rotate(-90 8.0004 22.6262)"
          fill={fill}
        />
        <ellipse
          opacity="0.5"
          cx="4.00091"
          cy="5.62622"
          rx="3"
          ry="2.99969"
          transform="rotate(-90 4.00091 5.62622)"
          fill={fill}
        />
        <ellipse
          opacity="0.5"
          cx="4.00091"
          cy="12.6262"
          rx="3"
          ry="2.99969"
          transform="rotate(-90 4.00091 12.6262)"
          fill={fill}
        />
        <ellipse
          opacity="0.5"
          cx="4.00091"
          cy="19.6262"
          rx="3"
          ry="2.99969"
          transform="rotate(-90 4.00091 19.6262)"
          fill={fill}
        />
      </svg>
    </>
  );
}

export default FMS;
