import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "./Pagination";
import "alertifyjs/build/css/alertify.css";

export default function StickyHeadTable({
  list,
  columns,
  pfList,
  setManualPFList,
  isCurrentMonth,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
}) {
  const handleChange = (e, i) => {
    const { name, value } = e?.target;
    const newArr = [...pfList];
    newArr[i][name] = value;
    setManualPFList(newArr);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, i) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    {
                      <TableCell className="table-body !w-[7%]">
                        {(currentPage - 1) * pageSize + (i + 1)}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.employeeCode}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.first_name + " " + row?.fathers_name}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {/* {isCurrentMonth ? ( */}
                        <input
                          type="number"
                          className="input-field"
                          name="pf_amount"
                          onChange={(e) => {
                            handleChange(e, i);
                          }}
                          placeholder="PF Amount"
                          autoComplete="off"
                          value={pfList[i]["pf_amount"]}
                        />
                        {/* ) : ( */}
                        {/* row?.pf_amount */}
                        {/* )} */}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {/* {isCurrentMonth ? ( */}
                        <input
                          className="input-field"
                          name="pf_deduction"
                          onChange={(e) => {
                            handleChange(e, i);
                          }}
                          placeholder="PF Deductions"
                          type="number"
                          autoComplete="off"
                          value={pfList[i]["pf_deduction"]}
                        />
                        {/* ) : (
                          row?.pf_deduction
                        )} */}
                      </TableCell>
                    }
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
