import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Pagination from "./Pagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import MoreOption from "../MoreOption";

export default function StickyHeadTable({
  list,
  columns,
  showDetails,
  icon,
  multiple,
  deleteRow,
  editPage,
  allowance,
  termination,
  terminateEmployee,
  activeEdit,
  activeDelete,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage
}) {

  const navigate = useNavigate();
  const showDetailsPage = (listDetail) => {
    const id = listDetail?.employeeId;
    navigate(`/report/parttimeEmployee/details/${id}`, {
      state: {
        list: listDetail,
      },
    });
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list
                .map((row, i) => {

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={i}
                      className="table-column"
                    >
                      {
                        <TableCell className="!w-[7%] table-body ">
                          {(currentPage - 1) * pageSize + (i + 1)}
                        </TableCell>
                      }

                      {
                        <TableCell className="table-body">
                          <a onClick={() => showDetailsPage(row)} style={{ color: "#0284C7" }} className="cursor-pointer text-sky-600 border-b-[1px] border-b-[#0284C7]" >
                            {row.EmployeeCode}
                          </a>
                        </TableCell>
                      }

                      {
                        <TableCell className="table-body">
                          {row?.EmployeeName}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row?.departmentName}
                        </TableCell>
                      }

                      {
                        <TableCell className="table-body">
                          {row?.designationName}
                        </TableCell>
                      }
                      {
                        <MoreOption
                          icon={icon}
                          row={row}
                          showDetails={showDetails}
                          multiple={multiple}
                          deleteRow={deleteRow}
                          editPage={editPage}
                          allowance={allowance}
                          termination={termination}
                          terminateEmployee={terminateEmployee}
                          activeEdit={activeEdit}
                          activeDelete={activeDelete}
                        />
                      }
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
