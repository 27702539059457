import React, { useEffect, useState } from "react";
import alertify from "alertifyjs";
import Select from "react-select";
import { GET_API, BASE_PATH, BASE_URL } from "../../../Services/api";
import BranchWiseReportTable from "../../DataTable/BranchWiseReportTable";
import Spinner from "../../Spinner";
import dayjs from "dayjs";
import Filter from "../../../Assets/Icons/Filter.svg";
import Export from "../../../Assets/Icons/Export.svg";
import ButtonLoader from "../../ButtonLoader";

function AbsentReportList() {
  const [isLoading, setIsLoading] = useState(false);
  const [columnsData, setColumnsData] = useState([]);
  const [floorDate, setFloorData] = useState([]);
  const [floorName, setFloorName] = useState("");
  const [floorList, setFloorList] = useState([]);
  const [currentMonth, setCurrentMonth] = useState("");
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(false);
  const [employeeName, setEmployeeName] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [isFloorLoading, setIsFloorLoading] = useState(false);
  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [isEmpTypeLoading, setIsEmpTypeLoading] = useState(false);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
  const [isDesignationLoading, setIsDesignationLoading] = useState(false);
  const [filterParams, setFilterParams] = useState("");
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [filterList, setfilterList] = useState({
    employee_id: "",
    employee_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();

  useEffect(() => {
    const currentMonth = dayjs().month() + 1;
    const currentYear = dayjs().format("YYYY");
    getFloorList();
    setCurrentMonth(`${currentYear}-${currentMonth}`);
  }, []);

  const getFloorList = async () => {
    setIsFloorLoading(true);
    try {
      const floorList = await GET_API("/floorDropdown");
      if (floorList.status === 200) {
        const floor = floorList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floor.unshift({ value: "", label: "Select..." });
        setFloorName(floor[1]);
        setFloorList(floor);
        setIsFloorLoading(false);
      }
    } catch (e) {
      setIsFloorLoading(false);
      setFloorName("");
      setFloorList([]);
      alertify.error("Failed to Get Floor List");
    }
  };

  useEffect(() => {
    getFloorWiseReportList(currentPage, pageSize, filterParams);
  }, [floorList?.length, currentPage, pageSize, showFilterResultData]);

  const getFloorWiseReportList = async (page, size, filters) => {
    try {
      setIsLoading(true);
      const floorList = await GET_API(
        `/floorWiseAttendance?year=${dayjs(currentMonth).format(
          "YYYY"
        )}&month=${dayjs(currentMonth).format("MM")}&floor=${
          floorName?.value
        }&page=${page}&size=${size}${filters}`
      );
      if (floorList.status === 200) {
        const date = floorList?.data?.dates;
        const floor = floorList?.data?.floorData?.map((item) => {
          return {
            empCode: item?.empCode,
            name: item?.name,
            ...item?.attendance,
          };
        });
        setLastPage(floorList?.data?.employees?.last_page);
        setColumnsData(["S.NO", "Employee Code", "Employee Name", ...date]);
        setFloorData(floor);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      setColumnsData([]);
      setFloorData([]);
      alertify.error("Failed to Get Floor Wise Report List");
    }
  };

  useEffect(() => {
    getEmployeeNameList();
    getEmployeeType();
    getDepartmentList();
    getDesignationList();
  }, []);

  const getEmployeeNameList = async () => {
    setIsEmpLoading(true);
    try {
      const empName = await GET_API("/employeeDropdown");
      if (empName?.status === 200) {
        const empList = empName?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeName(empList);
        setIsEmpLoading(false);
      }
    } catch (error) {
      setIsEmpLoading(false);
      setEmployeeName([]);
      alertify.error("Failed to get Employee List");
    }
  };

  const getEmployeeType = async () => {
    setIsEmpTypeLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setIsEmpTypeLoading(false);
      }
    } catch (e) {
      setIsEmpTypeLoading(false);
      setEmployeetype([]);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getDepartmentList = async () => {
    setIsDepartmentLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setIsDepartmentLoading(false);
      }
    } catch (e) {
      setIsDepartmentLoading(false);
      setDepartment([]);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setIsDesignationLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setIsDesignationLoading(false);
      }
    } catch (e) {
      setIsDesignationLoading(false);
      setDesignation([]);
      alertify.error("Failed to Get Designation List");
    }
  };

  const handleDateChange = (e) => {
    setCurrentMonth(e.target.value);
  };

  const genderList = [
    { label: "Please Select", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };
  const handleFilter = async (e, selectName) => {
    const { value } = e;
    filterList[selectName] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [selectName]: value });
    }
    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };
  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  if (
    loading ||
    isLoading ||
    isFloorLoading ||
    isEmpLoading ||
    isEmpTypeLoading ||
    isDepartmentLoading ||
    isDesignationLoading
  ) {
    return <Spinner />;
  }

  return (
    <div className="grow bg-[#F7F6F4] w-[80%]">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Floor Wise Attendance Report List</h4>
          <div className="search-block">
            <div className="flex justify-center items-center gap-3">
              <div className="field-block dropdown-select-to-second">
                <label className="label-name">Floor Name</label>
                <Select
                  options={floorList}
                  value={floorName}
                  onChange={(e) => setFloorName(e)}
                  name="floorid"
                  className=""
                  isSearchable
                />
              </div>
              <div className="field-block">
                <label className="label-name">Month</label>
                <input
                  type="month"
                  name="date"
                  className="input-field"
                  placeholder="Enter Month"
                  onChange={handleDateChange}
                  value={currentMonth}
                  max={dayjs().format("YYYY-MM")}
                />
              </div>

              <div>
                <button
                  onClick={() =>
                    getFloorWiseReportList(currentPage, pageSize, filterParams)
                  }
                  className="submit-button"
                >
                  <span className="submit-button-label">Search</span>
                </button>
              </div>
            </div>
            {/* <div className="add-new-button-block">
              {isExportLoading ? <ButtonLoader isBtnDisable={isExportLoading} /> :
                <button className="add-new-button" onClick={() => getExport()}>
                  <span>Export</span>
                  <img src={Export} alt="export" />
                </button>}
            </div> */}
            <div
              className="filter-button"
              onClick={() => setFilter((prev) => !prev)}
            >
              <span className="filter-btn-label">Filter</span>
              <img src={Filter} alt="" />
            </div>
          </div>
          {filter && (
            <form>
              <div className="form ">
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Employee Name</label>
                  <Select
                    options={employeeName}
                    value={employeeName?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Employee Type</label>
                  <Select
                    options={employeetype}
                    value={employeetype?.find(
                      (option) => option?.value === filterList.employee_type_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_type_id")}
                    name="employee_type_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Gender</label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-fourth">
                  <label className="label-name">Department</label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-fourth">
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) => handleFilter(e, "designation_id")}
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="add-new-button-block w-[40%] h-max">
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      showFilterResult(e);
                    }}
                  >
                    <span style={{ cursor: "pointer" }}>Show Result</span>
                  </button>
                </div>
              </div>
            </form>
          )}
          <div className="mx-4">
            <BranchWiseReportTable
              list={floorDate}
              columns={columnsData}
              setPageNo={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              lastPage={lastPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AbsentReportList;
