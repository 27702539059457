import React from "react";
import { Route } from "react-router-dom";

import CreatePR from "../../../Components/PurchaseOrder/PR/CreatePR";
import PreviousPR from "../../../Components/PurchaseOrder/PR/PreviousPrList";
import PrDetails from "../../../Components/PurchaseOrder/PR/PRDetails";

function PRRoutes() {
  return (
    <>
      {/***Employee */}
      <Route path="new" element={<CreatePR />}></Route>
      <Route path="previousList" element={<PreviousPR />}></Route>
      <Route path="details/:id" element={<PrDetails />}></Route>
    </>
  );
}

export default PRRoutes;
