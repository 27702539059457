import React from "react";
import { Route } from "react-router-dom";

import Invoice from "../../../Components/PurchaseOrder/Invoice/CreateInvoice";
import InvoiceList from "../../../Components/PurchaseOrder/Invoice/InvoiceList";
import InvoiceDetails from "../../../Components/PurchaseOrder/Invoice/InvoiceDetails";

function InventoryRoute() {
  return (
    <>
      <Route path="new" element={<Invoice />}></Route>
      <Route path="previous" element={<InvoiceList />}></Route>
      <Route path="details/:id" element={<InvoiceDetails />}></Route>
    </>
  );
}

export default InventoryRoute;
