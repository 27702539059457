import React from "react";

function HRMS({ fill }) {
  return (
    <>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.9996 13.012C15.9996 11.4735 17.3426 10.2263 18.9993 10.2263C20.656 10.2263 21.999 11.4735 21.999 13.012L21.999 20.4406C21.999 21.9791 20.656 23.2263 18.9993 23.2263C17.3426 23.2263 15.9996 21.9791 15.9996 20.4406L15.9996 13.012Z"
          fill={fill}
        />
        <path
          d="M9.00031 11.0388C9.00031 9.48552 10.3433 8.22632 12 8.22632C13.6567 8.22632 14.9997 9.48552 14.9997 11.0388L14.9997 20.4138C14.9997 21.9671 13.6567 23.2263 12 23.2263C10.3433 23.2263 9.00031 21.9671 9.00031 20.4138L9.00031 11.0388Z"
          fill={fill}
        />
        <path
          d="M2.00122 13.012C2.00122 11.4735 3.34423 10.2263 5.00092 10.2263C6.6576 10.2263 8.00061 11.4735 8.00061 13.012L8.00061 20.4406C8.00061 21.9791 6.6576 23.2263 5.00092 23.2263C3.34423 23.2263 2.00122 21.9791 2.00122 20.4406L2.00122 13.012Z"
          fill={fill}
        />
        <path
          opacity="0.5"
          d="M21.999 6.22632C21.999 7.88317 20.656 9.22632 18.9993 9.22632C17.3426 9.22632 15.9996 7.88317 15.9996 6.22632C15.9996 4.56946 17.3426 3.22632 18.9993 3.22632C20.656 3.22632 21.999 4.56946 21.999 6.22632Z"
          fill={fill}
        />
        <path
          opacity="0.5"
          d="M14.9997 4.22632C14.9997 5.88317 13.6567 7.22632 12 7.22632C10.3433 7.22632 9.00031 5.88317 9.00031 4.22632C9.00031 2.56946 10.3433 1.22632 12 1.22632C13.6567 1.22632 14.9997 2.56946 14.9997 4.22632Z"
          fill={fill}
        />
        <path
          opacity="0.5"
          d="M8.00061 6.22632C8.00061 7.88317 6.6576 9.22632 5.00092 9.22632C3.34423 9.22632 2.00122 7.88317 2.00122 6.22632C2.00122 4.56946 3.34423 3.22632 5.00092 3.22632C6.6576 3.22632 8.00061 4.56946 8.00061 6.22632Z"
          fill={fill}
        />
      </svg>
    </>
  );
}

export default HRMS;
