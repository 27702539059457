import React, { useState } from "react";
import { Link } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import Save from "../../../Assets/Icons/Save.svg";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { POST_API } from "../../../Services/api";
function EditSecurity() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const listData = state?.list;
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [editSecurityDetails, setEditSecurityDetails] = useState({
    id: listData?.id,
    company_name: listData?.company_name,
    address: listData?.address,
    phone: listData?.phone,
    contact_person: listData?.contact_person,
    contact_email: listData?.contact_email,
    type: "security_agent",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditSecurityDetails(() => ({ ...editSecurityDetails, [name]: value }));
  };
  const saveSecurity = async (e) => {
    setButtonDisabled(true);
    const isValid = Validation.validateAgent(editSecurityDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      setButtonDisabled(false);
      seterrors({});
    }
    try {
      const editSecurity = await POST_API("/updateAgents", editSecurityDetails);
      if (editSecurity?.data.status === 200) {
        alertify.success(editSecurity?.data?.message);
        navigate("/master/hrms/agent/security");
        setButtonDisabled(false);
        return;
      }
      if (editSecurity?.data.status === 403) {
        setButtonDisabled(false);
        alertify.error(editSecurity?.data?.message);
      }
    } catch (error) {
      alertify.error(error?.response?.data?.message);
    }
  };

  const resetForm = () => {
    setEditSecurityDetails({
      id: listData?.id,
      company_name: listData?.company_name,
      address: listData?.address,
      phone: listData?.phone,
      contact_person: listData?.contact_person,
      contact_email: listData?.contact_email,
    });
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/agent/security" className="in-active">
              Security Agent List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Edit Security agent</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Security Details</h4>
          <form className="form">
            <div className="field-block">
              <label className="label-name">
                Company Name
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                name="company_name"
                autoComplete="off"
                onChange={(e) => handleChange(e)}
                value={editSecurityDetails.company_name}
              />
              {errors.company_name && (
                <span className="validation-error-label">
                  {errors.company_name}
                </span>
              )}
            </div>
            <div className="field-block">
              <label className="label-name">
                Contact Person
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                autoComplete="off"
                name="contact_person"
                onChange={(e) => handleChange(e)}
                value={editSecurityDetails.contact_person}
              />
              {errors.contact_person && (
                <span className="validation-error-label">
                  {errors.contact_person}
                </span>
              )}
            </div>

            <div className="field-block">
              <label className="label-name">
                Contact Number
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                autoComplete="off"
                name="phone"
                value={editSecurityDetails.phone}
                onChange={(e) => handleChange(e)}
              />
              {errors.phone && (
                <span className="validation-error-label">{errors.phone}</span>
              )}
            </div>

            <div className="field-block">
              <label className="label-name">
                Email
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                autoComplete="off"
                name="contact_email"
                value={editSecurityDetails.contact_email}
                onChange={(e) => handleChange(e)}
              />
              {errors.contact_email && (
                <span className="validation-error-label">
                  {errors.contact_email}
                </span>
              )}
            </div>

            <div className="field-block">
              <label className="label-name">
                Address
                <span className="required-symbol">*</span>
              </label>
              <textarea
                className="input-field"
                type="text"
                autoComplete="off"
                name="address"
                value={editSecurityDetails.address}
                onChange={(e) => handleChange(e)}
                rows={5}
              />
              {errors.address && (
                <span className="validation-error-label">{errors.address}</span>
              )}
            </div>
          </form>
          <div className="submit-reset-button">
            <Link to="/masters/agent/security">
              <button type="button" className="cancel-button">
                <span className="">Cancel</span>
              </button>
            </Link>
            <button
              type="submit"
              className="submit-button"
              onClick={() => saveSecurity()}
              disabled={buttonDisabled}
            >
              <span className="submit-button-label">Save</span>
              <img src={Save} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSecurity;
