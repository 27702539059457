import React from "react";
import { Link } from "react-router-dom";
import Edit from "../../../Assets/Icons/Edit.svg";
import View from "../../../Assets/Icons/View.svg";

function BasicInfo({ listDetails, isEditAndView }) {
  const date_of_birth = new Date(listDetails?.date_of_birth);
  const dob_year = date_of_birth.getFullYear();
  const dob_month = String(date_of_birth.getMonth() + 1).padStart(2, "0");
  const dob_date = String(date_of_birth.getDate()).padStart(2, "0");

  const Details = [
    {
      label: "Hr Agent",
      value: listDetails?.agentname ? listDetails?.agentname : "-",
    },
    {
      label: "Date of Birth",
      value: dob_year + "-" + dob_month + "-" + dob_date,
    },
    {
      label: "Blood Group",
      value: listDetails?.blood_groupname ? listDetails?.blood_groupname : "-",
    },
    {
      label: "Present Address",
      value: listDetails?.present_address ? listDetails?.present_address : "-",
    },
    {
      label: "Permanent Address",
      value: listDetails?.permanent_address
        ? listDetails?.permanent_address
        : "-",
    },
    {
      label: "Gender",
      value: listDetails?.gender ? listDetails?.gender : "-",
    },
  ];

  return (
    <div className="border-t-[1px] border-t-[#D9D9D9]">
      <div
        className="p-3 w-3/12 bg-primary-color text-center"
        style={{ borderRadius: "0px 0px 100px 0px" }}
      >
        <span className="text-white-color font-karla font-base ">
          Basic Info
        </span>
      </div>
      <div className="list-container mt-3">
        <div className="list-content-block">
          {Details.map((list) => {
            return (
              <div className="list">
                <label className="list-label">{list?.label}</label>
                <div style={{ wordBreak: "break-word" }} className="list-value">
                  {list?.value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default BasicInfo;
