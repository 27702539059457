import React, { useState, useEffect, useContext } from "react";
import alertify from "alertifyjs";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import More from "../../../Assets/Icons/More.svg";
import { DELETE_API, GET_API } from "../../../Services/api";
import PayslipsTable from "../../DataTable/PayslipsTable";
import Search from "../../../Assets/Icons/Search.svg";
import Spinner from "../../Spinner";
import Filter from "../../../Assets/Icons/Filter.svg";
import "alertifyjs/build/css/alertify.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PrivilegeContext } from "../../../PrivilegeContext";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function PayslipsList() {
  const navigate = useNavigate();
  const [payslipsList, setPayslipsList] = useState([]);
  const [filteredPayslips, setFilteredPayslips] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employeetype, setEmployeetype] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [filter, setFilter] = useState(false);
  const [filterParams, setFilterParams] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [isBranchLoading, setIsBranchLoading] = useState(false);
  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [isEmpTypeLoading, setIsEmpTypeLoading] = useState(false);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
  const [isDesignationLoading, setIsDesignationLoading] = useState(false);
  const { access } = useContext(PrivilegeContext);
  const [showAccess, setShowAccess] = useState(false);
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);

  const [filterList, setfilterList] = useState({
    branch_id: "",
    employee_id: "",
    employee_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
  });

  const showPayslipsDetails = (listDetail) => {
    navigate(`/payroll/payslip/details/${listDetail.employee_Id}`, {
      state: {
        list: listDetail,
        month: month,
        year: year,
      },
    });
  };

  useEffect(() => {
    getAccess();
  }, [access]);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "generate_payslip") {
        setShowAccess(item?.view);
      }
      return "";
    });
  };
  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "employee_code", label: "Employee Code" },
    { id: "employee_name", label: "Employee Name" },
    { id: "department", label: "Department" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  const getBranchList = async () => {
    try {
      setIsBranchLoading(true);
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setIsBranchLoading(false);
      }
    } catch (e) {
      setIsBranchLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setIsEmpTypeLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((exp) => {
          return { value: exp?.id, label: exp?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setIsEmpTypeLoading(false);
      }
    } catch (e) {
      setIsEmpTypeLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getDepartmentList = async () => {
    setIsDepartmentLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setIsDepartmentLoading(false);
      }
    } catch (e) {
      setIsDepartmentLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setIsDesignationLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setIsDesignationLoading(false);
      }
    } catch (e) {
      setIsDesignationLoading(false);
      alertify.error("Failed to Get Designation List");
    }
  };

  const getEmpDropdownList = async () => {
    setIsEmpLoading(true);
    try {
      const empList = await GET_API("/employeeDropdown");
      if (empList.status === 200) {
        const emp = empList?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });

        emp.unshift({ value: "", label: "Select..." });
        setEmployeeList(emp);
        setIsEmpLoading(false);
      }
    } catch (e) {
      setIsEmpLoading(false);
      alertify.error("Failed to Get Employee List");
    }
  };

  const genderList = [
    { label: "Please Select", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  useEffect(() => {
    getBranchList();
    getEmployeeType();
    getDepartmentList();
    getDesignationList();
    getEmpDropdownList();
  }, []);

  useEffect(() => {
    getPayslipsList(currentPage, pageSize, filterParams, searchKey);
  }, [currentPage, pageSize, showFilterResultData]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getPayslipsList(1, pageSize, filterParams, searchKey);
  };

  const getPayslipsList = async (page, size, filters, search) => {
    try {
      setLoading(true);
      const empList = await GET_API(
        `/employeesList?page=${page}&size=${size}${filters}&search=${search}`
      );
      if (empList?.data?.status === 200) {
        if (
          empList?.data?.data?.current_page > empList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setPayslipsList(empList?.data?.data?.data);
        setFilteredPayslips(empList?.data?.data?.data);
        setLastPage(empList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to Get Payslip List");
    }
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Payslips",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deletepayslips/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getPayslipsList(currentPage, pageSize, filterParams, searchKey);
              return;
            }
            alertify.error("Failed To Delete Payslip");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const navigatePaySlipPage = (listDetail) => {
    navigate("/payslip-details", {
      state: {
        list: listDetail,
      },
    });
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleFilter = async (e, name) => {
    const { value } = e;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";
    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }
    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  if (
    loading ||
    isBranchLoading ||
    isEmpLoading ||
    isEmpTypeLoading ||
    isDepartmentLoading ||
    isDesignationLoading
  ) {
    return <Spinner />;
  }

  const handlePayslipBasedonMonthAndYear = async (value) => {
    const dateObject = new Date(value.$d);
    const year = dateObject?.getFullYear();
    const month = dateObject?.getMonth() + 1;
    setYear(year);
    setMonth(month);
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <div className="card-header">Generate Payslip</div>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => setSearchKey(e?.target?.value)}
                value={searchKey}
                style={{ outline: "none", border: "none" }}
              />

              <img
                src={Search}
                className="cursor-pointer"
                onClick={() => {
                  getPayslipsList(
                    currentPage,
                    pageSize,
                    filterParams,
                    searchKey
                  );
                }}
                alt=""
              />
            </div>
            <div className="flex gap-3 justify-center items-center">
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={'"Month" and "Year"'}
                    views={["month", "year"]}
                    slotProps={{
                      field: { clearable: true, onClear: () => false },
                    }}
                    onChange={(value, context) => {
                      handlePayslipBasedonMonthAndYear(value);
                    }}
                  />
                </LocalizationProvider>
              </div>

              <div
                className="filter-button"
                onClick={() => setFilter((prev) => !prev)}
              >
                <span className="filter-btn-label">Filter</span>
                <img src={Filter} alt="" />
              </div>
            </div>
          </div>
          {filter && (
            <form>
              <div className="form ">
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Name</label>
                  <Select
                    options={employeeList}
                    value={employeeList.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Type</label>
                  <Select
                    options={employeetype}
                    value={employeetype.find(
                      (option) => option?.value === filterList.employee_type_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_type_id")}
                    name="employee_type_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Gender</label>
                  <Select
                    options={genderList}
                    value={genderList.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Department</label>
                  <Select
                    options={department}
                    value={department.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) => handleFilter(e, "designation_id")}
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="add-new-button-block w-[40%] h-max">
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      showFilterResult(e);
                    }}
                  >
                    <span style={{ cursor: "pointer" }}>Show Result</span>
                  </button>
                </div>
              </div>
            </form>
          )}

          {/**************** * Table ********************/}

          <PayslipsTable
            list={filteredPayslips}
            columns={columns}
            icon={More}
            multiple={showAccess}
            paySlip={showAccess ? navigatePaySlipPage : false}
            showDetails={showPayslipsDetails}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
            enableMoreOption={showAccess}
          />
        </div>
      </div>
    </div>
  );
}

export default PayslipsList;
