import React, { useEffect, useState } from "react";
import Noimage from "../../../Assets/Icons/NoImage.svg";
import FileUpload from "../../../Assets/Icons/FileUpload.svg";
import { GET_API, POST_API, PUT_API } from "../../../Services/api";
import Select from "react-select";
import alertify from "alertifyjs";
import Spinner from "../../Spinner";
import Add from "../../../Assets/Icons/ColorAdd.svg";
import removeImage from "../../../Assets/Icons/Delete.svg";
import POValidator from "../../../Validation/POValidation";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../../../Services/config.json";
import RecycleBin from "../../../Assets/Icons/Delete.svg";

function AddInventory() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const id = state?.list;
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [attributeList, setAttribute] = useState([]);
  const [attributeID, setAttributeID] = useState([]);
  const [error, setError] = useState({});
  const [buttonDisabled, setbuttonDisabled] = useState(false);
  const [attributeValueList, setAttributeValueList] = useState({});
  const [inventory, setInventory] = useState({
    name: "",
    hsn_code: "",
    product_code: "",
    model_no: "",
    uom: "",
    category_id: "",
    quantity: "",
    price: "",
    description: "",
    product_attributes: [
      {
        id: "",
        attribute_id: "",
        attribute_value_id: "",
      },
    ],
    tax_details: [],
    product_images: [],
    product_images_to_show: [],
    deletedImageId: [],
  });

  useEffect(() => {
    getProductCategoryList();
    getProductAttribute();
    getProductDetails();
  }, []);

  const getProductDetails = async () => {
    setLoading(true);
    try {
      const details = await GET_API(`po/productById/${id}`);
      if (details?.data?.status === 200) {
        const product = details?.data?.data?.product;
        const attributes = details?.data?.data?.attributes;
        const images = details?.data?.data?.images;
        const tax = details?.data?.data?.poTax;

        const attributeArr = [];
        const imageArr = [];

        attributes &&
          attributes?.map((attr, i) => {
            getAttributeValueByAttributeID(attr?.attribute_id, i);
            return attributeArr.push({
              id: attr?.id,
              attribute_id: attr?.attribute_id,
              attribute_value_id: attr?.attribute_value_id,
            });
          });

        images?.map((image, i) => {
          return imageArr.push(image);
        });
        setInventory({
          ...inventory,
          id: product?.id,
          name: product?.name,
          hsn_code: product?.hsn_code,
          product_code: product?.product_code,
          model_no: product?.model_no,
          uom: product?.uom,
          category_id: product?.category_id,
          quantity: product?.quantity.toString(),
          price: product?.price,
          description: product?.description,
          product_attributes: attributeArr,
          product_images_to_show: imageArr,
          tax_details: tax,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to get Product details...");
    }
  };

  const getProductCategoryList = async () => {
    setLoading(true);
    try {
      const categoryList = await GET_API("po/categories");
      if (categoryList?.data?.status === 200) {
        const attribute = categoryList?.data?.data?.data.map((item) => {
          return { label: item?.name, value: item?.id };
        });
        attribute.unshift({ label: "Select...", value: "" });
        setCategory(attribute);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to get Category list...");
    }
  };

  const getProductAttribute = async () => {
    try {
      const productAttribute = await GET_API("po/attributes");
      if (productAttribute?.data?.status === 200) {
        const attribute = productAttribute?.data?.data?.data.map((item) => {
          return { label: item?.name, value: item?.id };
        });
        attribute.unshift({ label: "Select...", value: "" });
        setAttribute(attribute);
        setLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get attribute list...");
    }
  };

  const getAttributeValueByAttributeID = async (id, i) => {
    if (id === 0 || id === "") return "";
    const productAttributeValue = await GET_API(
      `po/attributeValueByAttrId/${id}`
    );
    if (productAttributeValue?.data?.status === 200) {
      const attributeList = productAttributeValue?.data?.data?.map((item) => {
        return { label: item?.name, value: item?.id };
      });
      attributeList?.unshift({ label: "Select...", value: "" });
      setAttributeValueList((prevList) => ({
        ...prevList,
        [i]: attributeList,
      }));
    }
  };

  const getAttributeValue = async (e, i) => {
    const { value } = e;
    if (value === "") return;
    setAttributeValueList({ ...attributeValueList, [i]: [] });

    const arr = [...inventory?.product_attributes];
    arr[i].attribute_id = value;
    setInventory({ ...inventory, product_attributes: arr });
    const productAttributeValue = await GET_API(
      `po/attributeValueByAttrId/${value}`
    );
    if (productAttributeValue?.data?.status === 200) {
      const attributeList = productAttributeValue?.data?.data?.map((item) => {
        return { label: item?.name, value: item?.id };
      });
      attributeList?.unshift({ label: "Select...", value: "" });
      setAttributeValueList((prevList) => ({
        ...prevList,
        [i]: attributeList,
      }));
    }
  };

  const addNewAttribute = () => {
    const arr = [...inventory?.product_attributes];
    const newAttribute = { id: 0, attribute_id: "", attribute_value_id: " " };
    arr.push(newAttribute);
    setInventory({ ...inventory, product_attributes: arr });
  };

  const removeAttribute = (index) => {
    const arr = [...inventory?.product_attributes];
    const filterArr = arr.filter((item, i) => {
      return i !== index;
    });
    setInventory({ ...inventory, product_attributes: filterArr });
  };

  const handleRemoveImage = (index, imageType, imageId) => {
    const arr = inventory?.product_images_to_show;
    const filterArr = arr.filter((item, i) => {
      return i !== index;
    });
    const imageIDArr = [...inventory?.deletedImageId];
    imageIDArr.push(imageId);
    const value = {
      ...inventory,
      product_images_to_show: filterArr,
      deletedImageId: imageIDArr,
    };
    setInventory(value);
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setInventory({ ...inventory, [name]: value });
  };

  const handleSelectChange = async (e) => {
    const { value } = e;
    setInventory({ ...inventory, category_id: value });
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    const filePromises = Array.from(files).map((file) => {
      return new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          if (fileReader.readyState === 2) {
            resolve({
              dataURL: fileReader.result,
              size: file.size,
              type: file.type,
            });
          }
        };
        fileReader.readAsDataURL(file);
      });
    });

    Promise.all(filePromises).then((fileDataArray) => {
      const imageArr = [];
      fileDataArray?.map((list) => {
        imageArr.push(list?.dataURL);
        return setInventory((prevInventory) => ({
          ...prevInventory,
          product_images: imageArr,
        }));
      });
    });
  };

  const handleAttributeValueChange = (e, i) => {
    const { value } = e;
    if (value === "") return;
    const arr = [...inventory?.product_attributes];
    arr[i].attribute_value_id = value;
    setInventory({ ...inventory, product_attributes: arr });
  };

  const editProduct = async () => {
    setbuttonDisabled(true);
    const isValid = POValidator.Inventory(inventory);
    if (isValid && isValid.error) {
      setbuttonDisabled(false);
      setError({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setError({});
      setbuttonDisabled(false);
    }
    try {
      const addNewProduct = await PUT_API("po/updateProduct", inventory);
      if (addNewProduct?.data?.status === 200) {
        navigate("/po/inventory/product");
        alertify?.success(addNewProduct?.data?.message);
      }
    } catch (error) {
      for (const key in error?.response?.data?.data) {
        if (error?.response?.data?.data.hasOwnProperty(key)) {
          error?.response?.data?.data[key].forEach((value) => {
            alertify.error(value);
          });
        }
      }
    }
  };

  const handleTaxCalculation = (e, index) => {
    const { name, value } = e?.target;
    const taxArr = [...inventory?.tax_details];
    taxArr[index][name] = value;
    setInventory({ ...inventory, tax_details: taxArr });
  };

  const addNewTax = () => {
    const arr = [...inventory?.tax_details];
    arr.push({ id: 0, tax: "", from_amount: " ", to_amount: "" });
    setInventory({ ...inventory, tax_details: arr });
  };

  const removeTax = (index) => {
    const arr = [...inventory?.tax_details];
    const filterArr = arr.filter((item, i) => {
      return i !== index;
    });
    setInventory({ ...inventory, tax_details: filterArr });
  };

  const resetForm = () => {};

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="po-container">
      <div className="po-header">Inventory</div>
      <div className="po-contect-box">
        <form className="po-form">
          <div className="form-block-2">
            <div className="header">Edit Product</div>
            <div className="grid-block">
              <div className="input-field-block">
                <span className="label-name">
                  Product Name
                  <span className="required-symbol">*</span>
                </span>
                <input
                  type="text"
                  name="name"
                  className="input-field"
                  placeholder="Enter Product Name"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                  value={inventory?.name}
                />
                {error.name ? (
                  <div className="validation-error-label">{error.name}</div>
                ) : null}
              </div>
              <div className="input-field-block">
                <span className="label-name">
                  HSN Code <span className="required-symbol">*</span>
                </span>
                <input
                  type="number"
                  name="hsn_code"
                  className="input-field"
                  placeholder="Enter HSN Code"
                  onChange={(e) => handleChange(e)}
                  value={inventory?.hsn_code}
                />
                {error.hsn_code ? (
                  <div className="validation-error-label">{error.hsn_code}</div>
                ) : null}
              </div>
              <div className="input-field-block">
                <span className="label-name">
                  Product Code <span className="required-symbol">*</span>
                </span>
                <input
                  type="number"
                  name="product_code"
                  className="input-field"
                  placeholder="Enter Product Code"
                  onChange={(e) => handleChange(e)}
                  value={inventory?.product_code}
                />
                {error.product_code ? (
                  <div className="validation-error-label">
                    {error.product_code}
                  </div>
                ) : null}
              </div>
              <div className="input-field-block">
                <span className="label-name">
                  Model No <span className="required-symbol">*</span>
                </span>
                <input
                  type="text"
                  name="model_no"
                  className="input-field"
                  placeholder="Enter Model No"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                  value={inventory?.model_no}
                />
                {error.model_no ? (
                  <div className="validation-error-label">{error.model_no}</div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  UOM(unit of measurement)
                  <span className="required-symbol">*</span>
                </span>
                <input
                  type="text"
                  name="uom"
                  className="input-field"
                  placeholder="UOM"
                  onChange={(e) => handleChange(e)}
                  value={inventory?.uom}
                />
                {error.uom ? (
                  <div className="validation-error-label">{error.uom}</div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  Category <span className="required-symbol">*</span>
                </span>
                <Select
                  options={category}
                  value={
                    inventory?.category_id
                      ? category?.find(
                          (option) => option?.value === inventory?.category_id
                        )
                      : ""
                  }
                  name="category_id"
                  isSearchable
                  onChange={(e) => handleSelectChange(e)}
                />
                {error.category_id ? (
                  <div className="validation-error-label">
                    {error.category_id}
                  </div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  Quantity <span className="required-symbol">*</span>
                </span>
                <input
                  type="number"
                  name="quantity"
                  className="input-field"
                  placeholder="Enter Quantity"
                  onChange={(e) => handleChange(e)}
                  value={inventory?.quantity}
                />
                {error.quantity ? (
                  <div className="validation-error-label">{error.quantity}</div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  Price <span className="required-symbol">*</span>
                </span>
                <input
                  type="number"
                  name="price"
                  className="input-field"
                  placeholder="Enter Quantity"
                  onChange={(e) => handleChange(e)}
                  value={inventory?.price}
                />
                {error.price ? (
                  <div className="validation-error-label">{error.price}</div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">Description</span>
                <textarea
                  rows={5}
                  type="number"
                  name="description"
                  className="input-field"
                  placeholder="Enter Description"
                  onChange={(e) => handleChange(e)}
                  value={inventory?.description}
                />
                {error.description ? (
                  <div className="validation-error-label">
                    {error.description}
                  </div>
                ) : null}
              </div>

              <div className="input-field-block">
                <div className="flex items-end">
                  <div className="w-[20%]">
                    <img src={Noimage} alt="" className="h-[40px] w-[40px]" />
                  </div>
                  <div className="w-[80%] p-2 w-max h-max bg-[#FFF2EC] rounded flex justify-center items-center gap-1 cursor-pointer">
                    <label for="files" className="label-name cursor-pointer">
                      Choose
                    </label>
                    <input
                      type="file"
                      className="w-0 cursor-pointer"
                      id="files"
                      style={{ visibility: "hidden" }}
                      accept=".jpeg,.jpg,.png"
                      multiple
                      name="product_images[]"
                      onChange={(e) => handleImageChange(e)}
                    />
                    <img src={FileUpload} alt="" />
                  </div>
                </div>

                <div className="flex gap-1 items-center flex-wrap max-h-[164px] overflow-scroll">
                  {inventory?.product_images_to_show?.length > 0 &&
                    inventory?.product_images_to_show?.map((image, i) => {
                      return (
                        <div className="relative">
                          <img
                            src={removeImage}
                            alt=""
                            className="h-[20px] p-0.5 bg-[#ccc] rounded cursor-pointer absolute left-14	top-1.5"
                            onClick={() => {
                              handleRemoveImage(i, "product_images", image?.id);
                            }}
                          />
                          <img
                            src={config?.path + image?.image}
                            alt=""
                            className="h-[80px] w-[80px] rounded"
                          />
                        </div>
                      );
                    })}

                  {inventory?.product_images?.length > 0 &&
                    inventory?.product_images?.map((image, i) => {
                      return (
                        <div className="relative">
                          <img
                            src={removeImage}
                            alt=""
                            className="h-[20px] p-0.5 bg-[#ccc] rounded cursor-pointer absolute left-14	top-1.5"
                            onClick={() => {
                              handleRemoveImage(i, "product_images", image?.id);
                            }}
                          />
                          <img
                            src={image}
                            alt=""
                            className="h-[80px] w-[80px] rounded"
                          />
                        </div>
                      );
                    })}

                  {error.product_images ? (
                    <div className="validation-error-label">
                      {error.product_images}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div>
              <span className="label-name">
                Attribute<span className="required-symbol">*</span>
              </span>
              <div className="flex flex-col gap-2">
                {inventory?.product_attributes?.map((item, i) => {
                  return (
                    <div className="flex gap-2 items-center w-[65%] justify-between">
                      <Select
                        options={attributeList}
                        className="w-[200px]"
                        value={
                          inventory?.product_attributes
                            ? attributeList?.find(
                                (option) =>
                                  option?.value ===
                                  inventory?.product_attributes[i].attribute_id
                              )
                            : ""
                        }
                        name="attribute_id"
                        onChange={(e) => {
                          getAttributeValue(e, i);
                        }}
                        isSearchable
                      />

                      <Select
                        options={attributeValueList[i]}
                        className="w-[200px]"
                        value={
                          inventory?.product_attributes[i]
                            ? attributeValueList[i]?.find(
                                (option) =>
                                  option?.value ===
                                  inventory?.product_attributes[i]
                                    .attribute_value_id
                              )
                            : ""
                        }
                        name="attribute_value_id"
                        isSearchable
                        onChange={(e) => {
                          handleAttributeValueChange(e, i);
                        }}
                      />
                      {i === 0 && (
                        <img
                          src={Add}
                          alt="add_btn"
                          className="bg-[#000] cursor-pointer"
                          onClick={() => {
                            addNewAttribute();
                          }}
                        />
                      )}
                      {i > 0 && (
                        <span
                          className="required-symbol cursor-pointer p-1"
                          onClick={() => {
                            removeAttribute(i);
                          }}
                        >
                          <img src={RecycleBin} alt="" />
                        </span>
                      )}
                    </div>
                  );
                })}
                {error.product_attributes ? (
                  <div className="validation-error-label">
                    {error.product_attributes}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="mt-2">
              <span className="label-name">
                Tax<span className="required-symbol">*</span>
              </span>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 w-[65%] justify-around">
                  <div className="w-[21.7%] text-text-dark text-sm font-karla">
                    Tax amount
                  </div>
                  <div className="w-[21.7%] text-text-dark text-sm font-karla">
                    Amount from
                  </div>
                  <div className="w-[21.7%] text-text-dark text-sm font-karla">
                    Amount to
                  </div>
                </div>
                {inventory?.tax_details?.map((item, index) => {
                  return (
                    <div className="flex gap-2 items-center w-[65%] justify-between">
                      <input
                        type="number"
                        className="input-field"
                        name="tax"
                        placeholder="Enter Tax amount"
                        onChange={(e) => {
                          handleTaxCalculation(e, index);
                        }}
                        value={inventory?.tax_details[index]?.tax}
                      />
                      <input
                        type="number"
                        className="input-field"
                        name="from_amount"
                        placeholder="Amount From"
                        onChange={(e) => {
                          handleTaxCalculation(e, index);
                        }}
                        value={inventory?.tax_details[index]?.from_amount}
                      />
                      <input
                        type="number"
                        className="input-field"
                        name="to_amount"
                        placeholder="Amount To"
                        onChange={(e) => {
                          handleTaxCalculation(e, index);
                        }}
                        value={inventory?.tax_details[index]?.to_amount}
                      />
                      {index === 0 && (
                        <img
                          src={Add}
                          alt="add_btn"
                          className="bg-[#000] cursor-pointer"
                          onClick={() => {
                            addNewTax();
                          }}
                        />
                      )}
                      {index > 0 && (
                        <span
                          className="required-symbol cursor-pointer p-1"
                          onClick={() => {
                            removeTax(index);
                          }}
                        >
                          <img src={RecycleBin} alt="" />
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
              {error.tax_details ? (
                <div className="validation-error-label">
                  {error.tax_details}
                </div>
              ) : null}
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={() => {
                  resetForm();
                }}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                type="button"
                className="submit-button"
                disabled={buttonDisabled}
              >
                <span
                  className="submit-button-label"
                  onClick={() => editProduct()}
                >
                  Save
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddInventory;
