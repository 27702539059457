import React from "react";
import { Link, useLocation } from "react-router-dom";

function BranchDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const Details = [
    {
      label: "Name",
      value: listDetails?.name,
    },

    {
      label: "Half Day Amount",
      value: listDetails?.half_day_amount,
    },
    {
      label: "Full Day Amount",
      value: listDetails?.full_day_amount,
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className=" sm-container">
          <div className="bread-crumbs">
            <div>
              <Link to="/master/hrms/halfDayVariant" className="in-active">
                HalfDay Variant List
              </Link>
              <Link>{" > "}</Link>
              <Link className="active">HalfDay Variant Details</Link>
            </div>
          </div>
          <div className="card-wrapper">
            <h4 className="card-header">HalfDay Variant Details</h4>

            <div className="list-container">
              <div className="list-content-block">
                {Details.map((list, i) => {
                  return (
                    <div className="list " key={i}>
                      <label className="list-label">{list.label}</label>
                      <div
                        style={{ wordBreak: "break-word" }}
                        className="list-value"
                      >
                        {list.value}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BranchDetails;
