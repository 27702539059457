import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreOption from "../MoreOption";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";

export default function StickyHeadTable({
  list,
  columns,
  icon,
  showDetails,
  multiple,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
  enableMoreOption,
}) {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, i) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    {
                      <TableCell className="table-body !w-[7%]">
                        {(currentPage - 1) * pageSize + (i + 1)}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        <Link style={{ color: "#0284C7" }} className="text-sky-600 border-b-[1px] border-b-[#0284C7]" to={`/attendance/details/${row?.employeeCode}`}>
                          {row.employeeCode}
                        </Link>
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.first_name} {row?.fathers_name}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.departmentname}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row?.clock_time ? (
                          <span
                            className="bg-[#3EAB82] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Present
                          </span>
                        ) : (
                          <span
                            className=" bg-[#FF5C42] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Absent
                          </span>
                        )}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row.clock_time}
                      </TableCell>
                    }
                    {
                      <MoreOption
                        icon={icon}
                        row={row}
                        showDetails={showDetails}
                        multiple={multiple}
                        activeEdit={false}
                        activeDelete={false}
                        enableMoreOption={enableMoreOption}
                      />
                    }
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
