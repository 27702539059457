import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GET_API, DELETE_API } from "../../../Services/api";
import DesignationListTable from "../../DataTable/DesignationTable";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

function DesignationList() {
  const navigate = useNavigate();
  const [designationList, setDesignationList] = useState([]);
  const [filteredDesignation, setFilteredDesignation] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const header = [
    { id: "s.no", label: "S.No" },
    { id: "branch_name", label: "Designation Name" },
    { id: "branch_name", label: "Department Name" },
    { id: "description", label: "Description" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getDesignationList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const navigateEditPage = (listDetail) => {
    navigate("/master/hrms/designation/edit", {
      state: {
        list: listDetail,
      },
    });
  };

  const showBranchDetails = (listDetail) => {
    navigate("/master/hrms/designation/details", {
      state: {
        list: listDetail,
      },
    });
  };

  const isPageNotExit = () => {
    setCurrentPage(1);
    getDesignationList(1, pageSize, searchKey);
  };

  const getDesignationList = async (page, size, search) => {
    try {
      setLoading(true);
      const designationList = await GET_API(
        `/designationList?page=${page}&size=${size}&search=${search}`
      );
      if (designationList?.status === 200) {
        if (
          designationList?.data?.data?.current_page >
          designationList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setLoading(false);
        setDesignationList(designationList?.data?.data?.data?.data);
        setLastPage(designationList?.data?.data?.last_page);
        setFilteredDesignation(designationList?.data?.data?.data);
      }
    } catch (error) {
      alertify.error(error?.response?.data?.message);
      setLoading(false);
      setDesignationList([]);
      setLastPage("");
      setFilteredDesignation([]);
    }
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Designation..?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteDesignation/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getDesignationList(currentPage, pageSize, searchKey);
              return;
            }
            alertify.error("Failed To Delete Floor");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Designation List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => setSearchKey(e?.target?.value)}
                style={{ outline: "none", border: "none" }}
                value={searchKey}
              />
              <img
                src={Search}
                className="cursor-pointer"
                alt=""
                onClick={() => {
                  getDesignationList(currentPage, pageSize, searchKey);
                }}
              />
            </div>

            <div className="add-new-button-block">
              <Link
                to="/master/hrms/designation/create"
                className="add-new-button"
              >
                <span>Add New</span>
                <img src={ColorAdd} alt="add" />
              </Link>
            </div>
          </div>

          {/**************** * Table ********************/}
          <DesignationListTable
            list={filteredDesignation}
            columns={header}
            icon={More}
            multiple={true}
            editPage={navigateEditPage}
            showDetails={showBranchDetails}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default DesignationList;
