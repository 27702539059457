import React from "react";
import { Route } from "react-router-dom";

import MastersApproval from "../Approval/Masters/Approval";
import MastersLog from "../Approval/Masters/LogDetails";

import BonusApproval from "../Approval/BonusApproval/BonusApproval";

import ExpenseApproval from "../Approval/ExpenseApproval/ExpenseApproval";

import FinanceApproval from "../Approval/FinanceApproval/FinanceApproval";

import LeaveApproval from "../Approval/LeaveApproval/LeaveApproval";

import LateEntryApproval from "../Approval/LateEntryApproval/LateEntryApproval";

import EarlyExitApproval from "../Approval/LateEntryApproval/EarlyExitApproval";
import EarlyExitApprovalDetails from "../Approval/LateEntryApproval/EarlyExitApprovalDetails";

function ApproveRoutes() {
  return (
    <>
      <Route path="masters" element={<MastersApproval />}></Route>
      <Route path="masters/log/:id" element={<MastersLog />}></Route>

      <Route path="bonus" element={<BonusApproval />}></Route>

      <Route path="expense" element={<ExpenseApproval />}></Route>

      <Route path="finance" element={<FinanceApproval />}></Route>

      <Route path="leave" element={<LeaveApproval />}></Route>

      <Route path="lateentry" element={<LateEntryApproval />}></Route>

      <Route path="earlyExit" element={<EarlyExitApproval />}></Route>
      <Route
        path="earlyExit/details/:id"
        element={<EarlyExitApprovalDetails />}
      ></Route>
    </>
  );
}

export default ApproveRoutes;
