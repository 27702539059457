import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import More from "../../Assets/Icons/More.svg";
import { useNavigate } from "react-router-dom";
import Pagination from "./Pagination";

export default function DataTable({
  list,
  setSelectedRows,
  setPageNo,
  setPageSize,
  lastPage,
  currentPage,
  pageSize,
}) {
  const navigate = useNavigate();

  const columns = [
    { field: "module_name", headerName: "Masters", minWidth: 150 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 70,
      renderCell: (row) => {
        const rowData = JSON.parse(row?.row?.original_data);
        const displayName =
          rowData?.name ||
          rowData?.first_name ||
          rowData?.bank_name ||
          rowData?.branch_name;
        return displayName;
      },
    },
    { field: "save_type", headerName: "Functionality", minWidth: 70 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (row) => (
        <img
          src={More}
          alt=""
          onClick={() => navigateDetailsPage(row)}
          className="log-icon"
        />
      ),
    },
  ];
  const navigateDetailsPage = (row) => {
    navigate(`/approve/masters/log/${row?.id}`, {
      state: {
        list: row?.row,
      },
    });
  };
  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };
  const heightStyle =
    list.length === 0 ? { height: 320, width: "100%" } : { width: "100%" };
  return (
    <div style={heightStyle}>
      <DataGrid
        rows={list}
        columns={columns}
        checkboxSelection
        sx={{
          ".MuiDataGrid-footerContainer": {
            display: "none",
          },
        }}
        onRowSelectionModelChange={handleSelectionModelChange}
      />
      <div className="bg-[#fff]">
        <Pagination
          setPageNo={setPageNo}
          setPageSize={setPageSize}
          lastPage={lastPage}
          currentPage={currentPage}
          pageSize={pageSize}
          shape="rounded"
        />
      </div>
    </div>
  );
}
