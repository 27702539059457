import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import View from "../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import { GET_API, POST_API } from "../../Services/api";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import Spinner from "../Spinner";
import Validation from "../../Validation/Validation";
import BonusPrintVoucher from "./BonusPrintVoucher";
import { useReactToPrint } from "react-to-print";
import Select from "react-select";

function CreateLoan() {
  const navigate = useNavigate();
  const componentRef = useRef();
  const [bonusDetails, setBonusDetails] = useState({
    employee: "",
    employee_id: "",
    finance_category_id: "",
    religion_id: "",
    due_date: "",
    remarks: "",
    duration: "",
    amount: "",
    approved_by: "",
    approved_by_id: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [bonusList, setBonusList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isPrintVoucher, setIsPrintVoucher] = useState(false);
  const [printVoucherData, setPrintVoucherData] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [religionList, setReligionList] = useState([]);

  useEffect(() => {
    getDesignationList();
    getUserList();
    getReligionList();
  }, []);

  const getDesignationList = async () => {
    setLoading2(true);
    try {
      const designationList = await GET_API(
        "/FinanceCategoriesListbonusDropdown"
      );
      if (designationList.status === 200) {
        const bonus = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        bonus.unshift({ value: "", label: "Select..." });
        setBonusList(bonus);
        setLoading2(false);
        return;
      }
    } catch (error) {
      setLoading2(false);
      alertify.error("Failed to get Bonus List...");
    }
  };

  const getUserList = async () => {
    try {
      setLoading1(true);
      const userList = await GET_API("/employeeDropdown");
      if (userList.status === 200) {
        const user = userList?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        user.unshift({ value: "", label: "Select..." });
        setUserList(user);
        setLoading1(false);
        return;
      }
    } catch (error) {
      alertify.error("Failed to get Employee List...");
      setLoading1(false);
    }
  };

  const getReligionList = async () => {
    setLoading3(true);
    try {
      const list = await GET_API("/religionDropdown");
      if (list.status === 200) {
        const religion = list?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        setLoading3(false);
        setReligionList(religion);
        return;
      }
    } catch (error) {
      setReligionList([]);
      setLoading3(false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e?.target;
    setBonusDetails(() => ({ ...bonusDetails, [name]: value }));
  };

  const handleEmployeeNameChange = (e, name) => {
    const { value } = e;
    setBonusDetails(() => ({
      ...bonusDetails,
      [name]: value,
      employee: value,
    }));
  };

  const handleSelectChange = (e, name) => {
    const { value } = e;
    setBonusDetails(() => ({
      ...bonusDetails,
      [name]: value,
      finance_category: value,
    }));
  };

  const handleApprovedByChange = (e, name) => {
    const { value } = e;
    setBonusDetails(() => ({
      ...bonusDetails,
      [name]: value,
      approved_by: value,
    }));
  };

  const printVoucher = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (isPrintVoucher) {
      printVoucher();
      navigate("/expense/bonus");
    }
  }, [isPrintVoucher, printVoucher]);

  const createLoan = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateBonus(bonusDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
      // setButtonDisabled(false);
    }
    try {
      const addBonus = await POST_API("/addBonusDetails", bonusDetails);
      if (addBonus?.status === 200) {
        alertify.success(addBonus?.data?.message);
        setPrintVoucherData(addBonus?.data?.data[0]);
        setTimeout(() => {
          printVoucher();
          setIsPrintVoucher(true);
          setButtonDisabled(false);
        }, 300);
      }
    } catch (error) {
      setButtonDisabled(false);
      alertify.error(error?.response?.data?.message);
    }
  };

  const resetForm = () => {
    setBonusDetails({
      employee_id: "",
      finance_category_id: "",
      due_date: "",
      remarks: "",
      duration: "",
      amount: "",
      approved_by: "",
    });
  };

  if (loading1 || loading2 || loading3) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/expense/bonus" className="in-active">
              Bonus Details List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Bonus</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Add Bonus</div>

          {/***************Form************* **/}
          {isPrintVoucher && (
            <BonusPrintVoucher
              ref={componentRef}
              printVoucherData={printVoucherData}
            />
          )}
          <form onSubmit={(e) => createLoan(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Employee Name
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={userList}
                  value={userList?.find(
                    (option) => option?.value === bonusDetails?.employee_id
                  )}
                  onChange={(e) => handleEmployeeNameChange(e, "employee_id")}
                  name="employee_id"
                  className=""
                  isSearchable
                />
                {errors.employee_id ? (
                  <div className="validation-error-label">
                    {errors.employee_id}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Finance Category
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={bonusList}
                  value={bonusList?.find(
                    (option) =>
                      option?.value === bonusDetails?.finance_category_id
                  )}
                  onChange={(e) => handleSelectChange(e, "finance_category_id")}
                  name="finance_category_id"
                  className=""
                  isSearchable
                />
                {errors.finance_category_id ? (
                  <div className="validation-error-label">
                    {errors.finance_category_id}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Religion
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={religionList}
                  value={religionList?.find(
                    (option) => option?.value === bonusDetails?.religion_id
                  )}
                  onChange={(e) => handleSelectChange(e, "religion_id")}
                  name="religion_id"
                  isSearchable
                />
                {errors.religion_id ? (
                  <div className="validation-error-label">
                    {errors.religion_id}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Amount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="amount"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={bonusDetails?.amount}
                  placeholder="Enter Amount"
                />
                {errors.amount ? (
                  <div className="validation-error-label">{errors.amount}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Approved By
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={userList}
                  value={userList?.find(
                    (option) => option?.value === bonusDetails?.approved_by
                  )}
                  onChange={(e) => handleApprovedByChange(e, "approved_by_id")}
                  name="approved_by_id"
                  className=""
                  isSearchable
                />
                {errors.approved_by_id ? (
                  <div className="validation-error-label">
                    {errors.approved_by_id}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Remarks</label>
                <textarea
                  rows={5}
                  className="input-field"
                  type="text"
                  name="remarks"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={bonusDetails?.remarks}
                  placeholder="Enter Remarks"
                />
                {errors.remarks ? (
                  <div className="validation-error-label">{errors.remarks}</div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateLoan;
