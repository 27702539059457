import React from "react";
import { Link, useLocation } from "react-router-dom";

function PartTimeEmployeeReportDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const father_name =
    listDetails?.fathers_name !== "" && listDetails?.fathers_name !== undefined
      ? listDetails?.fathers_name
      : "";
  const Details = [
    {
      label: "Employee Name",
      value: listDetails?.EmployeeName + father_name,
    },
    {
      label: "Employee Code",
      value: listDetails?.EmployeeCode,
    },
    {
      label: "Employee Type Name",
      value: listDetails?.EmployeeType,
    },
    {
      label: "Department Name",
      value: listDetails?.departmentName,
    },
    {
      label: "Designation Name",
      value: listDetails?.designationName,
    },
    {
      label: "Branch Name",
      value: listDetails?.branchName,
    },
    {
      label: "Gender",
      value: listDetails?.Gender,
    },
    {
      label: "Religion",
      value: listDetails?.Religion,
    },
    {
      label: "Caste",
      value: listDetails?.Caste,
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/report/parttimeEmployee" className="in-active">
              Parttime Report
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Parttime Report Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Part Time Report Details</h4>

          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartTimeEmployeeReportDetails;
