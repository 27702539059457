import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import Save from "../../../Assets/Icons/Save.svg";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { POST_API } from "../../../Services/api";

function EditShift() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const listData = state?.list;
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [editShiftDetails, setEditShiftDetails] = useState({});

  useEffect(() => {
    if (listData) {
      setEditShiftDetails({
        id: listData?.id,
        name: listData?.name,
        from_time: listData?.from_time,
        to_time: listData?.to_time,
        fine_per_min: listData?.fine_per_min,
        max_time_limit: listData?.max_time_limit,
      });
    }
  }, [listData]);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setEditShiftDetails(() => ({ ...editShiftDetails, [name]: value }));
  };

  const saveShift = async (e) => {
    setButtonDisabled(true);
    const isValid = Validation.validateShift(editShiftDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    POST_API("/updateshifts", editShiftDetails)
      .then(async function (data) {
        alertify.success(data?.data?.message);
        navigate("/master/hrms/shift");
        setButtonDisabled(false);
      })
      .catch((error) => {
        alertify.error(error.response.data.message);
        setButtonDisabled(false);
      });
  };
  const resetForm = () => {
    setEditShiftDetails({
      id: listData?.id,
      name: listData?.name,
      from_time: listData?.from_time,
      to_time: listData?.to_time,
      fine_per_min: listData?.fine_per_min,
      max_time_limit: listData?.max_time_limit,
    });
  };
  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/shift" className="in-active">
              Shift List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Edit Shift</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Shift</h4>

          <form>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Shift Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter Shift Name"
                  value={editShiftDetails.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  From Time
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="time"
                  autoComplete="off"
                  placeholder="Please select from time"
                  name="from_time"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={editShiftDetails.from_time}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  To Time
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="time"
                  autoComplete="off"
                  placeholder="Please select to time"
                  name="to_time"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={editShiftDetails.to_time}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Fine Per Min
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="number"
                  autoComplete="off"
                  placeholder="Please Enter Fine Amount"
                  name="fine_per_min"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={editShiftDetails.fine_per_min}
                />
                {errors.fine_per_min ? (
                  <div className="validation-error-label">
                    {errors.fine_per_min}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Max Time Limit
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="number"
                  autoComplete="off"
                  placeholder="Please Enter Time Limit"
                  name="max_time_limit"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={editShiftDetails.max_time_limit}
                />
                {errors.max_time_limit ? (
                  <div className="validation-error-label">
                    {errors?.max_time_limit}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
                onClick={() => saveShift()}
              >
                <span className="submit-button-label">Save</span>
                <img src={Save} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditShift;
