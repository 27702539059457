import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { GET_API, BASE_PATH, BASE_URL } from "../../../Services/api";
import AbsentReport from "../../DataTable/AbsentReportTable";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import Filter from "../../../Assets/Icons/Filter.svg";
import More from "../../../Assets/Icons/More.svg";
import Search from "../../../Assets/Icons/Search.svg";
import ButtonLoader from "../../ButtonLoader";
import ExcelExport from "../../../Assets/Icons/excel-export.svg";
import PDFExport from "../../../Assets/Icons/pdf-export.svg";

function AbsentReportList() {
  const navigate = useNavigate();
  const [filteredList, setfilteredList] = useState([]);
  const [absentList, setAbsentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [hragentList, sethragentList] = useState([]);
  const [casteList, setcasteList] = useState([]);
  const [religionList, setreligionList] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState(false);

  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
  const [isDesignationLoading, setIsDesignationLoading] = useState(false);
  const [isShiftLoading, setIsShiftLoading] = useState(false);
  const [isHrAgentLoading, setIsHrAgentLoading] = useState(false);
  const [isReligionLoading, setIsReligionLoading] = useState(false);
  const [isCasteLoading, setIsCasteLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [filterParams, setFilterParams] = useState("");
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [isExportLoading, setIsExportLoading] = useState(false);

  const [filterList, setfilterList] = useState({
    branch_id: "",
    employee_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    employee_id: "",
    from_date: "",
    to_date: "",
    shift: "",
    agent: "",
    caste: "",
    religion: "",
  });

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "emp_code", label: "Employee Code" },
    { id: "name", label: "Employee Name" },
    { id: "mobile", label: "Mobile No" },
    { id: "gender", label: "Gender" },
    { id: "department", label: "Department" },
    { id: "designation", label: "Designation" },
    { id: "icon", label: "" },
  ];

  const genderList = [
    { label: "Please Select", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  useEffect(() => {
    getEmployeeList();
    getDepartmentList();
    getDesignationList();
    getShiftList();
    getHRAgentList();
    getCasteList();
    getReligionList();
  }, []);

  useEffect(() => {
    getAbsentReportList(currentPage, pageSize, filterParams, searchKey);
  }, [currentPage, pageSize, showFilterResultData]);

  const getDepartmentList = async () => {
    setIsDepartmentLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const depList = departmentList.data?.data?.map((item) => {
          return { label: item?.name, value: item?.id };
        });
        depList.unshift({ label: "Select...", value: "" });
        setDepartment(depList);
        setIsDepartmentLoading(false);
      }
    } catch (e) {
      setIsDepartmentLoading(false);
      setDepartment([]);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setIsDesignationLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const desList = designationList.data?.data?.map((item) => {
          return { label: item?.name, value: item?.id };
        });
        desList.unshift({ label: "Select...", value: "" });
        setDesignation(desList);
        setIsDesignationLoading(false);
      }
    } catch (e) {
      setIsDesignationLoading(false);
      setDesignation([]);
      alertify.error("Failed to Get Designation List");
    }
  };

  const getShiftList = async () => {
    setIsShiftLoading(true);
    try {
      const shiftList = await GET_API("/shiftDropdown");
      if (shiftList.status === 200) {
        const floor = shiftList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floor.unshift({ value: "", label: "Select..." });
        setShiftList(floor);
        setIsShiftLoading(false);
        return;
      }
    } catch (error) {
      alertify.error("Failed to Get Shift List");
      setIsShiftLoading(false);
      setShiftList([]);
    }
  };

  const getHRAgentList = async () => {
    setIsHrAgentLoading(true);
    try {
      const HRAgent = await GET_API("/agentDropdown/security_agent");
      if (HRAgent?.data?.status === 200) {
        const agent = HRAgent?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.company_name };
        });
        agent.unshift({ value: "", label: "Select..." });
        sethragentList(agent);
        setIsHrAgentLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get HR Agent List...");
      setIsHrAgentLoading(false);
      sethragentList([]);
    }
  };

  const getCasteList = async () => {
    setIsCasteLoading(true);
    try {
      const casteList = await GET_API("/casteDropdown");
      if (casteList?.data?.status === 200) {
        setIsCasteLoading(false);
        const cast = casteList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        cast.unshift({ value: "", label: "Select..." });
        setcasteList(cast);
      }
    } catch (error) {
      alertify?.error("Failed to get Caste List");
      setIsCasteLoading(false);
      setcasteList([]);
    }
  };

  const getReligionList = async () => {
    setIsReligionLoading(true);
    try {
      const religionList = await GET_API("/religionDropdown");
      if (religionList?.data?.status === 200) {
        const religion = religionList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        religion.unshift({ value: "", label: "Select..." });
        setreligionList(religion);
        setIsReligionLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get Religion List");
      setIsReligionLoading(false);
      setreligionList([]);
    }
  };

  const getexportData = (data) => {
    const attributesToRemove = ["id"];
    const updatedList = data.map((obj) => {
      const updatedObj = { ...obj };
      attributesToRemove.forEach((attr) => delete updatedObj[attr]);
      return updatedObj;
    });
    setExportList(updatedList);
  };
  // useEffect(() => {
  //   getAbsentReportList(1, pageSize, filterParams);
  // }, []);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getAbsentReportList(1, pageSize, filterParams, searchKey);
  };

  const getAbsentReportList = async (page, size, filters, search) => {
    try {
      setLoading(true);
      const list = await GET_API(
        `/employeeAbsent?page=${page}&size=${size}${filters}&search=${search}`
      );

      if (list.status === 200) {
        if (
          list?.data?.data?.data.current_page > list?.data?.data?.data.last_page
        ) {
          isPageNotExit();
          return;
        }

        setfilteredList(list?.data?.data?.data);
        setAbsentList(list?.data?.data?.data);
        setLastPage(list?.data?.data?.last_page);
        setLoading(false);
        getexportData(list?.data?.data?.data);
      }
    } catch (error) {
      alertify.error("Failed to get Absent Report List");
      setfilteredList([]);
      setAbsentList([]);
      getexportData([]);
      setLoading(false);
    }
  };

  const getEmployeeList = async () => {
    setIsEmpLoading(true);
    try {
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.status === 200) {
        const empList = employeeList.data?.data?.map((item) => {
          return {
            value: item?.id,
            label: `${item?.first_name} (${item?.emp_code})`,
          };
        });
        empList.unshift({ label: "Select...", value: "" });
        setEmployeeList(empList);
        setIsEmpLoading(false);
      }
    } catch (error) {
      setIsEmpLoading(false);
      setEmployeeList([]);
      alertify.error("Failed to get Employee List");
    }
  };

  const getExport = async () => {
    try {
      setIsExportLoading(true);
      const downloadForm = await GET_API(`${BASE_URL}export-Absent`);
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = `${BASE_PATH}${downloadForm?.data?.data}`;
        alink.setAttribute("target", "_blank");
        alink.download = "Abxent.xlsx";
        alink.click();
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };

  const getExportasPDF = async () => {
    try {
      setDownloadPDF(true);
      const downloadForm = await GET_API(`absentReportPdf`);
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = downloadForm?.data?.filename;
        alink.setAttribute("target", "_blank");
        alink.download = "EmployeeForm.pdf";
        alink.click();
        setDownloadPDF(false);
      }
    } catch (error) {
      setDownloadPDF(false);
    }
  };

  const handleFilter = async (e, name, type) => {
    const value = type === "date" ? e?.target?.value : e?.value;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    const isEmptyObject = (obj) => {
      return Object.keys(obj).length === 0;
    };

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }

    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  const showDetails = (listDetail) => {
    const id = listDetail?.id;
    navigate(`/report/absent/details/${id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  if (
    loading ||
    isEmpLoading ||
    isDepartmentLoading ||
    isDesignationLoading ||
    isShiftLoading ||
    isHrAgentLoading ||
    isReligionLoading ||
    isCasteLoading
  ) {
    return <Spinner />;
  }

  return (
    <div className="grow bg-[#F7F6F4]">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Absent Report List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                style={{ outline: "none", border: "none" }}
                onChange={(e) => {
                  setSearchKey(e?.target?.value);
                }}
                value={searchKey}
              />
              <img
                src={Search}
                alt=""
                className="cursor-pointer"
                onClick={() => {
                  getAbsentReportList(
                    currentPage,
                    pageSize,
                    filterParams,
                    searchKey
                  );
                }}
              />
            </div>
            <div className="flex gap-3">
              <div
                className="filter-button"
                onClick={() => setFilter((prev) => !prev)}
              >
                <span className="filter-btn-label">Filter</span>
                <img src={Filter} alt="" />
              </div>

              <div className="add-new-button-block">
                {isExportLoading ? (
                  <ButtonLoader isBtnDisable={isExportLoading} />
                ) : (
                  <button
                    className="add-new-button"
                    onClick={() => getExport()}
                  >
                    <span>Export</span>

                    <img
                      src={ExcelExport}
                      style={{ height: "25px" }}
                      alt="export"
                    />
                  </button>
                )}
              </div>

              <div className="add-new-button-block">
                {downloadPDF ? (
                  <ButtonLoader isBtnDisable={downloadPDF} />
                ) : (
                  <button
                    className="add-new-button"
                    onClick={() => getExportasPDF()}
                  >
                    <span>Export as PDF</span>
                    <img
                      src={PDFExport}
                      style={{ height: "25px" }}
                      alt="export"
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
          {filter && (
            <form>
              <div className="form ">
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Name</label>
                  <Select
                    options={employeeList}
                    value={employeeList?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id", "select")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Gender</label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender", "select")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Department</label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id", "select")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) =>
                      handleFilter(e, "designation_id", "select")
                    }
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Shift</label>
                  <Select
                    options={shiftList}
                    value={shiftList?.find(
                      (option) => option?.value === filterList.shift
                    )}
                    onChange={(e) => handleFilter(e, "shift", "select", false)}
                    name="shift"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">HR Agent</label>
                  <Select
                    options={hragentList}
                    value={hragentList?.find(
                      (option) => option?.value === filterList?.agent
                    )}
                    onChange={(e) => handleFilter(e, "agent", "select", false)}
                    name="agent"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Religion</label>
                  <Select
                    options={religionList}
                    value={religionList?.find(
                      (option) => option?.value === filterList?.religion
                    )}
                    onChange={(e) =>
                      handleFilter(e, "religion", "select", false)
                    }
                    name="religion"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-fourth">
                  <label className="label-name">Caste</label>
                  <Select
                    options={casteList}
                    value={casteList?.find(
                      (option) => option?.value === filterList?.caste
                    )}
                    onChange={(e) => handleFilter(e, "caste", "select", false)}
                    name="caste"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block">
                  <label className="label-name">Absent Date</label>
                  <div className="">
                    <input
                      className="input-field w-full"
                      type="date"
                      autoComplete="off"
                      name="absent_date"
                      value={filterList?.absent_date}
                      onChange={(e) => handleFilter(e, "absent_date", "date")}
                    />
                  </div>
                </div>

                <div className="add-new-button-block w-[40%] h-max">
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      showFilterResult(e);
                    }}
                  >
                    <span style={{ cursor: "pointer" }}>Show Result</span>
                  </button>
                </div>
              </div>
            </form>
          )}
          <AbsentReport
            list={filteredList}
            columns={columns}
            activeEdit={false}
            activeDelete={false}
            multiple={true}
            icon={More}
            showDetails={showDetails}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default AbsentReportList;
