import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import Save from "../../../Assets/Icons/Save.svg";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import Select from "react-select";
import "alertifyjs/build/css/alertify.css";
import { POST_API, GET_API } from "../../../Services/api";
import Spinner from "../../Spinner";
import Validation from "../../../Validation/Validation";

function EditDesignation() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const listData = state?.list;

  const [employeeList, setemployeeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errors, seterrors] = useState({});
  const [editDesignationDetails, setEditDesignationDetails] = useState({
    id: listData?.id,
    name: listData?.name,
    DepartmentName: listData?.DepartmentName,
    department_id: listData?.department_id,
    description: listData?.description,
  });

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setEditDesignationDetails(() => ({
      ...editDesignationDetails,
      [name]: value,
    }));
  };

  useEffect(() => {
    getDepartmentList();
  }, []);

  const getDepartmentList = async () => {
    try {
      setLoading(true);
      const employeeList = await GET_API("/departmentDropdown");
      if (employeeList?.data?.status === 200) {
        const department = employeeList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        setemployeeList(department);
        setLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get Department list...");
    }
  };

  const handleEmployee = (key, value) => {
    setEditDesignationDetails(() => ({
      ...editDesignationDetails,
      [key]: value,
    }));
  };

  const handleOptionChange = (e, name) => {
    setEditDesignationDetails(() => ({
      ...editDesignationDetails,
      [name]: e?.value,
    }));
  };

  const saveDesignation = async (e) => {
    setButtonDisabled(true);
    const isValid = Validation.validateDesingnation(editDesignationDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }

    try {
      const designation = await POST_API(
        "/updateDesignation",
        editDesignationDetails
      );
      if (designation?.data?.status === 200) {
        alertify.success(designation?.data?.message);
        navigate("/master/hrms/designation");
        setButtonDisabled(false);
      }
    } catch (error) {
      alertify.error(error?.response?.data?.message);
      setButtonDisabled(false);
    }
  };

  const resetForm = () => {
    setEditDesignationDetails({
      id: listData?.id,
      name: listData?.name,
      DepartmentName: listData?.DepartmentName,
      department_id: listData?.department_id,
      description: listData?.description !== null ? listData?.description : "",
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/designation" className="in-active">
              Designation List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Edit Designation</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Designation</h4>
          <form onSubmit={(e) => saveDesignation(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Designation Name
                  <span className="font-bold" style={{ color: "#FF5C42" }}>
                    *
                  </span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  placeholder="Enter Name"
                  name="name"
                  value={editDesignationDetails.name}
                  onChange={(e) => {
                    handleEmployee("name", e.target.value);
                  }}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Department
                  <span className="font-bold" style={{ color: "#FF5C42" }}>
                    *
                  </span>
                </label>
                <Select
                  options={employeeList}
                  value={employeeList?.find(
                    (option) =>
                      option?.value === editDesignationDetails?.department_id
                  )}
                  onChange={(e) => handleOptionChange(e, "department_id")}
                  name="department_id"
                  className=""
                  isSearchable
                />
                {errors.department_id ? (
                  <div className="validation-error-label">
                    {errors.department_id}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Description</label>
                <textarea
                  rows={5}
                  name="description"
                  className="input-field"
                  placeholder="Enter Description"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={editDesignationDetails.description}
                  autoComplete="off"
                />
                {errors.description ? (
                  <div className="validation-error-label">
                    {errors.description}
                  </div>
                ) : null}
              </div>
            </div>
          </form>
          <div className="submit-reset-button">
            <button
              type="button"
              className="reset-button"
              onClick={() => {
                resetForm();
              }}
            >
              <span className="reset-button-label">Reset</span>
            </button>
            <button
              type="submit"
              className="submit-button"
              onClick={() => saveDesignation()}
              disabled={buttonDisabled}
            >
              <span className="submit-button-label">Save</span>
              <img src={Save} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditDesignation;
