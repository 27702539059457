import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import View from "../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import Select from "react-select";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../Validation/Validation";
import { GET_API, POST_API } from "../../Services/api";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import Footer from "../Footer";
import Spinner from "../Spinner";

function CreateHoliday() {
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [holidayList, setHolidayList] = useState({
    name: "",
    religion_id: [],
    description: "",
    leave_type: "",
    financial_year: "",
  });

  const [religionValue, setReligionValue] = useState([]);
  const [religionList, setReligionList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [errors, seterrors] = useState({});
  useEffect(() => {
    getReligionsList();
  }, []);

  const getReligionsList = async () => {
    try {
      setLoading(true);
      const religionList = await GET_API("/religionDropdown");
      if (religionList.status === 200) {
        const religion = religionList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        setReligionList(religion);
        setLoading(false);
      }
      return;
    } catch (error) {
      setLoading(false);
      alertify.error(error?.response?.data?.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHolidayList(() => ({ ...holidayList, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    setHolidayList(() => ({ ...holidayList, [name]: e?.value }));
  };

  const createHoldayList = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateHoliday(holidayList);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }

    try {
      const createHoliday = await POST_API("/addHolidays", holidayList);
      if (createHoliday?.data?.status === 200) {
        alertify.success(createHoliday?.data?.message);
        navigate("/master/hrms/holiday");
        setButtonDisabled(false);
        return;
      }
      if (createHoliday?.data?.status === 403) {
        setButtonDisabled(false);
        alertify.error(createHoliday?.data?.message);
        return;
      }
    } catch (error) {
      setButtonDisabled(false);
      alertify.error("Failed to create Holiday");
      return;
    }
  };

  const resetForm = () => {
    setHolidayList({
      name: "",
      description: "",
      religion_id: [],
      leave_type: "",
      financial_year: "",
    });
    setReligionValue([]);
  };

  const handleEmployeeName = async (e) => {
    const religionId = [];
    e.map((religion) => {
      return religionId.push(religion.value);
    });
    setReligionValue(e);
    setHolidayList({ ...holidayList, religion_id: religionId });
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/holiday" className="in-active">
              Holiday List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Holiday</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Add Holiday</h4>

          <form>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Holiday Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  placeholder="Enter Holidays Name"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  value={holidayList?.name}
                />
                {errors.name && (
                  <span className="validation-error-label">{errors.name}</span>
                )}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Religion
                  <span className="required-symbol">*</span>
                </label>

                <Select
                  name="religion_id"
                  value={religionValue}
                  onChange={handleEmployeeName}
                  options={religionList}
                  isMulti
                />
                {errors.religion_id && (
                  <span className="validation-error-label">
                    {errors.religion_id}
                  </span>
                )}
              </div>

              <div className="field-block">
                <label className="label-name">Leave Type</label>
                <input
                  className="input-field"
                  type="text"
                  placeholder="Enter Leave Type"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="leave_type"
                  autoComplete="off"
                  value={holidayList?.leave_type}
                />
                {errors.leave_type && (
                  <span className="validation-error-label">
                    {errors.leave_type}
                  </span>
                )}
              </div>

              <div className="field-block">
                <label className="label-name">Financial Year</label>
                <input
                  className="input-field"
                  type="text"
                  placeholder="Enter Financial Year"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="financial_year"
                  autoComplete="off"
                  value={holidayList?.financial_year}
                />
                {errors?.financial_year && (
                  <span className="validation-error-label">
                    {errors.financial_year}
                  </span>
                )}
              </div>
              <div className="field-block">
                <label className="label-name">Description</label>
                <textarea
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  rows={5}
                  name="description"
                  placeholder="Enter Description"
                  value={holidayList?.description}
                />
                {errors.description && (
                  <span className="validation-error-label">
                    {errors.description}
                  </span>
                )}
              </div>
            </div>
          </form>
          <div className="submit-reset-button">
            <button type="button" className="reset-button" onClick={resetForm}>
              <span className="reset-button-label">Reset</span>
            </button>
            <button
              disabled={buttonDisabled}
              type="submit"
              className="submit-button"
              onClick={(e) => createHoldayList(e)}
            >
              <span className="submit-button-label">Save</span>
              <img src={ColorAdd} alt="" />
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CreateHoliday;
