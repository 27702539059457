import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import alertify from "alertifyjs";
import { Link } from "react-router-dom";
import { GET_API } from "../../Services/api";
import AgentCommisionDetailsListTable from "../DataTable/AgentCommisionDetailsListTable";
import Spinner from "../Spinner";
import Footer from "../../Components/Footer";

function AgentcommisionDetails() {
  const { id } = useParams();
  const [agentName, setAgentName] = useState("");
  const [agentcommisonList, setAgentCommissonList] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "profile_picture", label: "Profile Picture" },
    { id: "employeeName", label: "Employee Name" },
    { id: "employeeCode", label: "Employee Code" },
    { id: "employeeJoiningdate", label: "Joining Date" },
    { id: "employmentDuration", label: "Duration" },
  ];

  useEffect(() => {
    getAgentCommissondetailsList();
  }, []);

  const getAgentCommissondetailsList = async () => {
    try {
      setLoading(true);
      const agentCommissionList = await GET_API(`/Agentdetails/${id}`);
      if (agentCommissionList?.status === 200) {
        setAgentCommissonList(
          agentCommissionList?.data?.data[0]?.employee_data
        );
        setAgentName(agentCommissionList?.data?.data[0]?.agent_name);
        setLoading(false);
      }
    } catch (e) {
      alertify.error("Failed to get Agent Commission Details List");
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <div className="card-header">
            <span className="in-active">Home</span>
            <Link>{">"}</Link>
            <Link to="/expense/agentcommission" className="in-active">
              Agent Commission List
            </Link>
            <Link>{">"}</Link>
            <Link className="active">Agent Commission Details</Link>
          </div>

          <div className="p-4 font-karla">
            <span className="text-text-light">Agent Name: </span>
            {agentName}
          </div>
          <AgentCommisionDetailsListTable
            list={agentcommisonList}
            columns={columns}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AgentcommisionDetails;
