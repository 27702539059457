import React, { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import Footer from "../../Footer";
import ExpensePrintVoucher from "./ExpensePrintVoucher";
import { useReactToPrint } from "react-to-print";
// import useScanDetection from "use-scan-detection";

function ExpenseDetails() {
  const { state } = useLocation();
  const componentRef = useRef();
  const listDetails = state?.list;
  const Details = [
    {
      label: "Employee Name",
      value: listDetails?.employee_name + " " + listDetails?.fathers_name,
    },
    {
      label: "Employee Code",
      value: listDetails?.EmployeeCode,
    },
    {
      label: "Branch Name",
      value: listDetails?.department_name,
    },
    {
      label: "Department Name",
      value: listDetails?.branchName,
    },
    {
      label: "Designation",
      value: listDetails?.designation_name,
    },
    {
      label: "Total Paid Amount",
      value: listDetails?.total_paid_amount,
    },
    {
      label: "Expense Type",
      value: listDetails?.finance_category_name,
    },
    {
      label: "Amount",
      value: listDetails?.amount,
    },
    {
      label: "Expense Date",
      value: listDetails?.expense_date,
    },
    {
      label: "Paid Status",
      value: listDetails?.voucher_status === 1 ? "Paid" : "Unpaid",
    },
    {
      label: "Remarks",
      value: listDetails?.remarks ? listDetails?.remarks : "-",
    },
    {
      label: "Approval",
      value: listDetails?.approved_by_name
        ? listDetails?.approved_by_name
        : "-",
    },
  ];

  const printVoucher = useReactToPrint({
    content: () => componentRef.current,
  });

  const [BarcodeScan, setBarcodeScan] = useState("");
  // useScanDetection({
  //   onComplete: (code) => {
  //     setBarcodeScan(code);
  //   },
  // });

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/expense" className="in-active">
              Expense List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Expense Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Expense Details</div>
          <div className="search-block" style={{ justifyContent: "flex-end" }}>
            <button
              type="button"
              className="submit-button"
              onClick={() => printVoucher()}
            >
              <span className="submit-button-label">Print</span>
            </button>
          </div>
          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <ExpensePrintVoucher
            ref={componentRef}
            printVoucherData={listDetails}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ExpenseDetails;
