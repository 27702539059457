import React from "react";

function PurchaseOrderDashboard() {
  return (
    <div className="flex justify-center items-center text-3xl w-[80%] font-karla text-text-light">
      Processing...
    </div>
  );
}

export default PurchaseOrderDashboard;
