import React from "react";
import Edit from "../Assets/Icons/Edit.svg";
import Show from "../Assets/Icons/View.svg";
import Delete from "../Assets/Icons/Delete.svg";
import TerminateEmployee from "../Assets/Icons/EmployeeTerminate.svg";
import Allowance from "../Assets/Icons/Allowance.svg";
import FoodAllowance from "../Assets/Icons/FoodAllowance.svg";
import DownloadIcon from "@mui/icons-material/FileDownload";
import IDCard from "../Assets/Icons/IDcard.svg";
import { useNavigate } from "react-router-dom";

function Action({
  editPage,
  row,
  multiplePage,
  deleteRow,
  showDetails,
  handlePrintVoucher,
  isFoodVoucher,
  editList,
  editStatus,
  allowance = false,
  activeEdit = true,
  activeDelete = true,
  paySlip = false,
  termination = false,
  terminateEmployee,
  enableMoreOption = true,
  foodAllowance,
  download,
  downloadEmployeeForm,
  isEnableIDCard = false,
  idCardPreviewPage,
}) {
  const navigate = useNavigate();
  const handleEdit = () => {
    if (multiplePage) {
      editPage(row);
    } else {
      editList(row);
      editStatus(true);
    }
  };
  const handleDelete = () => {
    deleteRow(row?.id);
  };
  const showRowDetails = () => {
    showDetails(row);
  };
  const handleFoodVoucher = () => {
    handlePrintVoucher(row);
  };
  const handleAllownace = () => {
    navigate(`/employee/allowance/${row?.employee_Id}`, {
      state: {
        listId: row?.id,
      },
    });
  };

  return (
    enableMoreOption && (
      <div className="min-w-[75px] bg-[#fff] rounded border-solid border-[1px] border-primary absolute top-[20px] right-[35px] z-50">
        {activeEdit && (
          <div
            className="px-4 py-2 min-w-[138px] gap-2 border-solid border-b-[1px] border-primary flex justify-between items-center cursor-pointer"
            onClick={() => handleEdit()}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Edit
            </span>
            <img src={Edit} alt="" />
          </div>
        )}
        {allowance && (
          <div
            className="px-4 py-2 min-w-[138px] gap-2 border-solid border-b-[1px] border-primary flex justify-between items-center cursor-pointer"
            onClick={() => handleAllownace()}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Allowance
            </span>
            <img src={Allowance} alt="" />
          </div>
        )}

        {multiplePage && (
          <div
            className="px-4 py-2 min-w-[138px] gap-2 border-solid border-b-[1px] border-primary flex justify-between items-center cursor-pointer"
            onClick={() => showRowDetails()}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Show
            </span>
            <img src={Show} alt="" />
          </div>
        )}

        {paySlip && (
          <div
            className="px-4 py-2 min-w-[138px] gap-2 border-solid border-b-[1px] border-primary flex justify-between items-center cursor-pointer"
            onClick={() => showRowDetails()}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Payslip
            </span>
            <img src={Show} alt="" />
          </div>
        )}

        {activeDelete && (
          <div
            className="px-4 py-2 min-w-[138px] gap-2 border-solid border-b-[1px] border-primary flex justify-between items-center cursor-pointer"
            onClick={() => handleDelete()}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Delete
            </span>
            <img src={Delete} alt="" />
          </div>
        )}

        {termination && (
          <div
            className="px-4 py-2 min-w-[138px] gap-2 border-solid  border-primary flex justify-between items-center cursor-pointer"
            onClick={() => terminateEmployee(row)}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Terminate
            </span>
            <img src={TerminateEmployee} alt="" />
          </div>
        )}

        {isFoodVoucher && (
          <div
            className="px-4 py-2 w-[170px] gap-2 border-solid  border-primary flex justify-between items-center cursor-pointer"
            onClick={() => handleFoodVoucher(row)}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Print Voucher
            </span>
            <img src={Show} alt="" />
          </div>
        )}

        {foodAllowance && (
          <div
            className="px-4 py-2 w-[170px] gap-2 border-solid  border-primary flex justify-between items-center cursor-pointer"
            onClick={() => foodAllowance(row)}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Food Allowance
            </span>
            <img src={FoodAllowance} alt="food allowance" className="h-6 w-6" />
          </div>
        )}

        {download && (
          <div
            className="px-4 py-2 w-[170px] gap-2 border-solid  border-primary flex justify-between items-center cursor-pointer"
            onClick={() => downloadEmployeeForm(row)}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Download
            </span>
            <DownloadIcon className="h-6 w-6" />
          </div>
        )}
        {isEnableIDCard && (
          <div
            className="px-4 py-2 w-[170px] gap-2 border-solid  border-primary flex justify-between items-center cursor-pointer"
            onClick={() => idCardPreviewPage(row)}
          >
            <span className="font-karla text-base font-normal text-text-light">
              ID Card
            </span>
            <img src={IDCard} alt="idcard" className="h-[25px] w-[30px]" />
          </div>
        )}
      </div>
    )
  );
}

export default Action;
