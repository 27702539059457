import React from "react";
import config from "../../../Services/config.json";

function PersonalInfo({ listDetails }) {
  const Details = [
    {
      label: "Email",
      value: listDetails?.email ? listDetails?.email : "-",
    },
    {
      label: "Mobile Number",
      value: listDetails?.phone_no ? listDetails?.phone_no : "-",
    },
    {
      label: "Alternative Mobile Number",
      value: listDetails?.alternate_phone_no
        ? listDetails?.alternate_phone_no
        : "-",
    },
    {
      label: "Marital Status",
      value: listDetails?.marital_status ? listDetails?.marital_status : "-",
    },
    {
      label: "Father Name",
      value: listDetails?.fathers_name ? listDetails?.fathers_name : "-",
    },
    {
      label: "Religion",
      value: listDetails?.religionname ? listDetails?.religionname : "-",
    },
    {
      label: "Caste",
      value: listDetails?.castename ? listDetails?.castename : "-",
    },
    {
      label: "ID Proof",
      value: listDetails?.proofname ? listDetails?.proofname : "-",
    },
    {
      label: "Proof Number",
      value: listDetails?.id_proof_number
        ? listDetails?.id_proof_number
        : listDetails?.government_proof_value
          ? listDetails?.government_proof_value
          : "-",
    },
  ];

  return (
    <div className="border-t-[1px] border-t-[#D9D9D9]">
      <div
        className="p-3 w-3/12 bg-primary-color text-center"
        style={{ borderRadius: "0px 0px 100px 0px" }}
      >
        <span className="text-white-color font-karla font-base ">
          Personal Info
        </span>
      </div>
      <div className="list-container mt-3">
        <div className="list-content-block">
          {Details.map((list) => {
            return (
              <div className="list">
                <label className="list-label">{list?.label}</label>
                <div style={{ wordBreak: "break-word" }} className="list-value">
                  {list?.value}
                </div>
              </div>
            );
          })}
          <div className="list">
            <label className="list-label">Proof Image</label>
            <div style={{ wordBreak: "break-word" }} className="list-value">
              <img
                src={config?.path + listDetails?.proof_image}
                alt=""
                className="h-[40px] w-[40px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;
