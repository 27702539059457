import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import config from "../../../Services/config.json";
import { GET_API } from "../../../Services/api";
import Footer from "../../Footer";
import LoanPrintVoucher from "./LoanPrintVoucher";
import { useReactToPrint } from "react-to-print";
import "alertifyjs/build/css/alertify.css";

function LoanDetails() {
  const componentRef = useRef();
  const [paymentDetailsLogs, setPaymentDetailsLogs] = useState([]);
  const [filteredEmployee, setFilteredEmployee] = useState([]);
  const { state } = useLocation();
  const listDetails = filteredEmployee;
  const [loading, setLoading] = useState(false);

  const Details = [
    {
      label: "Employee ID",
      value: listDetails?.emp_code ? listDetails?.emp_code : "-",
    },
    {
      label: "Designation",
      value: listDetails?.designation_name,
    },
    {
      label: "Employee Name",
      value: listDetails?.first_name + " " + listDetails?.fathers_name,
    },
    {
      label: "Department Name",
      value: listDetails?.department_name,
    },
    {
      label: "Mobile Number",
      value: listDetails?.phone_no,
    },
    {
      label: "Branch Name",
      value: listDetails?.branch_name,
    },
    {
      label: "Paid Status",
      value: listDetails?.voucher_status === 1 ? "Paid" : "Unpaid",
    },
  ];
  // {row.voucher_status === 1 ?
  //   <span style={{ color: "#3EAB82" }} >
  //     Paid
  //   </span>
  //   :
  //   <span style={{ color: "#ed452a" }} >
  //     Unpaid
  //   </span>
  // }

  const [Loandetails, setLoandetails] = useState([]);
  const printVoucher = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    getLoanDetails();
  }, []);

  const getLoanDetails = async () => {
    setLoading(true);
    try {
      const loanDetails = await GET_API(
        "/FinancePaymentDetailsList/" + state?.list?.id
      );
      if (loanDetails?.data?.status === 200) {
        setFilteredEmployee(loanDetails.data.data.employeeDetails);
        setPaymentDetailsLogs(loanDetails.data.data.financePaymentDetails);
        setLoandetails(loanDetails.data.data.FinanceDetails);
        setLoading(false);
      }
    } catch (error) {
      setPaymentDetailsLogs([]);
      setLoandetails([]);
      setLoading(false);
    }
  };

  const advanceDetails = [
    {
      label: "Payment Type",
      value: Loandetails?.payment_type,
    },
    {
      label: "Type",
      value: Loandetails?.type,
    },

    {
      label: "Finance Date",
      value: Loandetails?.finance_date,
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/expense/loan" className="in-active">
              Loan List
            </Link>
            <Link>{">"}</Link>
            <Link className="active">Loan Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Loan Details</div>
          <div className="search-block" style={{ justifyContent: "flex-end" }}>
            {/* {Loandetails?.amount === 0 && ( */}
            <button
              type="button"
              className="submit-button"
              onClick={() => printVoucher()}
            >
              <span className="submit-button-label">Print</span>
            </button>
            {/* )} */}
          </div>

          <div className="advance-details-image">
            <div className="advance-details-image-container">
              <div
                style={{ wordBreak: "break-word" }}
                className="text-[#7B7B7B] text-base font-karla"
              >
                <img
                  src={`${config.path}${listDetails?.profile_image}`}
                  alt=""
                  style={{ height: "100px", width: "100px" }}
                />
              </div>
            </div>
          </div>

          <div className="list-container border-bottom">
            <div className="list-content-block">
              {Details?.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="list-container border-bottom">
            <div className="advance-section">
              {advanceDetails?.map((list, i) => {
                return (
                  <div className="flex " key={i}>
                    <label className="list-label">{list?.label}: </label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="advance-section-list-value"
                    >
                      {list?.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <LoanPrintVoucher ref={componentRef} printVoucherData={listDetails} />
          <div className="p-5">
            <span className="text-text-dark font-karla text-base">
              Loan Details Logs
            </span>
            <table className="log-table mt-[4px]">
              <tr>
                <th>Amount</th>
                <th>Paid Amount</th>
                <th>Actual Due Date</th>
                <th>Remarks</th>
                <th>Image</th>
              </tr>
              {paymentDetailsLogs?.map((list, i) => {
                return (
                  <tr key={i}>
                    <td className="log-table log-table-data content-label">
                      {list?.amount}
                    </td>
                    <td className="log-table log-table-data content-label">
                      {list?.paid_amount}
                    </td>
                    <td className="log-table log-table-data content-label">
                      {list?.actual_due_date}
                    </td>
                    <td className="log-table log-table-data content-label">
                      {list?.remarks}
                    </td>
                    <td className="log-table log-table-data content-label">
                      <img
                        src={config?.path + list?.image}
                        alt="images"
                        className="h-[30px] w-[30px]"
                      />
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LoanDetails;
