import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import config from "../../Services/config.json";

export default function StickyHeadTable({ list, columns }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={i}
                      className="table-column"
                    >
                      {
                        <TableCell className="table-body !w-[7%]">
                          {page * rowsPerPage + (i + 1)}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          <img
                            src={`${config?.path}${row?.profile_picture}`}
                            className="h-10 w-10"
                            alt=""
                          />
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row.employeeName}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row.employeeCode}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {dayjs(row.employeeJoiningdate).format("DD-MM-YYYY")}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row.employmentDuration}
                        </TableCell>
                      }
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={list?.length ? list.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
