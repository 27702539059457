import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import View from "../../../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../../Validation/POValidation";
import { POST_API } from "../../../../Services/api";
import ColorAdd from "../../../../Assets/Icons/ColorAdd.svg";

function CreateWarehouse() {
  const navigate = useNavigate();

  const [warehouseDetails, setWarehouseDetails] = useState({
    code: "",
    name: "",
    description: "",
    landline_no: "",
    address: "",
    contact_person_name: "",
    contact_person_mobile: "",
    contact_person_email: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setWarehouseDetails(() => ({ ...warehouseDetails, [name]: value }));
  };

  const createWarehouse = async (e) => {
    e.preventDefault();

    setButtonDisabled(true);
    const isValid = Validation.Warehouse(warehouseDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const createWarehouse = await POST_API(
        "/po/addWarehouse",
        warehouseDetails
      );
      if (createWarehouse?.data?.status === 200) {
        setButtonDisabled(false);
        alertify.success(createWarehouse?.data?.message);
        navigate("/po/master/warehouse");
      }
    } catch (error) {
      setButtonDisabled(false);
      for (const key in error?.response?.data?.data) {
        if (error?.response?.data?.data.hasOwnProperty(key)) {
          error?.response?.data?.data[key].forEach((value) => {
            alertify.error(value);
          });
        }
      }
    }
  };
  const resetForm = () => {
    setWarehouseDetails({
      code: "",
      name: "",
      description: "",
      landline_no: "",
      address: "",
      contact_person_name: "",
      contact_person_mobile: "",
      contact_person_email: "",
    });
  };

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Purchase Order</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Warehouse</h4>
          <div className="search-block">
            <Link to="/po/master/warehouse" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>

          {/***************Form************* **/}

          <form onSubmit={(e) => createWarehouse(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Warehouse Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter Warehouse Name"
                  value={warehouseDetails.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Code
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="code"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Code"
                  value={warehouseDetails?.code}
                />
                {errors.code ? (
                  <div className="validation-error-label">{errors.code}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Contact Person
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="contact_person_name"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Contact Person Name"
                  value={warehouseDetails?.contact_person_name}
                />
                {errors.contact_person_name ? (
                  <div className="validation-error-label">
                    {errors.contact_person_name}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">LandLine Number</label>
                <input
                  className="input-field"
                  type="text"
                  name="landline_no"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter LandLine Number"
                  value={warehouseDetails?.landline_no}
                />
                {errors.landline_no ? (
                  <div className="validation-error-label">
                    {errors.landline_no}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Contact Number
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="contact_person_mobile"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Contact Number"
                  value={warehouseDetails?.contact_person_mobile}
                />
                {errors.contact_person_mobile ? (
                  <div className="validation-error-label">
                    {errors.contact_person_mobile}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Contact Email
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="contact_person_email"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Email"
                  value={warehouseDetails?.contact_person_email}
                />
                {errors.contact_person_email ? (
                  <div className="validation-error-label">
                    {errors.contact_person_email}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Address</label>
                <textarea
                  className="input-field"
                  type="text"
                  name="address"
                  autoComplete="off"
                  rows={5}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Address"
                  value={warehouseDetails?.address}
                />
                {errors.address ? (
                  <div className="validation-error-label">{errors.address}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Description</label>
                <textarea
                  className="input-field"
                  type="text"
                  name="description"
                  autoComplete="off"
                  rows={5}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Description"
                  value={warehouseDetails?.description}
                />
                {errors.description ? (
                  <div className="validation-error-label">
                    {errors.description}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateWarehouse;
