import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import View from "../../../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../../Validation/POValidation";
import { GET_API, PUT_API } from "../../../../Services/api";
import ColorAdd from "../../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../../Spinner";
import Select from "react-select";

function CreateSupplier() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const listData = state?.list;
  const id = listData?.id;
  const [supplierDetails, setSupplierDetails] = useState({
    id: "",
    name: "",
    supplier_code: "",
    email: "",
    mobile_number: "",
    gstn_no: "",
    billing_address: "",
    billing_state: "",
    billing_city: "",
    billing_country: "",
    billing_pincode: "",
    bank_name: "",
    account_number: "",
    ifsc_code: "",
    bank_branch: "",
    discount: "",
    department_id: [],
    remarks: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    getDepartmentList();
    getSupplier();
  }, []);

  const getSupplier = async () => {
    const supplier = await GET_API(`po/getSupplier/${id}`);
    if (supplier?.data?.status === 200) {
      const supplierData = supplier?.data?.data?.suppliers;
      const discount = supplier?.data?.data?.poSupplierDiscount;
      const department = supplier?.data?.data?.poSupplierDepartment;
      const selectedID = [...selectedOptions];
      const deptID = [...supplierDetails?.department_id];
      department?.map((departmentId) => {
        selectedID?.push({
          label: departmentId?.name,
          value: departmentId?.id,
        });
        deptID?.push(departmentId?.id);
        return setSelectedOptions(selectedID);
      });
      setSupplierDetails({
        id: supplierData?.id,
        name: supplierData?.name,
        supplier_code: supplierData?.supplier_code,
        email: supplierData?.email,
        mobile_number: supplierData?.mobile_number,
        gstn_no: supplierData?.gstn_no,
        billing_address: supplierData?.billing_address,
        billing_state: supplierData?.billing_state,
        billing_city: supplierData?.billing_city,
        billing_country: supplierData?.billing_country,
        billing_pincode: supplierData?.billing_pincode,
        bank_name: supplierData?.bank_name,
        account_number: supplierData?.account_number,
        ifsc_code: supplierData?.ifsc_code,
        bank_branch: supplierData?.bank_branch,
        department_id: deptID,
        discount: discount?.discount,
        remarks: supplierData?.remarks,
      });
    }
  };

  const getDepartmentList = async () => {
    setLoading(true);
    try {
      const department = await GET_API("po/poDepartmentDropdown");
      if (department?.data?.status === 200) {
        const deptList = department?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        setDepartment(deptList);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get department list");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setSupplierDetails(() => ({ ...supplierDetails, [name]: value }));
  };

  const handleDeptChange = (e, name) => {
    setSelectedOptions(e);
    let id = [...e]?.map((emp, i) => {
      return emp.value;
    });
    setSupplierDetails(() => ({ ...supplierDetails, [name]: id }));
  };
  const updateSuppliers = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.Suppliers(supplierDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const createSuppliers = await PUT_API(
        "/po/updatesupplierList",
        supplierDetails
      );
      if (createSuppliers?.data?.status === 200) {
        setButtonDisabled(false);
        alertify.success(createSuppliers?.data?.message);
        navigate("/master/po/suppliers");
      }
    } catch (error) {
      setButtonDisabled(false);
      for (const key in error?.response?.data?.data) {
        if (error?.response?.data?.data.hasOwnProperty(key)) {
          error?.response?.data?.data[key].forEach((value) => {
            alertify.error(value);
          });
        }
      }
    }
  };
  const resetForm = () => {
    setSupplierDetails({
      id: listData?.id,
      name: listData?.name,
      warehosuppliersListuse_id: listData?.suppliersList,
      email: listData?.email,
      mobile_number: listData?.mobile_number,
      gstn_no: listData?.gstn_no,
      billing_address: listData?.billing_address,
      billing_state: listData?.billing_state,
      billing_city: listData?.billing_city,
      billing_country: listData?.billing_country,
      billing_pincode: listData?.billing_pincode,
      bank_name: listData?.bank_name,
      account_number: listData?.account_number,
      ifsc_code: listData?.ifsc_code,
      bank_branch: listData?.bank_branch,
      discount: listData?.discount,
      remarks: listData?.remarks,
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/po/suppliers" className="in-active">
              Supplier List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Edit Supplier</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Supplier</h4>

          {/***************Form************* **/}

          <form onSubmit={(e) => updateSuppliers(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Supplier Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter Supplier Name"
                  value={supplierDetails.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Code
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="supplier_code"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Code"
                  value={supplierDetails?.supplier_code}
                />
                {errors.supplier_code ? (
                  <div className="validation-error-label">
                    {errors.supplier_code}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Email
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="email"
                  name="email"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter mobile number"
                  value={supplierDetails?.email}
                />
                {errors.email ? (
                  <div className="validation-error-label">{errors.email}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Mobile Number
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="number"
                  name="mobile_number"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Mobile Number"
                  value={supplierDetails?.mobile_number}
                />
                {errors.mobile_number ? (
                  <div className="validation-error-label">
                    {errors.mobile_number}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Discount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="number"
                  name="discount"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Discount amount"
                  value={supplierDetails?.discount}
                />
                {errors.discount ? (
                  <div className="validation-error-label">
                    {errors.discount}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Department
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  isMulti
                  options={department}
                  name="department_id"
                  value={selectedOptions}
                  onChange={(e) => handleDeptChange(e, "department_id")}
                />
                {errors.department_id ? (
                  <div className="validation-error-label">
                    {errors.department_id}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Address
                  <span className="required-symbol">*</span>
                </label>
                <textarea
                  rows={5}
                  className="input-field"
                  type="number"
                  name="billing_address"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter address"
                  value={supplierDetails?.billing_address}
                />
                {errors.billing_address ? (
                  <div className="validation-error-label">
                    {errors.billing_address}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  State
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="billing_state"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter state"
                  value={supplierDetails?.billing_state}
                />
                {errors.billing_state ? (
                  <div className="validation-error-label">
                    {errors.billing_state}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  City
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="billing_city"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter city"
                  value={supplierDetails?.billing_city}
                />
                {errors.billing_city ? (
                  <div className="validation-error-label">
                    {errors.billing_city}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Country
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="billing_country"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter country"
                  value={supplierDetails?.billing_country}
                />
                {errors.billing_country ? (
                  <div className="validation-error-label">
                    {errors.billing_country}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Pincode
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="billing_pincode"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter pincode"
                  value={supplierDetails?.billing_pincode}
                />
                {errors.billing_pincode ? (
                  <div className="validation-error-label">
                    {errors.billing_pincode}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  GSTIN
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="gstn_no"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter pincode"
                  value={supplierDetails?.gstn_no}
                />
                {errors.gstn_no ? (
                  <div className="validation-error-label">{errors.gstn_no}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Bank name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="bank_name"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter bank name"
                  value={supplierDetails?.bank_name}
                />
                {errors.bank_name ? (
                  <div className="validation-error-label">
                    {errors.bank_name}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Account Number
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="account_number"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter account number"
                  value={supplierDetails?.account_number}
                />
                {errors.account_number ? (
                  <div className="validation-error-label">
                    {errors.account_number}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  IFSC code
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="ifsc_code"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter IFSC Code"
                  value={supplierDetails?.ifsc_code}
                />
                {errors.ifsc_code ? (
                  <div className="validation-error-label">
                    {errors.ifsc_code}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Bank branch
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="bank_branch"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter bank branch name"
                  value={supplierDetails?.bank_branch}
                />
                {errors.bank_branch ? (
                  <div className="validation-error-label">
                    {errors.bank_branch}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Discount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="discount"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter discount"
                  value={supplierDetails?.discount}
                />
                {errors.discount ? (
                  <div className="validation-error-label">
                    {errors.discount}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Remarks</label>
                <textarea
                  rows={5}
                  className="input-field"
                  type="text"
                  name="remarks"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter remarks"
                  value={supplierDetails?.remarks}
                />
                {errors.remarks ? (
                  <div className="validation-error-label">{errors.remarks}</div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateSupplier;
