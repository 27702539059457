import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Components/Login";

import {
  HomeSideBar,
  MasterRoutesSideBar,
  PurchaseOrderSideBar,
} from "./Components/HomeLayout";
import AllRoutes from "./Components/HomeLayout";

import HomeRoutes from "./Components/Routes/HomeRoutes";
import PurhcaseOrderRoutes from "./Components/Routes/PORoutes/index";
import FindYourAccount from "./Components/FindYourAccount";
import ResetPassword from "./Components/ResetPassword";
import { useState } from "react";
import { PrivilegeContext } from "./PrivilegeContext";
import MasterRoutes from "./Components/Routes/MasterRoutes";
import PageNotFound from "./Components/PageNotFound/PageNotFound";

function App() {
  const [privilege, setPrivilege] = useState([]);
  const [access, setAccess] = useState([]);
  return (
    <>
      <PrivilegeContext.Provider
        value={{ privilege, setPrivilege, access, setAccess }}
      >
        <Router>
          <Routes>
            <Route path="*" element={<PageNotFound />} />
            <Route path="/login" element={<Login />}></Route>
            <Route path="/login/identify" element={<FindYourAccount />}></Route>
            <Route path="/resetPassword" element={<ResetPassword />}></Route>

            <Route path="/" element={<Navigate to="/login" replace={true} />} />
            <Route path="/" element={<AllRoutes />}>
              <Route element={<HomeSideBar />}>{HomeRoutes()}</Route>
              <Route path="/master" element={<MasterRoutesSideBar />}>
                {MasterRoutes()}
              </Route>
              <Route path="/hrms"></Route>
              <Route path="/po" element={<PurchaseOrderSideBar />}>
                {PurhcaseOrderRoutes()}
              </Route>
              <Route path="/fms"></Route>
              <Route path="/crm"></Route>
            </Route>
          </Routes>
        </Router>
      </PrivilegeContext.Provider>
    </>
  );
}

export default App;
