import React from "react";
import { Route } from "react-router-dom";

import CreatePO from "../../../Components/PurchaseOrder/PO/CreatePO";
import POlist from "../../../Components/PurchaseOrder/PO/POlist";
import POdetails from "../../../Components/PurchaseOrder/PO/POdetails";

function PORoutes() {
  return (
    <>
      <Route path="new" element={<CreatePO />}></Route>
      <Route path="previousList" element={<POlist />}></Route>
      <Route path="details/:id" element={<POdetails />}></Route>
    </>
  );
}

export default PORoutes;
