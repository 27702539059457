import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GET_API, BASE_PATH, BASE_URL } from "../../../Services/api";
import MovementReportTable from "../../DataTable/MovementReportTable";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import More from "../../../Assets/Icons/More.svg";
import ExcelExport from "../../../Assets/Icons/excel-export.svg";
import PDFExport from "../../../Assets/Icons/pdf-export.svg";
import Select from "react-select";
import ButtonLoader from "../../ButtonLoader";

function MovementReportList() {
  const navigate = useNavigate();
  const [filteredList, setfilteredList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [employeeList, setEmployeeList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [empName, setEmpName] = useState({});

  // Export Excel and PDF
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isExportPdfLoading, setIsExportPdfLoading] = useState(false);

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "employeeCode", label: "Employee Code" },
    { id: "EmployeeName", label: "Employee Name" },
    { id: "branchName", label: "Branch Name" },
    { id: "department_name", label: "Department Name" },
    { id: "designation_name", label: "Designation Name" },
    { id: "logs", label: "Logs" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getEmployeeList();
  }, []);

  const getexportData = (data) => {
    setExportList(data);
  };

  const getEmployeeList = async () => {
    setLoading(true);
    try {
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.status === 200) {
        const empType = employeeList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.first_name };
        });
        empType.unshift({ value: "", label: "Select..." });
        setEmployeeList(empType);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to get Employee List");
    }
  };

  const showDetails = (listDetail) => {
    const id = listDetail?.id;
    navigate(`/report/movement/details/${id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  const isPageNotExit = () => {
    setCurrentPage(1);
    handleEmpMomentReport(empName, 1, pageSize);
  };

  useEffect(() => {
    if (empName?.value) {
      handleEmpMomentReport(empName?.value, currentPage, pageSize);
    }
  }, [empName?.value, currentPage, pageSize]);

  const handleEmpMomentReport = async (empId, page, size) => {
    try {
      setIsLoading(true);
      const momentReport = await GET_API(
        `movementReport/${empId}?page=${page}&size=${size}`
      );
      if (momentReport?.status === 200) {
        if (
          momentReport?.data?.data?.current_page >
          momentReport?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setLastPage(momentReport?.data?.data?.last_page);
        setfilteredList(momentReport?.data?.data?.data);
        getexportData(momentReport?.data?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setLastPage("");
      setfilteredList([]);
      getexportData([]);
      setIsLoading(false);
    }
  };

  const getExport = async () => {
    try {
      setIsExportLoading(true);
      const downloadForm = await GET_API(
        `${BASE_URL}export-movementReport/${empName?.value}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = `${BASE_PATH}${downloadForm?.data?.data}`;
        alink.setAttribute("target", "_blank");
        alink.download = "Movement.xlsx";
        alink.click();
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };

  const getExportasPDF = async () => {
    try {
      setIsExportPdfLoading(true);
      const downloadForm = await GET_API(`movementReportPdf/${empName?.value}`);
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = downloadForm?.data?.filename;
        alink.setAttribute("target", "_blank");
        alink.download = "Movement Report.pdf";
        alink.click();
        setIsExportPdfLoading(false);
      }
    } catch (error) {
      setIsExportPdfLoading(false);
    }
  };

  if (loading || isLoading) {
    return <Spinner />;
  }

  return (
    <div className="grow bg-[#F7F6F4]">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Movement Report List</h4>
          <div className="search-block">
            <div className="flex gap-3">
              <div className="field-block dropdown-select-to-first">
                <label className="label-name">
                  Select Employee
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={employeeList}
                  value={empName}
                  onChange={(e) => setEmpName(e)}
                  name="employee_id"
                  className=""
                  isSearchable
                  placeholder="Please Select"
                />
              </div>
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                {isExportLoading ? (
                  <ButtonLoader isBtnDisable={isExportLoading} />
                ) : (
                  <button
                    className="add-new-button"
                    onClick={() => getExport()}
                  >
                    <span>Export</span>
                    <img
                      src={ExcelExport}
                      alt="export"
                      style={{ height: "25px" }}
                    />
                  </button>
                )}
              </div>
              <div className="add-new-button-block">
                {isExportPdfLoading ? (
                  <ButtonLoader isBtnDisable={isExportPdfLoading} />
                ) : (
                  <button
                    className="add-new-button"
                    onClick={() => getExportasPDF()}
                  >
                    <span>Export as PDF</span>
                    <img
                      src={PDFExport}
                      alt="export"
                      style={{ height: "25px" }}
                    />
                  </button>
                )}
              </div>
            </div>
          </div>

          <MovementReportTable
            list={filteredList}
            columns={columns}
            activeEdit={false}
            activeDelete={false}
            multiple={true}
            icon={More}
            showDetails={showDetails}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default MovementReportList;
