import React, { useState } from "react";
import TableCell from "@mui/material/TableCell";
import Action from "./Action";
import UseOutsideClick from "./OutsideClick";
function MoreOption({
  icon,
  editStatus,
  editList,
  row,
  multiple,
  deleteRow,
  paySlip,
  showDetails,
  handlePrintVoucher,
  isFoodVoucher,
  activeDelete,
  activeEdit,
  editPage,
  allowance,
  termination,
  terminateEmployee,
  enableMoreOption,
  foodAllowance,
  download,
  downloadEmployeeForm,
  isEnableIDCard,
  idCardPreviewPage,
}) {
  const [toggle, setToggle] = useState(false);
  const actionModel = UseOutsideClick(() => {
    setToggle(false);
  });
  return (
    <>
      <TableCell
        className="table-body"
        style={{ position: "relative" }}
        onClick={() => setToggle((prev) => !prev)}
      >
        <img src={icon} alt="" style={{ float: "right", cursor: "pointer" }} />
        <div ref={actionModel}>
          {toggle && (
            <Action
              editStatus={editStatus}
              editList={editList}
              row={row}
              multiplePage={multiple}
              deleteRow={deleteRow}
              showDetails={showDetails}
              handlePrintVoucher={handlePrintVoucher}
              isFoodVoucher={isFoodVoucher}
              activeDelete={activeDelete}
              activeEdit={activeEdit}
              paySlip={paySlip}
              editPage={editPage}
              allowance={allowance}
              termination={termination}
              terminateEmployee={terminateEmployee}
              enableMoreOption={enableMoreOption}
              foodAllowance={foodAllowance}
              download={download}
              downloadEmployeeForm={downloadEmployeeForm}
              isEnableIDCard={isEnableIDCard}
              idCardPreviewPage={idCardPreviewPage}
            />
          )}
        </div>
      </TableCell>
    </>
  );
}

export default MoreOption;
