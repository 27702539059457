import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import View from "../../../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../../Validation/POValidation";
import { POST_API } from "../../../../Services/api";
import ColorAdd from "../../../../Assets/Icons/ColorAdd.svg";

function CreateAgent() {
  const navigate = useNavigate();

  const [agentDetails, setAgentDetails] = useState({
    name: "",
    address: "",
    commission: "",
    description: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setAgentDetails(() => ({ ...agentDetails, [name]: value }));
  };

  const createAgent = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.Agent(agentDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const agent = await POST_API("/po/addPoAgent", agentDetails);
      if (agent?.data?.status === 200) {
        setButtonDisabled(false);
        alertify.success(agent?.data?.message);
        navigate("/master/po/agent");
      }
    } catch (error) {
      setButtonDisabled(false);
      for (const key in error?.response?.data?.data) {
        if (error?.response?.data?.data.hasOwnProperty(key)) {
          error?.response?.data?.data[key].forEach((value) => {
            alertify.error(value);
          });
        }
      }
    }
  };
  const resetForm = () => {
    setAgentDetails({
      name: "",
      commission: "",
      description: "",
      address: "",
    });
  };

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/po/agent" className="in-active">
              Agent List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Agent</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Add Agent</h4>

          {/***************Form************* **/}
          <form onSubmit={(e) => createAgent(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Agent name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter Agent Name"
                  value={agentDetails.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Agent Commission
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="commission"
                  placeholder="Enter Agent Commission"
                  value={agentDetails.commission}
                />
                {errors.commission ? (
                  <div className="validation-error-label">
                    {errors.commission}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Address</label>
                <textarea
                  className="input-field"
                  type="text"
                  name="address"
                  autoComplete="off"
                  rows={5}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Address"
                  value={agentDetails?.address}
                />
                {errors.address ? (
                  <div className="validation-error-label">{errors.address}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Description</label>
                <textarea
                  className="input-field"
                  type="text"
                  name="description"
                  autoComplete="off"
                  rows={5}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Description"
                  value={agentDetails?.description}
                />
                {errors.description ? (
                  <div className="validation-error-label">
                    {errors.description}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateAgent;
