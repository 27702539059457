import React, { useContext, useEffect, useState } from "react";
import Noimage from "../../../Assets/Icons/NoImage.svg";
import FileUpload from "../../../Assets/Icons/FileUpload.svg";
import PurchaseRequestTable from "../../DataTable/PurchaseOrder/PurchaseRequestTable";
import { GET_API, POST_API } from "../../../Services/api";
import Spinner from "../../Spinner";
import Select from "react-select";
import alertify from "alertifyjs";
import removeImage from "../../../Assets/Icons/Delete.svg";
import POValidator from "../../../Validation/POValidation";
import Joi from "joi";
import { PrivilegeContext } from "../../../PrivilegeContext";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";

function CreatePurchaseRequest() {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [loading6, setLoading6] = useState(false);
  const [loading7, setLoading7] = useState(false);
  const [loading8, setLoading8] = useState(false);
  const [loading9, setLoading9] = useState(false);
  const [purchaseRequestList, setPurchaseRequestList] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [agent, setAgent] = useState([]);
  const [transport, setTransport] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [styleList, setStyleList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [size, setSize] = useState([]);

  const [errors, setErrors] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();

  const [item, setItem] = useState({
    productName: "",
    brandName: "",
    styleName: "",
    po_brand_id: "",
    po_style_id: "",
    po_product_id: "",
    hsn_code: "",
    sleeve: "",
    design: "",
    uom: "",
    expected_date: "",
    product_images: [],
    size_data: [
      {
        attribute_id: "",
        quantity: "",
      },
    ],
  });
  const { access } = useContext(PrivilegeContext);
  const [addAccess, setAddAccess] = useState(false);
  const [newPurchaseRequest, setnewPurchaseRequest] = useState({
    po_purchase_request_id: "",
    po_warehouse_id: "",
    po_department_id: "",
    po_supplier_id: "",
    po_agent_id: "",
    po_transport_id: "",
    items: [],
  });

  useEffect(() => {
    getPurchaseRequstList();
    getWarehouseList();
    getDepartmentList();
    getSupplierList();
    getAgentList();
    getTransportList();
    getBrandList();
    getStyleList();
    getSize();
  }, []);

  useEffect(() => {
    getAccess();
  }, [access]);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "new_request") {
        setAddAccess(item?.add);
      }
      return "";
    });
  };

  const getPurchaseRequstList = async () => {
    setLoading9(true);
    try {
      const PO = await GET_API("/po/purchaseRequestDropdown");
      if (PO?.data?.status === 200) {
        const POListData = PO?.data?.data?.map((item) => {
          return { label: item?.pr_number, value: item?.id };
        });
        POListData.unshift({ label: "Select...", value: "" });
        setPurchaseRequestList(POListData);
        setLoading9(false);
      }
    } catch (error) {
      setLoading9(false);
      alertify?.error("Failed to get PO list...");
    }
  };

  const getWarehouseList = async () => {
    setLoading(true);
    try {
      const Warehouse = await GET_API("/po/warehouseDropdown");
      if (Warehouse?.data?.status === 200) {
        const warehouseListData = Warehouse?.data?.data?.map((item) => {
          return { label: item?.name, value: item?.id };
        });
        warehouseListData.unshift({ label: "Select...", value: "" });
        setWarehouseList(warehouseListData);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get warehouse list...");
    }
  };

  const getDepartmentList = async () => {
    setLoading2(true);
    try {
      const department = await GET_API("/po/poDepartmentDropdown");
      if (department?.data?.status === 200) {
        const departmentListData = department?.data?.data?.map((item) => {
          return { label: item?.name, value: item?.id };
        });
        departmentListData.unshift({ label: "Select...", value: "" });
        setDepartment(departmentListData);
        setLoading2(false);
      }
    } catch (error) {
      alertify?.error("Failed to get department list...");
      setLoading2(false);
    }
  };

  const getSupplierList = async () => {
    setLoading3(true);
    try {
      const suppliers = await GET_API("/po/suppliersDropdown");
      if (suppliers?.data?.status === 200) {
        const suppliersListData = suppliers?.data?.data?.map((item) => {
          return { label: item?.name, value: item?.id };
        });
        suppliersListData.unshift({ label: "Select...", value: "" });
        setSupplier(suppliersListData);
        setLoading3(false);
      }
    } catch (error) {
      setLoading3(false);
      alertify?.error("Failed to get suppliers list...");
    }
  };

  const getAgentList = async () => {
    setLoading4(true);
    try {
      const agent = await GET_API("/po/poAgentDropdown");
      if (agent?.data?.status === 200) {
        const agentListData = agent?.data?.data?.map((item) => {
          return { label: item?.name, value: item?.id };
        });
        agentListData.unshift({ label: "Select...", value: "" });
        setAgent(agentListData);
        setLoading4(false);
      }
    } catch (error) {
      alertify?.error("Failed to get agent list...");
      setLoading4(false);
    }
  };

  const getTransportList = async () => {
    setLoading5(true);
    try {
      const transport = await GET_API("/po/PoTransportDropdown");
      if (transport?.data?.status === 200) {
        const transportListData = transport?.data?.data?.map((item) => {
          return { label: item?.name, value: item?.id };
        });
        transportListData.unshift({ label: "Select...", value: "" });
        setTransport(transportListData);
        setLoading5(false);
      }
    } catch (error) {
      alertify?.error("Failed to get agent list...");
      setLoading5(false);
    }
  };

  const getBrandList = async () => {
    setLoading6(true);
    try {
      const brandList = await GET_API("po/poAttributesDropdown/brand");
      if (brandList?.data?.status === 200) {
        const brand = brandList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        brand.unshift({ value: "", label: "Select..." });
        setBrandList(brand);
        setLoading6(false);
      }
    } catch (error) {
      setLoading6(false);
      alertify?.error("Failed to get Brand list...");
    }
  };

  const getStyleList = async () => {
    setLoading7(true);
    try {
      const styleList = await GET_API("po/poAttributesDropdown/style");
      if (styleList?.data?.status === 200) {
        const style = styleList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        style.unshift({ value: "", label: "Select..." });
        setStyleList(style);
        setLoading7(false);
      }
    } catch (error) {
      setLoading7(false);
      alertify?.error("Failed to get Brand list...");
    }
  };

  const getSize = async () => {
    setLoading8(true);
    try {
      const size = await GET_API("po/poAttributesDropdown/size");
      if (size?.data?.status === 200) {
        const sizeList = size?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        sizeList.unshift({ value: "", label: "Select..." });
        setSize(sizeList);
        setLoading8(false);
      }
    } catch (error) {
      setLoading8(false);
      alertify?.error("Failed to get size list...");
    }
  };
  const handleChange = (e, name) => {
    setnewPurchaseRequest({ ...newPurchaseRequest, [name]: e?.value });
  };

  const handleItemChange = async (e, name, showName) => {
    const itemsList = { ...item };
    itemsList[name] = e?.value;
    itemsList[showName] = e?.label;
    setItem(itemsList);
    try {
      if (itemsList?.po_brand_id !== "" && itemsList?.po_style_id !== "") {
        setLoading(true);
        const attributIDArr = [itemsList?.po_brand_id, itemsList?.po_style_id];
        const getProductList = await POST_API("po/poProductAttrDropdown", {
          attribute: attributIDArr,
        });
        if (getProductList?.data?.status === 200) {
          const dropDownList = [];
          getProductList?.data?.data?.map((product) => {
            return dropDownList?.push({
              value: product?.id,
              label: product?.name,
            });
          });
          dropDownList.unshift({ value: "", label: "Select..." });
          setProductList(dropDownList);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get Product List...");
    }
  };
  const handleAttributeChange = (value, name, index) => {
    const arr = [...item?.size_data];
    arr[index][name] = value;
    setItem({ ...item, size_data: arr });
  };

  const getProductDetails = async (data, showName) => {
    const { value, label } = data;
    if (value === "") return;
    setLoading(true);
    try {
      const productDetails = await GET_API(`po/productById/${value}`);
      if (productDetails?.data?.status === 200) {
        setItem({ ...item, po_product_id: value });
        const product = productDetails?.data?.data?.product;
        setItem({
          ...item,
          po_product_id: value,
          hsn_code: product?.hsn_code,
          uom: product?.uom,
          [showName]: label,
        });
        setLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get product details");
      setLoading(false);
    }
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    if (e?.target?.files[0] !== undefined) {
      const { error } = schema.validate({
        size: e?.target?.files[0]?.size,
        type: e?.target?.files[0]?.type,
        length: files?.length,
      });

      if (error?.message) {
        setErrors({ product_images: error?.message });
        return;
      } else {
        setErrors({});
      }

      const filePromises = Array.from(files).map((file) => {
        return new Promise((resolve) => {
          const fileReader = new FileReader();

          fileReader.onload = () => {
            if (fileReader.readyState === 2) {
              resolve({
                imageURL: fileReader.result,
                size: file.size,
                type: file.type,
              });
            }
          };

          fileReader.readAsDataURL(file);
        });
      });

      Promise.all(filePromises).then((fileDataArray) => {
        const imageArr = [];
        fileDataArray?.map((image) => {
          return imageArr.push(image?.imageURL);
        });
        setItem({ ...item, product_images: imageArr });
      });
    }
  };

  const schema = Joi.object({
    size: Joi.number()
      .required()
      .max(5 * 1024 * 1024)
      .message("Image size must be less than 5MB."),

    type: Joi.string().valid("image/jpeg", "image/png").required().messages({
      "any.only": "Invalid image format. Only JPEG and PNG are allowed.",
    }),

    length: Joi.number().required().max(4).messages({
      "number.max": "Max 4 Image is allowed...",
    }),
  });

  const handleRemoveImage = (index) => {
    const arr = [...item?.product_images];
    const filterArr = arr.filter((item, i) => {
      return i !== index;
    });
    setItem({ ...item, product_images: filterArr });
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "product_name", label: "Product Name" },
    { id: "hsn", label: "HSN Number" },
    { id: "brand", label: "Brand" },
    { id: "style", label: "Style" },
    { id: "qauntity", label: "Quantity" },
    { id: "uom", label: "UOM" },
    { id: "created_at", label: "Created at" },
    { id: "delete", label: "" },
  ];

  const addProductToBulkList = () => {
    const isValid = POValidator.PurchaseRequestItem(item);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setErrors({});
    }

    const bulkData = [...newPurchaseRequest?.items];
    bulkData?.push(item);
    setnewPurchaseRequest({ ...newPurchaseRequest, items: bulkData });
    setItem({
      productName: "",
      brandName: "",
      styleName: "",
      po_brand_id: "",
      po_style_id: "",
      po_product_id: "",
      hsn_code: "",
      sleeve: "",
      design: "",
      uom: "",
      expected_date: "",
      product_images: [],
      size_data: [
        {
          attribute_id: "",
          quantity: "",
        },
      ],
    });
  };

  const removeProductFromTable = (index) => {
    const bulkDataArr = [...newPurchaseRequest?.items];
    const listArr = bulkDataArr.filter((item, i) => {
      return i !== index;
    });
    setnewPurchaseRequest({ ...newPurchaseRequest, items: listArr });
  };

  const addNewProducts = async () => {
    const isValid = POValidator.PurchaseRequest(newPurchaseRequest);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setErrors({});
    }
    try {
      setButtonDisabled(true);
      const addProducts = await POST_API(
        "/po/addPurchaseorderItems",
        newPurchaseRequest
      );
      if (addProducts?.data?.status === 200) {
        alertify?.success(addProducts?.data?.message);
        setButtonDisabled(false);
        resetState();
        navigate("/po/previousList");
      }
    } catch (error) {
      alertify?.error("Failed to add New Product...");
      setButtonDisabled(false);
    }
  };

  const resetState = () => {
    setItem({});
    setnewPurchaseRequest({
      po_warehouse_id: "",
      po_department_id: "",
      po_supplier_id: "",
      po_agent_id: "",
      po_transport_id: "",
      items: [],
    });
  };

  const addNewAttribute = () => {
    const arr = [...item?.size_data];
    arr.push({ attribute_id: "", quantity: " " });
    setItem({ ...item, size_data: arr });
  };

  const removeAttribute = (index) => {
    const arr = [...item?.size_data];
    const filterArr = arr.filter((item, i) => {
      return i !== index;
    });
    setItem({ ...item, size_data: filterArr });
  };

  if (
    loading ||
    loading2 ||
    loading3 ||
    loading4 ||
    loading5 ||
    loading6 ||
    loading7 ||
    loading8 ||
    loading9
  ) {
    return <Spinner />;
  }
  return (
    <div className="po-container">
      <div className="po-header">Purchase Order</div>
      <div className="po-contect-box">
        <div className="header-name">New Order</div>
        <form className="po-form">
          <div className="form-block-1">
            <div className="input-field-block">
              <span className="label-name">
                Purchase Request<span className="required-symbol">*</span>
              </span>
              <Select
                options={purchaseRequestList}
                name="po_purchase_request_id"
                isSearchable
                onChange={(e) => {
                  handleChange(e, "po_purchase_request_id");
                }}
                value={
                  newPurchaseRequest?.po_purchase_request_id
                    ? purchaseRequestList?.find(
                        (option) =>
                          option?.value ===
                          newPurchaseRequest?.po_purchase_request_id
                      )
                    : ""
                }
              />
              {errors.po_purchase_request_id ? (
                <div className="validation-error-label">
                  {errors.po_purchase_request_id}
                </div>
              ) : null}
            </div>

            <div className="input-field-block">
              <span className="label-name">
                Ware House <span className="required-symbol">*</span>
              </span>
              <Select
                options={warehouseList}
                name="po_warehouse_id"
                isSearchable
                onChange={(e) => {
                  handleChange(e, "po_warehouse_id");
                }}
                value={
                  newPurchaseRequest?.po_warehouse_id
                    ? warehouseList?.find(
                        (option) =>
                          option?.value === newPurchaseRequest?.po_warehouse_id
                      )
                    : ""
                }
              />
              {errors.po_warehouse_id ? (
                <div className="validation-error-label">
                  {errors.po_warehouse_id}
                </div>
              ) : null}
            </div>

            <div className="input-field-block">
              <span className="label-name">Department</span>
              <Select
                options={department}
                name="po_department_id"
                isSearchable
                onChange={(e) => {
                  handleChange(e, "po_department_id");
                }}
                value={
                  newPurchaseRequest?.po_department_id
                    ? department?.find(
                        (option) =>
                          option?.value === newPurchaseRequest?.po_department_id
                      )
                    : ""
                }
              />
              {errors.po_department_id ? (
                <div className="validation-error-label">
                  {errors.po_department_id}
                </div>
              ) : null}
            </div>

            <div className="input-field-block">
              <span className="label-name">
                Supplier <span className="required-symbol">*</span>
              </span>
              <Select
                options={supplier}
                value={
                  newPurchaseRequest?.po_supplier_id
                    ? supplier?.find(
                        (option) =>
                          option?.value === newPurchaseRequest?.po_supplier_id
                      )
                    : ""
                }
                name="po_supplier_id"
                isSearchable
                onChange={(e) => {
                  handleChange(e, "po_supplier_id");
                }}
              />
              {errors.po_supplier_id ? (
                <div className="validation-error-label">
                  {errors.po_supplier_id}
                </div>
              ) : null}
            </div>
            <div className="input-field-block">
              <span className="label-name">
                Agent <span className="required-symbol">*</span>
              </span>
              <Select
                options={agent}
                name="po_agent_id"
                isSearchable
                onChange={(e) => {
                  handleChange(e, "po_agent_id");
                }}
                value={
                  newPurchaseRequest?.po_agent_id
                    ? agent?.find(
                        (option) =>
                          option?.value === newPurchaseRequest?.po_agent_id
                      )
                    : ""
                }
              />
              {errors.po_agent_id ? (
                <div className="validation-error-label">
                  {errors.po_agent_id}
                </div>
              ) : null}
            </div>
            <div className="input-field-block">
              <span className="label-name">
                Transport <span className="required-symbol">*</span>
              </span>
              <Select
                options={transport}
                name="po_transport_id"
                isSearchable
                onChange={(e) => {
                  handleChange(e, "po_transport_id");
                }}
                value={
                  newPurchaseRequest?.po_transport_id
                    ? transport?.find(
                        (option) =>
                          option?.value === newPurchaseRequest?.po_transport_id
                      )
                    : ""
                }
              />
              {errors.po_transport_id ? (
                <div className="validation-error-label">
                  {errors.po_transport_id}
                </div>
              ) : null}
            </div>
          </div>
          <div className="form-block-2">
            <div className="header">Add Item</div>
            <div className="grid-block">
              <div className="input-field-block">
                <span className="label-name">
                  Brand <span className="required-symbol">*</span>
                </span>
                <Select
                  options={brandList}
                  name="po_brand_id"
                  isSearchable
                  onChange={(e) =>
                    handleItemChange(e, "po_brand_id", "brandName")
                  }
                  value={
                    item?.po_brand_id
                      ? brandList?.find(
                          (option) => option?.value === item?.po_brand_id
                        )
                      : ""
                  }
                />
                {errors.po_brand_id ? (
                  <div className="validation-error-label">
                    {errors.po_brand_id}
                  </div>
                ) : null}
              </div>
              <div className="input-field-block">
                <span className="label-name">
                  Style <span className="required-symbol">*</span>
                </span>

                <Select
                  options={styleList}
                  name="po_style_id"
                  isSearchable
                  onChange={(e) =>
                    handleItemChange(e, "po_style_id", "styleName")
                  }
                  value={
                    item?.po_style_id
                      ? styleList?.find(
                          (option) => option?.value === item?.po_style_id
                        )
                      : ""
                  }
                />
                {errors.po_style_id ? (
                  <div className="validation-error-label">
                    {errors.po_style_id}
                  </div>
                ) : null}
              </div>
              <div className="input-field-block">
                <span className="label-name">
                  Product <span className="required-symbol">*</span>
                </span>
                <Select
                  name="product_id"
                  isSearchable
                  options={productList}
                  onChange={(e) => getProductDetails(e, "productName")}
                  value={
                    item?.po_product_id
                      ? productList?.find(
                          (option) => option?.value === item?.po_product_id
                        )
                      : ""
                  }
                />
                {errors.po_product_id ? (
                  <div className="validation-error-label">
                    {errors.po_product_id}
                  </div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">HSN Code</span>
                <input
                  type="number"
                  name="hsn_code"
                  placeholder="Select product to get HSN code"
                  className="input-field"
                  value={item?.hsn_code ? item?.hsn_code : ""}
                />
                {errors.hsn_code ? (
                  <div className="validation-error-label">
                    {errors.hsn_code}
                  </div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">Sleeve</span>
                <Select options={[]} name="product_id" isSearchable />
                {errors.model_no ? (
                  <div className="validation-error-label">
                    {errors.model_no}
                  </div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">Design</span>
                <Select options={[]} name="product_id" isSearchable />

                {errors.brand_name ? (
                  <div className="validation-error-label">
                    {errors.brand_name}
                  </div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">UOM</span>
                <input
                  type="text"
                  className="input-field"
                  placeholder="Select product to get UOM"
                  name="style"
                  value={item?.uom ? item?.uom : ""}
                />
                {errors.style ? (
                  <div className="validation-error-label">{errors.style}</div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  Expected Date <span className="required-symbol">*</span>
                </span>
                <input
                  type="date"
                  name="size"
                  className="input-field"
                  placeholder="Select product to get Size"
                  value={item?.expected_date ? item?.expected_date : ""}
                  onChange={(e) => {
                    setItem({ ...item, expected_date: e?.target?.value });
                  }}
                />
                {errors.expected_date ? (
                  <div className="validation-error-label">
                    {errors.expected_date}
                  </div>
                ) : null}
              </div>

              <div className="">
                <div className="flex justify-start items-start">
                  <div className="w-[20%]">
                    <img
                      src={Noimage}
                      alt=""
                      className="h-[40px] w-[40px]"
                      multiple
                      name="files[]"
                    />
                  </div>

                  <div className="w-[80%] p-2 w-max h-max bg-[#FFF2EC] rounded flex justify-center items-center gap-1 cursor-pointer">
                    <label for="files" className="label-name cursor-pointer">
                      Upload
                    </label>
                    <input
                      className="w-0 cursor-pointer"
                      id="files"
                      style={{ visibility: "hidden" }}
                      type="file"
                      multiple
                      name="product_images[]"
                      onChange={(e) => handleImageChange(e)}
                      accept=".jpeg,.jpg,.png"
                    />
                    <img src={FileUpload} alt="" />
                  </div>
                </div>
                {errors.product_images ? (
                  <div className="validation-error-label">
                    {errors.product_images}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="p-2 gap-2 grid grid-cols-4">
              {item?.product_images?.map((image, i) => {
                return (
                  <div className="relative ">
                    <img
                      src={removeImage}
                      alt=""
                      className="h-[24px] p-0.5 bg-[#ccc] rounded cursor-pointer absolute left-[120px]	top-1.5"
                      onClick={() => {
                        handleRemoveImage(i);
                      }}
                    />
                    <img
                      src={image}
                      alt=""
                      className="h-[150px] w-[150px] rounded"
                    />
                  </div>
                );
              })}
            </div>

            <div className="flex flex-col gap-2">
              <div className="w-[70%] grid grid-cols-3">
                <span className="w-[50%] text-text-dark font-karla text-base">
                  Size
                </span>
                <span className="w-[50%] text-text-dark font-karla text-base">
                  Quantity
                </span>
              </div>
              {item?.size_data?.map((attr, index) => {
                return (
                  <div className="grid grid-cols-3 gap-2 items-center w-[70%] justify-between">
                    <Select
                      className="w-[200px]"
                      name="attribute_id"
                      isSearchable
                      options={size}
                      onChange={(e) => {
                        handleAttributeChange(e?.value, "attribute_id", index);
                      }}
                      value={
                        item?.size_data[index]?.attribute_id
                          ? size?.find(
                              (option) =>
                                option?.value ===
                                item?.size_data[index]?.attribute_id
                            )
                          : ""
                      }
                    />

                    <input
                      type="number"
                      className="input-field"
                      placeholder="Enter Quantity"
                      name="quantity"
                      autoComplete="off"
                      onChange={(e) => {
                        handleAttributeChange(
                          e?.target?.value,
                          "quantity",
                          index
                        );
                      }}
                      value={
                        item?.size_data[index]?.quantity
                          ? item?.size_data[index]?.quantity
                          : ""
                      }
                    />
                    {index === 0 && (
                      <button
                        type="button"
                        className="reset-button w-[130px] reset-button-label"
                        onClick={() => {
                          addNewAttribute();
                        }}
                      >
                        <AddCircleOutlineIcon />
                        Add Size
                      </button>
                    )}
                    {index > 0 && (
                      <span
                        className="font-karla font-normal font-sm text-[#F33838] cursor-pointer underline"
                        onClick={() => {
                          removeAttribute(index);
                        }}
                      >
                        Remove
                      </span>
                    )}
                  </div>
                );
              })}
              {errors.size_data ? (
                <div className="validation-error-label">{errors.size_data}</div>
              ) : null}
            </div>

            {addAccess && (
              <div className="submit-reset-button">
                <button type="button" className="reset-button">
                  <span className="reset-button-label">Reset</span>
                </button>
                <button
                  type="button"
                  className="submit-button"
                  onClick={() => addProductToBulkList()}
                  disabled={buttonDisabled}
                >
                  <span className="submit-button-label">Add Product</span>
                </button>
              </div>
            )}
          </div>
          <div className="pt-6">
            <PurchaseRequestTable
              columns={columns}
              list={newPurchaseRequest?.items}
              removeList={removeProductFromTable}
            />
          </div>

          {errors.items ? (
            <div className="validation-error-label mt-4">{errors.items}</div>
          ) : null}

          {addAccess && (
            <div className="submit-reset-button">
              <button type="button" className="reset-button">
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                type="button"
                className="submit-button"
                onClick={() => addNewProducts()}
                disabled={buttonDisabled}
              >
                <span className="submit-button-label">Submit</span>
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default CreatePurchaseRequest;
