import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import Save from "../../../Assets/Icons/Save.svg";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { POST_API, GET_API } from "../../../Services/api";
import Spinner from "../../Spinner";
import Footer from "../../Footer";

function EditSeasonalSalary() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const listData = state?.list;
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [employementTypeList, setEmployementTypeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editSeasonalSalaryDetails, setEditSeasonalSalaryDetails] = useState({
    id: listData?.id,
    employee_type_id: listData?.employee_type_id,
    from_date: listData?.from_date,
    to_date: listData?.to_date,
    per_day_salary: listData?.per_day_salary,
  });

  useEffect(() => {
    getEmploymentTypeList();
  }, []);

  const getEmploymentTypeList = async () => {
    try {
      setLoading(true);
      const empTypeList = await GET_API("/employeeTypeDropdown");
      if (empTypeList?.status === 200) {
        const empType = empTypeList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        setEmployementTypeList(empType);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error(error?.response?.data?.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setEditSeasonalSalaryDetails(() => ({
      ...editSeasonalSalaryDetails,
      [name]: value,
    }));
  };

  const handleSelectChange = (e, name) => {
    setEditSeasonalSalaryDetails(() => ({
      ...editSeasonalSalaryDetails,
      [name]: e?.value,
    }));
  };

  const saveSeasonalSalary = async (e) => {
    setButtonDisabled(true);
    const isValid = Validation.validateSeasonalSalary(
      editSeasonalSalaryDetails
    );
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    POST_API("/updateSeasonalSalaryDetails", editSeasonalSalaryDetails)
      .then(async function (data) {
        setButtonDisabled(false);
        alertify.success(data?.data?.message);
        navigate("/master/hrms/seasonalsalary");
      })
      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error.response.data.message);
      });
  };

  const resetForm = () => {
    setEditSeasonalSalaryDetails({
      id: listData?.id,
      employee_type_id: listData?.employee_type_id,
      from_date: listData?.from_date,
      to_date: listData?.to_date,
      per_day_salary: listData?.per_day_salary,
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/seasonalsalary" className="in-active">
              Seasonal Salary List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Edit Seasonal Salary</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Seasonal Salary</h4>

          <form className="form">
            <div className="field-block">
              <label className="label-name">
                Employee Type
                <span className="required-symbol">*</span>
              </label>
              <Select
                options={employementTypeList}
                value={employementTypeList?.find(
                  (option) =>
                    option?.value === editSeasonalSalaryDetails.employee_type_id
                )}
                onChange={(e) => handleSelectChange(e, "employee_type_id")}
                name="employee_type_id"
                className=""
                isSearchable
              />
              {errors.employee_type_id ? (
                <div className="validation-error-label">
                  {errors.employee_type_id}
                </div>
              ) : null}
            </div>
            <div className="field-block">
              <label className="label-name">
                From Date
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field "
                type="date"
                autoComplete="off"
                onChange={(e) => {
                  handleChange(e);
                }}
                name="from_date"
                placeholder="Enter From Date"
                value={editSeasonalSalaryDetails.from_date}
              />
              {errors.from_date ? (
                <div className="validation-error-label">{errors.from_date}</div>
              ) : null}
            </div>

            <div className="field-block">
              <label className="label-name">
                To Date
                <span className="required-symbol">*</span>
              </label>
              <input
                name="to_date"
                className="input-field"
                type="date"
                autoComplete="off"
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder="Enter To Date"
                value={editSeasonalSalaryDetails?.to_date}
              />
              {errors.to_date ? (
                <div className="validation-error-label">{errors.to_date}</div>
              ) : null}
            </div>

            <div className="field-block">
              <label className="label-name">
                Per Day Salary
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="number"
                name="per_day_salary"
                autoComplete="off"
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder="Enter Per Day Salary"
                value={editSeasonalSalaryDetails?.per_day_salary}
              />
              {errors.per_day_salary ? (
                <div className="validation-error-label">
                  {errors.per_day_salary}
                </div>
              ) : null}
            </div>
          </form>

          <div className="submit-reset-button" onClick={resetForm}>
            <button type="button" className="reset-button">
              <span className="reset-button-label">Reset</span>
            </button>
            <button
              type="submit"
              className="submit-button"
              onClick={() => saveSeasonalSalary()}
              disabled={buttonDisabled}
            >
              <span className="submit-button-label">Save</span>
              <img src={Save} alt="" />
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default EditSeasonalSalary;
