import React, { useState } from "react";
import { Link } from "react-router-dom";
import View from "../../../../Assets/Icons/View.svg";
import Save from "../../../../Assets/Icons/Save.svg";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../../Validation/POValidation";
import { PUT_API } from "../../../../Services/api";

function EditWarehouse() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const listData = state?.list;
  const [errors, seterrors] = useState({});
  const [editWarehouseDetails, setEditWarehouseDetails] = useState({
    id: listData?.id,
    code: listData?.code,
    name: listData?.name,
    description: listData?.description,
    landline_no: listData?.landline_no,
    address: listData?.address,
    contact_person_name: listData?.contact_person_name,
    contact_person_mobile: listData?.contact_person_mobile,
    contact_person_email: listData?.contact_person_email,
  });
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setEditWarehouseDetails(() => ({ ...editWarehouseDetails, [name]: value }));
  };

  const updateWarehouse = async (e) => {
    e?.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.Warehouse(editWarehouseDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const updateWarehouse = await PUT_API(
        "/po/updateWarehouse",
        editWarehouseDetails
      );
      if (updateWarehouse?.data?.status === 200) {
        setButtonDisabled(false);
        alertify.success(updateWarehouse?.data?.message);
        navigate("/po/master/warehouse");
      } else {
        alertify.error(updateWarehouse?.data?.message);
        setButtonDisabled(false);
      }
    } catch (e) {
      setButtonDisabled(false);
      for (const key in e?.response?.data?.data) {
        if (e?.response?.data?.data.hasOwnProperty(key)) {
          e?.response?.data?.data[key].forEach((value) => {
            alertify.error(value);
          });
        }
      }
    }
  };

  const resetForm = () => {
    setEditWarehouseDetails({
      id: listData?.id,
      code: listData?.code,
      name: listData?.name,
      description: listData?.description,
      landline_no: listData?.landline_no,
      address: listData?.address,
      contact_person_name: listData?.contact_person_name,
      contact_person_mobile: listData?.contact_person_mobile,
      contact_person_email: listData?.contact_person_email,
    });
  };

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Purchase Order</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Warehouse</h4>
          <div className="search-block">
            <Link to="/po/master/warehouse" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>

          {/***************Form************* **/}

          <form onSubmit={(e) => updateWarehouse(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Warehouse Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter Warehouse Name"
                  value={editWarehouseDetails.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Code
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="code"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Code"
                  value={editWarehouseDetails?.code}
                />
                {errors.code ? (
                  <div className="validation-error-label">{errors.code}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Contact Person
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="contact_person_name"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Contact Person Name"
                  value={editWarehouseDetails?.contact_person_name}
                />
                {errors.contact_person_name ? (
                  <div className="validation-error-label">
                    {errors.contact_person_name}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">LandLine Number</label>
                <input
                  className="input-field"
                  type="text"
                  name="landline_no"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter LandLine Number"
                  value={editWarehouseDetails?.landline_no}
                />
                {errors.landline_no ? (
                  <div className="validation-error-label">
                    {errors.landline_no}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Contact Number
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="contact_person_mobile"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Contact Number"
                  value={editWarehouseDetails?.contact_person_mobile}
                />
                {errors.contact_person_mobile ? (
                  <div className="validation-error-label">
                    {errors.contact_person_mobile}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Contact Email
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="contact_person_email"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Email"
                  value={editWarehouseDetails?.contact_person_email}
                />
                {errors.contact_person_email ? (
                  <div className="validation-error-label">
                    {errors.contact_person_email}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Address</label>
                <textarea
                  className="input-field"
                  type="text"
                  name="address"
                  autoComplete="off"
                  rows={5}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Address"
                  value={editWarehouseDetails?.address}
                />
                {errors.address ? (
                  <div className="validation-error-label">{errors.address}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Description</label>
                <textarea
                  className="input-field"
                  type="text"
                  name="description"
                  autoComplete="off"
                  rows={5}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Description"
                  value={editWarehouseDetails?.description}
                />
                {errors.description ? (
                  <div className="validation-error-label">
                    {errors.description}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Save</span>
                <img src={Save} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditWarehouse;
