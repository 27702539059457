import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { DELETE_API, GET_API, POST_API, PUT_API } from "../../../Services/api";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Edit from "../../../Assets/Icons/Edit.svg";
import CategoriesTabel from "../../DataTable/PurchaseOrder/CategoriesTable";
import POValidation from "../../../Validation/POValidation";

function LessMaster() {
  const [editStatus, setEditStatus] = useState(false);
  const [lessList, setLessList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errors, setErrors] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");
  const [lessDetails, setLessDetails] = useState({
    name: "",
    description: "",
  });

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "name", label: "Name" },
    { id: "description", label: "Description" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getLessList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const getLessList = async (page, size, search) => {
    setLoading(true);

    try {
      const lessList = await GET_API(
        `/po/poLess?page=${page}&size=${size}&search=${search}`
      );
      if (lessList?.data?.status === 200) {
        setLastPage(lessList?.data?.data?.last_page);
        setLessList(lessList?.data?.data?.data);
        setFilteredList(lessList?.data?.data?.data);
        setLoading(false);
      }
      if (
        lessList?.data?.data?.current_page > lessList?.data?.data?.last_page
      ) {
        isPageNotExit();
        return;
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const isPageNotExit = () => {
    setCurrentPage(1);
    getLessList(1, pageSize, searchKey);
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete List..?",
        async function () {
          try {
          } catch (error) {}
          if (row_id) {
            const deleteRow = await DELETE_API(`/po/deletePoLess/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getLessList(currentPage, pageSize, searchKey);
              return;
            }
            alertify.error("Failed To Delete List");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const handleList = async (e, type) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = POValidation.Categories(lessDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setErrors({});
    }
    try {
      const url =
        type === "add"
          ? POST_API("/po/addPoLess", lessDetails)
          : PUT_API("/po/updatePoLess", lessDetails);
      const crateList = await url;
      if (crateList?.data?.status === 200) {
        setButtonDisabled(false);
        setEditStatus(false);
        setLessDetails({ name: "", description: "" });
        getLessList(currentPage, pageSize, searchKey);
        alertify.success(crateList?.data?.message);
        return;
      }
    } catch (error) {
      alertify.error("Failed to create List...");
      alertify.error(error?.response?.data?.data?.name.toString());
      setButtonDisabled(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  const resetForm = () => {
    setLessDetails({ name: "", description: "" });
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Less</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => setSearchKey(e?.target?.value)}
                style={{ outline: "none", border: "none" }}
                value={searchKey}
              />

              <img
                src={Search}
                alt=""
                className="cursor-pointer"
                onClick={() => {
                  getLessList(currentPage, pageSize, searchKey);
                }}
              />
            </div>
          </div>
          <div className="add-list-container">
            <div className="input-block">
              <label className="add-new-input-label">
                Name <span className="required-symbol">*</span>
              </label>

              <input
                type="text"
                name="name"
                className="input-field"
                placeholder="Enter Name"
                onChange={(e) => {
                  setLessDetails({
                    ...lessDetails,
                    [e?.target?.name]: e?.target?.value,
                  });
                }}
                autoComplete="off"
                value={lessDetails.name}
              />
              {errors.name ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.name}
                </div>
              ) : null}
            </div>
            <div className="input-block">
              <label className="add-new-input-label">Description</label>
              <textarea
                name="description"
                className="input-field"
                placeholder="Enter Description"
                onChange={(e) => {
                  setLessDetails({
                    ...lessDetails,
                    [e?.target?.name]: e?.target?.value,
                  });
                }}
                value={lessDetails.description}
                autoComplete="off"
                rows={5}
              />
              {errors.description ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.description}
                </div>
              ) : null}
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                {editStatus ? (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      handleList(e, "edit");
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Save</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                ) : (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      handleList(e, "add");
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Add New</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                )}
              </div>
              <Link className="edit-button" onClick={() => resetForm()}>
                <span className="button-label">Cancel</span>
                <img src={Edit} alt="add" />
              </Link>
            </div>
          </div>

          {/**************** * Table ********************/}

          <CategoriesTabel
            list={filteredList}
            columns={columns}
            icon={More}
            editStatus={setEditStatus}
            editList={setLessDetails}
            multiple={false}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}
export default LessMaster;
