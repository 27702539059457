import React from "react";
import { Link, useLocation } from "react-router-dom";

function LeaveReportDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const father_name = listDetails?.fathers_name
    ? listDetails?.fathers_name
    : "";
  const Details = [
    {
      label: "Employee Name",
      value: listDetails?.name + " " + father_name,
    },
    {
      label: "Employee Code",
      value: listDetails?.emp_code,
    },

    {
      label: "Department Name",
      value: listDetails?.DepartmentName,
    },
    {
      label: "Leave ID",
      value: listDetails?.LeaveId ? listDetails?.LeaveId : "-",
    },
    { label: "Designation Name", value: listDetails?.DesignationName },
    { label: "Branch Name", value: listDetails?.branchName },
    { label: "Employee Type Name", value: listDetails?.EmployeeTypeName },
    { label: "Gender", value: listDetails?.Gender },
    { label: "From Date", value: listDetails?.from_date },
    { label: "To Date", value: listDetails?.to_date },
    { label: "No Of Days", value: listDetails?.no_of_days },
    { label: "Leave Type", value: listDetails?.leave_type_name },
    { label: "Approved by", value: listDetails?.approved_by_name },
    { label: "Remarks", value: listDetails?.remarks },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/report/leave" className="in-active">
              Leave Report
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Leave Report Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Employee Leave Report Details</h4>

          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LeaveReportDetails;
