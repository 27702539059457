import React from "react";
import { Route } from "react-router-dom";
import BranchList from "../EnterpriseMaster/Branch/BranchList";
import CreateBranch from "../EnterpriseMaster/Branch/CreateBranch";
import EditBranch from "../EnterpriseMaster/Branch/EditBranch";
import BranchDetails from "../EnterpriseMaster/Branch/BranchDetails";

import Biometrics from "../EnterpriseMaster/Biometrics/BiometricsList";

import Floor from "../EnterpriseMaster/Floor/Floor";

function EmployeeMastersRoutes() {
  return (
    <>
      {/****Branch Routes */}
      <Route path="branch" element={<BranchList />}></Route>
      <Route path="branch/add" element={<CreateBranch />}></Route>
      <Route path="branch/edit" element={<EditBranch />}></Route>
      <Route path="branch/details" element={<BranchDetails />}></Route>

      {/****Bio metrics */}
      <Route path="biometrics" element={<Biometrics />}></Route>

      {/****Floor */}
      <Route path="floor" element={<Floor />}></Route>
    </>
  );
}

export default EmployeeMastersRoutes;
