import React, { useEffect, useState } from "react";
import Arrow from "../../../Assets/Icons/RightArrow.svg";
import PrDetailsTable from "../../../Components/DataTable/PurchaseOrder/PrDetailsTable";
import ItemDetails from "../../../Components/PurchaseOrder/PR/ItemDetails";
import Spinner from "../../Spinner";
import { GET_API, POST_API } from "../../../Services/api";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";

function PRDetails() {
  const { state } = useLocation();
  const id = state?.list;
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({});
  const [items, setItems] = useState([]);
  const [summaryDetails, setSummaryDetails] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const columns = [
    { id: "product_name", label: "Product Name" },
    { id: "hsn", label: "HSN Number" },
    { id: "quantity", label: "Quantity" },
    { id: "uom", label: "UOM" },
    { id: "created_at", label: "Created at" },
  ];

  useEffect(() => {
    getProductDetails();
  }, []);

  const getProductDetails = async () => {
    setLoading(true);
    try {
      const prDetails = await GET_API(`po/purchaseRequestById/${id}`);
      if (prDetails?.data?.status === 200) {
        const product = prDetails?.data?.data?.product;
        const items = prDetails?.data?.data?.items;
        const summaryDetails = prDetails?.data?.data?.summaryDetails;
        setSummaryDetails(summaryDetails);
        setProduct(product);
        setItems(items);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get PR Details...");
    }
  };

  const handleStatus = async (status) => {
    setButtonDisabled(true);
    try {
      const updateStatus = await POST_API(
        `po/purchaseRequestApproval/${id}/${status}`
      );
      if (updateStatus?.data?.status === 200) {
        alertify?.success(updateStatus?.data?.message);
        getProductDetails();
        navigate("/po/pr/previousList");
        setButtonDisabled(false);
      }
    } catch (error) {
      alertify?.error("Failed to update status");
      setButtonDisabled(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="po-container">
      <div className="po-header">Purchase Request</div>
      <div className="po-contect-box">
        <div className="header-name flex justify-between">
          <div>PR Details</div>
          {product?.status === 1 && (
            <div className="flex gap-2">
              <button
                type="button"
                disabled={buttonDisabled}
                className="reset-button"
                onClick={() => {
                  handleStatus("rejected");
                }}
              >
                <span className="reset-button-label">Reject</span>
              </button>
              <button
                type="submit"
                className="submit-button"
                onClick={() => {
                  handleStatus("approved");
                }}
                disabled={buttonDisabled}
              >
                <span className="submit-button-label">Approve</span>
              </button>
            </div>
          )}
        </div>
        <div className="po-pr-details-content-block">
          <div className="">
            <div className="details-header-block">
              <span className="details-header">PR Summary</span>
              <img src={Arrow} alt="" className="h-[20px] w-[20px] rotate-90" />
            </div>
            <div className="pr-details-toggle ">
              <div className="details-block">
                <div className="label">PR NO:</div>
                <div className="value">{product?.pr_number}</div>
                <span
                  className={`pr-details-approved-card ${
                    product?.status === 1
                      ? "bg-[#ed9302f2]"
                      : product?.status === 2
                      ? "bg-[#11b76b]"
                      : product?.status === 3
                      ? "bg-[#0f40ff]"
                      : "bg-[#d60808]"
                  }`}
                >
                  {product?.status === 1
                    ? "Pending"
                    : product?.status === 2
                    ? "Approved"
                    : product?.status === 3
                    ? "Converted as PO"
                    : "Rejected"}
                </span>
              </div>

              <div className="details-block justify-end">
                <div className="label">Date</div>
                <div className="value">{product?.date}</div>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <PrDetailsTable columns={columns} list={summaryDetails} />
          </div>
        </div>
        <div className="po-pr-details-content-block">
          <div className="header-name">Item Details</div>
          <div className="flex flex-col gap-2">
            {items?.map((detail) => {
              return <ItemDetails detail={detail} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PRDetails;
