import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "./Pagination";

export default function DataTable({
  list,
  setSelectedRows,
  columns,
  setPageNo,
  setPageSize,
  lastPage,
  currentPage,
  pageSize,
}) {
  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };
  const heightStyle =
    list.length === 0 ? { height: 320, width: "100%" } : { width: "100%" };

  return (
    <div style={heightStyle}>
      <DataGrid
        rows={list}
        columns={columns}
        sx={{
          ".MuiDataGrid-footerContainer": {
            display: "none",
          },
        }}
        checkboxSelection
        onRowSelectionModelChange={handleSelectionModelChange}
      />
      <div className="bg-[#fff]">
        <Pagination
          setPageNo={setPageNo}
          setPageSize={setPageSize}
          lastPage={lastPage}
          currentPage={currentPage}
          pageSize={pageSize}
          shape="rounded"
        />
      </div>
    </div>
  );
}
